import Vue from "vue";
import VueRouter from "vue-router";
import Trainingsplan from "../views/Trainingsplan.vue";

import Login from "../components/Login.vue";

import Garmin from "../components/Garmin.vue";
import Register from "../components/Register.vue";
import David from "../components/David.vue";
import Matschfuss from "../components/Matschfuss.vue";
import IronmanFrankfurt from "../components/IronmanFrankfurt.vue";

import ConfirmMail from "../components/ConfirmMail.vue";

import CompactCalendar from "@/components/CompactCalendar.vue";
import CalendarVorschau from "@/components/CalendarVorschau.vue";
import Kraftraum from "../components/Kraftraum.vue";
import KraftraumNew from "../components/kraftraumNew/Kraftraum.vue";

import Esszimmer from "../components/Esszimmer.vue";

import Foodplan from "../components/Foodplan.vue";
import OauthLogin from "../components/oauth/Login.vue";

import Restart from "../components/Restart.vue";

import CreateRezept from "../components/CreateRezept.vue";
import ShowRezept from "../components/ShowRezept.vue";
import Oauth from "../components/Oauth.vue";

import WorkoutAnalysis from "../components/workout/WorkoutAnalysis.vue";

import WorkoutDetails from "../components/WorkoutDetails.vue";
import Auswertung from "../components/Auswertung.vue";
import Rekordleistungen from "../components/Rekordleistungen.vue";
//import WorkoutLibrary from '../components/WorkoutLibrary.vue'
import PasswortReset from "../components/PasswortReset.vue";
import Upload from "../components/Upload.vue";
import Trainingsplaene from "../components/Trainingsplaene.vue";

import Profile from "../components/Profile.vue";
import Introduction from "../components/Introduction.vue";

import Vorlagen from "../components/Vorlagen.vue";
import EffortViewer from "../components/EffortViewer.vue";
import CreateTrainingsplan from "../components/CreateTrainingsplan.vue";
import CreateTrainingsplanCalendar from "../components/CreateTrainingsplanCalendar.vue";
//import CreateTrainingsplanCalendarNew from '../components/CreateTrainingsplanCalendarNew.vue'

import Exercise from "../components/kraftraum/Exercise.vue";
// import RadraumExercise from "../components/radraum/RadraumExercise.vue";
import RadraumExerciseNew from "../components/radraum/RadraumExerciseNew.vue";

// import RadraumWorkoutPicker from "../components/radraum/RadraumWorkoutPicker.vue";
import RadraumWorkoutPickerNew from "../components/radraum/RadraumWorkoutPickerNew.vue";

import NewWorkout from "../components/workout/NewWorkout.vue";
import Media from "../components/Media.vue";
import ShowMedia from "../components/ShowMedia.vue";
import CreateMedia from "../components/CreateMedia.vue";

import ShowVideo from "../components/video/ShowVideo.vue";
import CreateVideo from "../components/video/CreateVideo.vue";
import Video from "../components/video/Video.vue";

import StopMail from "../components/StopMail.vue";

import Zielgruppe from "../components/Zielgruppe.vue";

import Checkout from "../components/Checkout.vue";
import SocialShare from "../components/SocialShare.vue";

import Impressum from "../components/Impressum.vue";
import Glossar from "../components/Glossar.vue";

import FAQ from "../components/FAQ.vue";
import DeleteAccount from "../components/DeleteAccount.vue";

import AskTheCoach from "../components/AskTheCoach.vue";

import Dashboard from "../components/Dashboard.vue";
import AICoach from "../components/AICoach.vue";

import TakePicture from "../components/fooddiary/TakePicture.vue";
import FoodDiary from "../components/fooddiary/FoodDiary.vue";
import FoodTracker from "../components/fooddiary/FoodTracker.vue";
import Statistics from "../components/fooddiary/Statistics.vue";
import FoodStatistics from "../components/fooddiary/StatisticsPage.vue";
import Kuendigen from "../components/Kuendigen.vue";

import { App } from "@capacitor/app";

import store from "../store/store";

import MetabolicProfile from '@/components/MetabolicProfile.vue'

import EffortList from '@/components/EffortList.vue'

import WorkoutAnalysisView from '@/components/WorkoutAnalysisView.vue'

import { Capacitor } from '@capacitor/core';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Start",
    component: Dashboard,
  },
  {
    path: "/efforts/:id",
    name: "effort-viewer",
    component: EffortViewer,
    props: true
  },
  {
    path: "/training/aicoach",
    name: "AICoach",
    component: AICoach,
  },

  {
    path: "/socialshare",
    name: "SocialShare",
    component: SocialShare,
  },
  {
    path: "/workout/analysis",
    name: "WorkoutAnalysis",
    component: WorkoutAnalysis,
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: Impressum,
    meta: { authNotRequired: true },
  },
  {
    path: "/glossar",
    name: "Glossar",
    component: Glossar,
    meta: { authNotRequired: true },
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
  },

  {
    path: "/zielgruppe",
    name: "Zielgruppe",
    component: Zielgruppe,
    meta: { authNotRequired: true },
  },

  {
    path: "/food/picture",
    name: "TakePicture",
    component: TakePicture,
  },

  {
    path: "/esszimmer/foodlog",
    name: "FoodDiary",
    component: FoodDiary,
  },

  {
    path: "/kraftraum",
    name: "KraftraumNew",
    component: KraftraumNew,
  },

  {
    path: "/esszimmer/tracker",
    name: "FoodTracker",
    component: FoodTracker,
  },

  {
    path: "/esszimmer/statistics",
    name: "Statistics",
    component: Statistics,
  },

  {
    path: "/cancel",
    name: "Cancel",
    meta: { authNotRequired: true },

    component: Kuendigen,
  },

  {
    path: "/stopmail/:id",
    name: "StopMail",
    component: StopMail,
    meta: { authNotRequired: true },
    props(route) {
      return {
        id: route.params.id,
      };
    },
  },

  {
    path: "/delete_acccount",
    name: "DeleteAccount",
    component: DeleteAccount,
    meta: { authNotRequired: true },
  },

  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
    meta: { authNotRequired: true },
  },
  {
    path: "/training/FragDenCoach",
    name: "AskTheCoach",
    component: AskTheCoach,
    meta: { authNotRequired: true },
  },

  {
    path: "/training/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },

  {
    path: "/training/kalender",
    name: "Kalender",
    component: Trainingsplan,
  },

  {
    path: "/training/vorlagen",
    name: "Vorlagen",
    component: Vorlagen,
  },

  {
    path: '/esszimmer/statistiken',
    name: 'FoodStatistics',
    component: FoodStatistics
  },

  {
    path: "/training/vorschau/:id",
    name: "CalendarVorschau",
    component: CalendarVorschau,
    props(route) {
      return {
        id: route.params.id,
      };
    },
  },

  {
    path: "/training/kompakt",
    name: "KalenderCompact",
    component: CompactCalendar,
  },

  {
    path: "/training/liste",
    name: "Trainingsplaene",
    component: Trainingsplaene,
  },

  {
    path: "/training/upload",
    name: "Upload",
    component: Upload,
  },

  {
    path: "/training/wissen",
    name: "Wissen",
    component: Media,
  },

  {
    path: "/training/wissen/create",
    name: "CreateWissen",
    component: CreateMedia,
  },

  {
    path: "/training/wissen/:id/update",
    name: "UpdatewWissen",
    component: CreateMedia,
    props(route) {
      return {
        id: route.params.id,
      };
    },
  },

  {
    path: "/training/wissen/:id",
    name: "ShowWissen",
    component: ShowMedia,
    props(route) {
      return {
        id: route.params.id,
      };
    },
  },

  {
    path: "/kraftraum/alt",
    name: "Video",
    component: Video,
  },

  {
    path: "/kraftraum/create",
    name: "CreateVideo",
    component: CreateVideo,
  },

  {
    path: "/kraftraum/:id/update",
    name: "UpdateVideo",
    component: CreateVideo,
    props(route) {
      return {
        id: route.params.id,
      };
    },
  },

  {
    path: "/kraftraum/:id",
    name: "ShowVideo",
    component: ShowVideo,
    props(route) {
      return {
        id: route.params.id,
      };
    },
  },

  {
    path: "/createtrainingsplan",
    name: "CreateTrainingsplan",
    component: CreateTrainingsplan,
  },

  /*
  {
    path: '/createtrainingsplannew/:id',
    name: 'CreateTrainingsplanCalendarNew',
    component: CreateTrainingsplanCalendarNew,
    props(route) {
      return {
        id: route.params.id
      }
    }
  },

  */
  {
    path: "/createtrainingsplan/:id",
    name: "CreateTrainingsplanCalendar",
    component: CreateTrainingsplanCalendar,
    props(route) {
      return {
        id: route.params.id,
      };
    },
  },

  {
    path: "/passwortreset",
    name: "PasswortReset",
    component: PasswortReset,
    meta: { authNotRequired: true },
  },

  {
    path: "/passwortreset/:token",
    name: "PasswortResetToken",
    component: PasswortReset,
    meta: { authNotRequired: true },
    props(route) {
      return {
        token: route.params.token,
      };
    },
  },

  {
    path: '/admin/landing-pages',
    name: 'LandingPageManager',
    component: () => import('@/components/admin/LandingPageManager.vue'),
  },

  {
    path: '/landing-pages/:id/stats',
    name: 'LandingPageStats',
    component: () => import('@/components/admin/LandingPageStatsView.vue'),
    props: true
  },

  {
    path: '/r/:slug',
    name: 'LandingPage',
    component: () => import('@/components/Landingpage.vue'),
    meta: { authNotRequired: true },
  },

  {
    path: "/training",
    name: "Trainingsplan",
    component: Trainingsplan,
  },
  /*
    {
      path: '/workoutlibrary',
      name: 'Workoutlibrary',
      component: WorkoutLibrary
    },
  */
  {
    path: "/kraftraum",
    name: "Kraftraum",
    component: Kraftraum,
  },

  {
    path: "/esszimmer",
    name: "Esszimmer",
    component: Esszimmer,
  },

  {
    path: "/esszimmer/plan",
    name: "Foodplan",
    component: Foodplan,
  },

  {
    path: "/profil",
    name: "Profil",
    component: Profile,
  },

  {
    path: "/einfuehrung",
    name: "Introduction",
    component: Introduction,
  },

  {
    path: "/esszimmer/rezept/neu",
    name: "CreateRezept",
    component: CreateRezept,
  },

  {
    path: "/esszimmer/rezept/:id",
    name: "ShowRezept",
    component: ShowRezept,
    props(route) {
      return {
        id: route.params.id,
      };
    },
  },

  {
    path: "/oauth/:id/:state",
    name: "Oauth",
    component: Oauth,
    meta: { authNotRequired: true },

    props(route) {
      return {
        id: route.params.id,
        state: route.params.state,
      };
    },
  },

  {
    path: "/esszimmer/rezept/:id/edit",
    name: "ShowRezeptEdit",
    component: CreateRezept,
    props(route) {
      return {
        id: route.params.id,
      };
    },
  },

  {
    path: "/esszimmer/rezept/:id/:typ",
    name: "ShowRezeptType",
    component: ShowRezept,
    props(route) {
      return {
        id: route.params.id,
        typ: route.params.typ,
      };
    },
  },

  {
    path: "/kraftraum/exercise/:workoutIds",
    name: "Exercise",
    component: Exercise,
    props(route) {
      return {
        strengthWorkouts: route.params.workoutIds.split(",").map((id) => parseInt(id)),
      };
    },
  },

  {
    path: "/radraum",
    name: "RadraumWorkoutPicker",
    component: RadraumWorkoutPickerNew,
  },

  {
    path: "/radraum/new",
    name: "RadraumWorkoutPickerNew",
    component: RadraumWorkoutPickerNew,
  },

  {
    path: "/restart/:id",
    name: "Restart",
    component: Restart,
    meta: { authNotRequired: true },

    props(route) {
      return {
        id: route.params.id,
      };
    },
  },

  {
    path: "/radraum/new/:workout_id",
    name: "RadraumExerciseNew",
    component: RadraumExerciseNew,
    props(route) {
      return {
        workout_id: route.params.workout_id,
      };
    },
  },
  

  {
    path: "/radraum/:workout_id",
    name: "RadraumExercise",
    component: RadraumExerciseNew,
    props(route) {
      return {
        workout_id: route.params.workout_id,
      };
    },
  },

  {
    path: "/auswertung",
    name: "Auswertung",
    component: Auswertung,
  },

  {
    path: "/oauth/login",
    name: "OauthLogin",
    component: OauthLogin,
  },

  {
    path: "/rekordleistungen",
    name: "Rekordleistungen",
    component: Rekordleistungen,
  },

  {
    path: "/auswertung/workout/details/:workout_id",
    name: "WorkoutDetails",
    component: WorkoutDetails,
    props(route) {
      return {
        workout_id: route.params.workout_id,
      };
    },
  },
  {
    path: "/workout/new/:day_date",
    name: "NewWorkout",
    component: NewWorkout,
    props(route) {
      return {
        dayDate: parseInt(route.params.day_date),
      };
    },
  },

  {
    path: "/auswertung/:page",
    name: "AuswertungPage",
    component: Auswertung,
  },

  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { authNotRequired: true },
  },

  {
    path: "/register",
    name: "register",
    component: Register,
    meta: { authNotRequired: true },
  },

  {
    path: "/david",
    name: "David",
    component: David,
    meta: { authNotRequired: true },
  },

  {
    path: "/matschfuss",
    name: "Matschfuss",
    component: Matschfuss,
    meta: { authNotRequired: true },
  },

  {
    path: "/ironman-frankfurt",
    name: "IronmanFrankfurt",
    component: IronmanFrankfurt,
    meta: { authNotRequired: true },
  },

  {
    path: "/confirm_mail/:token",
    name: "confirm_mail",
    component: ConfirmMail,
    meta: { authNotRequired: true },
    props(route) {
      return {
        token: route.params.token,
      };
    },
  },
  {
    path: "/garmin/success",
    name: "garminSuccess",
    component: Garmin,
  },
  {
    path: "/garmin",
    name: "garmin",
    component: Garmin,
  },
  {
    path: '/metabolic-profile',
    name: 'MetabolicProfile',
    component: MetabolicProfile,
    meta: { requiresAuth: true }
  },
  {
    path: '/efforts',
    name: 'efforts',
    component: EffortList,
    props: true
  },

  {
    path: "/kraftraum/workout/:id",
    name: "KraftraumWorkout",
    component: KraftraumNew,
    props(route) {
      return {
        workoutId: route.params.id
      };
    }
  },

  {
    path: '/workout-analyses',
    name: 'WorkoutAnalyses',
    component: WorkoutAnalysisView
  },

];

const router = new VueRouter({
  routes,
  /*
  scrollBehavior() {
    return { x: 0, y: 0 };
  }*/
});

const handleAppUrlOpen = (urlOpen) => {
  // Example url: https://pushinglimits.club/#/tabs/tabs2
  // slug = /#/tabs/tabs2
  const slug = urlOpen.url.split("pushinglimits.club").pop();

  // We only push to the route if there is a slug present
  if (slug) {
    router.push({
      path: slug,
    });
  }
};

const initializeDeepLinking = () => {
  if (Capacitor.isNativePlatform()) {
    App.addListener('appUrlOpen', handleAppUrlOpen);
  }
};

initializeDeepLinking();

router.beforeEach((to, from, next) => {
  if (
    to.name != "Kalender" &&
    (from.name != "Auswertung" || to.name != "Auswertung") &&
    (from.name != "Auswertung" || to.name != "AuswertungPage") &&
    (from.name != "AuswertungPage" || to.name != "Auswertung") &&
    (from.name != "AuswertungPage" || to.name != "AuswertungPage")
  ) {
    window.scroll(0, 0);
  }

  // Remove scroll locks
  document.body.classList.remove("locked");
  document.body.classList.remove("scroll-bounce-lock");

  // Check if route is public
  const isPublicRoute = to.matched.some(record => record.meta.authNotRequired);

  if (isPublicRoute) {
    next();
  } else {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/register");
  }
});

export default router;
