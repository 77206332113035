<template>
  <div>
    <div class="row" v-if="!user">
      <div class="col-lg-12 top-spacer-100">
        <div class="spinner-border text-primary" role="status"></div>
      </div>
    </div>

    <div class="row" v-if="user">
      <div class="col-lg-3"></div>

      <div class="col-lg-6" v-if="step == 0">
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-12">
                <h2 class="top-spacer-50">Kurze Einführung in den Club</h2>

                <div class="white_wrapper" style="text-align: left; font-size: 16px">
                  Schön, dass du dich angemeldet hast! Schaue dir gerne das folgende Video an, um einen ersten Überblick
                  über den Club und den Einstieg in die Nutzung zu bekommen.<br /><br />
                  Bei Fragen, Problemen oder Feedback kannst du dich jederzeit melden. Du erreichst uns unter
                  feedback@pushinglimits.club
                  <div>
                    <iframe
                      class="top-spacer-25"
                      width="100%"
                      style="height: 350px"
                      src="https://www.youtube-nocookie.com/embed/lqL1sR4jK5I"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                  </div>
                  <div class="centered">
                    <button
                      class="top-spacer-50"
                      @click="
                        step = 1;
                        $scrollToTop();
                      "
                    >
                      Weiter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6" v-if="step == 1">
        <div class="row">
          <div class="col-lg-12">
            <h2 class="top-spacer-50">Herzlich Willkommen!</h2>
          </div>
        </div>
        <div class="white_wrapper light-shadow" style="background: #fff">
          <div class="row" style="padding: 15px" v-if="swimThreshold == '00:00' && runThreshold == '00:00' && ftp == 0">
            <div class="col-lg-12">
              <h5 style="color: red">Hinweis</h5>
              <span
                >Zur Berechnung deiner Trainingsdaten musst du deine Leistungsschwellen eintragen. Wenn du sie nicht
                kennst, kannst du sie zunächst auch schätzen. Gib bitte auch einen Wert für die Sportarten an, die du
                nicht betreibst.</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <p>
                Bitte trage deine aktuellen Schwellenleistungen ein. Du solltest die Leistung ca. 40min-1h halten
                können. Wenn du die Werte nicht kennst, kannst du sie auch schätzen und später jederzeit anpassen.
              </p>

              <div class="row top-spacer-50">
                <div class="col-lg-4">
                  <h4>Schwimmen</h4>
                  <input type="text" v-model="swimThreshold" /> <br /><small>(min/100m)</small>
                </div>
                <div class="col-lg-4">
                  <h4>Rad</h4>
                  <input type="number" v-model.number="ftp" /> <br /><small>Watt</small><br />
                </div>
                <div class="col-lg-4">
                  <h4>Laufen</h4>
                  <input type="text" v-model="runThreshold" /> <br /><small>(min/km)</small>
                </div>
              </div>
              <div class="row top-spacer-25">
                <div class="col-lg-4">
                  <h4>Gewicht</h4>
                  <input type="number" v-model="weight" /> <br /><small>(kg)</small>
                </div>
                <div class="col-lg-4">
                  <h4>Max Herzfrequenz</h4>
                  <input type="number" v-model.number="maxHr" /> <br /><small>(bpm)</small><br />
                </div>
                <div class="col-lg-4">
                  <button style="margin-top: 28px; width: 100%" @click="setNewThresholds">Speichern</button>
                  <!-- 
                  <h4>Ruhe Herzfrequenz</h4>
                  <input type="number" v-model="restingHr" /> <br /><small
                    >(bpm)</small
                  >>-->
                </div>
              </div>
              <div class="row" v-if="thresholdsUpdated">
                <div class="col-lg-12 top-spacer-25" style="color: green">Neue Werte wurden gespeichert</div>
              </div>

              <div v-if="showThresholdDevelopment">
                <threshold-chart
                  :key="thresholdKey"
                  v-bind:thresholds="thresholds"
                  style="height: 230px"
                  class="top-spacer-50"
                />
                <div
                  @click="showThresholdDetails = !showThresholdDetails"
                  class="pull-right pointer"
                  style="padding: 15px"
                >
                  Daten editieren
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="row">
          <div class="col-lg-12">
            <h1 class="staydreaming kraftraum-heading top-spacer-25">
              Aktuell
            </h1>
          </div>
        </div>
        <div class="white_wrapper">
          <div class="row info">
            <div class="col-lg-3">INFO</div>
            <div class="col-lg-9" style="text-align: justify">
              Neues Workout wurde synchronisiert
            </div>
          </div>
        </div>-->
      </div>

      <div class="col-lg-6" v-if="step == 2">
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-12">
                <h2 class="top-spacer-50">Wichtige Hinweise zum Training</h2>
                <div class="white_wrapper" style="text-align: left; font-size: 16px">
                  <ul>
                    <li>
                      <strong>Deine Gesundheit ist wichtiger als deine Leistungsfähigkeit!</strong><br />
                      Höre beim Training auf deinen Körper und ziehe Expert:innen zu Rate. Lasse dir die korrekte
                      Ausführung von Übungen von Trainer:innen zeigen.
                      <br />Vor der Aufnahme intensiven Trainings solltest du dich ärztlich untersuchen lassen!
                    </li>
                    <br />
                    <li>Trainiere nicht über deinem Limit oder mit Schmerzen.</li>
                    <br />
                    <li>
                      Wir können keine Haftung in Bezug auf deine sportlichen Aktivitäten übernehmen. Dies gilt
                      insbesondere für die Durchführung der Trainingspläne & -einheiten, der Ausführung der in Videos
                      dargestellten Übungen und des Radraums.
                    </li>
                  </ul>
                  <div class="centered">
                    <button class="top-spacer-25" style="min-width: 250px" @click="confirmHinweis()">
                      Bestätigen & Weiter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6" v-if="step == 3">
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-12">
                <h2 class="top-spacer-50">Verbindungen</h2>

                <div class="white_wrapper" style="text-align: left; font-size: 16px">
                  <div>
                    Wenn du möchtest, dass wir deine Trainingseinheiten
                    <strong>automatisch synchronisieren</strong>, kannst du dich nach dieser Einführung in deinem Profil
                    mit Garmin, Apple Watch, Polar, Suunto, Strava, icTrainer und Wahoo verbinden.<br /><br />
                    Damit sparst du dir das manuelle Hochladen deiner durchgeführten Trainingseinheiten. Für Garmin, die Apple Watch, COROS, icTrainer und Wahoo
                    werden die geplanten Einheiten auf deine Geräte synchronisiert.
                  </div>
                  <div class="centered">
                    <button
                      class="top-spacer-50"
                      @click="
                        step = 4;
                        $scrollToTop();
                      "
                    >
                      Weiter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6" v-if="step == 4">
        <div class="row">
          <div class="col-lg-12">
            <div class="row">
              <div class="col-lg-12">
                <h2 class="top-spacer-50">Erklärungen</h2>

                <div class="white_wrapper" style="text-align: left; font-size: 16px">
                  <div>
                    Das Training wird bei uns grob in drei Bereiche aufgeteilt:<br />
                    <ul>
                      <li><strong>LIT</strong> - Low Intensity Training</li>
                      <li><strong>MIT</strong> - Medium Intensity Training</li>
                      <li><strong>HIT</strong> - High Intensity Training</li>
                    </ul>
                    Wenn deine Workouts zu uns synchronisiert werden, berechnen wir soweit möglich automatisch diese
                    <strong>Trainingsintensitätsverteilung</strong>. Du kannst die Verteilung jederzeit auch manuell
                    anpassen, indem du das Workout im Kalender öffnest. <br /><br />
                    <strong
                      >Ein Großteil deines Trainings sollte sich im LIT-Bereich abspielen. Viele Sportler machen den
                      Fehler, zu intensiv zu trainieren. Achte bei deinem Training darauf, viel Zeit im LIT-Bereich zu
                      verbringen!</strong
                    >
                    <br /><br />Damit du dein Training besser planen kannst,
                    <strong>wird dir zu den nächsten Tagen eine Wettervorhersage eingeblendet</strong>. Unter dem
                    Menüpunkt Profil kannst du den Ort, für den die Wettervorhersage geladen werden soll, festlegen.<br />
                  </div>
                  <div class="centered">
                    <button
                      class="top-spacer-50"
                      @click="
                        step = 5;
                        $scrollToTop();
                      "
                    >
                      Weiter
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6" v-if="step == 5">
        <div class="row">
          <div class="col-lg-12 top-spacer-50">
            <h2>Mitgliedschaft</h2>
          </div>
        </div>

        <div class="white_wrapper light-shadow" style="text-align: left; font-size: 16px">
          <div>
            <div>
              <strong>Du kannst alle Funktionen zwei Wochen kostenlos testen.</strong>

              Wenn dir der Club gefällt und du unsere Arbeit unterstützen möchtest, kannst du eine Mitgliedschaft
              abschließen - ansonsten fallen dir selbstverständlich keine Kosten an, es fallen aber auch die meisten
              Funktionen weg.<br /><br />

              Die Mitgliedschaft kannst du in unserer App unter dem Menüpunkt "Profil" abschließen.

              <div v-if="platform != 'web'">
                <hr class="top-spacer-25" />
                <strong>TIPP: </strong> Du kannst den Pushing Limits Club auch im Browser auf deinem Computer nutzen!
                <hr />
              </div>

              <br v-if="platform == 'web'" />

              <br />
              <strong> Wir hoffen, dir gefällt der Pushing Limits Club! Wir freuen uns über jedes Mitglied!</strong>
              <br />
              <br />
              PS: Du findest einen Link auf häufige
              <a style="color: blue" href="/#/faq" title="Fragen und Antworten ">Fragen und Antworten (FAQ)</a>
              im Footer.

              <strong>Häufige Fragen zum Training</strong> findest in unserem Format
              <a style="color: blue" href="/#/training/FragDenCoach" title="Fragen und Antworten ">Ask The Coach</a>.
              Schicke uns gerne deine Fragen zum Training zu - wir sammeln und beantworten dann Fragen, die häufig
              gestellt werden!
            </div>
          </div>
          <div class="centered">
            <button @click="$router.push({ name: 'Start' })" class="top-spacer-50" style="width: 250px">
              Einführung beenden
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row top-spacer-100"></div>
  </div>
</template>

<script>
import axios from "axios";
import { Capacitor } from "@capacitor/core";

export default {
  name: "Profil",
  components: {}, //ThresholdChart
  data: function () {
    return {
      step: 0,
      unsubscribeConfirmation: false,
      unsubscribed: false,
      sessionId: undefined,

      loading: false,

      alltimetotals: {},

      thresholdKey: 0,
      thresholdsChanged: false,
      runThreshold: 0,
      swimThreshold: 0,
      ftp: 0,
      weight: 0,
      restingHr: 0,
      maxHr: 0,
      runThresholdStart: 0,
      swimThresholdStart: 0,
      ftpStart: 0,
      thresholds: [],
      editThresholdId: "",
      showThresholdDetails: false,
      edit: {},

      thresholdsUpdated: false,
      user: undefined,
      token: undefined,
      showThresholdDevelopment: false,
      showMeineMitgliedschaft: false,
      platform: Capacitor.getPlatform(),
    };
  },
  created: async function () {
    this.updateThresholds();
    const _self = this;
    axios
      .get(this.$host + "/workout/analysis/power/year")
      .then(function (res) {
        _self.alltimetotals.bp = [];

        for (const [key, value] of Object.entries(res.data)) {
          if (key != "_id") {
            _self.alltimetotals.bp.push({
              key: key.split("_")[1],
              value: value,
            });
          }
        }
      })
      .catch(function (err) {
        console.log("ERR", err);
      });
  },
  mounted: async function () {
    this.user = (await axios.get(this.$host + "/user/me")).data;
  },
  computed: {
    reverseThresholds() {
      return this.thresholds.slice().reverse();
    },
  },
  methods: {
    async confirmHinweis() {
      await axios.post(this.$host + "/user/introduction");

      this.step = 3;
      this.$scrollToTop();
    },
    toggleMeineMitgliedschaft() {
      this.showMeineMitgliedschaft = !this.showMeineMitgliedschaft;
    },
    async unsubscribe() {
      const _self = this;

      axios
        .delete(this.$host + "/stripe/subscription")
        .then(async function () {
          _self.user = (await axios.get(this.$host + "/user/me")).data;
          _self.unsubscribed = true;
          _self.unsubscribeConfirmation = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    /*
    async createCustomerIfNotExists() {
      if (!this.user.subscriptionCustomerId) {
        await axios.post(this.$host + "/stripe/customer");
        this.user = (
          await axios.get(this.$host + "/user/me")
        ).data;
      }
    },
    */
    async createSession(priceId) {
      const req = { price: priceId };
      const session = (await axios.post(this.$host + "/stripe/session", req)).data;
      return session;
    },

    async submit() {
      // await this.createCustomerIfNotExists();

      this.sessionId = (await this.createSession("price_1Ja2aSIJ1TUV8BWfF2soRKBS")).session_id;

      this.$refs.checkoutRef.redirectToCheckout();
    },
    async submitYearly() {
      //await this.createCustomerIfNotExists();

      this.sessionId = (await this.createSession("price_1Ja2aSIJ1TUV8BWfc3Q3PrEU")).session_id;
      this.$refs.checkoutYearlyRef.redirectToCheckout();
    },
    formattedTime(seconds) {
      return this.$moment.utc(seconds * 1000).format("H:mm:ss");
    },

    deleteThreshold(threshold) {
      const _self = this;
      // TODO: Send to Server
      const req = { _id: threshold._id };
      axios
        .post(this.$host + "/user/thresholds/delete", req)
        .then(function () {
          _self.editThresholdId = "";
          _self.edit = {};
          _self.updateThresholds();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    editThreshold(threshold) {
      this.editThresholdId = threshold._id;
      this.edit.threshold_swim = this.secondsToMinutesAndSeconds(threshold.threshold_swim);
      this.edit.threshold_run = this.secondsToMinutesAndSeconds(threshold.threshold_run);
      this.edit.ftp = threshold.ftp;
    },
    saveThreshold() {
      // EDITED THRESHOLD
      let req = {
        _id: this.editThresholdId,
        ftp: this.edit.ftp,
        threshold_swim: this.$moment.duration("0:" + this.edit.threshold_swim).asSeconds(),
        threshold_run: this.$moment.duration("0:" + this.edit.threshold_run).asSeconds(),
      };

      const _self = this;
      axios
        .post(this.$host + "/user/thresholds", req)
        .then(function () {
          _self.editThresholdId = "";
          _self.edit = {};
          _self.updateThresholds();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    secondsToMinutesAndSeconds(seconds) {
      return this.$moment().startOf("day").seconds(seconds).format("mm:ss");
    },

    async updateThresholds() {
      this.thresholds = await (await axios.get(this.$host + "/user/thresholds")).data;
      this.swimThreshold = this.swimThresholdStart = this.$moment()
        .startOf("day")
        .seconds(this.thresholds[this.thresholds.length - 1].threshold_swim)
        .format("mm:ss");
      this.runThreshold = this.runThresholdStart = this.$moment()
        .startOf("day")
        .seconds(this.thresholds[this.thresholds.length - 1].threshold_run)
        .format("mm:ss");
      this.ftp = this.ftpStart = this.thresholds[this.thresholds.length - 1].ftp;
      this.weight = this.thresholds[this.thresholds.length - 1].weight;
      this.maxHr = this.thresholds[this.thresholds.length - 1].maxHr;
      this.restingHr = this.thresholds[this.thresholds.length - 1].restingHr;

      this.thresholdKey++;
    },
    noValueChanged() {
      return (
        (this.runThresholdStart == this.runThreshold &&
          this.swimThresholdStart == this.swimThreshold &&
          this.ftpStart == this.ftp) ||
        // Noch Default-Settings
        (this.runThresholdStart == 0 && this.swimThresholdStart == 0 && this.ftpStart == 0)
      );
    },

    setNewThresholds: function () {
      this.thresholdsUpdated = false;
      let req = {
        ftp: this.ftp,
        threshold_swim: this.$moment.duration("0:" + this.swimThreshold).asSeconds(),
        threshold_run: this.$moment.duration("0:" + this.runThreshold).asSeconds(),
        maxHr: this.maxHr,
        restingHr: this.restingHr,
        weight: this.weight,
      };

      const _self = this;

      axios
        .post(this.$host + "/user/thresholds", req)
        .then(function () {
          _self.thresholdsChanged = false;
          _self.updateThresholds();
          _self.thresholdsUpdated = true;
          _self.step = 2;
          _self.$scrollToTop();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.threshold {
  padding: 10px;
  border-bottom: 2px solid #fff;
}

.close-overlay i {
  cursor: pointer;
  font-size: 45px;
  float: right;
  color: #000;
}

.overlay-box {
  padding: 15px;
  overflow-y: auto;
  position: fixed;
  top: 3%;
  left: 5%;
  right: 5%;
  bottom: 3%;
  background: #e8e8e8;
}

.library {
  background: #efefef;
  padding: 20px;
  border-radius: 5px;
}

.info {
  padding: 25px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0%;
  z-index: 600;
  background: rgba(0, 0, 0, 0.5);
}

.profil-heading {
  font-size: 80px;
  text-align: center;
}
</style>
