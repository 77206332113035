var render = function render(){var _vm=this,_c=_vm._self._c;return (Object.keys(_vm.alternativeWorkouts).length > 0 || _vm.feelingButton == 'Krank' || _vm.feelingButton == 'Erschöpft')?_c('div',[(Object.keys(_vm.alternativeWorkouts).length > 0 || _vm.feelingButton == 'Krank' || _vm.feelingButton == 'Erschöpft')?_c('div',{staticClass:"top-spacer-50"},[_c('h4',[_vm._v("Training optimieren")])]):_vm._e(),_c('div',{staticClass:"adjusted-plan"},[(_vm.sicknessNote && _vm.feelingButton != 'Krank')?_c('div',[_vm._m(0),_c('br')]):_vm._e(),(_vm.feelingButton === 'Erschöpft' || _vm.feelingButton === 'Krank')?_c('div',{staticClass:"rest-container"},[_c('i',{staticClass:"fa fa-bed",attrs:{"aria-hidden":"true"}}),_c('p',[_vm._v(_vm._s(_vm.$t("Ruhe dich heute aus!")))]),(_vm.feelingButton == 'Krank')?_c('div',[_vm._v(" Starte erst wieder mit dem Training, wenn du nicht mehr krank bist und dich gut fühlst! Ein zu frühes Training könnte die Erholung verzögern oder sogar deinen Krankheitsverlauf verschlimmern. ")]):_vm._e(),_c('br'),(_vm.workouts.length > 0)?_c('button',{staticClass:"delete",staticStyle:{"width":"250px"},attrs:{"disabled":_vm.isDeletingWorkouts},on:{"click":_vm.deleteAllWorkouts}},[(!_vm.isDeletingWorkouts)?_c('span',[_vm._v(_vm._s(_vm.$t("Heutige Workouts löschen")))]):_vm._e()]):_vm._e()]):_vm._e(),(_vm.workouts.length > 0)?_c('div',_vm._l((_vm.workouts),function(workout,index){return _c('div',{key:workout._id,staticClass:"mb-4"},[(_vm.alternativeWorkouts[workout._id] && _vm.alternativeWorkouts[workout._id].length > 0 && _vm.feelingButton != 'Krank')?_c('div',{staticClass:"row workout-row"},[_c('div',{staticClass:"col-md-5 d-flex flex-column justify-content-center"},[_c('h6',{staticClass:"d-none d-md-block"},[_vm._v("Aktuelles Workout")]),_c('h4',{staticClass:"workout-name",on:{"click":function($event){return _vm.$emit('click')}}},[_vm._v(_vm._s(workout.name))]),(
              workout.sport != 'Kraft' &&
              workout.sport != 'Schwimmen' &&
              workout.workoutFile &&
              workout.workoutFile.length != 0
            )?_c('div',{staticClass:"workout-svg-container"},[_c('workout-svg',{attrs:{"workout-file":workout.workoutFile},on:{"click":function($event){return _vm.$emit('click')}}})],1):_vm._e(),_c('p',{staticClass:"top-spacer-15"},[_c('strong',[_vm._v("Dauer:")]),_vm._v(" "+_vm._s(_vm.formatDuration(workout.durationShould))+" ")]),_c('intensity-distribution-view',{attrs:{"workout":workout,"thresholds":_vm.thresholds}})],1),_c('div',{staticClass:"col-md-2 d-flex align-items-center justify-content-center"},[_c('i',{staticClass:"fa",class:{ 'fa-arrow-right': !_vm.isMobile, 'fa-arrow-down': _vm.isMobile },style:({ 'font-size': '2em' })})]),_c('div',{staticClass:"col-md-5 d-flex flex-column justify-content-center position-relative"},[_c('h6',{staticClass:"d-none d-md-block"},[_vm._v("Alternative")]),(_vm.alternativeWorkouts[workout._id].length > 1)?_c('button',{staticClass:"btn btn-link alternative-nav-btn alternative-nav-btn-left",on:{"click":function($event){return _vm.previousAlternative(workout._id)}}},[_c('i',{staticClass:"fa fa-chevron-left"})]):_vm._e(),(_vm.alternativeWorkouts[workout._id].length > 1)?_c('button',{staticClass:"btn btn-link alternative-nav-btn alternative-nav-btn-right",on:{"click":function($event){return _vm.nextAlternative(workout._id)}}},[_c('i',{staticClass:"fa fa-chevron-right"})]):_vm._e(),_c('h4',{staticClass:"workout-name",on:{"click":function($event){return _vm.$emit('click')}}},[_vm._v(_vm._s(_vm.currentAlternative(workout._id).name))]),(
              _vm.currentAlternative(workout._id).sport != 'Kraft' &&
              _vm.currentAlternative(workout._id).sport != 'Schwimmen' &&
              _vm.currentAlternative(workout._id).workoutFile &&
              _vm.currentAlternative(workout._id).workoutFile.length != 0
            )?_c('div',{staticClass:"workout-svg-container"},[_c('workout-svg',{attrs:{"workout-file":_vm.currentAlternative(workout._id).workoutFile}})],1):_vm._e(),_c('p',{staticClass:"top-spacer-15"},[_c('strong',[_vm._v("Dauer:")]),_vm._v(" "+_vm._s(_vm.formatDuration(_vm.currentAlternative(workout._id).durationShould))+" ")]),_c('intensity-distribution-view',{attrs:{"workout":_vm.currentAlternative(workout._id),"thresholds":_vm.thresholds}})],1),(_vm.alternativeWorkouts[workout._id] && _vm.alternativeWorkouts[workout._id].length > 0)?_c('div',{staticClass:"row mt-3"}):_vm._e(),_c('div',{staticClass:"col-12 text-center d-flex justify-content-center align-items-center top-spacer-25"},[_c('button',{staticStyle:{"margin":"3px","width":"200px"},on:{"click":function($event){return _vm.acceptAlternative(workout._id)}}},[(!_vm.loadingWorkouts[workout._id])?_c('span',[_vm._v("Alternative akzeptieren")]):_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}})]),_c('button',{staticClass:"delete",staticStyle:{"margin":"3px","width":"50px","min-width":"50px"},attrs:{"title":"Vorschlag verwerfen"},on:{"click":function($event){return _vm.discardAlternative(workout._id)}}},[_c('i',{staticClass:"fa fa-trash",attrs:{"aria-hidden":"true"}})])])]):_vm._e(),(
          index < _vm.workouts.length - 1 &&
          _vm.alternativeWorkouts[workout._id] &&
          _vm.alternativeWorkouts[workout._id].length > 0
        )?_c('div',{staticClass:"workout-separator"}):_vm._e()])}),0):_c('div',[_vm._v(" Das Training bleibt unverändert. ")]),(_vm.isDeletingWorkouts)?_c('div',{staticClass:"spinner-border text-primary top-spacer-25",attrs:{"role":"status"}}):_vm._e()])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert alert-warning"},[_c('strong',[_vm._v("Hinweis: Trainiere nicht, wenn du krank bist!")]),_c('br'),_vm._v(" Da du zuletzt krank warst, empfehlen wir dir, die Intensität zu reduzieren und Workouts mit weniger Umfang zu trainieren. Hier findest du Vorschläge, die zum Wiedereinstieg passen können - eventuell ist es aber auch zu viel. Höre auf deinen Körper und trainiere nur, wenn du dich gut fühlst und achte darauf, dich nicht zu überlasten! Gib deinem Körper die Zeit, die er braucht. Sprich im Zweifel mit deinem Arzt. ")])
}]

export { render, staticRenderFns }