import { Plugins, Capacitor } from '@capacitor/core';
import axios from 'axios';
import LZString from 'lz-string';
import convertToFitJson from '../js/apple_fit_json_converter';
import moment from 'moment';

const { AppleWorkoutPlugin } = Plugins;

class AppleHealthService {


  static async initialize() {
    try {
      if (Capacitor.getPlatform() === 'ios') {
        const isIos17Plus = await this.isIos17OrNewer();
        console.log("IS IOS 17 OR NEWER", isIos17Plus);
      
      }
    } catch (error) {
      console.error('Error checking iOS version:', error);
    }
  }

  static async isIos17OrNewer() {
    try {
      if (Capacitor.getPlatform() !== 'ios') {
        return false; // Exit if not on iOS
      }

      const result = await AppleWorkoutPlugin.isIos17OrNewer();
      console.log("IS IOS 17 OR NEWER 2", result);

      return result.value || false;
    } catch (error) {
      console.error('Error checking iOS version:', error);
      return false;
    }
  }

  static async fetchAppleWorkouts() {
    try {
      if (Capacitor.getPlatform() !== 'ios' || !await AppleWorkoutPlugin.isIos17OrNewer()) {
        return; // Exit if not on iOS
      }


      await AppleWorkoutPlugin.authorize();

      const sixWeeksAgo = new Date(Date.now() - 1000 * 60 * 60 * 24 * 42).toISOString();
      const result = await AppleWorkoutPlugin.fetchWorkoutsOnly({ lastFetchedDate: sixWeeksAgo });

      const importedWorkouts = await axios.get(`https://pushinglimits.club/api/workout/apple_workouts?from=${sixWeeksAgo}`);
      console.log("Imported workouts:", importedWorkouts.data.workouts.length);

      const importedWorkoutIds = new Set(importedWorkouts.data.workouts.map((w) => w.appleId));

      const appleWorkouts = result.workouts.map((workout) => ({
        ...workout,
        isImported: importedWorkoutIds.has(workout.id),
      }));

      const hasNewWorkouts = appleWorkouts.some((workout) => !workout.isImported);

      return { appleWorkouts, hasNewWorkouts };
    } catch (error) {
      console.error("Error fetching Apple Watch workouts:" + error, error);
      throw error;
    }
  }

  static async importWorkout(workout, name) {

    if (Capacitor.getPlatform() !== 'ios' || !await AppleWorkoutPlugin.isIos17OrNewer()) {
      return; // Exit if not on iOS
    }

    await AppleWorkoutPlugin.authorize();

    try {
      if (workout && !workout.id) {
        console.log("Workout has no id, using uuid", workout, name);
        workout.id = workout.uuid;
      }

      console.log("Importing workout " + name, workout.id, workout);
      const completeWorkout = await AppleWorkoutPlugin.fetchWorkout({ workoutId: workout.id });
      const fitJson = convertToFitJson(completeWorkout.workout);
      const compressedWorkout = LZString.compressToBase64(JSON.stringify(fitJson));

      await axios.post(`https://pushinglimits.club/api/workout/upload_apple`, {
        workout: compressedWorkout,
        appleId: workout.id,
        name: name || `${this.getWorkoutTypeName(workout.workoutActivityType)}`,
      });

      return true;
    } catch (error) {
      console.error("Error importing workout:", error);
      throw error;
    }
  }

  static getWorkoutTypeName(type) {
    const types = {
      37: "Laufen",
      24: "Laufen",
      13: "Radfahren",
      46: "Schwimmen",
      81: "Schwimmen",
      20: "Kraft",
      50: "Kraft",
      59: "Kraft",
      63: "Kraft",
    };
    return types[type] || "Aktivität";
  }


  static async saveCyclingWorkoutToAppleHealth(workout) {
    console.log("Saving workout to Apple Health started");

    if (Capacitor.getPlatform() !== 'ios' || !await AppleWorkoutPlugin.isIos17OrNewer()) {
      return; // Exit if not on iOS
    }

    await AppleWorkoutPlugin.authorize();

    const workoutData = {
      startTime: new Date(workout.time).toISOString(),
      endTime: new Date(workout.powerSamples[workout.powerSamples.length - 1].time).toISOString(),
      activityType: 13, // Cycling
      powerSamples: workout.powerSamples.map(sample => ({
        value: sample.value,
        time: new Date(sample.time).toISOString()
      })),
      heartRateSamples: workout.heartRateSamples.map(sample => ({
        value: sample.value,
        time: new Date(sample.time).toISOString()
      })),
      cadenceSamples: workout.cadenceSamples.map(sample => ({
        value: sample.value,
        time: new Date(sample.time).toISOString()
      })),
      distanceSamples: workout.distanceSamples.map(sample => ({
        value: sample.value,
        time: new Date(sample.time).toISOString()
      })),
      distance: workout.distance,
      duration: workout.duration,
    };

    try {
      await AppleWorkoutPlugin.saveWorkoutToAppleHealth({ workoutData });
      console.log("Workout saved to Apple Health");
    } catch (error) {
      console.error("Error saving workout to Apple Health  // " + error, error);
      throw error;
    }
  }

  static async syncWorkoutsToApple(force = false) {
    // force is true for manual sync or when there is no updated workout (i.e. on deletion)

    try {
      if (Capacitor.getPlatform() !== 'ios' || !await AppleWorkoutPlugin.isIos17OrNewer()) {
        return; // Exit if not on iOS
      }

      
      await AppleWorkoutPlugin.authorize();

      // always force for now
      force = true;

      let workouts = [];
      if (!force) {
        workouts = await axios.get("https://pushinglimits.club/api/workout/sync_apple_workouts");
      } else {
        workouts = await axios.get("https://pushinglimits.club/api/workout/sync_apple_workouts?force=true");
      }

      if (workouts.data && workouts.data.workouts) {
        await AppleWorkoutPlugin.requestWorkoutKitAuthorization();

        if (workouts.data.workouts.length > 0) {
          // Reschedule all workouts
          await AppleWorkoutPlugin.removeAllScheduledWorkouts();
          console.log(
            "There is at least one new or changed workout. Reschedule all " + workouts.data.workouts.length
          );
        

          const fetchThresholds = await axios.get("https://pushinglimits.club/api/user/threshold");
          const thresholds = fetchThresholds.data;

          //const targetIsHeartRate = await axios.get("https://pushinglimits.club/api/healthkit/target_is_heart_rate");


          // console.log("Apple sync thresholds:", thresholds);

          let scheduledWorkoutIds = [];
          for (const workout of workouts.data.workouts) {
            try {
              // 13 = Radfahren, 37 = Laufen, 20 = Kraft
              let activityType;
              switch (workout.sport) {
                case "Radfahren":
                  activityType = "13";
                  break;
                case "Laufen":
                  activityType = "37";
                  break;
                case "Kraft":
                  activityType = "20";
                  break;
                case "Schwimmen":
                  activityType = "46";
                  break;
                default:
                  activityType = "20";
              }

              let result = null;
              if(activityType === "13" || activityType === "37") {

              var useHeartRate = false;
               if(activityType === "13") {
                useHeartRate = false; //targetIsHeartRate.data.bikeTargetIsHeartRate;
               } else {
                useHeartRate = false; //targetIsHeartRate.data.runTargetIsHeartRate;
               }

              const options = {
                workoutName: workout.name,
                date: moment(workout.date).format("YYYY-MM-DDTHH:mm:ss[Z]"),
                useHeartRate: useHeartRate,
                maxHeartRate: thresholds.maxHr,
                ftp: thresholds.ftp,
                thresholdSpeed: 3600 / thresholds.threshold_run, // in km/h
                activityType: activityType,
                location: "outdoor",
              };

              // console.log("Scheduling workout with options:", options);

              result = await AppleWorkoutPlugin.transformAndScheduleWorkout({
                data: workout.workoutFile,
                options: options,
              });
             } else if(activityType === "20") {
             const options = {
                date: moment(workout.date).format("YYYY-MM-DDTHH:mm:ss[Z]"),
                workoutName: workout.name,
                duration: workout.durationShould,
                description: workout.description,
              };

              result = await AppleWorkoutPlugin.scheduleStrengthWorkout({
                data: workout.workoutFile,
                options: options,
              });
              } else if(activityType === "46") {
              const options = {
                date: moment(workout.date).format("YYYY-MM-DDTHH:mm:ss[Z]"),
                workoutName: workout.name,
                duration: workout.durationShould,
                description: workout.description,
              };

              result = await AppleWorkoutPlugin.scheduleSwimmingWorkout({
                data: workout.workoutFile,
                options: options,
              });
            } else  {
              console.log("Unknown activity type", activityType, workout);
            }

              // console.log("Workout scheduled successfully!!!", result);

              scheduledWorkoutIds.push({ workout: workout._id, appleUuid: result.uuid });
            } catch (error) {
              console.error("Error scheduling workout:", error, workout);
            }
          }

          try {
            // console.log("Mark scheduled workouts", scheduledWorkoutIds);

             await AppleWorkoutPlugin.getAllScheduledWorkouts();

            await axios.post("https://pushinglimits.club/api/workout/sync_apple_workouts", {
              workouts: scheduledWorkoutIds,
            });
          } catch (error) {
            console.error("Error marking scheduled workouts:", error, scheduledWorkoutIds);
            }
          }
      }
    } catch (error) {
      console.error("Error syncing workouts to Apple:", error);
      throw error;
    }
  }
}

AppleHealthService.initialize();

export default AppleHealthService;
