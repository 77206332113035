<template>
  <div>
    <div class="row" v-if="!user">
      <div class="col-lg-12 top-spacer-100">
        <div class="spinner-border text-primary" role="status"></div>
      </div>
    </div>

    <div
      class="col-12"
      v-if="warnPremiumExpiration"
      style="
        text-align: center;
        color: #fff;
        background-size: 200% auto;
        padding: 20px;
        padding-top: 30px;
        background: #000;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
      "
    >
      <strong>Hinweis:</strong> Dein Zugang ist abgelaufen. Um dauerhaft alle Funktionen nutzen zu können, musst du über
      die App ein Abo abschließen. &nbsp; <br class="top-spacer-10 d-block d-lg-none" />
      <button @click="warnPremiumExpiration = false">Verstanden</button>
    </div>
    <div class="row" v-if="user">
      <div class="col-lg-6">
        <div class="row">
          <div class="col-lg-12">
            <span v-if="user.wahooToken && user.wahooScopes && !user.wahooScopes.includes('plans_write')">
              <h2 class="top-spacer-50">Hinweis</h2>

              Wahoo erlaubt seit neuestem die Übertragung von Workouts! Du kannst jetzt deine Workouts automatisch auf
              dein Gerät übertragen. Dafür musst du dich aber einmal neu mit Wahoo verbinden. Im Moment übertragen wir
              nur Radworkouts. Wir sind in der Testphase für diese Funktion - Bitte teile uns mit, wenn es Probleme bei
              der Übertragung gibt.<br /><br />
            </span>

            <h2 class="top-spacer-50">Einstellungen</h2>
          </div>
        </div>
        <div class="white_wrapper light-shadow" style="background: #fff">
          <div class="row" style="padding: 15px" v-if="swimThreshold == '00:00' && runThreshold == '00:00' && ftp == 0">
            <div class="col-lg-12">
              <h5 style="color: red">Hinweis</h5>
              <span
                >Zur Berechnung deiner Trainingsdaten musst du deine Leistungsschwellen eintragen. Wenn du sie nicht
                kennst, kannst du sie zunächst auch schätzen. Gib bitte auch einen Wert für die Sportarten an, die du
                nicht betreibst.</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <p>
                Bitte trage deine aktuellen Schwellenleistungen ein. Du solltest die Leistung ca. 50min-1h halten
                können. Wenn du die Werte nicht kennst, kannst du sie auch schätzen.
              </p>
              <br />
              <div class="row">
                <div class="col-lg-4 col-md-6 top-spacer-10">
                  <h4>Schwimmen</h4>
                  <input type="text" v-model="swimThreshold" style="width: 100%" />
                  <br /><small>(min/100m)</small>
                </div>
                <div class="col-lg-4 col-md-6 top-spacer-10">
                  <h4>Rad</h4>
                  <input type="number" v-model.number="ftp" style="width: 100%" />
                  <br /><small>Watt</small><br />
                </div>
                <div class="col-lg-4 col-md-6 top-spacer-10">
                  <h4>Laufen</h4>
                  <input type="text" v-model="runThreshold" style="width: 100%" />
                  <br /><small>(min/km)</small>
                </div>
                <div class="col-lg-4 col-md-6 top-spacer-10">
                  <h4>Gewicht</h4>
                  <input type="number" v-model="weight" style="width: 100%" />
                  <br /><small>(kg)</small>
                </div>
                <div class="col-lg-4 col-md-6 top-spacer-10">
                  <h4>Max Herzfrequenz</h4>
                  <input type="number" v-model.number="maxHr" style="width: 100%" />
                  <br /><small>(bpm)</small><br />
                </div>
                <div class="col-lg-4 col-md-6 top-spacer-10">
                  <button style="margin-top: 29px; width: 100%" @click="setNewThresholds">Speichern</button>
                  <!-- 
                  <h4>Ruhe Herzfrequenz</h4>
                  <input type="text" v-model="restingHr" /> <br /><small
                    >(bpm)</small
                  >>-->
                </div>
                <div class="col-lg-6 top-spacer-25">
                  <span
                    style="color: #777; cursor: pointer"
                    @click="
                      showThresholdDetails = !showThresholdDetails;
                      showThresholdDetailsSettings = false;
                    "
                  >
                    Entwicklung der Schwellen
                    <span v-if="showThresholdDetails">ausblenden &#9650;</span
                    ><span v-if="!showThresholdDetails">einblenden &#9660;</span>
                  </span>
                </div>
                <div class="col-lg-6 top-spacer-25">
                  <span
                    style="color: #777; cursor: pointer"
                    @click="
                      showThresholdDetailsSettings = !showThresholdDetailsSettings;
                      showThresholdDetails = false;
                    "
                  >
                    Zonen Konfiguration anpassen
                    <span v-if="showThresholdDetailsSettings">ausblenden &#9650;</span
                    ><span v-if="!showThresholdDetailsSettings">einblenden &#9660;</span>
                  </span>
                </div>
                <div class="col-lg-12 top-spacer-25">
                  <hr />
                  <strong>
                    <span v-if="!thresholds_automatic">
                      Rad- und Laufschwellen automatisch anpassen ist deaktiviert. Die Funktion ist noch neu und noch
                      nicht komplett optimiert. Nach jeder Einheit werden deine Schwellen in kleinen Schritten
                      verändert. <br /><br />
                      <button @click="setAutomaticThreshold(true)">Aktivieren</button>
                    </span>

                    <span v-if="thresholds_automatic">
                      Rad- und Laufschwellen automatisch anpassen ist aktiviert (experimentell).<br /><br />
                      <button @click="setAutomaticThreshold(false)">Deaktivieren</button></span
                    >
                  </strong>
                </div>
              </div>
              <div class="row" v-if="thresholdsUpdated">
                <div class="col-lg-12 top-spacer-25" style="color: green">Neue Werte wurden gespeichert</div>
              </div>

              <div class="row top-spacer-25" v-if="showThresholdDetailsSettings">
                <div class="col-lg-12 top-spacer-50">
                  <h2>Herzfrequenz-Zonen konfigurieren</h2>
                  Hier kannst du deine
                  <strong>prozentualen Herzfrequenzzonen</strong> manuell anpassen. Der Wert geht jeweils von der
                  vorherigen Zone bis zum angegebenen Wert. Der absolute Herzfrequenzwert der Zone wird automatisch
                  berechnet und steht unter dem Eingabefeld.
                  <div v-if="hrZonesUpdated" style="color: green" class="top-spacer-25">
                    <strong> Neue Herzfrequenz-Zonen wurden gespeichert!</strong>
                  </div>
                  <div><br /></div>
                  <h3 style="text-align: center">Radfahren</h3>
                </div>

                <div class="col-lg-4 col-md-6 top-spacer-10">
                  <h4>Zone 1</h4>
                  <input type="number" v-model.number="hr_zone_1" style="width: 100%" min="0" max="100" />
                  <br /><small>(0-100 %) ~ {{ Math.round((hr_zone_1 / 100) * maxHr) }}bpm</small><br />
                </div>
                <div class="col-lg-4 col-md-6 top-spacer-10">
                  <h4>Zone 2</h4>
                  <input type="number" v-model.number="hr_zone_2" style="width: 100%" min="0" max="100" />
                  <br /><small>(0-100 %) ~ {{ Math.round((hr_zone_2 / 100) * maxHr) }}bpm</small><br />
                </div>
                <div class="col-lg-4 col-md-6 top-spacer-10">
                  <h4>Zone 3</h4>
                  <input type="number" v-model.number="hr_zone_3" style="width: 100%" min="0" max="100" />
                  <br /><small>(0-100 %) ~ {{ Math.round((hr_zone_3 / 100) * maxHr) }}bpm </small><br />
                </div>
                <div class="col-lg-4 col-md-6 top-spacer-10">
                  <h4>Zone 4</h4>
                  <input type="number" min="0" max="100" v-model.number="hr_zone_4" style="width: 100%" />
                  <br /><small>(0-100 %) ~ {{ Math.round((hr_zone_4 / 100) * maxHr) }}bpm</small><br />
                </div>
                <div class="col-lg-4 col-md-6 top-spacer-10">
                  <h4>Zone 5</h4>
                  <input type="number" disabled value="100" style="width: 100%" />
                  <br /><small>(0-100 %) ~ {{ maxHr }}bpm</small><br />
                </div>
                <div
                  class="col-lg-4 col-md-6 top-spacer-10"
                  v-if="hr_zone_1 < hr_zone_2 && hr_zone_2 < hr_zone_3 && hr_zone_3 < hr_zone_4 && hr_zone_4 < 100"
                >
                  <button style="margin-top: 29px; width: 100%" @click="setNewHrZones">Speichern</button>
                </div>
                <div
                  class="col-lg-4 col-md-6 top-spacer-50"
                  v-if="!(hr_zone_1 < hr_zone_2 && hr_zone_2 < hr_zone_3 && hr_zone_3 < hr_zone_4 && hr_zone_4 < 100)"
                >
                  Bitte überprüfe deine Angaben
                </div>

                <div class="col-lg-12 top-spacer-50">
                  <div v-if="hrZonesUpdatedRun" style="color: green" class="top-spacer-25">
                    <strong> Neue Herzfrequenz-Zonen wurden gespeichert!</strong>
                  </div>
                  <div><br /></div>
                  <h3 style="text-align: center">Laufen</h3>
                </div>
                <div class="col-lg-4 col-md-6 top-spacer-10">
                  <h4>Zone 1</h4>
                  <input type="number" v-model.number="hr_zone_1_run" style="width: 100%" min="0" max="100" />
                  <br /><small>(0-100 %) ~ {{ Math.round((hr_zone_1_run / 100) * maxHr) }}bpm</small><br />
                </div>
                <div class="col-lg-4 col-md-6 top-spacer-10">
                  <h4>Zone 2</h4>
                  <input type="number" v-model.number="hr_zone_2_run" style="width: 100%" min="0" max="100" />
                  <br /><small>(0-100 %) ~ {{ Math.round((hr_zone_2_run / 100) * maxHr) }}bpm</small><br />
                </div>
                <div class="col-lg-4 col-md-6 top-spacer-10">
                  <h4>Zone 3</h4>
                  <input type="number" v-model.number="hr_zone_3_run" style="width: 100%" min="0" max="100" />
                  <br /><small>(0-100 %) ~ {{ Math.round((hr_zone_3_run / 100) * maxHr) }}bpm </small><br />
                </div>
                <div class="col-lg-4 col-md-6 top-spacer-10">
                  <h4>Zone 4</h4>
                  <input type="number" min="0" max="100" v-model.number="hr_zone_4_run" style="width: 100%" />
                  <br /><small>(0-100 %) ~ {{ Math.round((hr_zone_4_run / 100) * maxHr) }}bpm</small><br />
                </div>
                <div class="col-lg-4 col-md-6 top-spacer-10">
                  <h4>Zone 5</h4>
                  <input type="number" disabled value="100" style="width: 100%" />
                  <br /><small>(0-100 %) ~ {{ maxHr }}bpm</small><br />
                </div>
                <div
                  class="col-lg-4 col-md-6 top-spacer-10"
                  v-if="
                    hr_zone_1_run < hr_zone_2_run &&
                    hr_zone_2_run < hr_zone_3_run &&
                    hr_zone_3_run < hr_zone_4_run &&
                    hr_zone_4_run < 100
                  "
                >
                  <button style="margin-top: 29px; width: 100%" @click="setNewHrZonesRun">Speichern</button>
                </div>
                <div
                  class="col-lg-4 col-md-6 top-spacer-50"
                  v-if="
                    !(
                      hr_zone_1_run < hr_zone_2_run &&
                      hr_zone_2_run < hr_zone_3_run &&
                      hr_zone_3_run < hr_zone_4_run &&
                      hr_zone_4_run < 100
                    )
                  "
                >
                  Bitte überprüfe deine Angaben
                </div>
              </div>

              <div class="row top-spacer-25" v-if="showThresholdDetails">
                <div class="col-lg-12">
                  <h4>Schwellen editieren</h4>
                </div>
                <div class="col-lg-12 library">
                  <div class="row">
                    <div class="col-lg-2 col-4"><strong>Datum</strong></div>

                    <div class="col-lg-2 col-4">
                      <strong>FTP in Watt</strong>
                    </div>
                    <div class="col-lg-2 col-4">
                      <strong>Laufen</strong>
                    </div>
                    <div class="col-lg-2 col-4">
                      <strong>Schwimmen</strong>
                    </div>
                    <div class="col-lg-2 col-4"><strong>Gewicht</strong></div>
                    <div class="col-lg-2 col-4"><strong>MaxHf</strong></div>
                  </div>
                  <div v-for="threshold in reverseThresholds" :key="threshold._id" class="row threshold colored-row">
                    <div v-if="editThresholdId != threshold._id" class="col-lg-2 col-4">
                      {{ $moment(threshold.createdAt).format("DD.MM.YYYY") }}
                    </div>
                    <div v-if="editThresholdId == threshold._id" class="col-lg-2 col-4">
                      <span class="d-inline d-lg-none">Datum</span>

                      <input v-model="edit.createdAt" type="date" style="margin: 2px 0; width: 100%" />
                    </div>

                    <div v-if="editThresholdId != threshold._id" class="col-lg-2 col-4">
                      {{ threshold.ftp }}
                    </div>
                    <div v-if="editThresholdId != threshold._id" class="col-lg-2 col-4">
                      {{ secondsToMinutesAndSeconds(threshold.threshold_run) }}
                    </div>
                    <div v-if="editThresholdId != threshold._id" class="col-lg-2 col-4">
                      {{ secondsToMinutesAndSeconds(threshold.threshold_swim) }}
                    </div>

                    <div v-if="editThresholdId != threshold._id" class="col-lg-2 col-4">
                      {{ threshold.weight }}
                    </div>

                    <div v-if="editThresholdId != threshold._id" class="col-lg-2 col-4">
                      {{ threshold.maxHr }}
                    </div>

                    <div v-if="editThresholdId == threshold._id" class="col-lg-2 col-4">
                      <span class="d-inline d-lg-none">FTP</span>
                      <input type="number" v-model.number="edit.ftp" style="margin: 2px 0; width: 100%" />
                    </div>
                    <div v-if="editThresholdId == threshold._id" class="col-lg-2 col-4">
                      <span class="d-inline d-lg-none">Laufen</span>

                      <input v-model="edit.threshold_run" style="margin: 2px 0; width: 100%" />
                    </div>
                    <div v-if="editThresholdId == threshold._id" class="col-lg-2 col-4">
                      <span class="d-inline d-lg-none">Schwimmen</span>

                      <input v-model="edit.threshold_swim" style="margin: 2px 0; width: 100%" />
                    </div>

                    <div v-if="editThresholdId == threshold._id" class="col-lg-2 col-4">
                      <span class="d-inline d-lg-none">Gewicht</span>

                      <input v-model="edit.weight" style="margin: 2px 0; width: 100%" />
                    </div>

                    <div v-if="editThresholdId == threshold._id" class="col-lg-2 col-4">
                      <span class="d-inline d-lg-none">Maximale Herzfrequenz</span>

                      <input v-model="edit.maxHr" style="margin: 2px 0; width: 100%" />
                    </div>

                    <div class="col-lg-8 col-4"></div>

                    <div
                      v-if="editThresholdId != threshold._id"
                      class="col-lg-2 col-4 pointer top-spacer-10"
                      @click="editThreshold(threshold)"
                    >
                      <strong>Editieren</strong>
                    </div>
                    <div
                      v-if="editThresholdId == threshold._id"
                      class="col-lg-2 col-4 pointer top-spacer-10"
                      @click="saveThreshold(threshold)"
                    >
                      <strong>Speichern</strong>
                    </div>

                    <div
                      v-if="reverseThresholds.length > 1"
                      @click="deleteThreshold(threshold)"
                      class="col-lg-2 col-4 pointer top-spacer-10"
                      style="color: red"
                    >
                      <strong>Löschen</strong>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="showThresholdDevelopment">
                <threshold-chart
                  :key="thresholdKey"
                  v-bind:thresholds="thresholds"
                  style="height: 230px"
                  class="top-spacer-50"
                />
                <div
                  @click="showThresholdDetails = !showThresholdDetails"
                  class="pull-right pointer"
                  style="padding: 15px"
                >
                  Daten editieren
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--
        <div class="row">
          <div class="col-lg-12">
            <h1 class="staydreaming kraftraum-heading top-spacer-25">
              Aktuell
            </h1>
          </div>
        </div>
        <div class="white_wrapper">
          <div class="row info">
            <div class="col-lg-3">INFO</div>
            <div class="col-lg-9" style="text-align: justify">
              Neues Workout wurde synchronisiert
            </div>
          </div>
        </div>-->
        <div class="row">
          <div class="col-lg-12">
            <h2 class="top-spacer-50">Zeitzone</h2>
          </div>
        </div>
        <div class="white_wrapper light-shadow" style="background: #fff">
          <div class="row">
            <div class="col-lg-12">
              <h4>Zeitzone ändern</h4>

              <select v-model="tz" @change="setTimezone()">
                <Option value="Africa/Abidjan">Africa/Abidjan</Option>
                <Option value="Africa/Accra">Africa/Accra</Option>
                <Option value="Africa/Addis_Ababa">Africa/Addis_Ababa</Option>
                <Option value="Africa/Algiers">Africa/Algiers</Option>
                <Option value="Africa/Asmara">Africa/Asmara</Option>
                <Option value="Africa/Asmera">Africa/Asmera</Option>
                <Option value="Africa/Bamako">Africa/Bamako</Option>
                <Option value="Africa/Bangui">Africa/Bangui</Option>
                <Option value="Africa/Banjul">Africa/Banjul</Option>
                <Option value="Africa/Bissau">Africa/Bissau</Option>
                <Option value="Africa/Blantyre">Africa/Blantyre</Option>
                <Option value="Africa/Brazzaville">Africa/Brazzaville</Option>
                <Option value="Africa/Bujumbura">Africa/Bujumbura</Option>
                <Option value="Africa/Cairo">Africa/Cairo</Option>
                <Option value="Africa/Casablanca">Africa/Casablanca</Option>
                <Option value="Africa/Ceuta">Africa/Ceuta</Option>
                <Option value="Africa/Conakry">Africa/Conakry</Option>
                <Option value="Africa/Dakar">Africa/Dakar</Option>
                <Option value="Africa/Dar_es_Salaam">Africa/Dar_es_Salaam</Option>
                <Option value="Africa/Djibouti">Africa/Djibouti</Option>
                <Option value="Africa/Douala">Africa/Douala</Option>
                <Option value="Africa/El_Aaiun">Africa/El_Aaiun</Option>
                <Option value="Africa/Freetown">Africa/Freetown</Option>
                <Option value="Africa/Gaborone">Africa/Gaborone</Option>
                <Option value="Africa/Harare">Africa/Harare</Option>
                <Option value="Africa/Johannesburg">Africa/Johannesburg</Option>
                <Option value="Africa/Juba">Africa/Juba</Option>
                <Option value="Africa/Kampala">Africa/Kampala</Option>
                <Option value="Africa/Khartoum">Africa/Khartoum</Option>
                <Option value="Africa/Kigali">Africa/Kigali</Option>
                <Option value="Africa/Kinshasa">Africa/Kinshasa</Option>
                <Option value="Africa/Lagos">Africa/Lagos</Option>
                <Option value="Africa/Libreville">Africa/Libreville</Option>
                <Option value="Africa/Lome">Africa/Lome</Option>
                <Option value="Africa/Luanda">Africa/Luanda</Option>
                <Option value="Africa/Lubumbashi">Africa/Lubumbashi</Option>
                <Option value="Africa/Lusaka">Africa/Lusaka</Option>
                <Option value="Africa/Malabo">Africa/Malabo</Option>
                <Option value="Africa/Maputo">Africa/Maputo</Option>
                <Option value="Africa/Maseru">Africa/Maseru</Option>
                <Option value="Africa/Mbabane">Africa/Mbabane</Option>
                <Option value="Africa/Mogadishu">Africa/Mogadishu</Option>
                <Option value="Africa/Monrovia">Africa/Monrovia</Option>
                <Option value="Africa/Nairobi">Africa/Nairobi</Option>
                <Option value="Africa/Ndjamena">Africa/Ndjamena</Option>
                <Option value="Africa/Niamey">Africa/Niamey</Option>
                <Option value="Africa/Nouakchott">Africa/Nouakchott</Option>
                <Option value="Africa/Ouagadougou">Africa/Ouagadougou</Option>
                <Option value="Africa/Porto-Novo">Africa/Porto-Novo</Option>
                <Option value="Africa/Sao_Tome">Africa/Sao_Tome</Option>
                <Option value="Africa/Timbuktu">Africa/Timbuktu</Option>
                <Option value="Africa/Tripoli">Africa/Tripoli</Option>
                <Option value="Africa/Tunis">Africa/Tunis</Option>
                <Option value="Africa/Windhoek">Africa/Windhoek</Option>
                <Option value="America/Adak">America/Adak</Option>
                <Option value="America/Anchorage">America/Anchorage</Option>
                <Option value="America/Anguilla">America/Anguilla</Option>
                <Option value="America/Antigua">America/Antigua</Option>
                <Option value="America/Araguaina">America/Araguaina</Option>
                <Option value="America/Argentina/Buenos_Aires">America/Argentina/Buenos_Aires</Option>
                <Option value="America/Argentina/Catamarca">America/Argentina/Catamarca</Option>
                <Option value="America/Argentina/ComodRivadavia">America/Argentina/ComodRivadavia</Option>
                <Option value="America/Argentina/Cordoba">America/Argentina/Cordoba</Option>
                <Option value="America/Argentina/Jujuy">America/Argentina/Jujuy</Option>
                <Option value="America/Argentina/La_Rioja">America/Argentina/La_Rioja</Option>
                <Option value="America/Argentina/Mendoza">America/Argentina/Mendoza</Option>
                <Option value="America/Argentina/Rio_Gallegos">America/Argentina/Rio_Gallegos</Option>
                <Option value="America/Argentina/Salta">America/Argentina/Salta</Option>
                <Option value="America/Argentina/San_Juan">America/Argentina/San_Juan</Option>
                <Option value="America/Argentina/San_Luis">America/Argentina/San_Luis</Option>
                <Option value="America/Argentina/Tucuman">America/Argentina/Tucuman</Option>
                <Option value="America/Argentina/Ushuaia">America/Argentina/Ushuaia</Option>
                <Option value="America/Aruba">America/Aruba</Option>
                <Option value="America/Asuncion">America/Asuncion</Option>
                <Option value="America/Atikokan">America/Atikokan</Option>
                <Option value="America/Atka">America/Atka</Option>
                <Option value="America/Bahia">America/Bahia</Option>
                <Option value="America/Bahia_Banderas">America/Bahia_Banderas</Option>
                <Option value="America/Barbados">America/Barbados</Option>
                <Option value="America/Belem">America/Belem</Option>
                <Option value="America/Belize">America/Belize</Option>
                <Option value="America/Blanc-Sablon">America/Blanc-Sablon</Option>
                <Option value="America/Boa_Vista">America/Boa_Vista</Option>
                <Option value="America/Bogota">America/Bogota</Option>
                <Option value="America/Boise">America/Boise</Option>
                <Option value="America/Buenos_Aires">America/Buenos_Aires</Option>
                <Option value="America/Cambridge_Bay">America/Cambridge_Bay</Option>
                <Option value="America/Campo_Grande">America/Campo_Grande</Option>
                <Option value="America/Cancun">America/Cancun</Option>
                <Option value="America/Caracas">America/Caracas</Option>
                <Option value="America/Catamarca">America/Catamarca</Option>
                <Option value="America/Cayenne">America/Cayenne</Option>
                <Option value="America/Cayman">America/Cayman</Option>
                <Option value="America/Chicago">America/Chicago</Option>
                <Option value="America/Chihuahua">America/Chihuahua</Option>
                <Option value="America/Coral_Harbour">America/Coral_Harbour</Option>
                <Option value="America/Cordoba">America/Cordoba</Option>
                <Option value="America/Costa_Rica">America/Costa_Rica</Option>
                <Option value="America/Creston">America/Creston</Option>
                <Option value="America/Cuiaba">America/Cuiaba</Option>
                <Option value="America/Curacao">America/Curacao</Option>
                <Option value="America/Danmarkshavn">America/Danmarkshavn</Option>
                <Option value="America/Dawson">America/Dawson</Option>
                <Option value="America/Dawson_Creek">America/Dawson_Creek</Option>
                <Option value="America/Denver">America/Denver</Option>
                <Option value="America/Detroit">America/Detroit</Option>
                <Option value="America/Dominica">America/Dominica</Option>
                <Option value="America/Edmonton">America/Edmonton</Option>
                <Option value="America/Eirunepe">America/Eirunepe</Option>
                <Option value="America/El_Salvador">America/El_Salvador</Option>
                <Option value="America/Ensenada">America/Ensenada</Option>
                <Option value="America/Fort_Nelson">America/Fort_Nelson</Option>
                <Option value="America/Fort_Wayne">America/Fort_Wayne</Option>
                <Option value="America/Fortaleza">America/Fortaleza</Option>
                <Option value="America/Glace_Bay">America/Glace_Bay</Option>
                <Option value="America/Godthab">America/Godthab</Option>
                <Option value="America/Goose_Bay">America/Goose_Bay</Option>
                <Option value="America/Grand_Turk">America/Grand_Turk</Option>
                <Option value="America/Grenada">America/Grenada</Option>
                <Option value="America/Guadeloupe">America/Guadeloupe</Option>
                <Option value="America/Guatemala">America/Guatemala</Option>
                <Option value="America/Guayaquil">America/Guayaquil</Option>
                <Option value="America/Guyana">America/Guyana</Option>
                <Option value="America/Halifax">America/Halifax</Option>
                <Option value="America/Havana">America/Havana</Option>
                <Option value="America/Hermosillo">America/Hermosillo</Option>
                <Option value="America/Indiana/Indianapolis">America/Indiana/Indianapolis</Option>
                <Option value="America/Indiana/Knox">America/Indiana/Knox</Option>
                <Option value="America/Indiana/Marengo">America/Indiana/Marengo</Option>
                <Option value="America/Indiana/Petersburg">America/Indiana/Petersburg</Option>
                <Option value="America/Indiana/Tell_City">America/Indiana/Tell_City</Option>
                <Option value="America/Indiana/Vevay">America/Indiana/Vevay</Option>
                <Option value="America/Indiana/Vincennes">America/Indiana/Vincennes</Option>
                <Option value="America/Indiana/Winamac">America/Indiana/Winamac</Option>
                <Option value="America/Indianapolis">America/Indianapolis</Option>
                <Option value="America/Inuvik">America/Inuvik</Option>
                <Option value="America/Iqaluit">America/Iqaluit</Option>
                <Option value="America/Jamaica">America/Jamaica</Option>
                <Option value="America/Jujuy">America/Jujuy</Option>
                <Option value="America/Juneau">America/Juneau</Option>
                <Option value="America/Kentucky/Louisville">America/Kentucky/Louisville</Option>
                <Option value="America/Kentucky/Monticello">America/Kentucky/Monticello</Option>
                <Option value="America/Knox_IN">America/Knox_IN</Option>
                <Option value="America/Kralendijk">America/Kralendijk</Option>
                <Option value="America/La_Paz">America/La_Paz</Option>
                <Option value="America/Lima">America/Lima</Option>
                <Option value="America/Los_Angeles">America/Los_Angeles</Option>
                <Option value="America/Louisville">America/Louisville</Option>
                <Option value="America/Lower_Princes">America/Lower_Princes</Option>
                <Option value="America/Maceio">America/Maceio</Option>
                <Option value="America/Managua">America/Managua</Option>
                <Option value="America/Manaus">America/Manaus</Option>
                <Option value="America/Marigot">America/Marigot</Option>
                <Option value="America/Martinique">America/Martinique</Option>
                <Option value="America/Matamoros">America/Matamoros</Option>
                <Option value="America/Mazatlan">America/Mazatlan</Option>
                <Option value="America/Mendoza">America/Mendoza</Option>
                <Option value="America/Menominee">America/Menominee</Option>
                <Option value="America/Merida">America/Merida</Option>
                <Option value="America/Metlakatla">America/Metlakatla</Option>
                <Option value="America/Mexico_City">America/Mexico_City</Option>
                <Option value="America/Miquelon">America/Miquelon</Option>
                <Option value="America/Moncton">America/Moncton</Option>
                <Option value="America/Monterrey">America/Monterrey</Option>
                <Option value="America/Montevideo">America/Montevideo</Option>
                <Option value="America/Montreal">America/Montreal</Option>
                <Option value="America/Montserrat">America/Montserrat</Option>
                <Option value="America/Nassau">America/Nassau</Option>
                <Option value="America/New_York">America/New_York</Option>
                <Option value="America/Nipigon">America/Nipigon</Option>
                <Option value="America/Nome">America/Nome</Option>
                <Option value="America/Noronha">America/Noronha</Option>
                <Option value="America/North_Dakota/Beulah">America/North_Dakota/Beulah</Option>
                <Option value="America/North_Dakota/Center">America/North_Dakota/Center</Option>
                <Option value="America/North_Dakota/New_Salem">America/North_Dakota/New_Salem</Option>
                <Option value="America/Ojinaga">America/Ojinaga</Option>
                <Option value="America/Panama">America/Panama</Option>
                <Option value="America/Pangnirtung">America/Pangnirtung</Option>
                <Option value="America/Paramaribo">America/Paramaribo</Option>
                <Option value="America/Phoenix">America/Phoenix</Option>
                <Option value="America/Port-au-Prince">America/Port-au-Prince</Option>
                <Option value="America/Port_of_Spain">America/Port_of_Spain</Option>
                <Option value="America/Porto_Acre">America/Porto_Acre</Option>
                <Option value="America/Porto_Velho">America/Porto_Velho</Option>
                <Option value="America/Puerto_Rico">America/Puerto_Rico</Option>
                <Option value="America/Punta_Arenas">America/Punta_Arenas</Option>
                <Option value="America/Rainy_River">America/Rainy_River</Option>
                <Option value="America/Rankin_Inlet">America/Rankin_Inlet</Option>
                <Option value="America/Recife">America/Recife</Option>
                <Option value="America/Regina">America/Regina</Option>
                <Option value="America/Resolute">America/Resolute</Option>
                <Option value="America/Rio_Branco">America/Rio_Branco</Option>
                <Option value="America/Rosario">America/Rosario</Option>
                <Option value="America/Santa_Isabel">America/Santa_Isabel</Option>
                <Option value="America/Santarem">America/Santarem</Option>
                <Option value="America/Santiago">America/Santiago</Option>
                <Option value="America/Santo_Domingo">America/Santo_Domingo</Option>
                <Option value="America/Sao_Paulo">America/Sao_Paulo</Option>
                <Option value="America/Scoresbysund">America/Scoresbysund</Option>
                <Option value="America/Shiprock">America/Shiprock</Option>
                <Option value="America/Sitka">America/Sitka</Option>
                <Option value="America/St_Barthelemy">America/St_Barthelemy</Option>
                <Option value="America/St_Johns">America/St_Johns</Option>
                <Option value="America/St_Kitts">America/St_Kitts</Option>
                <Option value="America/St_Lucia">America/St_Lucia</Option>
                <Option value="America/St_Thomas">America/St_Thomas</Option>
                <Option value="America/St_Vincent">America/St_Vincent</Option>
                <Option value="America/Swift_Current">America/Swift_Current</Option>
                <Option value="America/Tegucigalpa">America/Tegucigalpa</Option>
                <Option value="America/Thule">America/Thule</Option>
                <Option value="America/Thunder_Bay">America/Thunder_Bay</Option>
                <Option value="America/Tijuana">America/Tijuana</Option>
                <Option value="America/Toronto">America/Toronto</Option>
                <Option value="America/Tortola">America/Tortola</Option>
                <Option value="America/Vancouver">America/Vancouver</Option>
                <Option value="America/Virgin">America/Virgin</Option>
                <Option value="America/Whitehorse">America/Whitehorse</Option>
                <Option value="America/Winnipeg">America/Winnipeg</Option>
                <Option value="America/Yakutat">America/Yakutat</Option>
                <Option value="America/Yellowknife">America/Yellowknife</Option>
                <Option value="Antarctica/Casey">Antarctica/Casey</Option>
                <Option value="Antarctica/Davis">Antarctica/Davis</Option>
                <Option value="Antarctica/DumontDUrville">Antarctica/DumontDUrville</Option>
                <Option value="Antarctica/Macquarie">Antarctica/Macquarie</Option>
                <Option value="Antarctica/Mawson">Antarctica/Mawson</Option>
                <Option value="Antarctica/McMurdo">Antarctica/McMurdo</Option>
                <Option value="Antarctica/Palmer">Antarctica/Palmer</Option>
                <Option value="Antarctica/Rothera">Antarctica/Rothera</Option>
                <Option value="Antarctica/South_Pole">Antarctica/South_Pole</Option>
                <Option value="Antarctica/Syowa">Antarctica/Syowa</Option>
                <Option value="Antarctica/Troll">Antarctica/Troll</Option>
                <Option value="Antarctica/Vostok">Antarctica/Vostok</Option>
                <Option value="Arctic/Longyearbyen">Arctic/Longyearbyen</Option>
                <Option value="Asia/Aden">Asia/Aden</Option>
                <Option value="Asia/Almaty">Asia/Almaty</Option>
                <Option value="Asia/Amman">Asia/Amman</Option>
                <Option value="Asia/Anadyr">Asia/Anadyr</Option>
                <Option value="Asia/Aqtau">Asia/Aqtau</Option>
                <Option value="Asia/Aqtobe">Asia/Aqtobe</Option>
                <Option value="Asia/Ashgabat">Asia/Ashgabat</Option>
                <Option value="Asia/Ashkhabad">Asia/Ashkhabad</Option>
                <Option value="Asia/Atyrau">Asia/Atyrau</Option>
                <Option value="Asia/Baghdad">Asia/Baghdad</Option>
                <Option value="Asia/Bahrain">Asia/Bahrain</Option>
                <Option value="Asia/Baku">Asia/Baku</Option>
                <Option value="Asia/Bangkok">Asia/Bangkok</Option>
                <Option value="Asia/Barnaul">Asia/Barnaul</Option>
                <Option value="Asia/Beirut">Asia/Beirut</Option>
                <Option value="Asia/Bishkek">Asia/Bishkek</Option>
                <Option value="Asia/Brunei">Asia/Brunei</Option>
                <Option value="Asia/Calcutta">Asia/Calcutta</Option>
                <Option value="Asia/Chita">Asia/Chita</Option>
                <Option value="Asia/Choibalsan">Asia/Choibalsan</Option>
                <Option value="Asia/Chongqing">Asia/Chongqing</Option>
                <Option value="Asia/Chungking">Asia/Chungking</Option>
                <Option value="Asia/Colombo">Asia/Colombo</Option>
                <Option value="Asia/Dacca">Asia/Dacca</Option>
                <Option value="Asia/Damascus">Asia/Damascus</Option>
                <Option value="Asia/Dhaka">Asia/Dhaka</Option>
                <Option value="Asia/Dili">Asia/Dili</Option>
                <Option value="Asia/Dubai">Asia/Dubai</Option>
                <Option value="Asia/Dushanbe">Asia/Dushanbe</Option>
                <Option value="Asia/Famagusta">Asia/Famagusta</Option>
                <Option value="Asia/Gaza">Asia/Gaza</Option>
                <Option value="Asia/Harbin">Asia/Harbin</Option>
                <Option value="Asia/Hebron">Asia/Hebron</Option>
                <Option value="Asia/Ho_Chi_Minh">Asia/Ho_Chi_Minh</Option>
                <Option value="Asia/Hong_Kong">Asia/Hong_Kong</Option>
                <Option value="Asia/Hovd">Asia/Hovd</Option>
                <Option value="Asia/Irkutsk">Asia/Irkutsk</Option>
                <Option value="Asia/Istanbul">Asia/Istanbul</Option>
                <Option value="Asia/Jakarta">Asia/Jakarta</Option>
                <Option value="Asia/Jayapura">Asia/Jayapura</Option>
                <Option value="Asia/Jerusalem">Asia/Jerusalem</Option>
                <Option value="Asia/Kabul">Asia/Kabul</Option>
                <Option value="Asia/Kamchatka">Asia/Kamchatka</Option>
                <Option value="Asia/Karachi">Asia/Karachi</Option>
                <Option value="Asia/Kashgar">Asia/Kashgar</Option>
                <Option value="Asia/Kathmandu">Asia/Kathmandu</Option>
                <Option value="Asia/Katmandu">Asia/Katmandu</Option>
                <Option value="Asia/Khandyga">Asia/Khandyga</Option>
                <Option value="Asia/Kolkata">Asia/Kolkata</Option>
                <Option value="Asia/Krasnoyarsk">Asia/Krasnoyarsk</Option>
                <Option value="Asia/Kuala_Lumpur">Asia/Kuala_Lumpur</Option>
                <Option value="Asia/Kuching">Asia/Kuching</Option>
                <Option value="Asia/Kuwait">Asia/Kuwait</Option>
                <Option value="Asia/Macao">Asia/Macao</Option>
                <Option value="Asia/Macau">Asia/Macau</Option>
                <Option value="Asia/Magadan">Asia/Magadan</Option>
                <Option value="Asia/Makassar">Asia/Makassar</Option>
                <Option value="Asia/Manila">Asia/Manila</Option>
                <Option value="Asia/Muscat">Asia/Muscat</Option>
                <Option value="Asia/Nicosia">Asia/Nicosia</Option>
                <Option value="Asia/Novokuznetsk">Asia/Novokuznetsk</Option>
                <Option value="Asia/Novosibirsk">Asia/Novosibirsk</Option>
                <Option value="Asia/Omsk">Asia/Omsk</Option>
                <Option value="Asia/Oral">Asia/Oral</Option>
                <Option value="Asia/Phnom_Penh">Asia/Phnom_Penh</Option>
                <Option value="Asia/Pontianak">Asia/Pontianak</Option>
                <Option value="Asia/Pyongyang">Asia/Pyongyang</Option>
                <Option value="Asia/Qatar">Asia/Qatar</Option>
                <Option value="Asia/Qyzylorda">Asia/Qyzylorda</Option>
                <Option value="Asia/Rangoon">Asia/Rangoon</Option>
                <Option value="Asia/Riyadh">Asia/Riyadh</Option>
                <Option value="Asia/Saigon">Asia/Saigon</Option>
                <Option value="Asia/Sakhalin">Asia/Sakhalin</Option>
                <Option value="Asia/Samarkand">Asia/Samarkand</Option>
                <Option value="Asia/Seoul">Asia/Seoul</Option>
                <Option value="Asia/Shanghai">Asia/Shanghai</Option>
                <Option value="Asia/Singapore">Asia/Singapore</Option>
                <Option value="Asia/Srednekolymsk">Asia/Srednekolymsk</Option>
                <Option value="Asia/Taipei">Asia/Taipei</Option>
                <Option value="Asia/Tashkent">Asia/Tashkent</Option>
                <Option value="Asia/Tbilisi">Asia/Tbilisi</Option>
                <Option value="Asia/Tehran">Asia/Tehran</Option>
                <Option value="Asia/Tel_Aviv">Asia/Tel_Aviv</Option>
                <Option value="Asia/Thimbu">Asia/Thimbu</Option>
                <Option value="Asia/Thimphu">Asia/Thimphu</Option>
                <Option value="Asia/Tokyo">Asia/Tokyo</Option>
                <Option value="Asia/Tomsk">Asia/Tomsk</Option>
                <Option value="Asia/Ujung_Pandang">Asia/Ujung_Pandang</Option>
                <Option value="Asia/Ulaanbaatar">Asia/Ulaanbaatar</Option>
                <Option value="Asia/Ulan_Bator">Asia/Ulan_Bator</Option>
                <Option value="Asia/Urumqi">Asia/Urumqi</Option>
                <Option value="Asia/Ust-Nera">Asia/Ust-Nera</Option>
                <Option value="Asia/Vientiane">Asia/Vientiane</Option>
                <Option value="Asia/Vladivostok">Asia/Vladivostok</Option>
                <Option value="Asia/Yakutsk">Asia/Yakutsk</Option>
                <Option value="Asia/Yangon">Asia/Yangon</Option>
                <Option value="Asia/Yekaterinburg">Asia/Yekaterinburg</Option>
                <Option value="Asia/Yerevan">Asia/Yerevan</Option>
                <Option value="Atlantic/Azores">Atlantic/Azores</Option>
                <Option value="Atlantic/Bermuda">Atlantic/Bermuda</Option>
                <Option value="Atlantic/Canary">Atlantic/Canary</Option>
                <Option value="Atlantic/Cape_Verde">Atlantic/Cape_Verde</Option>
                <Option value="Atlantic/Faeroe">Atlantic/Faeroe</Option>
                <Option value="Atlantic/Faroe">Atlantic/Faroe</Option>
                <Option value="Atlantic/Jan_Mayen">Atlantic/Jan_Mayen</Option>
                <Option value="Atlantic/Madeira">Atlantic/Madeira</Option>
                <Option value="Atlantic/Reykjavik">Atlantic/Reykjavik</Option>
                <Option value="Atlantic/South_Georgia">Atlantic/South_Georgia</Option>
                <Option value="Atlantic/St_Helena">Atlantic/St_Helena</Option>
                <Option value="Atlantic/Stanley">Atlantic/Stanley</Option>
                <Option value="Australia/ACT">Australia/ACT</Option>
                <Option value="Australia/Adelaide">Australia/Adelaide</Option>
                <Option value="Australia/Brisbane">Australia/Brisbane</Option>
                <Option value="Australia/Broken_Hill">Australia/Broken_Hill</Option>
                <Option value="Australia/Canberra">Australia/Canberra</Option>
                <Option value="Australia/Currie">Australia/Currie</Option>
                <Option value="Australia/Darwin">Australia/Darwin</Option>
                <Option value="Australia/Eucla">Australia/Eucla</Option>
                <Option value="Australia/Hobart">Australia/Hobart</Option>
                <Option value="Australia/LHI">Australia/LHI</Option>
                <Option value="Australia/Lindeman">Australia/Lindeman</Option>
                <Option value="Australia/Lord_Howe">Australia/Lord_Howe</Option>
                <Option value="Australia/Melbourne">Australia/Melbourne</Option>
                <Option value="Australia/NSW">Australia/NSW</Option>
                <Option value="Australia/North">Australia/North</Option>
                <Option value="Australia/Perth">Australia/Perth</Option>
                <Option value="Australia/Queensland">Australia/Queensland</Option>
                <Option value="Australia/South">Australia/South</Option>
                <Option value="Australia/Sydney">Australia/Sydney</Option>
                <Option value="Australia/Tasmania">Australia/Tasmania</Option>
                <Option value="Australia/Victoria">Australia/Victoria</Option>
                <Option value="Australia/West">Australia/West</Option>
                <Option value="Australia/Yancowinna">Australia/Yancowinna</Option>
                <Option value="Brazil/Acre">Brazil/Acre</Option>
                <Option value="Brazil/DeNoronha">Brazil/DeNoronha</Option>
                <Option value="Brazil/East">Brazil/East</Option>
                <Option value="Brazil/West">Brazil/West</Option>
                <Option value="CET">CET</Option>
                <Option value="CST6CDT">CST6CDT</Option>
                <Option value="Canada/Atlantic">Canada/Atlantic</Option>
                <Option value="Canada/Central">Canada/Central</Option>
                <Option value="Canada/Eastern">Canada/Eastern</Option>
                <Option value="Canada/Mountain">Canada/Mountain</Option>
                <Option value="Canada/Newfoundland">Canada/Newfoundland</Option>
                <Option value="Canada/Pacific">Canada/Pacific</Option>
                <Option value="Canada/Saskatchewan">Canada/Saskatchewan</Option>
                <Option value="Canada/Yukon">Canada/Yukon</Option>
                <Option value="Chile/Continental">Chile/Continental</Option>
                <Option value="Chile/EasterIsland">Chile/EasterIsland</Option>
                <Option value="Cuba">Cuba</Option>
                <Option value="EET">EET</Option>
                <Option value="EST">EST</Option>
                <Option value="EST5EDT">EST5EDT</Option>
                <Option value="Egypt">Egypt</Option>
                <Option value="Eire">Eire</Option>
                <Option value="Etc/GMT">Etc/GMT</Option>
                <Option value="Etc/GMT+0">Etc/GMT+0</Option>
                <Option value="Etc/GMT+1">Etc/GMT+1</Option>
                <Option value="Etc/GMT+10">Etc/GMT+10</Option>
                <Option value="Etc/GMT+11">Etc/GMT+11</Option>
                <Option value="Etc/GMT+12">Etc/GMT+12</Option>
                <Option value="Etc/GMT+2">Etc/GMT+2</Option>
                <Option value="Etc/GMT+3">Etc/GMT+3</Option>
                <Option value="Etc/GMT+4">Etc/GMT+4</Option>
                <Option value="Etc/GMT+5">Etc/GMT+5</Option>
                <Option value="Etc/GMT+6">Etc/GMT+6</Option>
                <Option value="Etc/GMT+7">Etc/GMT+7</Option>
                <Option value="Etc/GMT+8">Etc/GMT+8</Option>
                <Option value="Etc/GMT+9">Etc/GMT+9</Option>
                <Option value="Etc/GMT-0">Etc/GMT-0</Option>
                <Option value="Etc/GMT-1">Etc/GMT-1</Option>
                <Option value="Etc/GMT-10">Etc/GMT-10</Option>
                <Option value="Etc/GMT-11">Etc/GMT-11</Option>
                <Option value="Etc/GMT-12">Etc/GMT-12</Option>
                <Option value="Etc/GMT-13">Etc/GMT-13</Option>
                <Option value="Etc/GMT-14">Etc/GMT-14</Option>
                <Option value="Etc/GMT-2">Etc/GMT-2</Option>
                <Option value="Etc/GMT-3">Etc/GMT-3</Option>
                <Option value="Etc/GMT-4">Etc/GMT-4</Option>
                <Option value="Etc/GMT-5">Etc/GMT-5</Option>
                <Option value="Etc/GMT-6">Etc/GMT-6</Option>
                <Option value="Etc/GMT-7">Etc/GMT-7</Option>
                <Option value="Etc/GMT-8">Etc/GMT-8</Option>
                <Option value="Etc/GMT-9">Etc/GMT-9</Option>
                <Option value="Etc/GMT0">Etc/GMT0</Option>
                <Option value="Etc/Greenwich">Etc/Greenwich</Option>
                <Option value="Etc/UCT">Etc/UCT</Option>
                <Option value="Etc/UTC">Etc/UTC</Option>
                <Option value="Etc/Universal">Etc/Universal</Option>
                <Option value="Etc/Zulu">Etc/Zulu</Option>
                <Option value="Europe/Amsterdam">Europe/Amsterdam</Option>
                <Option value="Europe/Andorra">Europe/Andorra</Option>
                <Option value="Europe/Astrakhan">Europe/Astrakhan</Option>
                <Option value="Europe/Athens">Europe/Athens</Option>
                <Option value="Europe/Belfast">Europe/Belfast</Option>
                <Option value="Europe/Belgrade">Europe/Belgrade</Option>
                <Option value="Europe/Berlin">Europe/Berlin</Option>
                <Option value="Europe/Bratislava">Europe/Bratislava</Option>
                <Option value="Europe/Brussels">Europe/Brussels</Option>
                <Option value="Europe/Bucharest">Europe/Bucharest</Option>
                <Option value="Europe/Budapest">Europe/Budapest</Option>
                <Option value="Europe/Busingen">Europe/Busingen</Option>
                <Option value="Europe/Chisinau">Europe/Chisinau</Option>
                <Option value="Europe/Copenhagen">Europe/Copenhagen</Option>
                <Option value="Europe/Dublin">Europe/Dublin</Option>
                <Option value="Europe/Gibraltar">Europe/Gibraltar</Option>
                <Option value="Europe/Guernsey">Europe/Guernsey</Option>
                <Option value="Europe/Helsinki">Europe/Helsinki</Option>
                <Option value="Europe/Isle_of_Man">Europe/Isle_of_Man</Option>
                <Option value="Europe/Istanbul">Europe/Istanbul</Option>
                <Option value="Europe/Jersey">Europe/Jersey</Option>
                <Option value="Europe/Kaliningrad">Europe/Kaliningrad</Option>
                <Option value="Europe/Kiev">Europe/Kiev</Option>
                <Option value="Europe/Kirov">Europe/Kirov</Option>
                <Option value="Europe/Lisbon">Europe/Lisbon</Option>
                <Option value="Europe/Ljubljana">Europe/Ljubljana</Option>
                <Option value="Europe/London">Europe/London</Option>
                <Option value="Europe/Luxembourg">Europe/Luxembourg</Option>
                <Option value="Europe/Madrid">Europe/Madrid</Option>
                <Option value="Europe/Malta">Europe/Malta</Option>
                <Option value="Europe/Mariehamn">Europe/Mariehamn</Option>
                <Option value="Europe/Minsk">Europe/Minsk</Option>
                <Option value="Europe/Monaco">Europe/Monaco</Option>
                <Option value="Europe/Moscow">Europe/Moscow</Option>
                <Option value="Europe/Nicosia">Europe/Nicosia</Option>
                <Option value="Europe/Oslo">Europe/Oslo</Option>
                <Option value="Europe/Paris">Europe/Paris</Option>
                <Option value="Europe/Podgorica">Europe/Podgorica</Option>
                <Option value="Europe/Prague">Europe/Prague</Option>
                <Option value="Europe/Riga">Europe/Riga</Option>
                <Option value="Europe/Rome">Europe/Rome</Option>
                <Option value="Europe/Samara">Europe/Samara</Option>
                <Option value="Europe/San_Marino">Europe/San_Marino</Option>
                <Option value="Europe/Sarajevo">Europe/Sarajevo</Option>
                <Option value="Europe/Saratov">Europe/Saratov</Option>
                <Option value="Europe/Simferopol">Europe/Simferopol</Option>
                <Option value="Europe/Skopje">Europe/Skopje</Option>
                <Option value="Europe/Sofia">Europe/Sofia</Option>
                <Option value="Europe/Stockholm">Europe/Stockholm</Option>
                <Option value="Europe/Tallinn">Europe/Tallinn</Option>
                <Option value="Europe/Tirane">Europe/Tirane</Option>
                <Option value="Europe/Tiraspol">Europe/Tiraspol</Option>
                <Option value="Europe/Ulyanovsk">Europe/Ulyanovsk</Option>
                <Option value="Europe/Uzhgorod">Europe/Uzhgorod</Option>
                <Option value="Europe/Vaduz">Europe/Vaduz</Option>
                <Option value="Europe/Vatican">Europe/Vatican</Option>
                <Option value="Europe/Vienna">Europe/Vienna</Option>
                <Option value="Europe/Vilnius">Europe/Vilnius</Option>
                <Option value="Europe/Volgograd">Europe/Volgograd</Option>
                <Option value="Europe/Warsaw">Europe/Warsaw</Option>
                <Option value="Europe/Zagreb">Europe/Zagreb</Option>
                <Option value="Europe/Zaporozhye">Europe/Zaporozhye</Option>
                <Option value="Europe/Zurich">Europe/Zurich</Option>
                <Option value="GB">GB</Option>
                <Option value="GB-Eire">GB-Eire</Option>
                <Option value="GMT">GMT</Option>
                <Option value="GMT+0">GMT+0</Option>
                <Option value="GMT-0">GMT-0</Option>
                <Option value="GMT0">GMT0</Option>
                <Option value="Greenwich">Greenwich</Option>
                <Option value="HST">HST</Option>
                <Option value="Hongkong">Hongkong</Option>
                <Option value="Iceland">Iceland</Option>
                <Option value="Indian/Antananarivo">Indian/Antananarivo</Option>
                <Option value="Indian/Chagos">Indian/Chagos</Option>
                <Option value="Indian/Christmas">Indian/Christmas</Option>
                <Option value="Indian/Cocos">Indian/Cocos</Option>
                <Option value="Indian/Comoro">Indian/Comoro</Option>
                <Option value="Indian/Kerguelen">Indian/Kerguelen</Option>
                <Option value="Indian/Mahe">Indian/Mahe</Option>
                <Option value="Indian/Maldives">Indian/Maldives</Option>
                <Option value="Indian/Mauritius">Indian/Mauritius</Option>
                <Option value="Indian/Mayotte">Indian/Mayotte</Option>
                <Option value="Indian/Reunion">Indian/Reunion</Option>
                <Option value="Iran">Iran</Option>
                <Option value="Israel">Israel</Option>
                <Option value="Jamaica">Jamaica</Option>
                <Option value="Japan">Japan</Option>
                <Option value="Kwajalein">Kwajalein</Option>
                <Option value="Libya">Libya</Option>
                <Option value="MET">MET</Option>
                <Option value="MST">MST</Option>
                <Option value="MST7MDT">MST7MDT</Option>
                <Option value="Mexico/BajaNorte">Mexico/BajaNorte</Option>
                <Option value="Mexico/BajaSur">Mexico/BajaSur</Option>
                <Option value="Mexico/General">Mexico/General</Option>
                <Option value="NZ">NZ</Option>
                <Option value="NZ-CHAT">NZ-CHAT</Option>
                <Option value="Navajo">Navajo</Option>
                <Option value="PRC">PRC</Option>
                <Option value="PST8PDT">PST8PDT</Option>
                <Option value="Pacific/Apia">Pacific/Apia</Option>
                <Option value="Pacific/Auckland">Pacific/Auckland</Option>
                <Option value="Pacific/Bougainville">Pacific/Bougainville</Option>
                <Option value="Pacific/Chatham">Pacific/Chatham</Option>
                <Option value="Pacific/Chuuk">Pacific/Chuuk</Option>
                <Option value="Pacific/Easter">Pacific/Easter</Option>
                <Option value="Pacific/Efate">Pacific/Efate</Option>
                <Option value="Pacific/Enderbury">Pacific/Enderbury</Option>
                <Option value="Pacific/Fakaofo">Pacific/Fakaofo</Option>
                <Option value="Pacific/Fiji">Pacific/Fiji</Option>
                <Option value="Pacific/Funafuti">Pacific/Funafuti</Option>
                <Option value="Pacific/Galapagos">Pacific/Galapagos</Option>
                <Option value="Pacific/Gambier">Pacific/Gambier</Option>
                <Option value="Pacific/Guadalcanal">Pacific/Guadalcanal</Option>
                <Option value="Pacific/Guam">Pacific/Guam</Option>
                <Option value="Pacific/Honolulu">Pacific/Honolulu</Option>
                <Option value="Pacific/Johnston">Pacific/Johnston</Option>
                <Option value="Pacific/Kiritimati">Pacific/Kiritimati</Option>
                <Option value="Pacific/Kosrae">Pacific/Kosrae</Option>
                <Option value="Pacific/Kwajalein">Pacific/Kwajalein</Option>
                <Option value="Pacific/Majuro">Pacific/Majuro</Option>
                <Option value="Pacific/Marquesas">Pacific/Marquesas</Option>
                <Option value="Pacific/Midway">Pacific/Midway</Option>
                <Option value="Pacific/Nauru">Pacific/Nauru</Option>
                <Option value="Pacific/Niue">Pacific/Niue</Option>
                <Option value="Pacific/Norfolk">Pacific/Norfolk</Option>
                <Option value="Pacific/Noumea">Pacific/Noumea</Option>
                <Option value="Pacific/Pago_Pago">Pacific/Pago_Pago</Option>
                <Option value="Pacific/Palau">Pacific/Palau</Option>
                <Option value="Pacific/Pitcairn">Pacific/Pitcairn</Option>
                <Option value="Pacific/Pohnpei">Pacific/Pohnpei</Option>
                <Option value="Pacific/Ponape">Pacific/Ponape</Option>
                <Option value="Pacific/Port_Moresby">Pacific/Port_Moresby</Option>
                <Option value="Pacific/Rarotonga">Pacific/Rarotonga</Option>
                <Option value="Pacific/Saipan">Pacific/Saipan</Option>
                <Option value="Pacific/Samoa">Pacific/Samoa</Option>
                <Option value="Pacific/Tahiti">Pacific/Tahiti</Option>
                <Option value="Pacific/Tarawa">Pacific/Tarawa</Option>
                <Option value="Pacific/Tongatapu">Pacific/Tongatapu</Option>
                <Option value="Pacific/Truk">Pacific/Truk</Option>
                <Option value="Pacific/Wake">Pacific/Wake</Option>
                <Option value="Pacific/Wallis">Pacific/Wallis</Option>
                <Option value="Pacific/Yap">Pacific/Yap</Option>
                <Option value="Poland">Poland</Option>
                <Option value="Portugal">Portugal</Option>
                <Option value="ROC">ROC</Option>
                <Option value="ROK">ROK</Option>
                <Option value="Singapore">Singapore</Option>
                <Option value="Turkey">Turkey</Option>
                <Option value="UCT">UCT</Option>
                <Option value="US/Alaska">US/Alaska</Option>
                <Option value="US/Aleutian">US/Aleutian</Option>
                <Option value="US/Arizona">US/Arizona</Option>
                <Option value="US/Central">US/Central</Option>
                <Option value="US/East-Indiana">US/East-Indiana</Option>
                <Option value="US/Eastern">US/Eastern</Option>
                <Option value="US/Hawaii">US/Hawaii</Option>
                <Option value="US/Indiana-Starke">US/Indiana-Starke</Option>
                <Option value="US/Michigan">US/Michigan</Option>
                <Option value="US/Mountain">US/Mountain</Option>
                <Option value="US/Pacific">US/Pacific</Option>
                <Option value="US/Pacific-New">US/Pacific-New</Option>
                <Option value="US/Samoa">US/Samoa</Option>
                <Option value="UTC">UTC</Option>
                <Option value="Universal">Universal</Option>
                <Option value="W-SU">W-SU</Option>
                <Option value="WET">WET</Option>
              </select>

              <div class="top-spacer-25" v-if="tz != tzGuess">
                <h4>Zeitzone Vorschlag</h4>
                Es wurde automatisch die Zeitzone {{ tzGuess }} erkannt. Soll diese gesetzt werden?<br /><br />

                <button @click="setTimezone(tzGuess)">Setzen</button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <h2 class="top-spacer-50">Wetter</h2>
          </div>
        </div>
        <div class="white_wrapper light-shadow" style="background: #fff">
          <div class="row">
            <div class="col-lg-12" style="text-align: left">
              <h4>Automatische Ermittlung</h4>

              Dein Standort wird aktuell auf ca. 1km genau automatisch ermittelt, wenn du Workouts mit GPS-Daten
              hochlädst. Alternativ kannst du auch einen Standort festlegen, indem du eine Stadt angibst. Achtung: Bei
              virtuellen Workouts (z.B. Zwift) kann der automatische Standort ganz woanders sein, als du bist. Wir
              empfehlen, einen Ort festzulegen.<br /><br />
              <div v-if="!user.specified_location_string">
                Sobald du eine Stadt festgelegt hast, wird die automatische Ermittlung nicht mehr verwendet.<br /><br />
              </div>
              <div v-if="user.specified_location_string">
                <strong
                  >Zurzeit wird das Wetter für {{ user.specified_location_string }} geladen. Die automatische Ermittlung
                  ist deaktiviert.</strong
                >
                <br />
                <div style="cursor: pointer; color: blue" @click="setLocation(0, 0, '')">
                  Automatische Ermittlung aktivieren
                </div>
              </div>
            </div>
            <div class="col-lg-12 top-spacer-25">
              <h4>Stadt</h4>
              <input v-model="location" type="text" placeholder="Name der Stadt" /><br /><br />
              <button @click="searchLocation">Suchen</button>
              <div v-if="locationMessage" class="top-spacer-25">
                <strong>{{ locationMessage }}</strong>
              </div>

              <div v-if="cities" class="top-spacer-25">
                <div v-for="city of cities" :key="city.lat + city.lon">
                  <div class="row top-spacer-15">
                    <div class="col-lg-6">
                      {{ city.name }}<br /><small>{{ city.state }} / {{ city.country }}</small>
                    </div>
                    <div class="col-lg-6">
                      <button @click="setLocation(city.lat, city.lon, city.name)">Wählen</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <h2 class="top-spacer-50">{{ $t("Verbindungen") }}</h2>
          </div>
        </div>
        <div class="row" v-if="user">
          <div class="col-lg-12">
            <div class="white_wrapper light-shadow">
              Leider erlaubt Zwift uns bisher keine Möglichkeit zur Verbindung. Zwift hat aber angekündigt, dass sie
              bald eine Schnittstelle anbieten wollen.
            </div>
            <div class="white_wrapper light-shadow top-spacer-25">
              <div class="row">
                <div class="col-lg-3">
                  <img src="https://assets.pushinglimits.club/garminconnect.png" style="max-width: 120px" />
                </div>
                <div class="col-lg-9">
                  <div class="d-block d-md-none">&nbsp;</div>

                  <h4>Garmin Connect</h4>
                  <span v-if="user.garminUserToken"
                    >Du bist mit Garmin Connect verbunden.<br /><br />

                    Bitte wähle, ob du nach Herzfrequenz oder Leistung / Geschwindigkeit trainieren möchtest.
                    <br /><br />
                    Laufen:<br />
                    <select v-model="garminRunningTarget" style="width: 75%">
                      <option value="PACE">Geschwindigkeit</option>
                      <option value="HEART_RATE">Herzfrequenz</option></select
                    ><br /><br />

                    Radfahren:<br />
                    <select v-model="garminCyclingTarget" style="width: 75%">
                      <option value="POWER_3S">Leistung</option>
                      <option value="HEART_RATE">Herzfrequenz</option></select
                    ><br /><br />

                    Garmin benötigt für Schwimmeinheiten die Länge des Pools. Wähle hier die Länge, die wir zu deinen
                    Geräten senden sollen<br /><br />
                    <select v-model="poolLength">
                      <option value="16">16m</option>
                      <option value="20">20m</option>
                      <option value="25">25m</option>
                      <option value="33">33m</option>
                      <option value="50">50m</option></select
                    >&nbsp;<br /><br />
                    <button @click="saveGarminSettings()">Speichern</button> </span
                  ><br /><span v-if="poolLengthMessage"><br />{{ poolLengthMessage }}</span
                  ><br />
                  <span v-if="!user.garminUserToken">
                    Du bist noch nicht mit Garmin Connect verbunden. Um alle Funktionen nutzen zu können, musst du eine
                    Verbindung herstellen. Wir können dann deine Trainingseinheiten synchronisieren und auswerten.</span
                  ><br /><br />
                  <button v-if="!user.garminUserToken" @click="connectGarmin()">Verbinden</button>
                  <button v-if="user.garminUserToken" @click="disconnectGarmin()">Trennen</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="user && (platform == 'ios' || healthkitConnected)">
          <div class="col-lg-12">
            <div class="white_wrapper light-shadow top-spacer-25">
              <div class="row">
                <div class="col-lg-3">Apple Watch</div>
                <div class="col-lg-9">
                  <div class="d-block d-md-none">&nbsp;</div>

                  <div v-if="!isIos17OrNewer && platform == 'ios'" style="color: red">
                    Diese Funktion ist nur auf iOS 17 oder neuer verfügbar.
                  </div>

                  <h4>Apple Watch</h4>
                  <span v-if="healthkitConnected">
                    Du bist mit HealthKit verbunden.
                    <strong v-if="platform != 'ios'"
                      ><br />Öffne die App auf deinem iPhone, um Workouts zu synchronisieren.</strong
                    ></span
                  >
                  <span v-if="!healthkitConnected">
                    Du bist noch nicht mit Apple / HealthKit verbunden. Um alle Funktionen nutzen zu können, musst du
                    eine Verbindung herstellen. </span
                  ><br /><br />
                  <div v-if="platform == 'ios'">
                    <button v-if="!healthkitConnected" @click="connectHealthkit()">Verbinden</button>
                    <button v-if="healthkitConnected" @click="disconnectHealthkit()">Trennen</button>
                    <hr />

                    <div v-if="healthkitConnected">
                      <div v-if="false">
                        <h4>Zieltypen</h4>
                        Laufen:<br />
                        <select v-model="runTargetIsHeartRate" style="width: 75%">
                          <option value="false">Geschwindigkeit</option>
                          <option value="true">Herzfrequenz</option>
                        </select>
                        <br /><br />

                        Radfahren:<br />
                        <select v-model="runTargetIsHeartRate" style="width: 75%">
                          <option value="false">Leistung</option>
                          <option value="true">Herzfrequenz</option>
                        </select>
                        <br /><br />

                        <div v-if="appleTargetHeartRateLoading" class="spinner"></div>
                        <hr />

                      </div>

                      Wurden einige Workouts nicht korrekt mit deiner Apple Watch gesynct?<br /><br />
                      <button @click="syncWorkouts">
                        <span v-if="isSyncingApple">
                          <div class="spinner" style="display: inline-block; margin-right: 5px"></div>
                        </span>
                        <span v-else> Synchronisieren </span>
                      </button>
                      <div v-if="isSyncingApple" class="spinner"></div>
                      <hr />
                      Wurden einige Workouts nicht in den Club übertragen?<br /><br />
                      <button @click="fetchAppleWorkouts">Workouts abrufen</button>
                    </div>
                  </div>
                  <div v-if="platform != 'ios'">
                    Du kannst dich nur auf einem iOS-Gerät mit HealthKit verbinden bzw. die Verbindung trennen und
                    Workouts synchronisieren.
                  </div>
                  <div v-if="healthkitConnecting">
                    <div class="top-spacer-25 spinner-border text-primary" role="status"></div>
                    <br />Verbinde und synchronisiere...
                  </div>
                </div>
              </div>

              <div v-if="showWorkoutModal" class="workout-modal" @click="closeWorkoutModal">
                <div class="workout-modal-content" @click.stop>
                  <button class="close-button" @click="closeWorkoutModal">&times;</button>

                  <h2 class="modal-title">Apple Watch Workouts</h2>
                  <button @click="importAllWorkouts" class="import-all-button" v-if="hasNewWorkouts">
                    Alle importieren
                  </button>
                  <div class="workout-list">
                    <div v-for="workout in appleWorkouts" :key="workout.id" class="workout-item">
                      <div class="workout-info row">
                        <div class="col-3">
                          <div style="display: flex; align-items: center; margin-top: 15px">
                            <span v-if="getWorkoutTypeName(workout.workoutActivityType) == 'Kraft'">
                              <img src="@/assets/images/strength.png" style="width: 22px; margin-right: 10px" />
                            </span>
                            <span v-if="getWorkoutTypeName(workout.workoutActivityType) == 'Laufen'">
                              <img src="@/assets/images/run.png" style="width: 22px; margin-right: 10px" />
                            </span>
                            <span v-if="getWorkoutTypeName(workout.workoutActivityType) == 'Schwimmen'">
                              <img src="@/assets/images/swim.png" style="width: 22px; margin-right: 10px" />
                            </span>
                            <span v-if="getWorkoutTypeName(workout.workoutActivityType) == 'Radfahren'">
                              <img src="@/assets/images/bike.png" style="width: 22px; margin-right: 10px" />
                            </span>
                            <span v-if="getWorkoutTypeName(workout.workoutActivityType) == 'Aktivität'">
                              <img src="@/assets/images/activity.png" style="width: 22px; margin-right: 10px" />
                            </span>
                          </div>
                          <h3>
                            <!--{{ getWorkoutTypeName(workout.workoutActivityType) }}-->
                          </h3>
                        </div>
                        <div class="col-9">
                          <p>{{ new Date(workout.startDate).toLocaleString() }}</p>
                          <p>Dauer: {{ formatDuration(workout.duration) }}</p>
                          <p>Distanz: {{ formatDistance(workout.totalDistance) }} km</p>
                        </div>
                      </div>
                      <button v-if="!workout.isImported" @click="importWorkout(workout)" class="import-button">
                        Importieren
                      </button>
                      <span v-else class="imported-label">Bereits importiert</span>
                    </div>
                  </div>
                  <button @click="closeWorkoutModal" class="close-modal-button">Schließen</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="user && platform != 'ios' && !healthkitConnected">
          <div class="col-lg-12">
            <div class="white_wrapper light-shadow top-spacer-25">
              <div class="row">
                <div class="col-lg-3"><h3>Apple Watch</h3></div>
                <div class="col-lg-9">
                  <div class="d-block d-md-none">&nbsp;</div>

                  <h4>Apple Watch</h4>
                  Öffne die iOS-App, um dich mit der Apple Watch zu verbinden.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row top-spacer-25" v-if="user">
          <div class="col-lg-12">
            <div class="white_wrapper light-shadow">
              <div class="row">
                <div class="col-lg-3">
                  <img
                    class="top-spacer-25"
                    src="https://assets.pushinglimits.club/polar.png"
                    style="max-width: 120px"
                  />
                </div>
                <div class="col-lg-9">
                  <div class="d-block d-md-none">&nbsp;</div>

                  <h4>Polar Flow</h4>
                  <span v-if="user.polarToken"
                    >Du bist mit Polar Flow verbunden.<br /><strong>Hinweis:</strong> Leider ermöglicht Polar es uns
                    noch nicht, Workouts auf deine Geräte zu übertragen.</span
                  >
                  <span v-if="!user.polarToken">
                    Du bist noch nicht mit Polar Flow verbunden. Um alle Funktionen nutzen zu können, musst du eine
                    Verbindung herstellen. Wir können dann deine Trainingseinheiten synchronisieren und auswerten. Wir
                    synchronisieren aktuell keine Einheiten zu Polar.</span
                  ><br /><br />
                  <button v-if="!user.polarToken" @click="connectPolar()">Verbinden</button>
                  <button v-if="user.polarToken" @click="disconnectPolar()">Trennen</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" v-if="user">
          <div class="col-lg-12 top-spacer-25">
            <div class="white_wrapper light-shadow">
              <div class="row">
                <div class="col-lg-3">
                  <img class="top-spacer-25" src="@/assets/images/wahoo.png" style="max-width: 120px" />
                </div>
                <div class="col-lg-9">
                  <div class="d-block d-md-none">&nbsp;</div>

                  <h4>Wahoo</h4>
                  <span v-if="user.wahooToken"
                    >Du bist mit Wahoo verbunden.<br /><strong>Hinweis</strong>: Wir übertragen Workouts von Wahoo zu
                    uns und geplante Radeinheiten zu Wahoo. Die Anbindung ist noch ganz neu - melde uns gerne mögliche
                    Probleme! <br />
                    <br />
                    Bitte wähle, ob du nach Herzfrequenz oder Leistung trainieren möchtest. <br /><br />
                    Radfahren:<br />
                    <select v-model="wahooCyclingTarget" style="width: 75%">
                      <option value="POWER">Leistung</option>
                      <option value="HEART_RATE">Herzfrequenz</option>
                    </select>
                    <br />
                    {{ wahooMessage }}<br />
                    <button @click="saveWahooSettings()">Speichern</button> <br /><br />
                  </span>
                  <span v-if="!user.wahooToken">
                    Du bist noch nicht mit Wahoo verbunden. Um alle Funktionen nutzen zu können, musst du eine
                    Verbindung herstellen. Wir können dann deine Trainingseinheiten synchronisieren und auswerten. Wir
                    synchronisieren aktuell keine Laufeinheiten zu Wahoo, Radeinheiten werden aber übertragen.<br /></span
                  ><br /><br />
                  <button v-if="!user.wahooToken" @click="connectWahoo()">Verbinden</button>
                  <button v-if="user.wahooToken" @click="disconnectWahoo()">Trennen</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row top-spacer-25">
          <div class="col-lg-12">
            <div class="white_wrapper light-shadow">
              <div class="row">
                <div class="col-lg-3">
                  <img src="@/assets/images/coros.png" style="max-width: 120px" />
                </div>
                <div class="col-lg-9">
                  <div class="d-block d-md-none">&nbsp;</div>

                  <h4>COROS</h4>
                  <span v-if="user.corosConnected"
                    >Du bist mit COROS verbunden. Deine Einheiten werden von COROS zu uns synchronisiert. Wir
                    synchronisieren Einheiten von COROS zu uns und geplante Einheiten zu COROS.
                  </span>
                  <span v-if="!user.corosConnected">
                    Du bist noch nicht mit COROS verbunden. Um alle Funktionen nutzen zu können, musst du eine
                    Verbindung herstellen. Wir können dann deine Workouts synchronisieren und auswerten.</span
                  ><br /><br />
                  <button v-if="!user.corosConnected" @click="connectCoros()">Verbinden</button>
                  <button v-if="user.corosConnected" @click="disconnectCoros()">Trennen</button>
                  <br /><br /><small
                    >Hinweis: Die Verbindung zu COROS ist noch neu. Melde uns gerne Probleme oder Fehler per Mail</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row top-spacer-25">
          <div class="col-lg-12">
            <div class="white_wrapper light-shadow">
              <div class="row">
                <div class="col-lg-3">
                  <img src="@/assets/images/suunto.png" style="max-width: 120px" />
                </div>
                <div class="col-lg-9">
                  <div class="d-block d-md-none">&nbsp;</div>

                  <h4>Suunto</h4>
                  <span v-if="user.suuntoConnected"
                    >Du bist mit Suunto verbunden. Deine Einheiten werden von Suunto zu uns synchronisiert. Wir
                    synchronisieren aktuell keine Einheiten zu Suunto.
                  </span>
                  <span v-if="!user.suuntoConnected">
                    Du bist noch nicht mit Suunto verbunden. Um alle Funktionen nutzen zu können, musst du eine
                    Verbindung herstellen. Wir können dann deine Workouts synchronisieren und auswerten. Wir
                    synchronisieren aktuell keine Einheiten zu Suunto</span
                  ><br /><br />
                  <button v-if="!user.suuntoConnected" @click="connectSuunto()">Verbinden</button>
                  <button v-if="user.suuntoConnected" @click="disconnectSuunto()">Trennen</button>
                  <br /><br /><small
                    >Hinweis: Die Verbindung zu Suunto ist noch neu. Melde uns gerne Probleme oder Fehler per
                    Mail</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="white_wrapper light-shadow top-spacer-25" v-if="user">
          <div class="row">
            <div class="col-lg-3 top-spacer-25">
              <img src="@/assets/images/logo_strava.png" style="max-width: 120px" />
            </div>
            <div class="col-lg-9">
              <div class="d-block d-md-none">&nbsp;</div>

              <h4>Strava</h4>
              <span v-if="user.stravaToken"
                >Du bist mit Strava verbunden.<br />
                Deine Radraum Einheiten werden zu Strava übertragen und wir laden neue Einheiten von Strava.
                <br /><strong>Hinweis:</strong> Leider haben wir nur ein begrenztes Kontigent von Aufrufen. Daher kann
                es passieren, dass wir technisch nicht in der Lage sind, deine Einheiten über Strava zu
                synchronisieren.</span
              >
              <span v-if="!user.stravaToken">
                Du bist noch nicht mit Strava verbunden. <br />Wenn du dich mit Strava verbindest, übertragen wir deine
                Radraum-Einheiten zu Strava. <br /><br />

                <br />
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="flexCheckChecked" v-model="stravaCheck" />
                  <label class="form-check-label" for="flexCheckChecked">
                    Ich bin damit einverstanden, dass meine Radraum-Workouts inklusive aller Daten zu Strava übertragen
                    werden. Strava kann die Daten danach nach eigenem Ermessen im Rahmen ihrer Datenschutzrichtlinie
                    verwenden.
                  </label>
                </div>
                <br /><br />
                <a v-if="!user.stravaToken && stravaCheck" @click="connectStrava()">
                  <img src="@/assets/images/strava.png" />
                </a>
              </span>
              <br v-if="user.stravaToken" />
              <br v-if="user.stravaToken" />
              <button v-if="user.stravaToken" @click="disconnectStrava()">Trennen</button>
            </div>
          </div>
        </div>

        <div class="white_wrapper light-shadow top-spacer-25" v-if="user">
          <div class="row">
            <div class="col-lg-3 top-spacer-25">
              <img src="@/assets/images/whoop.png" style="max-width: 120px" />
            </div>
            <div class="col-lg-9">
              <div class="d-block d-md-none">&nbsp;</div>

              <h4>WHOOP</h4>
              <span v-if="user.whoopToken"
                >Du bist mit Whoop verbunden. Wir laden deine Schlaf- und Erholungsdaten, aber keine Workouts.<br /><br />

                <button @click="disconnectWhoop()">Trennen</button>
              </span>

              <span v-if="!user.whoopToken">
                Du bist noch nicht mit Whoop verbunden. <br />Wenn du dich mit Whoop verbindest, übertragen wir deine
                Schlaf- und Erholungsdaten zu uns. <br /><br />

                <button @click="connectWhoop()">Verbinden</button>
              </span>
            </div>
          </div>
        </div>

        <div class="white_wrapper light-shadow top-spacer-25" v-if="user">
          <div class="row">
            <div class="col-lg-3 top-spacer-25">
              <img src="https://assets.pushinglimits.club/ictrainer.webp" style="max-width: 120px" />
            </div>
            <div class="col-lg-9">
              <div class="d-block d-md-none">&nbsp;</div>

              <h4>icTrainer</h4>
              <span v-if="icTrainerConnected"
                >Du bist mit icTrainer verbunden.<br />
                Wir erhalten die bei icTrainer absolvierten Einheiten und senden deine geplanten Rad- und Laufeinheiten
                zu icTrainer.</span
              >
              <span v-if="!icTrainerConnected">
                Du bist noch nicht mit icTrainer verbunden. <br />Wenn du dich mit icTrainer verbindest, erhalten wir
                die bei icTrainer absolvierten Einheiten und senden deine geplanten Rad- und Laufeinheiten zu icTrainer.
                Du kannst dich aus icTrainer heraus mit uns verbinden.<br /><br />
              </span>
              <br v-if="icTrainerConnected" />
              <br v-if="icTrainerConnected" />
              <button v-if="icTrainerConnected" @click="disconnectICTrainer()">Trennen</button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6">
        <div class="row">
          <div class="col-lg-12" v-if="platform != 'web' && platform != 'electron'">
            <div class="white_wrapper top-spacer-25">
              <h2 class="top-spacer-25">Mitgliedschaft</h2>

              <div
                v-if="
                  !$moment(user.premiumAccountUntil).isAfter($moment()) &&
                  $moment(user.freeAccountUntil).isAfter($moment())
                "
              >
                Zurzeit läuft deine kostenlose Testphase. Nach der Testphase sind die meisten Funktionen nicht mehr
                verfügbar, wenn du kein Abo abschließt.<br /><br />
              </div>

              <div
                class="white_wrapper top-spacer-25"
                v-if="$parent.appstoreState && $parent.appstoreState.error && $parent.appstoreState.error.length > 3"
                style="color: red"
              >
                {{ $parent.appstoreState.error }}
                <br />
                <br />

                <small @click="restore()">Schon abonniert? Wiederherstellen</small><br />
              </div>

              <div
                v-if="
                  $parent.appstoreState &&
                  $parent.appstoreState.product1 &&
                  $parent.appstoreState.product1.offers &&
                  $parent.appstoreState.product1.offers.length > 0 &&
                  $parent.appstoreState.product2 &&
                  $parent.appstoreState.product2.offers &&
                  $parent.appstoreState.product2.offers.length > 0 &&
                  $parent.appstoreState.product3 &&
                  $parent.appstoreState.product3.offers &&
                  $parent.appstoreState.product3.offers.length > 0 &&
                  $parent.appstoreState.product3.offers[0] &&
                  $moment(user.premiumAccountUntil).isBefore($moment())
                "
              >
                <i>Kostet im Jahr so viel wie eine Leistungsdiagnostik, bringt aber mehr</i><br /><br />
                <strong>{{ $parent.appstoreState.product1.title }}</strong
                ><br />
                {{ $parent.appstoreState.product1.description }}<br />
                <!--{{ $parent.appstoreState.product1.raw.price }}/Monat<br /><br />-->

                {{
                  $parent.appstoreState.product1.offers[0].pricingPhases[0].price || "Preis wird geladen..."
                }}/Monat<br /><br />
                <button @click="order()">Abonnieren</button><br />
                <br />
                - oder günstiger im Jahresabo - <br /><br />
                <strong>{{ $parent.appstoreState.product2.title }}</strong
                ><br />
                {{ $parent.appstoreState.product2.description }}<br />
                <!--{{ $parent.appstoreState.product2.raw.price }}/Jahr<br /><br />-->
                {{
                  $parent.appstoreState.product2.offers[0].pricingPhases[0].price || "Preis wird geladen..."
                }}/Jahr<br /><br />

                <button @click="orderYearly()">Abonnieren</button><br />
                <br />
                - oder kürzer im 6-Monate Abo - <br /><br />
                <strong>{{ $parent.appstoreState.product3.title }}</strong
                ><br />
                {{ $parent.appstoreState.product3.description }}<br />
                <!--{{ $parent.appstoreState.product3.raw.price }}/Jahr<br /><br />-->
                {{
                  $parent.appstoreState.product3.offers[0].pricingPhases[0].price || "Preis wird geladen..."
                }}/6-Monate<br /><br />

                <button @click="orderHalfYearly()">Abonnieren</button><br />
                <br />
                <small @click="restore()">Schon abonniert? Wiederherstellen</small><br />
                <br />

                <div v-if="orderLoading" class="top-spacer-25 spinner-border text-primary" role="status"></div>
              </div>
              <hr />
              <!--
              <div
                v-if="
                  ($parent.appstoreState.product1.state == 'owned' ||
                    $parent.appstoreState.product2.state == 'owned') &&
                  !$moment(user.premiumAccountUntil).isAfter($moment()) &&
                  $moment($parent.loadUntil).isBefore($moment())
                "
              >
                Verarbeite.... gegebenfalls das Profil neu öffnen
                <br />
                <button @click="$parent.manageSubscription()">Verwalten</button>
                {{ user.premiumAccountUntil }}<br />
                {{ $moment() }}
              </div>

              <div
                v-if="
                  ($parent.appstoreState.product1.state == 'owned' ||
                    $parent.appstoreState.product2.state == 'owned') &&
                  !$moment(user.premiumAccountUntil).isAfter($moment()) &&
                  $moment($parent.loadUntil).isAfter($moment())
                "
              >
                Du scheinst dein Abo bereits in einem anderen Account zu nutzen.
                Bitte lösche den anderen Account, wenn du das Abo in diesem
                verwenden möchtest.<br />
                Ist das nicht so? Dann schick uns eine Mail an
                kontakt@pushinglimits.club<br /><br />
                {{ user.premiumAccountUntil }}<br />
                {{ $moment() }}
                <button @click="$parent.manageSubscription()">Verwalten</button>
              </div>-->

              <div
                v-if="
                  user &&
                  $parent.appstoreState &&
                  $parent.appstoreState.product1 &&
                  $parent.appstoreState.product2 &&
                  $parent.appstoreState.product3 &&
                  $moment(user.premiumAccountUntil).isAfter($moment())
                "
              >
                Du bist Mitglied im Pushing Limits Club! Vielen Dank, dass du unsere App nutzt und unsere Arbeit
                unterstützt.<br />
                <div
                  class="white_wrapper top-spacer-25"
                  v-if="$parent.appstoreState && $parent.appstoreState.error && $parent.appstoreState.error.length > 3"
                  style="color: red"
                >
                  {{ $parent.appstoreState.error }}
                </div>

                <br />

                <button @click="$parent.manageSubscription()">Verwalten</button>
              </div>
            </div>
          </div>
          <!--
          <div
            class="col-lg-12"
            v-if="platform == 'web' || platform == 'electron'"
          >
            <div
              class="white_wrapper light-shadow"
              v-if="user.blockedForPayments"
            >
              Du hast Zahlungen angefochten (was für uns sehr kostspielig ist)
              und kannst nicht wieder Mitglied bei uns werden. Sollte dies ein
              Fehler sein oder bei Fragen melde dich gerne unter
              kontakt@pushinglimits.club. <br /><br />

              <span
                v-if="
                  subscription.subscriptionPeriodEnd &&
                  $moment(subscription.subscriptionPeriodEnd * 1000).isAfter(
                    $moment()
                  )
                "
              >
                Deine aktuelle Mitgliedschaft läuft noch bis zum
                {{
                  $moment(subscription.subscriptionPeriodEnd * 1000).format(
                    "DD.MM.YYYY"
                  )
                }}
                und endet dann.</span
              >
            </div>
            <div
              class="white_wrapper light-shadow"
              style="background: #fff"
              v-if="!user.blockedForPayments"
            >
              <div
                v-if="subscription.subscriptionPaymentDue"
                style="border: 3px solid green; padding: 15px"
              >
                Wir warten auf den Zahlungseingang deines Abos vom
                {{
                  $moment(subscription.subscriptionPaymentDue).format(
                    "DD.MM.YYYY"
                  )
                }}. Du kannst alles schon ohne Einschränkungen nutzen!
              </div>
              <div
                v-if="subscription.subscriptionPaymentFailed"
                style="border: 3px solid red; padding: 15px"
              >
                Der letzte Zahlungsversuch ist fehlgeschlagen. Wenn du möchtest,
                kannst du einfach eine neue Mitgliedschaft abschließen.<br />
              </div>
              <div
                v-if="unsubscribed"
                style="border: 3px solid green; padding: 15px"
              >
                <h4>Vielen Dank für die Zeit mit dir!</h4>
                Deine Mitgliedschaft wurde beendet! Sollte irgendwas nicht
                geklappt haben, schick uns eine Mail an
                kuendigung@pushinglimits.club. Wir melden uns dann umgehend und
                lösen das Problem.<br /><br />
                Alles Gute und bis bald!
              </div>
              <div
                v-if="
                  !subscription.subscriptionId &&
                  !subscription.subscriptionPaymentDue
                "
              >
                <!- TODOOO
                  Nach Ablauf der Founding Member Phase:
                  
                  Bei Nutzern bei denen subscriptionPriceId den Founding Sachen entspricht diese Buttons mit dem Preis weiter anbieten, 
                  damit sie im Fall fehlgeschlagener Zahlungen zum gleichen Preis wieder verlängern können.



                ->
                <stripe-checkout
                  :pk="'pk_test_51INF2nIJ1TUV8BWfSMkbg2YogovpjkFyjurXkQ0TfwKRFzCjBx2SV0E0cOEtCF4chjXyNwGPkr9rQUvY9AvyKUiU00oLl9RG8f'"
                  ref="checkoutRef"
                  :session-id="sessionId"
                  @loading="(v) => (loading = v)"
                />
                <stripe-checkout
                  :pk="'pk_test_51INF2nIJ1TUV8BWfSMkbg2YogovpjkFyjurXkQ0TfwKRFzCjBx2SV0E0cOEtCF4chjXyNwGPkr9rQUvY9AvyKUiU00oLl9RG8f'"
                  ref="checkoutYearlyRef"
                  :session-id="sessionId"
                  @loading="(v) => (loading = v)"
                />
                <br />

                <div>
                  Wir befinden uns zurzeit in der Betaphase (und es wird
                  sicherlich noch nicht alles ganz rund sein), daher ist der
                  Club aktuell für jeden kostenlos nutzbar. Im Anschluss an die
                  Betaphase wird die Mitgliedschaft aber kostenpflichtig
                  (xx.yy€/Monat).
                  <br /><br />Die Entwicklung und der Betrieb der Plattform sind
                  aufwändig und teuer - daher freuen wir uns über jeden, der
                  bereits jetzt bereit ist, eine Mitgliedschaft abzuschließen.
                  <br /><br />
                  <strong
                    >Dadurch wirst du zum Gründungsmitglied und bekommst als
                    Dankeschön für die gesamte Dauer deiner Mitgliedschaft einen
                    Rabatt auf den Normalpreis.</strong
                  >
                </div>
                <br />
                <span
                  v-if="
                    !subscription.subscriptionPeriodEnd ||
                    $moment(subscription.subscriptionPeriodEnd * 1000).isBefore(
                      $moment()
                    )
                  "
                >
                  <button style="width: 250px" @click="submit">
                    Mitglied werden!
                  </button>
                </span>
                <span
                  v-if="
                    unsubscribed ||
                    (subscription.subscriptionPeriodEnd &&
                      $moment(
                        subscription.subscriptionPeriodEnd * 1000
                      ).isAfter($moment()))
                  "
                >
                  Deine aktuelle Mitgliedschaft läuft noch bis zum
                  {{
                    $moment(subscription.subscriptionPeriodEnd * 1000).format(
                      "DD.MM.YYYY"
                    )
                  }}
                  und endet dann. Wenn du doch wieder Mitglied werden möchtest,
                  kannst du danach eine neue Mitgliedschaft abschließen.
                </span>
              </div>

              <div
                v-if="
                  subscription && subscription.subscriptionId && !unsubscribed
                "
              >
                <strong
                  >Du bist ein Mitglied des Pushing Limit Clubs! Vielen
                  Dank!</strong
                ><br />
                Deine Mitgliedschaft ermöglicht den Betrieb und die
                Weiterentwicklung der Plattform!
                <br />
                <br />

                <div
                  v-if="
                    subscription.subscriptionPriceId ==
                      'price_1Ja2aSIJ1TUV8BWfF2soRKBS' ||
                    subscription.subscriptionPriceId ==
                      'price_1Ja2aSIJ1TUV8BWfc3Q3PrEU'
                  "
                >
                  <h3 class="top-spacer-25" style="padding: 0; margin: 0">
                    FOUNDING MEMBER
                  </h3>
                  <i
                    >Du gehörst zu unseren allerersten Membern! Unsere Founding
                    Member haben all das hier erst möglich gemacht. Als Dank
                    erhälst du, so lange dein Abo läuft, 40% Rabatt.</i
                  >
                  <br />
                  <br />
                </div>

                <div class="b-button" @click="toggleMeineMitgliedschaft">
                  Meine Mitgliedschaft
                </div>

                <br />

                <div v-if="showMeineMitgliedschaft && !unsubscribed">
                  <span
                    >Deine Mitgliedschaft läuft bis zum
                    {{
                      $moment(subscription.subscriptionPeriodEnd * 1000).format(
                        "DD.MM.YYYY"
                      )
                    }}

                    und verlängert sich danach automatisch</span
                  >
                  <br />
                  <br />

                  <a
                    @click="unsubscribeConfirmation = true"
                    style="color: #007bff"
                    >Möchtest du dich abmelden?</a
                  >
                  <div v-if="unsubscribeConfirmation" class="top-spacer-25">
                    Natürlich respektieren wir deine Entscheidung. Dein
                    Abonnement läuft - wenn du kündigst - am Ende des
                    Abrechnungszeitraums aus.<br /><br />
                    <strong
                      >Wenn wir aber irgendetwas tun können, um dich
                      umzustimmen, schick' uns eine Mail an
                      feedback@pushinglimits.club</strong
                    >. Auch wenn wir dich nicht umstimmen können: Wir freuen
                    uns über eine Mail mit Feedback! Danke, dass du bei uns Mitglied
                    warst!

                    <hr />
                    <!-
                    <button
                      class="delete"
                      @click="unsubscribe()"
                      style="padding: 5px 10px"
                    >
                      Mitgliedschaft beenden
                    </button>->
                    <strong>
                      Du kannst deine Mitgliedschaftüber dein Smartphone in
                      deinen Android / iOS Abos beenden</strong
                    >
                  </div>
                 
                </div>
              </div>
              <!-
              <div
                v-if="
                  subscription.invoices &&
                  subscription.invoices.length > 0 &&
                  showMeineMitgliedschaft
                "
                class="top-spacer-50"
              >
                <h4>Rechnungen</h4>
                <div class="row">
                  <div
                    v-for="rechnung in subscription.invoices"
                    :key="rechnung.date"
                    class="col-lg-3 col-md-4 col-sm-6"
                  >
                    <a :href="rechnung.url"
                      >{{ $moment(rechnung.date).format("DD.MM.YYYY") }} -
                      Ansehen</a
                    >
                  </div>
                </div>
              
              </div>
            </div>
                      -->
          <div
            class="col-lg-12 top-spacer-50"
            v-if="
              user &&
              (platform == 'web' || platform == 'electron') &&
              $moment(user.premiumAccountUntil).isAfter($moment())
            "
          >
            <h2>Mitgliedschaft</h2>
            <div class="white_wrapper">
              <!-- <span
                >Deine Mitgliedschaft läuft bis zum
                {{ $moment(user.premiumAccountUntil).format("DD.MM.YYYY") }}

                und verlängert sich danach automatisch, außer du hast sie
                gekündigt</span
              >-->
              Du bist Mitglied im Pushing Limits Club!
              <br />
              <br />

              <a @click="unsubscribeConfirmation = true" style="color: #007bff">Möchtest du dich abmelden?</a>
              <div v-if="unsubscribeConfirmation" class="top-spacer-25">
                Natürlich respektieren wir deine Entscheidung. Dein Abonnement läuft - wenn du kündigst - am Ende des
                Abrechnungszeitraums aus.<br /><br />
                <strong
                  >Wenn wir aber irgendetwas tun können, um dich umzustimmen, schick' uns eine Mail an
                  feedback@pushinglimits.club</strong
                >. Auch wenn wir dich nicht umstimmen können: Wir freuen uns über eine Mail mit Feedback! Danke, dass du
                bei uns Mitglied warst!

                <hr />

                <strong>
                  Du kannst deine Mitgliedschaft über dein Smartphone in deinen Android / iOS Abos beenden</strong
                >
              </div>
            </div>
          </div>
          <div
            class="col-lg-12 top-spacer-50"
            v-if="
              user &&
              (platform == 'web' || platform == 'electron') &&
              !$moment(user.premiumAccountUntil).isAfter($moment())
            "
          >
            <h2>Mitgliedschaft</h2>
            <div class="white_wrapper">
              <h4>Kostet im Jahr so viel wie eine Leistungsdiagnostik, bringt aber mehr!</h4>
              Zurzeit läuft deine kostenlose Testphase oder ist bereits abgelaufen. Um alle Funktionen dauerhaft nutzen zu können, musst du über
              unsere iOS oder Android App ein Abo abschließen. Nach der Testphase sind ohne Abo die meisten Funktionen
              nicht mehr verfügbar.<br /><br />
              <div class="row">
                <div class="col-lg-12 top-spacer-15" v-if="platform == 'web' || platform == 'electron'">
                  <div class="white_wrapper" style="padding: 25px">
                    <h4>Mitglied werden!</h4>
                    <small>inkl. Nutzung aller Trainingspläne, Auswertungen, Rezepte, Videos, Kraftraum und des Radraums!</small>

                    <hr />
                    <h4>19,99€/Monat*</h4>
                    oder<br />
                    <h4>179,99€/Jahr (unter 15€/Monat)*</h4>
                    <small>*Preis kann außerhalb Deutschlands abweichen</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="top-spacer-25">
          <div class="white_wrapper light-shadow">
            <h4>iCal-Export</h4>
            <div v-if="!user.icalToken">
              Du kannst deinen Kalender mit anderen Apps über ical synchronisieren. Klicke auf den Button, um ein
              ical-Feed zu generieren.
              <br /><br />
              <button @click="generate_ical_token()">Generieren</button>
            </div>
            <div v-if="user.icalToken">
              Dein iCal-Feed ist unter folgender URL erreichbar:
              <br /><br />
              <input
                style="width: 100%"
                type="text"
                :value="'https://pushinglimits.club/api/workout/ical_feed/' + user.icalToken"
                readonly
              />

              <br />
              <br /><small
                >Kopiere den Link und füge ihn in dein ical-Kalenderabonnement in einer App deiner Wahl ein.
                <br />
                <strong
                  >ACHTUNG: Jeder, der den Link kennt, kann deine Workouts sehen! Du kannst hier jederzeit einen neuen
                  generieren.</strong
                ></small
              >
              <br /><br />
              <button @click="generate_ical_token()">Neu generieren</button>
            </div>
          </div>
        </div>

        <div class="top-spacer-25">
          <div class="white_wrapper light-shadow">
            <div id="dropzone" v-if="showDropzone">
              <h3 style="text-align: center">.FIT File Upload</h3>
              <div
                v-if="filesUploaded + fileUploadErrors == totalFilesToUpload"
                style="padding: 15px; margin: 15px; border: 5px solid green"
              >
                <h2>
                  Upload abgeschlossen!<br />
                  Die Verarbeitung dauert ggf. noch einige Minuten.<br />
                </h2>
              </div>
              <div
                v-if="!(filesUploaded + fileUploadErrors == totalFilesToUpload)"
                style="padding: 15px; margin: 15px; border: 5px solid gold"
              >
                <h2>Upload läuft, bitte warten...</h2>
              </div>
              <div v-if="totalFilesToUpload > 0">
                <h4 style="text-align: center">{{ filesUploaded }}/{{ totalFilesToUpload }} erfolgreich</h4>
                <div
                  v-if="filesUploaded + fileUploadErrors < totalFilesToUpload"
                  class="top-spacer-25 spinner-border text-primary"
                  role="status"
                ></div>
                <br />
                <span style="text-align: center" class="centered top-spacer-25" v-if="fileUploadErrors > 0">
                  {{ fileUploadErrors }} Datei<span v-if="fileUploadErrors > 1">en</span> konnten nicht ausgewertet
                  werden. Bitte nur vollständige .FIT Files hochladen.<br /><br />
                  Hinweis: Zwift legt oft kleine unnötige FIT-Files ab, die hier als nicht-erfolgreich angezeigt werden.
                  Das ist aber kein Problem! Die Workouts müssen nicht nochmal hochgeladen werden!
                </span>
                <br />

                <button
                  class="closer"
                  v-if="fileUploadErrors + filesUploaded == totalFilesToUpload"
                  style="cursor: pointer"
                  @click="dragExit"
                >
                  Schließen
                </button>
                <hr class="top-spacer-25" />
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12">
                <div v-if="uploadFinished && uploadError == false" style="color: green">
                  <h5>
                    <span v-if="files.length == 1">Workout wurde</span
                    ><span v-if="files.length > 1">Workouts wurden</span>
                    erfolgreich hochgeladen!

                    <hr class="top-spacer-25" />
                  </h5>
                </div>
                <h4 class="top-spacer-25">.FIT Datei manuell hochladen</h4>
                <div class="top-spacer-25">
                  Du kannst mehrere Dateien gleichzeitig auswählen und per Drag und Drop auf den Kalender ziehen.
                </div>
                <div v-if="platform == 'web' || platform == 'electron'">
                  <input class="top-spacer-25" type="file" accept=".fit,.FIT" multiple @change="processFile($event)" />
                  <div class="col-lg-12">
                    <button @click="saveFile()" class="top-spacer-25">Hochladen</button>
                  </div>
                </div>
                <div v-if="platform == 'ios' || platform == 'android'">
                  Du kannst .FIT-Dateien am Computer unter https://pushinglimits.club manuell hochladen.
                </div>

                <hr class="top-spacer-50" />

                <h3 class="top-spacer-50">Automatischer Upload</h3>
                Verbinde dich mit deinem Garmin, Wahoo oder Polar Account, um deine Workouts automatisch zu
                synchronisieren.
              </div>
              <div class="col-lg-12">
                <hr class="top-spacer-50" />

                <h3 class="top-spacer-50">Drag & Drop</h3>
                <p>Du kannst mehrere .FIT-Dateien am Computer per Drag & Drop direkt auf deinen Kalender ziehen</p>

                <button @click="showDragDrop = !showDragDrop">Zeigen</button>

                <img
                  v-if="showDragDrop"
                  style="width: 100%"
                  class="top-spacer-25"
                  alt="Drag und Drop Beispiel"
                  src="
                  https://assets.pushinglimits.club/dragdrop.gif
                "
                />
              </div>
            </div>

            <div class="top-spacer-50"></div>
          </div>
        </div>
        <div class="top-spacer-25"></div>
        <div class="white_wrapper_tighter d-none d-md-block">
          <h4>Möchtest du deine Workout-Vorlagen anpassen?</h4>
          <a href="/#/training/vorlagen"> <button style="padding: 5px 15px">Workout-Vorlagen bearbeiten</button></a>
        </div>

        <div class="white_wrapper top-spacer-25" v-if="user">
          <h4>Mail Einstellung</h4>

          <div v-if="user.sendAllMails == undefined || user.sendAllMails">
            Wir senden dir zurzeit alle Mails (z.B. neue Bestleistungen)
          </div>
          <button
            class="top-spacer-10"
            style="padding: 5px 15px"
            v-if="user.sendAllMails == undefined || user.sendAllMails"
            @click="triggerMail()"
          >
            Ich möchte nur noch die nötigsten Mails empfangen
          </button>

          <div v-if="user.sendAllMails != undefined && !user.sendAllMails">
            Wir senden dir zurzeit nur die wichtigsten Mails (z.B. Passwort zurücksetzen)
          </div>

          <button
            class="top-spacer-10"
            style="padding: 5px 15px"
            v-if="user.sendAllMails != undefined && !user.sendAllMails"
            @click="triggerMail()"
          >
            Ich möchte alle E-Mails empfangen
          </button>
        </div>

        <div class="white_wrapper top-spacer-25">
          <h4>Möchtest du uns nicht mehr nutzen?</h4>
          <button style="padding: 5px 15px" @click="toggleAccountLoeschen">Ich möchte meinen Account löschen</button>
          <div v-if="showAccountLoeschen">
            <h4 class="top-spacer-50">Account löschen</h4>
            <p style="text-align: justify">
              Schade, dass du uns verlassen möchtest. Sende uns gerne eine Mail an feedback@pushinglimits.club, wenn du
              Verbesserungsvorschläge für uns hast.<br /><br />
              <strong
                >Bedenke bitte, dass alle deine Daten unwiderruflich gelöscht werden. <br /><br />Das Löschen des
                Accounts führt nicht zur Beendigung des Abos.<br /><br />
                Wenn du ein Abo abgeschlossen hast, kannst du es wie jedes andere auf deinem Smartphone
                kündigen.</strong
              ><br /><br />
              Hinweis: Es kann bis zu 24h dauern, bis alle deine Daten gelöscht sind und dein Account nicht mehr nutzbar
              ist.
            </p>
            <hr />

            <div v-if="accountDeleted" style="padding: 20px; border: 2px solid green">
              <h4>Dein Account und deine Daten werden gelöscht</h4>
              Dein Account ist in Kürze nicht mehr nutzbar. Danke, dass du unsere Plattform genutzt hast!
            </div>

            <button class="delete top-spacer-15" @click="accountLoeschen">Account Löschen</button><br />
          </div>
        </div>

        <div class="white_wrapper top-spacer-25" v-if="user.isAdmin">
          <input v-model="ad_id" />
          <button @click="adAnsehen()">Ansehen</button>
        </div>

        
      </div>

     
    </div>

    <!--
      <div class="row">
        <div class="col-lg-3">
         
          <h3> <i class="fa fa-trophy" aria-hidden="true"></i><br /> Bestleistungen Rad</h3>
                                  
          <div class="row">
            <div class="col-lg-4">
              <h4>Dauer</h4>
             </div>
              <div class="col-lg-4">
                <h4>Leistung</h4>
             </div>
             <div class="col-lg-4">
                <h4>KM/H</h4>
             </div>
          </div>
          <div v-for="power in alltimetotals.bp" :key="power" class="row">
            <div class="col-lg-4" v-if="power.value">
                {{formattedTime(power.key)}}
             </div>
              <div class="col-lg-4" v-if="power.value">
                {{Math.round(power.value)}}w
             </div>
               <div class="col-lg-4" v-if="power.value">
                {{Math.round(power.value)}}w
             </div>
          </div>
        </div>

      </div>
   -->

    <div class="row top-spacer-100"></div>
  </div>
</template>

<script>
//import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { Capacitor } from "@capacitor/core";
import axios from "axios";
import { Plugins } from "@capacitor/core";
import LZString from "lz-string";
const convertToFitJson = require("../js/apple_fit_json_converter.js");
const { AppleWorkoutPlugin } = Plugins;
import AppleHealthService from "../services/AppleHealthService";

export default {
  metaInfo: {
    title: "Profil",
  },
  name: "Profil",
  //components: { StripeCheckout }, //ThresholdChart
  data: function () {
    return {
      isIos17OrNewer: true,
      completeWorkoutView: {},
      thresholds_automatic: false,
      tz: undefined,
      tzGuess: undefined,
      locationMessage: "",
      location: "",
      cities: [],

      runTargetIsHeartRate: false,
      bikeTargetIsHeartRate: false,

      adminForm: {},
      isSyncingApple: false,

      warnPremiumExpiration: false,
      stravaCheck: false,

      platform: Capacitor.getPlatform(),
      showDropzone: false,
      totalFilesToUpload: 0,
      filesUploaded: 0,
      fileUploadErrors: 0,
      uploadFinishedLoader: false,
      uploadFinished: false,

      files: undefined,
      key: 0,
      subscription: {},
      unsubscribeConfirmation: false,
      unsubscribed: false,
      sessionId: undefined,
      loading: false,

      icTrainerConnected: false,
      healthkitConnected: false,
      healthkitConnecting: false,

      showDragDrop: false,

      alltimetotals: {},

      thresholdKey: 0,
      thresholdsChanged: false,
      runThreshold: 0,
      swimThreshold: 0,
      ftp: 0,
      weight: 0,
      restingHr: 0,
      maxHr: 0,
      runThresholdStart: 0,
      swimThresholdStart: 0,
      ftpStart: 0,
      thresholds: [],
      editThresholdId: "",
      showThresholdDetails: false,
      showThresholdDetailsSettings: false,

      edit: {},

      thresholdsUpdatedRun: false,
      thresholdsUpdated: false,
      hrZonesUpdated: false,
      hrZonesUpdatedRun: false,

      user: undefined,
      token: undefined,
      showThresholdDevelopment: false,
      showMeineMitgliedschaft: false,
      showAccountLoeschen: false,
      accountDeleted: false,
      ad_mail: "mail@mail.de",
      ad_id: "ad_id",
      orderLoading: false,
      appleTargetHeartRateLoading: false,
      message: "",
      userInterval: undefined,

      hr_zone_1: 0,
      hr_zone_2: 0,
      hr_zone_3: 0,
      hr_zone_4: 0,
      hr_zone_1_run: 0,
      hr_zone_2_run: 0,
      hr_zone_3_run: 0,
      hr_zone_4_run: 0,

      poolLengthMessage: "",
      poolLength: 25,

      garminRunningTarget: "PACE",
      garminCyclingTarget: "POWER_3S",
      wahooCyclingTarget: "POWER",
      wahooMessage: "",

      appleWorkouts: [],
      showWorkoutModal: false,
      hasNewWorkouts: false,
    };
  },

  watch: {
    /*
    runTargetIsHeartRate: function () {
      this.setTargetIsHeartRate();
    },
    bikeTargetIsHeartRate: function () {
      this.setTargetIsHeartRate();
    },*/
  },

  created: async function () {
    try {
      await this.updateThresholds();
      const _self = this;
      axios
        .get(this.$host + "/workout/analysis/power/year")
        .then(function (res) {
          _self.alltimetotals.bp = [];

          for (const [key, value] of Object.entries(res.data)) {
            if (key != "_id") {
              _self.alltimetotals.bp.push({
                key: key.split("_")[1],
                value: value,
              });
            }
          }
        })
        .catch(function (err) {
          console.error("Fehler beim Laden der Workout-Analyse:", err);
          _self.$parent.flashMessage.show({
            status: "error",
            title: "Fehler",
            time: 4500,
            message: "Die Workout-Analyse konnte nicht geladen werden. Bitte versuche es später erneut.",
            icon: false,
          });
        });
    } catch (err) {
      console.error("Fehler beim Aktualisieren der Schwellenwerte:", err);
      this.$parent.flashMessage.show({
        status: "error",
        title: "Fehler",
        time: 4500,
        message: "Die Schwellenwerte konnten nicht aktualisiert werden. Bitte überprüfe deine Einstellungen.",
        icon: false,
      });
    }
  },

  mounted: async function () {
    const _self = this;
    const tz = this.$moment.tz.guess();

    this.tzGuess = tz;

    try {
      axios
        .post(this.$host + "/user/tz/guess", {
          tz: tz,
        })
        .then(function () {
          console.log("Time Zone guess " + tz);
        })
        .catch(function (error) {
          console.log("Time Zone setting failed: " + error);
        });

      axios
        .get(this.$host + "/user/me")
        .then(function (res) {
          _self.user = res.data;

          _self.tz = _self.user.timezone || _self.$moment.tz.guess();
          _self.thresholds_automatic = _self.user.thresholdsAutomatic;

          _self.garminRunningTarget = _self.user.garminRunningTarget || "PACE";
          _self.garminCyclingTarget = _self.user.garminCyclingTarget || "POWER_3S";
          _self.wahooCyclingTarget = _self.user.wahooCyclingTarget || "POWER";

          _self.location = _self.user.specified_location_string || "";
          _self.poolLength = _self.user.poolLength || 25;

          _self.hr_zone_1 = _self.user.threshold_hr_zone_1 || 60;
          _self.hr_zone_2 = _self.user.threshold_hr_zone_2 || 75;
          _self.hr_zone_3 = _self.user.threshold_hr_zone_3 || 85;
          _self.hr_zone_4 = _self.user.threshold_hr_zone_4 || 90;

          _self.hr_zone_1_run = _self.user.threshold_hr_zone_1_run || 64;
          _self.hr_zone_2_run = _self.user.threshold_hr_zone_2_run || 77;
          _self.hr_zone_3_run = _self.user.threshold_hr_zone_3_run || 86;
          _self.hr_zone_4_run = _self.user.threshold_hr_zone_4_run || 90;
          if (
            !_self.$moment(_self.user.premiumAccountUntil).isAfter(_self.$moment()) &&
            !_self.$moment(_self.user.freeAccountUntil).isAfter(_self.$moment())
          ) {
            console.log("Warn Premium Expiration!");
            _self.warnPremiumExpiration = true;
          }
        })
        .catch(function (err) {
          console.log("USER ERR " + err, err);
        });

      axios
        .get(this.$host + "/user/subscription")
        .then(function (res) {
          _self.subscription = res.data;
        })
        .catch(function (err) {
          console.log("ERR " + err, err);
        });

      axios
        .get(this.$host + "/user/is_ictrainer_connected")
        .then(function (res) {
          _self.icTrainerConnected = res.data.token;
        })
        .catch(function (err) {
          console.log("ERR " + err, err);
        });

      axios
        .get(this.$host + "/healthkit/is_connected")
        .then(function (res) {
          _self.healthkitConnected = res.data.connected;
          _self.runTargetIsHeartRate = res.data.runTargetIsHeartRate;
          _self.bikeTargetIsHeartRate = res.data.bikeTargetIsHeartRate;
        })
        .catch(function (err) {
          console.log("ERR " + err, err);
        });

      this.isIos17OrNewer = await AppleHealthService.isIos17OrNewer();
    } catch (error) {
      console.error("Fehler beim Laden der Daten:" + error, error);
    }
  },

  computed: {
    reverseThresholds() {
      return this.thresholds && this.thresholds.slice().reverse();
    },
  },

  setTargetIsHeartRate: function () {
    this.appleTargetHeartRateLoading = true;
    const _self = this;
    axios
      .post(this.$host + "/healthkit/set_target_is_heart_rate", {
        runTargetIsHeartRate: this.runTargetIsHeartRate,
        bikeTargetIsHeartRate: this.bikeTargetIsHeartRate,
      })
      .then(function () {
        _self.appleTargetHeartRateLoading = false;
      })
      .catch(function (err) {
        console.log("ERR " + err, err);
        _self.appleTargetHeartRateLoading = false;
      });
  },

  unmounted: function () {
    clearInterval(this.userInterval);
    this.userInterval = undefined;
  },

  methods: {
    syncWorkouts: async function () {
      this.isSyncingApple = true;
      await AppleHealthService.syncWorkoutsToApple(true);
      this.isSyncingApple = false;
    },

    generate_ical_token: async function () {
      const _self = this;
      axios
        .post(this.$host + "/user/generate_ical_token")
        .then(function (res) {
          _self.user = res.data;
        })
        .catch(function (err) {
          console.log("ERR", err);
        });
    },

    closeWorkoutModal: function () {
      this.showWorkoutModal = false;
      document.body.style.overflow = "auto"; // Reset the overflow property
    },

    setTimezone: async function (tz) {
      const _self = this;
      _self.tz = tz || this.tz;
      axios
        .post(this.$host + "/user/tz", {
          tz: _self.tz,
        })
        .then(function () {
          console.log("Time Zone set " + _self.tz);
        })
        .catch(function (error) {
          console.log("Time Zone setting failed: " + error);
        });
    },

    setLocation(lat, lon, city) {
      const _self = this;

      axios
        .post(this.$host + "/weather/set_location", {
          city,
          lat,
          lon,
        })
        .then((result) => {
          _self.locationMessage = result.data.message;
          _self.cities = [];
          _self.location = "";

          axios.get(this.$host + "/user/me").then(function (res) {
            _self.user = res.data;
          });
        })
        .catch(() => {
          this.locationMessage = "Es ist ein Fehler aufgetreten. Bitte versuche es später nochmal.";
        });
    },
    async searchLocation() {
      if (this.location.length > 0) {
        let result = await axios.post(this.$host + "/weather/get_locations", {
          city: this.location,
        });

        result = result.data;

        if (result) {
          this.locationMessage = result.message;
          this.cities = result.cities;
        } else {
          this.locationMessage = "Es ist ein Problem aufgetreten. Bitte später nochmal verusuchen.";
        }
      }
    },
    refreshUser() {
      // TODO: Als Helferfunktion auslagern
      const isDeepEqual = (object1, object2) => {
        const objKeys1 = Object.keys(object1);
        const objKeys2 = Object.keys(object2);

        if (objKeys1.length !== objKeys2.length) return false;

        for (var key of objKeys1) {
          const value1 = object1[key];
          const value2 = object2[key];

          const isObjects = isObject(value1) && isObject(value2);

          if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
            return false;
          }
        }
        return true;
      };

      const isObject = (object) => {
        return object != null && typeof object === "object";
      };

      const _self = this;
      axios
        .get(this.$host + "/user/me")
        .then(function (res) {
          if (res) {
            if (!isDeepEqual(_self.user, res.data)) {
              _self.user = res.data;
              clearInterval(_self.userInterval);
              _self.userInterval = undefined;
            }
          }
        })
        .catch(function (error) {
          console.log("Error refresh user: " + error);
        });
    },

    setAutomaticThreshold(thresholds_automatic) {
      const _self = this;

      _self.thresholds_automatic = thresholds_automatic;
      axios
        .post(_self.$host + "/user/thresholds_automatic", {
          thresholds_automatic: _self.thresholds_automatic,
        })
        .then(function () {
          _self.refreshUser();
        })
        .catch(function () {
          _self.refreshUser();
        });
    },

    triggerMail() {
      const _self = this;
      axios
        .post(_self.$host + "/user/change_mail_setting")
        .then(function () {
          _self.refreshUser();
        })
        .catch(function (err) {
          console.log("Fehler", err);
          _self.refreshUser();
        });
    },

    saveGarminSettings() {
      const _self = this;
      axios
        .post(_self.$host + "/user/set_garmin_settings", {
          poolLength: _self.poolLength,
          garminRunningTarget: _self.garminRunningTarget,
          garminCyclingTarget: _self.garminCyclingTarget,
        })
        .then(function () {
          _self.poolLengthMessage = "Garmin Einstellungen gespeichert";
          _self.refreshUser();
        })
        .catch(function () {
          _self.poolLengthMessage = "Garmin Einstellungen konnte nicht gesetzt werden";

          _self.refreshUser();
        });
    },

    saveWahooSettings() {
      const _self = this;
      axios
        .post(_self.$host + "/user/set_wahoo_settings", {
          wahooCyclingTarget: _self.wahooCyclingTarget,
        })
        .then(function () {
          _self.wahooMessage = "Wahoo Einstellungen gespeichert";
          _self.refreshUser();
        })
        .catch(function () {
          _self.wahooMessage = "Wahoo Einstellungen konnte nicht gesetzt werden";

          _self.refreshUser();
        });
    },

    savePoolLength() {
      const _self = this;
      axios
        .post(_self.$host + "/user/set_pool_length", {
          poolLength: _self.poolLength,
        })
        .then(function () {
          _self.poolLengthMessage = "Pool-Länge wurde auf " + _self.poolLength + " gesetzt";
          _self.refreshUser();
        })
        .catch(function () {
          _self.poolLengthMessage = "Pool-Länge konnte nicht gesetzt werden";

          _self.refreshUser();
        });
    },

    async order() {
      try {
        const _self = this;
        this.orderLoading = true;
        if (this.$parent.appstoreState.product1 && this.$parent.appstoreState.product1.getOffer) {
          await this.$parent.appstoreState.product1.getOffer().order();
          this.userInterval = setInterval(function () {
            _self.refreshUser();
          }, 3500);
        } else {
          throw new Error("Product1 or getOffer method is not available");
        }
      } catch (error) {
        this.logError(error, "order");
        this.orderLoading = false;
      }
    },
    async orderYearly() {
      try {
        const _self = this;
        this.orderLoading = true;
        if (this.$parent.appstoreState.product2 && this.$parent.appstoreState.product2.getOffer) {
          await this.$parent.appstoreState.product2.getOffer().order();
          this.userInterval = setInterval(function () {
            _self.refreshUser();
          }, 3500);
        } else {
          throw new Error("Product2 or getOffer method is not available");
        }
      } catch (error) {
        this.logError(error, "orderYearly");
        this.orderLoading = false;
      }
    },
    async orderHalfYearly() {
      try {
        const _self = this;
        this.orderLoading = true;
        if (this.$parent.appstoreState.product3 && this.$parent.appstoreState.product3.getOffer) {
          await this.$parent.appstoreState.product3.getOffer().order();
          this.userInterval = setInterval(function () {
            _self.refreshUser();
          }, 3500);
        } else {
          throw new Error("Product3 or getOffer method is not available");
        }
      } catch (error) {
        this.logError(error, "orderHalfYearly");
        this.orderLoading = false;
      }
    },
    restore() {
      const _self = this;
      this.orderLoading = true;
      this.$parent.store.initialize();
      this.$parent.store.update();
      this.$parent.store.restorePurchases();

      setTimeout(function () {
        _self.orderLoading = false;
        _self.refreshUser();
      }, 15000);
    },
    dragEnter() {
      this.showDropzone = true;
    },
    dragExit() {
      this.showDropzone = false;
      this.filesUploaded = 0;
      this.fileUploadErrors = 0;
      this.totalFilesToUpload = 0;
    },
    /////// FILE UPLOAD
    processFile(event) {
      this.files = event.target.files;
    },
    saveFile: async function () {
      this.dragEnter();
      this.uploadFinishedLoader = false;
      this.uploadFinished = false;
      this.uploadError = false;

      const _self = this;

      if (this.files && this.files.length > 0) {
        let droppedFiles = this.files;
        this.showDropzone = true;

        if (this.totalFilesToUpload - this.filesUploaded - this.fileUploadErrors == 0) {
          if (!droppedFiles) return;

          this.filesUploaded = 0;
          this.fileUploadErrors = 0;

          this.totalFilesToUpload = droppedFiles.length;

          for (const f of droppedFiles) {
            await new Promise((resolve) => {
              this.uploadFinishedLoader = false;
              const formData = new FormData();
              this.uploadFinished = false;
              this.uploadError = false;

              formData.append("files[]", f);

              axios
                .post(_self.$host + "/fit/upload", formData)
                .then(function () {
                  _self.uploadFinished = true;
                  _self.filesUploaded += 1;
                })
                .catch(function () {
                  _self.uploadError = true;
                  _self.fileUploadErrors += 1;
                })
                .finally(function () {
                  _self.uploadFinishedLoader = true;
                  resolve();

                  if (_self.totalFilesToUpload - _self.filesUploaded == 0) {
                    //_self.dragExit();
                  }
                });
            });
          }
        } else {
          // Upload läuft noch...
        }
      }
    },

    toggleMeineMitgliedschaft() {
      this.showMeineMitgliedschaft = !this.showMeineMitgliedschaft;
    },
    toggleAccountLoeschen() {
      this.showAccountLoeschen = !this.showAccountLoeschen;
    },

    async adAnsehen() {
      const req = { user_id: this.ad_id, email: this.ad_mail };
      axios
        .post(this.$host + "/user/admin_login", req)

        .then(() => {
          console.log("Ok");
        })
        .catch(function (error) {
          console.log("Error: " + error);
        });
    },

    accountLoeschen() {
      const _self = this;

      axios
        .delete(this.$host + "/user/delete_user")
        .then(async function () {
          _self.accountDeleted = true;
        })
        .catch(function (error) {
          console.log("Error: " + error);
        });
    },
    async unsubscribe() {
      const _self = this;

      axios
        .delete(this.$host + "/stripe/subscription")
        .then(async function () {
          _self.user = (await axios.get(_self.$host + "/user/me")).data;
          _self.unsubscribed = true;
          _self.unsubscribeConfirmation = false;
        })
        .catch(function (error) {
          console.log("Error: " + error);
        });
    },

    async fetchAppleWorkouts() {
      try {
        const { appleWorkouts, hasNewWorkouts } = await AppleHealthService.fetchAppleWorkouts();
        this.appleWorkouts = appleWorkouts;
        this.hasNewWorkouts = hasNewWorkouts;
        this.showWorkoutModal = true;
        document.body.style.overflow = "hidden"; // Prevent scrolling when modal is open
      } catch (error) {
        console.error("Error fetching Apple Watch workouts /" + error, error);
        alert("Fehler beim Abrufen der Apple Watch Workouts. Bitte versuche es später erneut.");
      }
    },

    async importWorkout(workout) {
      try {
        await AppleHealthService.importWorkout(workout);
        workout.isImported = true;
        this.hasNewWorkouts = this.appleWorkouts.some((w) => !w.isImported);
        alert("Workout erfolgreich importiert!");
      } catch (error) {
        console.error("Error importing workout:", error);
        alert("Fehler beim Importieren des Workouts. Bitte versuche es später erneut.");
      }
    },

    importAllWorkouts() {
      const _self = this;
      this.appleWorkouts.forEach(async function (workout) {
        if (!workout.isImported) {
          await _self.importWorkout(workout);
        }
      });
      alert("Alle Workouts erfolgreich importiert!");
      this.showWorkoutModal = false;
    },

    getWorkoutTypeName(type) {
      // Map workout type numbers to German categories
      const types = {
        // Running
        37: "Laufen",
        24: "Laufen", // Hiking

        // Cycling
        13: "Radfahren",

        // Swimming
        46: "Schwimmen",
        81: "Schwimmen", // Swim-Bike-Run (Triathlon)

        // Strength
        20: "Kraft", // Functional Strength Training
        50: "Kraft", // Traditional Strength Training
        59: "Kraft", // Core Training
        63: "Kraft", // High Intensity Interval Training

        // Everything else falls under "Aktivität"
      };
      return types[type] || "Aktivität";
    },

    formatDuration(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      return `${hours}h ${minutes}m`;
    },

    formatDistance(distance) {
      return distance && !isNaN(distance) ? (distance / 1000).toFixed(2) : 0;
    },

    async createSession(priceId) {
      const req = { price: priceId };
      const session = (await axios.post(this.$host + "/stripe/session", req)).data;
      return session;
    },

    async submit() {
      // await this.createCustomerIfNotExists();

      this.sessionId = (await this.createSession("price_1Ja2aSIJ1TUV8BWfF2soRKBS")).session_id;

      this.$refs.checkoutRef.redirectToCheckout();
    },
    async submitYearly() {
      //await this.createCustomerIfNotExists();
      this.sessionId = (await this.createSession("price_1Ja2aSIJ1TUV8BWfc3Q3PrEU")).session_id;
      this.$refs.checkoutYearlyRef.redirectToCheckout();
    },
    formattedTime(seconds) {
      return this.$moment.utc(seconds * 1000).format("H:mm:ss");
    },

    deleteThreshold(threshold) {
      const _self = this;
      // TODO: Send to Server
      const req = { _id: threshold._id };
      axios
        .post(this.$host + "/user/thresholds/delete", req)
        .then(function () {
          _self.editThresholdId = "";
          _self.edit = {};
          _self.updateThresholds();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    editThreshold(threshold) {
      this.editThresholdId = threshold._id;
      this.edit.threshold_swim = this.secondsToMinutesAndSeconds(threshold.threshold_swim);
      this.edit.threshold_run = this.secondsToMinutesAndSeconds(threshold.threshold_run);
      this.edit.ftp = parseInt(threshold.ftp);
      this.edit.weight = parseFloat(threshold.weight);
      this.edit.maxHr = parseInt(threshold.maxHr);
      this.edit.createdAt = threshold.createdAt;
    },
    saveThreshold() {
      // EDITED THRESHOLD
      let req = {
        _id: this.editThresholdId,
        ftp: parseInt(this.edit.ftp),
        weight: parseInt(this.edit.weight),
        maxHr: parseInt(this.edit.maxHr),
        threshold_swim: this.$moment.duration("0:" + this.edit.threshold_swim).asSeconds(),
        threshold_run: this.$moment.duration("0:" + this.edit.threshold_run).asSeconds(),
        createdAt: this.edit.createdAt,
      };

      const _self = this;
      axios
        .post(this.$host + "/user/thresholds", req)
        .then(function () {
          _self.editThresholdId = "";
          _self.edit = {};
          _self.updateThresholds();
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    secondsToMinutesAndSeconds(seconds) {
      return this.$moment().startOf("day").seconds(seconds).format("mm:ss");
    },

    async updateThresholds() {
      this.thresholds = await (await axios.get(this.$host + "/user/thresholds")).data;
      this.swimThreshold = this.swimThresholdStart = this.$moment()
        .startOf("day")
        .seconds(this.thresholds[this.thresholds.length - 1].threshold_swim)
        .format("mm:ss");
      this.runThreshold = this.runThresholdStart = this.$moment()
        .startOf("day")
        .seconds(this.thresholds[this.thresholds.length - 1].threshold_run)
        .format("mm:ss");
      this.ftp = this.ftpStart = this.thresholds[this.thresholds.length - 1].ftp;
      this.weight = this.thresholds[this.thresholds.length - 1].weight;
      this.maxHr = this.thresholds[this.thresholds.length - 1].maxHr;
      this.restingHr = this.thresholds[this.thresholds.length - 1].restingHr;

      this.thresholdKey++;
    },
    noValueChanged() {
      return (
        (this.runThresholdStart == this.runThreshold &&
          this.swimThresholdStart == this.swimThreshold &&
          this.ftpStart == this.ftp) ||
        // Noch Default-Settings
        (this.runThresholdStart == 0 && this.swimThresholdStart == 0 && this.ftpStart == 0)
      );
    },

    connectStrava: function () {
      const _self = this;

      axios
        .get(this.$host + "/strava/auth")
        .then(function (response) {
          // handle success
          _self.userInterval = setInterval(function () {
            _self.refreshUser();
          }, 3500);

          window.location.href = response.data.url;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },

    disconnectHealthkit: async function () {
      await axios.post(`${this.$host}/healthkit/should_fetch`, { shouldFetch: false });
      const res = await axios.get(`${this.$host}/healthkit/is_connected`);
      this.healthkitConnected = res.data.connected;
    },

    connectHealthkit: async function () {
      try {
        this.healthkitConnecting = true;

        await AppleWorkoutPlugin.authorize();

        console.log("START FETCHING");
        // Calculate date for the last 6 weeks
        const sixWeeksAgo = new Date(Date.now() - 1000 * 60 * 60 * 24 * 42).toISOString();

        // Fetch workouts since six weeks ago
        const result = await AppleWorkoutPlugin.fetchWorkouts({ lastFetchedDate: sixWeeksAgo });
        console.log(`Fetched ${result.workouts.length} workouts`);

        for (const workout of result.workouts) {
          try {
            const fitJson = convertToFitJson(workout);
            const compressedWorkout = LZString.compressToBase64(JSON.stringify(fitJson));

            // Upload the workout data
            await axios.post(`${this.$host}/workout/upload_apple`, {
              workout: compressedWorkout,
              name: "Apple Watch",
            });

            console.log("Workout uploaded successfully");
          } catch (error) {
            console.error("Error processing workout:", error);
          }
        }

        // Update last fetch date
        await axios.post(`${this.$host}/healthkit/last_fetch`, { lastFetchedDate: sixWeeksAgo });
        await axios.post(`${this.$host}/healthkit/should_fetch`, { shouldFetch: true });

        // Check healthkit connection status
        const res = await axios.get(`${this.$host}/healthkit/is_connected`);
        this.healthkitConnected = res.data.connected;
        this.healthkitConnecting = false;

        await AppleWorkoutPlugin.requestWorkoutKitAuthorization();
        await AppleWorkoutPlugin.startObservingWorkouts();
      } catch (error) {
        console.error("Error connecting to Apple HealthKit:", error);
        this.healthkitConnecting = false;
        // Show error message to the user
        this.errorMessage = "Fehler bei der Verbindung zu Apple HealthKit. Bitte überprüfe die Berechtigungen.";
      }
    },

    // Method to display error messages in the template
    showError() {
      if (this.errorMessage) {
        return `<div class="alert alert-danger">${this.errorMessage}</div>`;
      }
      return "";
    },

    connectGarmin: function () {
      const _self = this;

      axios.get(this.$host + "/user/garmin/oauth").then(function (res) {
        _self.token = res.data.oauth_token;
        _self.userInterval = setInterval(function () {
          _self.refreshUser();
        }, 3500);

        if (_self.token && _self.token.length > 0) {
          window.location.href =
            "https://connect.garmin.com/oauthConfirm?" +
            _self.token +
            "&" +
            encodeURIComponent(
              "oauth_callback=https://backend.pushinglimits.club" + "/user/garmin/oauth/callback?action=step3"
            );
        }
      });
    },

    connectPolar: async function () {
      const _self = this;
      axios
        .get(this.$host + "/polar/auth")
        .then(function (response) {
          // handle success
          _self.userInterval = setInterval(function () {
            _self.refreshUser();
          }, 3500);
          window.location.href = response.data.url;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },

    connectWhoop: async function () {
      const _self = this;
      axios
        .get(this.$host + "/whoop/auth")
        .then(function (response) {
          // handle success
          _self.userInterval = setInterval(function () {
            _self.refreshUser();
          }, 3500);
          window.location.href = response.data.url;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },

    disconnectWhoop: function () {
      const _self = this;
      axios.delete(this.$host + "/whoop/disconnect").then(async function () {
        _self.user = (await axios.get(_self.$host + "/user/me")).data;
      });
    },

    connectSuunto: async function () {
      const _self = this;
      axios
        .get(this.$host + "/suunto/auth")
        .then(function (response) {
          // handle success
          _self.userInterval = setInterval(function () {
            _self.refreshUser();
          }, 3500);
          window.location.href = response.data.url;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },

    connectCoros: async function () {
      const _self = this;
      axios
        .get(this.$host + "/coros/auth")
        .then(function (response) {
          // handle success
          _self.userInterval = setInterval(function () {
            _self.refreshUser();
          }, 3500);
          window.location.href = response.data.url;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },

    connectWahoo: async function () {
      const _self = this;

      axios
        .get(this.$host + "/wahoo/auth")
        .then(function (response) {
          // handle success
          _self.userInterval = setInterval(function () {
            _self.refreshUser();
          }, 3500);
          window.location.href = response.data.url;
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    },

    disconnectSuunto: function () {
      const _self = this;
      axios.delete(this.$host + "/suunto/delete_registration").then(async function () {
        _self.user = (await axios.get(_self.$host + "/user/me")).data;
      });
    },

    disconnectICTrainer: function () {
      const _self = this;
      axios.delete(this.$host + "/user/disconnect_ictrainer").then(async function () {
        _self.icTrainerConnected = false;
      });
    },

    disconnectCoros: function () {
      const _self = this;
      axios.delete(this.$host + "/coros/delete_registration").then(async function () {
        _self.user = (await axios.get(_self.$host + "/user/me")).data;
      });
    },

    disconnectGarmin: function () {
      const _self = this;

      axios.delete(this.$host + "/garmin/delete_registration").then(async function () {
        _self.user = (await axios.get(_self.$host + "/user/me")).data;
        _self.token = await (await axios.get(_self.$host + "/user/garmin/oauth")).data.oauth_token;
      });
    },

    disconnectPolar: function () {
      const _self = this;
      axios.delete(this.$host + "/polar/delete_registration").then(async function () {
        _self.user = (await axios.get(_self.$host + "/user/me")).data;
      });
    },

    disconnectStrava: function () {
      const _self = this;
      axios.delete(this.$host + "/strava/delete_registration").then(async function () {
        _self.user = (await axios.get(_self.$host + "/user/me")).data;
      });
    },

    disconnectWahoo: function () {
      const _self = this;
      axios.delete(this.$host + "/wahoo/delete_registration").then(async function () {
        _self.user = (await axios.get(_self.$host + "/user/me")).data;
      });
    },

    setNewHrZones: function () {
      console.log("SET NEW HR ZONES");
      this.hrZonesUpdated = false;
      let req = {
        hr_zone_1: this.hr_zone_1,
        hr_zone_2: this.hr_zone_2,
        hr_zone_3: this.hr_zone_3,
        hr_zone_4: this.hr_zone_4,
      };

      const _self = this;

      axios
        .post(_self.$host + "/user/hr_zones", req)
        .then(function () {
          //self.updateThresholds();
          _self.hrZonesUpdated = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    setNewHrZonesRun: function () {
      this.hrZonesUpdatedRun = false;
      let req = {
        hr_zone_1_run: this.hr_zone_1_run,
        hr_zone_2_run: this.hr_zone_2_run,
        hr_zone_3_run: this.hr_zone_3_run,
        hr_zone_4_run: this.hr_zone_4_run,
      };

      const _self = this;

      axios
        .post(_self.$host + "/user/hr_zones_run", req)
        .then(function () {
          //self.updateThresholds();
          _self.hrZonesUpdatedRun = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    setNewThresholds: function () {
      this.thresholdsUpdated = false;
      let req = {
        ftp: this.ftp,
        threshold_swim: this.$moment.duration("0:" + this.swimThreshold).asSeconds(),
        threshold_run: this.$moment.duration("0:" + this.runThreshold).asSeconds(),
        maxHr: this.maxHr,
        restingHr: this.restingHr,
        weight: this.weight,
      };

      const _self = this;

      axios
        .post(this.$host + "/user/thresholds", req)
        .then(function () {
          _self.thresholdsChanged = false;
          _self.updateThresholds();
          _self.thresholdsUpdated = true;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.threshold {
  padding: 10px;
  border-bottom: 2px solid #fff;
}

.close-overlay i {
  cursor: pointer;
  font-size: 45px;
  float: right;
  color: #000;
}

.overlay-box {
  padding: 15px;
  overflow-y: auto;
  position: fixed;
  top: 3%;
  left: 5%;
  right: 5%;
  bottom: 3%;
  background: #e8e8e8;
}

.library {
  background: #efefef;
  padding: 20px;
  border-radius: 5px;
}

.info {
  padding: 25px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0%;
  z-index: 600;
  background: rgba(0, 0, 0, 0.5);
}

.profil-heading {
  font-size: 80px;
  text-align: center;
}

.workout-modal {
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.workout-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  font-size: 14px;
  position: relative;
}

.modal-title {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 15px;
}

.close-button {
  float: right;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  background: none;
  border: none;
  color: #333;
}

.workout-list {
  margin-top: 20px;
}

.workout-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.workout-info h3 {
  font-size: 16px;
  margin: 0 0 5px 0;
}

.workout-info p {
  margin: 0;
  color: #666;
  font-size: 12px;
}

.import-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.import-button:hover {
  background-color: #0056b3;
}

.close-modal-button {
  display: block;
  margin: 20px auto 0;
  padding: 8px 15px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.close-modal-button:hover {
  background-color: #5a6268;
}

.import-all-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.import-all-button:hover {
  background-color: #218838;
}

.imported-label {
  color: #28a745;
  font-weight: bold;
}

.import-all-button {
  opacity: 0.5;
  cursor: not-allowed;
}

.import-all-button:hover:not([disabled]) {
  background-color: #218838;
}

.spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.1); /* Change to white */
  border-radius: 50%;
  border-left-color: #fff; /* Change to white */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
