<template>
  <div class="analysis-view">
    <h2>Heart Rate Analysis History</h2>
    
    <div v-if="loading" class="loading">
      Loading analyses...
    </div>
    
    <div v-else-if="analyses.length === 0" class="no-data">
      No analyses available.
    </div>
    
    <div v-else class="analysis-table">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Date</th>
            <th>Sport</th>
            <th>Threshold</th>
            <th>Computed</th>
            <th>Avg Adjusted</th>
            <th>Median Adjusted</th>
            <th>Quality</th>
            <th>Points</th>
            <th>User ID</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody v-for="analysis in analyses" :key="analysis._id">
          <tr class="main-row" @click="toggleDetails(analysis._id)">
            <td>
              <i class="fas" :class="{'fa-chevron-down': !expandedRows[analysis._id], 'fa-chevron-up': expandedRows[analysis._id]}"></i>
            </td>
            <td>{{ formatDate(analysis.createdAt) }}</td>
            <td>
              <span class="sport-badge" :class="analysis.sport.toLowerCase()">
                {{ analysis.sport }}
              </span>
            </td>
            <td>
              <template v-if="analysis.sport === 'Laufen'">
                {{ formatPace(analysis.runThreshold) }}
              </template>
              <template v-else-if="analysis.sport === 'Radfahren'">
                {{ analysis.ftp }}W
              </template>
            </td>
            <td>
              <template v-if="analysis.sport === 'Laufen' && analysis.computedRunThreshold">
                <span :class="getAccuracyClass(analysis.runThreshold, kmhToPace(analysis.computedRunThreshold))">
                  {{ formatPace(kmhToPace(analysis.computedRunThreshold)) }}
                  <small class="diff">
                    {{ getDifference(analysis.runThreshold, kmhToPace(analysis.computedRunThreshold)) }}%
                  </small>
                </span>
              </template>
              <template v-else-if="analysis.sport === 'Radfahren' && analysis.computedFTP">
                <span :class="getAccuracyClass(analysis.ftp, analysis.computedFTP)">
                  {{ analysis.computedFTP }}W
                  <small class="diff">
                    {{ getDifference(analysis.ftp, analysis.computedFTP) }}%
                  </small>
                </span>
              </template>
              <template v-else>
                -
              </template>
            </td>
            <td v-html="getAdjustedValue(analysis, 'avg')"></td>
            <td v-html="getAdjustedValue(analysis, 'median')"></td>
            <td>
              <span class="quality-value" :class="getRSquaredClass(analysis.rSquared)">
                {{ formatDecimal(analysis.rSquared) }}
              </span>
            </td>
            <td>{{ analysis.numberOfAnalyses }}</td>
            <td>{{ analysis.user }}</td>
            <td>{{ formatDateTime(analysis.createdAt) }}</td>
          </tr>
          <tr v-if="expandedRows[analysis._id]" class="details-row">
            <td colspan="11">
              <div class="details-content">
                <div class="tabs">
                  <button 
                    class="tab-button" 
                    :class="{ active: activeTab[analysis._id] === 'chart' }"
                    @click="setActiveTab(analysis._id, 'chart')"
                  >
                    Chart
                  </button>
                  <button 
                    class="tab-button" 
                    :class="{ active: activeTab[analysis._id] === 'data' }"
                    @click="setActiveTab(analysis._id, 'data')"
                  >
                    Data
                  </button>
                </div>

                <div class="tab-content">
                  <!-- Chart Tab -->
                  <div v-if="activeTab[analysis._id] === 'chart'" class="charts-wrapper">
                    <div class="chart-container">
                      <h3>Raw Data</h3>
                      <div class="chart-wrapper">
                        <canvas :ref="'chart-' + analysis._id"></canvas>
                      </div>
                    </div>
                    <div class="chart-container">
                      <h3>HR-Adjusted Data</h3>
                      <div class="chart-wrapper">
                        <canvas :ref="'chart-adjusted-' + analysis._id"></canvas>
                      </div>
                    </div>
                    <div class="chart-container">
                      <h3>Normalized HR-Adjusted Data</h3>
                      <div class="chart-wrapper">
                        <canvas :ref="'chart-normalized-' + analysis._id"></canvas>
                      </div>
                    </div>
                  </div>

                  <!-- Data Tab -->
                  <div v-else class="details-grid">
                    <div class="detail-section">
                      <h4>Statistical Data</h4>
                      <div class="detail-item">
                        <span class="label">R²:</span>
                        <span class="value">{{ formatDecimal(analysis.rSquared) }}</span>
                      </div>
                      <div class="detail-item">
                        <span class="label">Slope:</span>
                        <span class="value">{{ formatDecimal(analysis.slope) }}</span>
                      </div>
                      <div class="detail-item">
                        <span class="label">Intercept:</span>
                        <span class="value">{{ formatDecimal(analysis.intercept) }}</span>
                      </div>
                    </div>
                    
                    <div class="detail-section">
                      <h4>Heart Rate Data</h4>
                      <div class="heart-rate-table">
                        <table>
                          <thead>
                            <tr>
                              <th>BPM</th>
                              <th>{{ analysis.sport === 'Laufen' ? 'Speed' : 'Power' }}</th>
                              <th>Points</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(data, index) in analysis.heartRateData" :key="index">
                              <td>{{ data.bpm }}</td>
                              <td>
                                {{ analysis.sport === 'Laufen' 
                                  ? formatSpeed(data.avgSpeed) 
                                  : `${data.avgPower}W` }}
                              </td>
                              <td>{{ data.numberOfPoints }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Chart from 'chart.js/auto';

export default {
  name: 'WorkoutAnalysisView',
  
  data() {
    return {
      analyses: [],
      loading: true,
      expandedRows: {},
      activeTab: {},
      charts: {},
    };
  },
  
  async created() {
    try {
      const response = await axios.get(this.$host + "/workout/heart-rate-analyses/all");
      this.analyses = response.data;
    } catch (error) {
      console.error('Error fetching analyses:', error);
    } finally {
      this.loading = false;
    }
  },
  
  methods: {
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
    
    formatDecimal(value) {
      return value ? value.toFixed(3) : 'N/A';
    },

    // Convert pace (sec/km) to speed (km/h)
    paceToKmh(paceInSecPerKm) {
      if (!paceInSecPerKm) return 0;
      return 3600 / paceInSecPerKm;
    },

    // Format pace in min:sec/km
    formatPace(seconds) {
      if (!seconds) return '-';
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}/km`;
    },
    
    getDifference(actual, computed) {
      if (!actual || !computed) return '';
      const diff = ((computed - actual) / actual) * 100;
      return diff > 0 ? `+${diff.toFixed(1)}` : diff.toFixed(1);
    },
    
    getAccuracyClass(actual, computed) {
      if (!actual || !computed) return '';
      const diff = Math.abs((computed - actual) / actual * 100);
      if (diff <= 5) return 'excellent';
      if (diff <= 10) return 'good';
      return 'poor';
    },
    
    getRSquaredClass(rSquared) {
      if (!rSquared) return 'poor';
      if (rSquared >= 0.9) return 'excellent';
      if (rSquared >= 0.7) return 'good';
      return 'poor';
    },

    setActiveTab(analysisId, tab) {
      // Cleanup existing charts before switching tabs
      if (this.charts[analysisId]) {
        if (Array.isArray(this.charts[analysisId])) {
          this.charts[analysisId].forEach(chart => chart.destroy());
        } else {
          this.charts[analysisId].destroy();
        }
        delete this.charts[analysisId];
      }
      
      this.$set(this.activeTab, analysisId, tab);
      if (tab === 'chart') {
        this.$nextTick(() => {
          this.initChart(analysisId);
        });
      }
    },

    toggleDetails(analysisId) {
      const isExpanding = !this.expandedRows[analysisId];
      
      // Cleanup existing charts before toggling
      if (this.charts[analysisId]) {
        if (Array.isArray(this.charts[analysisId])) {
          this.charts[analysisId].forEach(chart => chart.destroy());
        } else {
          this.charts[analysisId].destroy();
        }
        delete this.charts[analysisId];
      }
      
      this.$set(this.expandedRows, analysisId, isExpanding);
      
      if (isExpanding) {
        this.$set(this.activeTab, analysisId, 'chart');
        this.$nextTick(() => {
          this.initChart(analysisId);
        });
      }
    },

    calculateRegression(points) {
      // Need at least 2 points for regression
      if (points.length < 2) return null;
      
      let sumX = 0, sumY = 0, sumXY = 0, sumX2 = 0;
      const n = points.length;
      
      points.forEach(point => {
        sumX += point.x;
        sumY += point.y;
        sumXY += point.x * point.y;
        sumX2 += point.x * point.x;
      });
      
      const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
      const intercept = (sumY - slope * sumX) / n;
      
      return { slope, intercept };
    },

    calculateDistanceFromRegression(point, slope, intercept) {
      const predicted = slope * point.x + intercept;
      return Math.abs(point.y - predicted);
    },

    calculateHrAdjustedValue(value, heartRate, maxHr) {
      return value * (maxHr / heartRate);
    },

    initChart(analysisId) {
      const analysis = this.analyses.find(a => a._id === analysisId);
      if (!analysis || !analysis.heartRateData) return;

      // Cleanup existing charts
      if (this.charts[analysisId]) {
        if (Array.isArray(this.charts[analysisId])) {
          this.charts[analysisId].forEach(chart => chart.destroy());
        } else {
          this.charts[analysisId].destroy();
        }
      }

      // Get contexts for both charts using the separate refs
      const ctx1 = this.$refs[`chart-${analysisId}`][0].getContext('2d');
      const ctx2 = this.$refs[`chart-adjusted-${analysisId}`][0].getContext('2d');
      const ctx3 = this.$refs[`chart-normalized-${analysisId}`][0].getContext('2d');

      // Prepare scatter plot data with color based on number of points
      const maxPoints = Math.max(...analysis.heartRateData.map(d => d.numberOfPoints));
      const scatterData = analysis.heartRateData.map(d => ({
        x: d.bpm,
        y: analysis.sport === 'Laufen' 
          ? this.kmhToPace(d.avgSpeed)  // Convert speed to pace for running
          : d.avgPower,
        numberOfPoints: d.numberOfPoints
      }));

      // Calculate regression line points
      const minHR = Math.min(...analysis.heartRateData.map(d => d.bpm));
      const maxHR = Math.max(...analysis.heartRateData.map(d => d.bpm));
      
      // For running, convert the regression line values to pace
      const regressionPoints = [
        {
          x: minHR,
          y: analysis.sport === 'Laufen'
            ? this.kmhToPace(analysis.slope * minHR + analysis.intercept)
            : analysis.slope * minHR + analysis.intercept
        },
        {
          x: maxHR,
          y: analysis.sport === 'Laufen'
            ? this.kmhToPace(analysis.slope * maxHR + analysis.intercept)
            : analysis.slope * maxHR + analysis.intercept
        }
      ];

      // Calculate heart rate max point value and 90% point
      const hrMaxValue = analysis.sport === 'Laufen'
        ? this.kmhToPace(analysis.slope * analysis.heartRateMax + analysis.intercept)
        : analysis.slope * analysis.heartRateMax + analysis.intercept;
        
      const hr90Value = analysis.sport === 'Laufen'
        ? this.kmhToPace(analysis.slope * (analysis.heartRateMax * 0.9) + analysis.intercept)
        : analysis.slope * (analysis.heartRateMax * 0.9) + analysis.intercept;

      // Calculate high-intensity regression line with outlier removal
      const allPoints = analysis.heartRateData.map(d => ({
        x: d.bpm,
        y: analysis.sport === 'Laufen' ? this.kmhToPace(d.avgSpeed) : d.avgPower
      }));

      // First calculate initial regression to identify outliers
      const initialRegression = this.calculateRegression(allPoints);
      let highIntensityRegressionPoints = [];

      if (initialRegression) {
        // Calculate distances from regression line
        const pointsWithDistance = allPoints.map(point => ({
          ...point,
          distance: this.calculateDistanceFromRegression(point, initialRegression.slope, initialRegression.intercept)
        }));

        // Sort by distance and keep only the closest 80%
        const sortedPoints = pointsWithDistance.sort((a, b) => a.distance - b.distance);
        const numPointsToKeep = Math.floor(sortedPoints.length * 0.8);
        const filteredPoints = sortedPoints.slice(0, numPointsToKeep);

        // Calculate new regression with filtered points
        const highIntensityRegression = this.calculateRegression(filteredPoints);
        
        if (highIntensityRegression) {
          highIntensityRegressionPoints = [
            {
              x: minHR,
              y: analysis.sport === 'Laufen'
                ? this.kmhToPace(highIntensityRegression.slope * minHR + highIntensityRegression.intercept)
                : highIntensityRegression.slope * minHR + highIntensityRegression.intercept
            },
            {
              x: maxHR,
              y: analysis.sport === 'Laufen'
                ? this.kmhToPace(highIntensityRegression.slope * maxHR + highIntensityRegression.intercept)
                : highIntensityRegression.slope * maxHR + highIntensityRegression.intercept
            }
          ];
        }
      }

      // Prepare adjusted scatter plot data with weighting
      const adjustedScatterData = analysis.heartRateData.map(d => {
        // Repeat each point based on its weight (number of points)
        const repeatedPoints = Array(d.numberOfPoints).fill({
          x: d.bpm,
          y: analysis.sport === 'Laufen'
            ? this.kmhToPace(this.calculateHrAdjustedValue(d.avgSpeed, d.bpm, analysis.heartRateMax))
            : this.calculateHrAdjustedValue(d.avgPower, d.bpm, analysis.heartRateMax),
          numberOfPoints: 1  // Set to 1 since we're repeating the points
        });
        return repeatedPoints;
      }).flat();

      // Calculate median and average of adjusted values
      const adjustedValues = adjustedScatterData.map(d => d.y);
      const filteredAdjustedValues = this.removeOutliers(adjustedValues);
      const medianValue = this.calculateMedian(filteredAdjustedValues);
      const averageValue = this.calculateAverage(filteredAdjustedValues);

      // Calculate distances from regression line to identify outliers
      const initialAdjustedRegression = this.calculateRegression(adjustedValues);
      let adjustedRegression = null;  // Declare outside the if block

      if (initialAdjustedRegression) {
        const pointsWithDistance = adjustedValues.map(point => ({
          ...point,
          distance: this.calculateDistanceFromRegression(point, initialAdjustedRegression.slope, initialAdjustedRegression.intercept)
        }));

        // Sort by distance and remove 7% worst outliers
        const sortedPoints = pointsWithDistance.sort((a, b) => a.distance - b.distance);
        const numPointsToKeep = Math.floor(sortedPoints.length * 0.93);
        const filteredPoints = sortedPoints.slice(0, numPointsToKeep);

        // Calculate final regression with filtered points
        adjustedRegression = this.calculateRegression(filteredPoints);  // Assign to outer variable
        
       
      }

      // Calculate 90% HR point for adjusted data using the filtered regression
      const adjustedHr90Value = adjustedRegression 
        ? (analysis.sport === 'Laufen'
          ? this.kmhToPace(adjustedRegression.slope * (analysis.heartRateMax * 0.9) + adjustedRegression.intercept)
          : adjustedRegression.slope * (analysis.heartRateMax * 0.9) + adjustedRegression.intercept)
        : null;

      // Calculate normalized values
      const threshold = analysis.sport === 'Laufen' 
        ? this.paceToKmh(analysis.runThreshold)  // Convert pace to km/h for normalization
        : analysis.ftp;

      const thresholdAt90 = analysis.sport === 'Laufen'
        ? analysis.runThreshold  // Keep as pace for running
        : analysis.ftp;  // Keep as watts for cycling

      const normalizedScatterData = adjustedScatterData.map(point => ({
        x: point.x,  // Keep heart rate as is
        y: analysis.sport === 'Laufen'
          ? this.paceToKmh(point.y) / threshold  // Convert pace to speed and normalize
          : point.y / threshold,  // Normalize power
        numberOfPoints: point.numberOfPoints
      }));

      // Calculate normalized median and average
      const normalizedValues = normalizedScatterData.map(d => d.y);
      const filteredNormalizedValues = this.removeOutliers(normalizedValues);
      const normalizedMedian = this.calculateMedian(filteredNormalizedValues);
      const normalizedAverage = this.calculateAverage(filteredNormalizedValues);

      // Create first chart (keep existing chart code)
      const chart1 = new Chart(ctx1, {
        type: 'scatter',
        data: {
          datasets: [
            {
              label: analysis.sport === 'Laufen' ? 'Pace vs Heart Rate' : 'Power vs Heart Rate',
              data: scatterData,
              backgroundColor: scatterData.map(d => {
                const intensity = Math.min(d.numberOfPoints / maxPoints, 1);
                return `rgba(54, 162, 235, ${0.3 + intensity * 0.7})`;
              }),
              borderColor: scatterData.map(d => {
                const intensity = Math.min(d.numberOfPoints / maxPoints, 1);
                return `rgba(54, 162, 235, ${0.5 + intensity * 0.5})`;
              }),
              borderWidth: 1,
              pointRadius: 6,
            },
            {
              label: 'Regression Line',
              data: regressionPoints,
              type: 'line',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 2,
              fill: false,
              pointRadius: 0,
            },
            {
              label: 'Heart Rate Max',
              data: [{
                x: analysis.heartRateMax,
                y: hrMaxValue,
              }],
              type: 'scatter',
              backgroundColor: 'rgba(255, 193, 7, 1)',
              borderColor: 'rgba(255, 193, 7, 1)',
              borderWidth: 2,
              pointRadius: 8,
              pointStyle: 'triangle',
            },
            {
              label: '90% Heart Rate Max',
              data: [{
                x: analysis.heartRateMax * 0.9,
                y: hr90Value,
              }],
              type: 'scatter',
              backgroundColor: 'rgba(255, 87, 34, 1)',
              borderColor: 'rgba(255, 87, 34, 1)',
              borderWidth: 2,
              pointRadius: 8,
              pointStyle: 'triangle',
            },
            {
              label: 'Filtered Regression (80% best fit)',
              data: highIntensityRegressionPoints,
              type: 'line',
              borderColor: 'rgba(156, 39, 176, 1)',
              borderWidth: 2,
              borderDash: [5, 5],
              fill: false,
              pointRadius: 0,
            },
            {
              label: 'Threshold/FTP',
              data: [{
                x: analysis.heartRateMax * 0.9,
                y: thresholdAt90
              }],
              type: 'scatter',
              backgroundColor: 'rgba(255, 152, 0, 1)',
              borderColor: 'rgba(255, 152, 0, 1)',
              borderWidth: 2,
              pointRadius: 8,
              pointStyle: 'rect',
            },
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Heart Rate (BPM)'
              },
              min: Math.max(0, minHR - 10),
              max: Math.min(analysis.heartRateMax + 10, 200)
            },
            y: {
              title: {
                display: true,
                text: analysis.sport === 'Laufen' ? 'Pace (min/km)' : 'Power (W)'
              },
              min: analysis.sport === 'Laufen' ? this.kmhToPace(30) : 0, // 30 km/h pace as upper limit
              max: analysis.sport === 'Laufen' ? this.kmhToPace(5) : undefined, // 5 km/h pace as lower limit
              reverse: analysis.sport === 'Laufen', // Reverse axis for running pace
              ticks: {
                callback: (value) => {
                  if (analysis.sport === 'Laufen') {
                    return this.formatPace(value);
                  }
                  return value;
                }
              }
            }
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (context) => {
                  if (context.datasetIndex === 0) {
                    const dataPoint = analysis.heartRateData[context.dataIndex];
                    return [
                      `Heart Rate: ${dataPoint.bpm} BPM`,
                      analysis.sport === 'Laufen'
                        ? `Pace: ${this.formatPace(this.kmhToPace(dataPoint.avgSpeed))}`
                        : `Power: ${dataPoint.avgPower}W`,
                      `Points: ${dataPoint.numberOfPoints}`
                    ];
                  } else if (context.datasetIndex === 2) {
                    return [
                      `Heart Rate Max: ${analysis.heartRateMax} BPM`,
                      analysis.sport === 'Laufen'
                        ? `Predicted Pace: ${this.formatPace(hrMaxValue)}`
                        : `Predicted Power: ${Math.round(hrMaxValue)}W`
                    ];
                  } else if (context.datasetIndex === 3) {
                    return [
                      `90% Heart Rate Max: ${Math.round(analysis.heartRateMax * 0.9)} BPM`,
                      analysis.sport === 'Laufen'
                        ? `Predicted Pace: ${this.formatPace(hr90Value)}`
                        : `Predicted Power: ${Math.round(hr90Value)}W`
                    ];
                  } else if (context.datasetIndex === 4) {
                    return [
                      `Filtered Regression (80% best fit)`,
                      analysis.sport === 'Laufen'
                        ? `Predicted Pace: ${this.formatPace(highIntensityRegressionPoints[1].y)}`
                        : `Predicted Power: ${Math.round(highIntensityRegressionPoints[1].y)}W`
                    ];
                  } else if (context.datasetIndex === 5) {
                    return [
                      `90% Heart Rate Max: ${Math.round(analysis.heartRateMax * 0.9)} BPM`,
                      analysis.sport === 'Laufen'
                        ? `Threshold Pace: ${this.formatPace(thresholdAt90)}`
                        : `FTP: ${thresholdAt90}W`
                    ];
                  }
                  return null;
                }
              }
            }
          }
        }
      });

      // Create second chart with adjusted values
      const chart2 = new Chart(ctx2, {
        type: 'scatter',
        data: {
          datasets: [
            {
              label: analysis.sport === 'Laufen' ? 'HR-Adjusted Pace vs Heart Rate' : 'HR-Adjusted Power vs Heart Rate',
              data: adjustedScatterData,
              backgroundColor: adjustedScatterData.map(d => {
                const intensity = Math.min(d.numberOfPoints / maxPoints, 1);
                return `rgba(54, 162, 235, ${0.3 + intensity * 0.7})`;
              }),
              borderColor: adjustedScatterData.map(d => {
                const intensity = Math.min(d.numberOfPoints / maxPoints, 1);
                return `rgba(54, 162, 235, ${0.5 + intensity * 0.5})`;
              }),
              borderWidth: 1,
              pointRadius: 6,
            },
            {
              label: 'Median Value (20% outliers removed)',
              data: [{
                x: minHR + (maxHR - minHR) * 0.5,  // Place in middle of x-axis
                y: medianValue
              }],
              type: 'scatter',
              backgroundColor: 'rgba(76, 175, 80, 1)',  // Green
              borderColor: 'rgba(76, 175, 80, 1)',
              borderWidth: 2,
              pointRadius: 10,
              pointStyle: 'star',
            },
            {
              label: 'Average Value (20% outliers removed)',
              data: [{
                x: minHR + (maxHR - minHR) * 0.5,  // Place in middle of x-axis
                y: averageValue
              }],
              type: 'scatter',
              backgroundColor: 'rgba(156, 39, 176, 1)',  // Purple
              borderColor: 'rgba(156, 39, 176, 1)',
              borderWidth: 2,
              pointRadius: 10,
              pointStyle: 'rectRot',  // Rotated square
            },
            {
              label: '90% Heart Rate Max (Adjusted)',
              data: [{
                x: analysis.heartRateMax * 0.9,
                y: adjustedHr90Value,
              }],
              type: 'scatter',
              backgroundColor: 'rgba(255, 87, 34, 1)',
              borderColor: 'rgba(255, 87, 34, 1)',
              borderWidth: 2,
              pointRadius: 8,
              pointStyle: 'triangle',
            },
            {
              label: 'Threshold/FTP',
              data: [{
                x: analysis.heartRateMax * 0.9,
                y: thresholdAt90
              }],
              type: 'scatter',
              backgroundColor: 'rgba(255, 152, 0, 1)',
              borderColor: 'rgba(255, 152, 0, 1)',
              borderWidth: 2,
              pointRadius: 8,
              pointStyle: 'rect',
            },
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Heart Rate (BPM)'
              },
              min: Math.max(0, minHR - 10),
              max: Math.min(analysis.heartRateMax + 10, 200)
            },
            y: {
              title: {
                display: true,
                text: analysis.sport === 'Laufen' 
                  ? 'HR-Adjusted Pace (min/km)' 
                  : 'HR-Adjusted Power (W)'
              },
              min: analysis.sport === 'Laufen' ? this.kmhToPace(30) : 0,
              max: analysis.sport === 'Laufen' ? this.kmhToPace(5) : undefined,
              reverse: analysis.sport === 'Laufen',
              ticks: {
                callback: (value) => {
                  if (analysis.sport === 'Laufen') {
                    return this.formatPace(value);
                  }
                  return value;
                }
              }
            }
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (context) => {
                  if (context.datasetIndex === 0) {
                    const dataPoint = adjustedScatterData[context.dataIndex];
                    return [
                      `Heart Rate: ${dataPoint.x} BPM`,
                      analysis.sport === 'Laufen'
                        ? `Adjusted Pace: ${this.formatPace(dataPoint.y)}`
                        : `Adjusted Power: ${Math.round(dataPoint.y)}W`,
                      `Points: ${dataPoint.numberOfPoints}`
                    ];
                  } else if (context.datasetIndex === 1) {
                    return [
                      'Median Value (20% outliers removed)',
                      analysis.sport === 'Laufen'
                        ? `Pace: ${this.formatPace(medianValue)}`
                        : `Power: ${Math.round(medianValue)}W`,
                      `(${filteredAdjustedValues.length} of ${adjustedValues.length} points used)`
                    ];
                  } else if (context.datasetIndex === 2) {
                    return [
                      'Average Value (20% outliers removed)',
                      analysis.sport === 'Laufen'
                        ? `Pace: ${this.formatPace(averageValue)}`
                        : `Power: ${Math.round(averageValue)}W`,
                      `(${filteredAdjustedValues.length} of ${adjustedValues.length} points used)`
                    ];
                  } else if (context.datasetIndex === 3) {
                    return [
                      `90% Heart Rate Max: ${Math.round(analysis.heartRateMax * 0.9)} BPM`,
                      analysis.sport === 'Laufen'
                        ? `Adjusted Pace: ${this.formatPace(adjustedHr90Value)}`
                        : `Adjusted Power: ${Math.round(adjustedHr90Value)}W`,
                      analysis.sport === 'Laufen'
                        ? `Computed Threshold Pace: ${this.formatPace(this.kmhToPace(analysis.computedRunThreshold))}`
                        : `Computed FTP: ${analysis.computedFTP}W`
                    ];
                  } else if (context.datasetIndex === 4) {
                    return [
                      `90% Heart Rate Max: ${Math.round(analysis.heartRateMax * 0.9)} BPM`,
                      'Threshold/FTP Reference (1.0)'
                    ];
                  }
                  return null;
                }
              }
            }
          }
        }
      });

      // Create third chart with normalized values
      const chart3 = new Chart(ctx3, {
        type: 'scatter',
        data: {
          datasets: [
            {
              label: 'Normalized HR-Adjusted Values',
              data: normalizedScatterData,
              backgroundColor: normalizedScatterData.map(d => {
                const intensity = Math.min(d.numberOfPoints / maxPoints, 1);
                return `rgba(54, 162, 235, ${0.3 + intensity * 0.7})`;
              }),
              borderColor: normalizedScatterData.map(d => {
                const intensity = Math.min(d.numberOfPoints / maxPoints, 1);
                return `rgba(54, 162, 235, ${0.5 + intensity * 0.5})`;
              }),
              borderWidth: 1,
              pointRadius: 6,
            },
            {
              label: 'Normalized Median',
              data: [{
                x: minHR + (maxHR - minHR) * 0.5,  // Place in middle of x-axis
                y: normalizedMedian
              }],
              type: 'scatter',
              backgroundColor: 'rgba(76, 175, 80, 1)',
              borderColor: 'rgba(76, 175, 80, 1)',
              borderWidth: 2,
              pointRadius: 10,
              pointStyle: 'star',
            },
            {
              label: 'Normalized Average',
              data: [{
                x: minHR + (maxHR - minHR) * 0.5,
                y: normalizedAverage
              }],
              type: 'scatter',
              backgroundColor: 'rgba(156, 39, 176, 1)',
              borderColor: 'rgba(156, 39, 176, 1)',
              borderWidth: 2,
              pointRadius: 10,
              pointStyle: 'rectRot',
            },
            {
              label: 'Threshold Reference Line',
              data: [
                { x: minHR, y: 1 },
                { x: maxHR, y: 1 }
              ],
              type: 'line',
              borderColor: 'rgba(255, 87, 34, 0.5)',
              borderWidth: 2,
              borderDash: [5, 5],
              fill: false,
              pointRadius: 0,
            },
            {
              label: '90% Heart Rate Max',
              data: [{
                x: analysis.heartRateMax * 0.9,
                y: analysis.sport === 'Laufen'
                  ? this.paceToKmh(adjustedHr90Value) / threshold  // Normalize the 90% HR value
                  : adjustedHr90Value / threshold
              }],
              type: 'scatter',
              backgroundColor: 'rgba(255, 87, 34, 1)',
              borderColor: 'rgba(255, 87, 34, 1)',
              borderWidth: 2,
              pointRadius: 8,
              pointStyle: 'triangle',
            },
            {
              label: 'Threshold/FTP',
              data: [{
                x: analysis.heartRateMax * 0.9,
                y: 1.0  // Always 1.0 since this is the normalized value
              }],
              type: 'scatter',
              backgroundColor: 'rgba(255, 152, 0, 1)',
              borderColor: 'rgba(255, 152, 0, 1)',
              borderWidth: 2,
              pointRadius: 8,
              pointStyle: 'rect',
            },
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Heart Rate (BPM)'
              },
              min: Math.max(0, minHR - 10),
              max: Math.min(analysis.heartRateMax + 10, 200)
            },
            y: {
              title: {
                display: true,
                text: 'Normalized Value (1.0 = Threshold)'
              },
              min: 0,
              max: 2,  // Show up to 200% of threshold
            }
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (context) => {
                  if (context.datasetIndex === 0) {
                    const dataPoint = normalizedScatterData[context.dataIndex];
                    return [
                      `Heart Rate: ${dataPoint.x} BPM`,
                      `Normalized Value: ${(dataPoint.y * 100).toFixed(1)}% of threshold`,
                      `Points: ${dataPoint.numberOfPoints}`
                    ];
                  } else if (context.datasetIndex === 1) {
                    return [
                      'Normalized Median',
                      `${(normalizedMedian * 100).toFixed(1)}% of threshold`
                    ];
                  } else if (context.datasetIndex === 2) {
                    return [
                      'Normalized Average',
                      `${(normalizedAverage * 100).toFixed(1)}% of threshold`
                    ];
                  } else if (context.datasetIndex === 3) {
                    const point = context.dataset.data[0];
                    return [
                      `90% Heart Rate Max: ${Math.round(analysis.heartRateMax * 0.9)} BPM`,
                      `Normalized Value: ${(point.y * 100).toFixed(1)}% of threshold`
                    ];
                  } else if (context.datasetIndex === 4) {
                    return [
                      `90% Heart Rate Max: ${Math.round(analysis.heartRateMax * 0.9)} BPM`,
                      'Threshold/FTP Reference (1.0)'
                    ];
                  }
                  return null;
                }
              }
            }
          }
        }
      });

      // Store both charts
      this.charts[analysisId] = [chart1, chart2, chart3];
    },

    // Add new method to convert km/h to pace (seconds per km)
    kmhToPace(speedKmh) {
      if (!speedKmh) return 0;
      return 3600 / speedKmh; // Convert km/h to seconds per km
    },

    // Update formatSpeed to use pace for running
    formatSpeed(speed, sport) {
      if (!speed) return '-';
      if (sport === 'Laufen') {
        return this.formatPace(this.kmhToPace(speed));
      }
      return `${speed.toFixed(1)} km/h`;
    },

    // Add these helper methods to calculate median and average
    calculateMedian(values) {
      const sorted = [...values].sort((a, b) => a - b);
      const middle = Math.floor(sorted.length / 2);
      
      if (sorted.length % 2 === 0) {
        return (sorted[middle - 1] + sorted[middle]) / 2;
      }
      return sorted[middle];
    },

    calculateAverage(values) {
      return values.reduce((sum, val) => sum + val, 0) / values.length;
    },

    getAdjustedValue(analysis, type) {
      if (!analysis.heartRateData || analysis.heartRateData.length === 0) return '-';

      // Calculate adjusted values
      const adjustedValues = analysis.heartRateData.map(d => {
        const value = analysis.sport === 'Laufen' ? d.avgSpeed : d.avgPower;
        return this.calculateHrAdjustedValue(value, d.bpm, analysis.heartRateMax);
      });

      // Filter out outliers
      const filteredAdjustedValues = this.removeOutliers(adjustedValues);

      let adjustedValue;
      if (type === 'avg') {
        adjustedValue = this.calculateAverage(filteredAdjustedValues);
      } else {
        adjustedValue = this.calculateMedian(filteredAdjustedValues);
      }

      // Get the profile threshold value
      const profileValue = analysis.sport === 'Laufen' 
        ? this.paceToKmh(analysis.runThreshold)  // Convert pace to speed for comparison
        : analysis.ftp;

      // Calculate difference percentage
      const diff = this.getDifference(profileValue, adjustedValue);
      const diffClass = this.getAccuracyClass(profileValue, adjustedValue);

      // Format the value
      const formattedValue = analysis.sport === 'Laufen'
        ? this.formatPace(this.kmhToPace(adjustedValue))
        : `${Math.round(adjustedValue)}W`;

      // Get background color based on accuracy
      const bgColor = diffClass === 'excellent' ? 'rgba(46, 125, 50, 0.1)' 
        : diffClass === 'good' ? 'rgba(245, 124, 0, 0.1)' 
        : 'rgba(198, 40, 40, 0.1)';

      // Add count of points used to the display
      const pointsInfo = `(${filteredAdjustedValues.length}/${adjustedValues.length})`;

      return `<span class="${diffClass}" style="padding: 4px 8px; border-radius: 4px; background: ${bgColor}">
        ${formattedValue} ${pointsInfo} <small class="diff">${diff}%</small>
      </span>`;
    },

    formatDateTime(dateString) {
      return new Date(dateString).toLocaleString('de-DE', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    },

    // Add this helper method to the methods section
    removeOutliers(values, percentToRemove = 0.3) {
      // Sort values
      const sorted = [...values].sort((a, b) => a - b);
      
      // Calculate how many values to remove from each end
      const removeCount = Math.floor(values.length * (percentToRemove / 2));
      
      // Return the middle values
      return sorted.slice(removeCount, sorted.length - removeCount);
    },
  },

  beforeDestroy() {
    // Cleanup all charts
    Object.values(this.charts).forEach(chart => {
      if (Array.isArray(chart)) {
        chart.forEach(c => c.destroy());
      } else {
        chart.destroy();
      }
    });
  }
};
</script>

<style scoped>
.analysis-view {
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.analysis-table {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9em;
}

th {
  text-align: left;
  padding: 12px;
  background: #f8f9fa;
  border-bottom: 2px solid #dee2e6;
  color: #495057;
  font-weight: 600;
}

td {
  padding: 12px;
  border-bottom: 1px solid #dee2e6;
  vertical-align: middle;
}

tr:last-child td {
  border-bottom: none;
}

.sport-badge {
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 0.8em;
  font-weight: 500;
}

.sport-badge.running {
  background: #e3f2fd;
  color: #1976d2;
}

.sport-badge.cycling {
  background: #e8f5e9;
  color: #388e3c;
}

.diff {
  font-size: 0.75em;
  margin-left: 4px;
  color: #666;
}

.excellent {
  color: #2e7d32;
  font-weight: 500;
}

.good {
  color: #f57c00;
  font-weight: 500;
}

.poor {
  color: #c62828;
  font-weight: 500;
}

.loading, .no-data {
  text-align: center;
  padding: 40px;
  color: #666;
  font-size: 1.1em;
}

tr:hover {
  background-color: #f8f9fa;
}

.debug-info {
  margin-top: 20px;
  padding: 10px;
  background: #f8f9fa;
  border-radius: 4px;
  font-size: 0.8em;
  color: #666;
}

pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.main-row {
  cursor: pointer;
}

.main-row:hover {
  background-color: #f8f9fa;
}

.details-row {
  background-color: #f8f9fa;
}

.details-content {
  padding: 20px;
  max-width: 100%;
  overflow-x: hidden;
}

.details-grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
}

.detail-section {
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.05);
}

.detail-section h4 {
  margin: 0 0 15px 0;
  color: #495057;
  font-size: 1em;
}

.detail-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.detail-item:last-child {
  border-bottom: none;
}

.detail-item .label {
  color: #666;
}

.detail-item .value {
  font-weight: 500;
}

.heart-rate-table table {
  width: 100%;
  font-size: 0.9em;
}

.heart-rate-table th,
.heart-rate-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.heart-rate-table th {
  font-weight: 500;
  color: #495057;
  background: #f8f9fa;
}

.fas {
  width: 16px;
  text-align: center;
  transition: transform 0.2s ease;
}

.fa-chevron-up {
  transform: rotate(180deg);
}

.tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-button {
  padding: 8px 16px;
  border: none;
  background: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  color: #666;
}

.tab-button:hover {
  background: #f0f0f0;
}

.tab-button.active {
  background: #e3f2fd;
  color: #1976d2;
  font-weight: 500;
}

.charts-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin: 20px 0;
}

.chart-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.05);
  min-width: 0;
  margin-bottom: 20px;
}

.chart-container:last-child {
  margin-bottom: 0;
}

.chart-container h3 {
  margin: 0 0 15px 0;
  color: #495057;
  font-size: 1.1em;
}

.chart-wrapper {
  position: relative;
  height: 400px;
  width: 100%;
}

.chart-wrapper canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tab-content {
  position: relative;
  width: 100%;
}

/* Add Font Awesome icons */
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');
</style>
