<template>
  <div class="impressum">
    <div class="row">
      <div class="col-lg-3"></div>
      <div class="col-lg-6 white_wrapper">
        <h3 class="top-spacer-50">Impressum</h3>

        <h4 class="top-spacer-25">Kontaktaufnahme:</h4>

        E-Mail und Support: kontakt@pushingideas.com<br />

        <h4 class="top-spacer-25">Anbieter</h4>

        Pushing Ideas GmbH <br />
        Hovesaatstr. 6<br />
        48432 Rheine<br /><br />

        Datenschutzbeauftrager: Jan Staggenborg<br />
        <br /><br /><br />

        Amtsgericht Münster<br />
        HRB 20150<br />
        Geschäftsführer: Jan Staggenborg
        <br /><br /><br />
        USt-IdNr: DE351526567
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Start",
  props: {
    msg: String,
  },
};
</script>

<style>
.impressum {
  font-size: 16px;
}
</style>