<template>
  <div class="kraftraum" :class="workoutStarted ? 'py-4' : 'py-4'">
    <!-- Loading Overlay -->
    <div
      class="
        position-fixed
        top-0
        start-0
        w-100
        h-100
        d-flex
        justify-content-center
        align-items-center
        bg-dark bg-opacity-75
      "
      style="z-index: 1050"
      v-if="isLoading"
    >
      <div class="spinner-border text-light" role="status">
        <span class="visually-hidden">Lade...</span>
      </div>
    </div>

    <!-- Error Toast -->
    <div class="toast-container position-fixed top-0 end-0 p-3" v-if="showError" style="z-index: 1150">
      <div class="toast" :class="{ show: showError }" role="alert">
        <div class="toast-header bg-danger text-white d-flex justify-content-between align-items-center">
          <strong>Error</strong>
          <button
            type="button"
            class="text-white"
            style="background: none; border: none; text-align: right; font-size: 1.2rem"
            @click="showError = false"
          >
            ×
          </button>
        </div>
        <div class="toast-body">{{ errorMessage }}</div>
      </div>
    </div>

    <div class="setup-section" v-if="!workoutStarted">
      <!-- Mode Tabs -->
      <ul class="nav nav-tabs custom-tabs">
        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: workoutMode === 'custom' }"
            @click.prevent="workoutMode = 'custom'"
            href="#"
          >
            <i class="bi bi-pencil-square me-2"></i> Zusammenstellen
          </a>
        </li>

        <li class="nav-item" v-if="formattedPlannedWorkouts.length > 0">
          <a
            class="nav-link"
            :class="{ active: workoutMode === 'planned' }"
            @click.prevent="workoutMode = 'planned'"
            href="#"
          >
            <i class="bi bi-calendar-check me-2"></i> Geplante Workouts <span style="font-weight:bold; color: #0d6efd;">({{formattedPlannedWorkouts.length}})</span>
          </a>
        </li>

        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: workoutMode === 'templates' }"
            @click.prevent="workoutMode = 'templates'"
            href="#"
          >
            <i class="bi bi-file-text me-2"></i> Vorlagen
          </a>
        </li>

        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: workoutMode === 'settings' }"
            @click.prevent="workoutMode = 'settings'"
            href="#"
          >
            <i class="bi bi-gear-fill me-2"></i> Übungen einstellen ({{
              filteredAvailableExercises.filter((ex) => ex.enabled).length
            }}/{{ filteredAvailableExercises.length }})
          </a>
        </li>

        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: workoutMode === 'videos' }"
            @click.prevent="workoutMode = 'videos'"
            href="#"
          >
            <i class="bi bi-play-circle me-2"></i> Videos
          </a>
        </li>        
      </ul>

      <!-- Tab content -->
      <div class="">
        <div v-if="workoutMode === 'templates'" class="templates-container">
          <!-- Empty state -->
          <div v-if="blueprints.length === 0" class="empty-state">
            <div class="empty-state-content">
              <i class="bi bi-file-text display-4"></i>
              <h4>Keine Vorlagen vorhanden</h4>
              <p class="text-muted">
                Erstelle ein Workout und speichere es als Vorlage. Wir werden zukünftig eine Vielzahl von Vorlagen
                anbieten.
              </p>
            </div>
          </div>

          <!-- Templates grid -->
          <div v-else class="templates-grid">
            <div v-for="blueprint in blueprints" :key="blueprint._id" class="template-card">
              <!-- Template header -->
              <div class="template-header">
                <h3 class="template-title">{{ blueprint.name }}</h3>
                <div class="template-duration">
                  <i class="bi bi-clock"></i>
                  {{ calculateWorkoutDuration(blueprint) }}
                </div>
              </div>

              <!-- Template stats -->
              <div class="template-stats">
                <div class="stat-item">
                  <i class="bi bi-grid-3x3"></i>
                  <span>{{ blueprint.settings.blockLength }} Übungen/Block</span>
                </div>
                <div class="stat-item">
                  <i class="bi bi-pause-circle"></i>
                  <span>{{ blueprint.settings.shortBreakDuration }}s Übungspause</span>
                </div>
                <div class="stat-item">
                  <i class="bi bi-hourglass-split"></i>
                  <span>{{ formatBreakDuration(blueprint.settings.breakDuration) }} Blockpause</span>
                </div>
              </div>

              <!-- Template actions -->
              <div class="template-actions">
                <button class="btn-preview" @click="previewBlueprint(blueprint)">
                  <i class="bi bi-eye"></i>
                  <span>Vorschau</span>
                </button>
                <button class="btn-load" @click="loadBlueprint(blueprint)">
                  <i class="bi bi-play-fill"></i>
                  <span>Laden</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Add in the tab content section -->
        <div v-if="workoutMode === 'planned'" class="templates-container">
          <!-- Empty state -->
          <div v-if="formattedPlannedWorkouts.length === 0" class="empty-state">
            <div class="empty-state-content">
              <i class="bi bi-calendar-x display-4"></i>
              <h4>Keine geplanten Workouts</h4>
              <p class="text-muted">
                Aktuell sind keine Kraftraum-Workouts geplant.
              </p>
            </div>
          </div>

          <!-- Planned workouts grid -->
          <div v-else class="templates-grid">
            <div v-for="workout in formattedPlannedWorkouts" :key="workout._id" class="template-card">
              <!-- Template header -->

              <div class="template-header">
                <h3 class="template-title">{{ workout.name }}</h3>
                <div class="template-duration">
                  <i class="bi bi-clock"></i>
                  {{ calculateWorkoutDuration(workout) }} 
                </div>
              </div><br/>
              <!-- Template stats -->
              <div class="template-stats">
                <div class="stat-item">
                  <i class="bi bi-calendar-check"></i>
                  <span>{{ $moment(workout.date).format('DD.MM.YYYY') }}</span>
                </div>
                <div class="stat-item">
                  <i class="bi bi-grid-3x3"></i>
                  <span>{{ workout.settings.blockLength }} Übungen/Block</span>
                </div>
                <div class="stat-item">
                  <i class="bi bi-pause-circle"></i>
                  <span>{{ workout.settings.shortBreakDuration }}s Übungspause</span>
                </div>
                <div class="stat-item">
                  <i class="bi bi-hourglass-split"></i>
                  <span>{{ formatBreakDuration(workout.settings.breakDuration) }} Blockpause</span>
                </div>
              </div>

              <!-- Template actions -->
              <div class="">
      
                <button class="btn-load w-100" style="border:0" @click="loadPlannedWorkout(workout)">
                  <i class="bi bi-play-fill"></i>
                  <span>Laden</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Settings Mode Content -->
        <div v-if="workoutMode === 'settings'" class="exercise-settings">
          <!-- Header Section -->
          <div class="settings-header">
            <div class="header-content">
              <h5>Verfügbare Übungen</h5>
              <p class="text-muted">
                Wähle die Übungen aus, die in deinem Workout erscheinen sollen. Deaktivierte Workouts tauchen nicht zur
                Auswahl aus und werden in Vorlagen ersetzt.
              </p>
            </div>
            <button class="btn btn-outline-primary" @click="toggleAllExercises">
              {{ allExercisesEnabled ? "Alle deaktivieren" : "Alle aktivieren" }}
            </button>
          </div>

          <!-- Exercise Categories -->
          <div class="exercise-categories">
            <button
              v-for="category in [
                'Alle',
                'Bodyweight',
                'Pilates',
                'Kurzhantel',
                'Yoga',
                'Resistance Band',
                'Dehnung & Mobilität',
              ]"
              :key="category"
              class="category-btn"
              :class="{ active: selectedCategory === category }"
              @click="selectCategory(category)"
            >
              {{ category }}
            </button>
            <button
              class="category-btn"
              :class="{ active: showMuscleGroupFilter }"
              @click="showMuscleGroupFilter = !showMuscleGroupFilter"
            >
              <i class="bi bi-funnel"></i> Muskelgruppen
            </button>
          </div>

          <div v-if="showMuscleGroupFilter && availableMuscleGroups.length > 0" style="margin-bottom: 2rem">
            <div class="muscle-groups">
              <h6>Muskelgruppen Filter</h6>
              <div class="muscle-group-buttons">
                <button
                  v-for="group in filteredMuscleGroups"
                  :key="group.english"
                  class="muscle-btn"
                  :class="{ active: selectedMuscleGroup === group.english }"
                  @click="selectedMuscleGroup = group.english"
                >
                  {{ group.german }}
                </button>
              </div>
            </div>
          </div>

          <!-- Fügen Sie diesen Button-Container hinzu -->
          <div v-if="selectedCategory != 'Alle' || selectedMuscleGroup != 'all'" class="category-actions">
            <button class="btn btn-outline-primary" @click="toggleCategoryExercises(selectedCategory, true)">
              <i class="bi bi-check-all"></i> {{ selectedCategory }} Übungen aktivieren
            </button>
            <button class="btn btn-outline-danger" @click="toggleCategoryExercises(selectedCategory, false)">
              <i class="bi bi-x-lg"></i> {{ selectedCategory }} Übungen deaktivieren
            </button>
          </div>

          <!-- Search Bar -->
          <div class="search-bar" style="padding-left: 1rem; padding-right: 1rem">
            <input type="text" v-model="searchQuery" placeholder="Übungen durchsuchen..." class="search-input" />
            <button v-if="searchQuery" class="clear-search" @click="searchQuery = ''">
              <i class="bi bi-x"></i>
            </button>
          </div>

          <!-- Exercise Grid -->
          <div class="exercise-grid">
            <div
              v-for="exercise in filteredAvailableExercises"
              :key="exercise.id"
              class="exercise-card"
              :class="{ 'is-disabled': !exercise.enabled }"
            >
              <div class="exercise-image" @click="openVideoPreview(exercise)">
                <img :src="exercise.thumbnail" :alt="exercise.title" />
                <div class="exercise-badges">
                  <span v-for="category in exerciseBadges(exercise)" :key="category" class="badge" :class="category">
                    {{ getCategoryLabel(category) }}
                  </span>
                </div>
                <div class="play-overlay">
                  <i class="bi bi-play-circle"></i>
                </div>
              </div>
              <div class="exercise-content">
                <h5>{{ exercise.title }}</h5>
                <div class="toggle-wrapper">
                  <label class="switch">
                    <input type="checkbox" v-model="exercise.enabled" @change="handleExerciseToggle(exercise)" />
                    <span class="slider"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Custom Mode Block Builder -->
        <div v-if="workoutMode === 'custom'">
          <div class="row">
            <!-- Add settings section here, before the exercise list -->
            <div class="col-md-12">
              <div class="workout-settings">
                <div class="settings-grid">
                  <div class="setting-card">
                    <div class="setting-icon">
                      <i class="bi bi-grid-3x3"></i>
                    </div>
                    <div class="setting-content">
                      <label>Übungen pro Block</label>
                      <select v-model="blockLength" class="form-select">
                        <option v-for="n in 12" :key="n" :value="n">{{ n }} Übungen</option>
                      </select>
                    </div>
                  </div>

                  <div class="setting-card">
                    <div class="setting-icon">
                      <i class="bi bi-pause-circle"></i>
                    </div>
                    <div class="setting-content">
                      <label>Übungspause</label>
                      <select v-model="shortBreakDuration" class="form-select">
                        <option
                          v-for="n in [0, 3, 5, 7, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]"
                          :key="n"
                          :value="n"
                        >
                          {{ n }} Sekunden
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="setting-card">
                    <div class="setting-icon">
                      <i class="bi bi-hourglass-split"></i>
                    </div>
                    <div class="setting-content">
                      <label>Blockpause</label>
                      <select v-model="breakDuration" class="form-select">
                        <option
                          v-for="n in [
                            5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 75, 90, 120, 150, 180, 210, 240, 300,
                          ]"
                          :key="n"
                          :value="n"
                        >
                          {{ n >= 60 ? Math.floor(n / 60) + "min " + (n % 60 ? (n % 60) + "s" : "") : n + "s" }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-none d-lg-block col-lg-4">
              <div class="workout-settings">
                <div>
                  <div class="filter-buttons">
                    <button
                      class="btn btn-sm"
                      :class="[
                        selectedCategory !== 'Alle' ? 'btn-primary text-white' : 'btn-light'
                      ]"
                      @click="showCategoryFilterModal = true"
                    >
                      <i class="bi bi-grid-3x3"></i>
                      {{ selectedCategory !== "Alle" ? selectedCategory : "Kategorien" }}
                    </button>
                    <button
                      class="btn btn-sm"
                      :class="[
                        selectedMuscleGroup !== 'all' ? 'btn-primary text-white' : 'btn-light'
                      ]"
                      @click="showMuscleGroupFilterModal = true"
                    >
                      <i class="bi bi-funnel"></i>
                      {{ selectedMuscleGroup !== "all" ? muscleGroupsMap[selectedMuscleGroup] : "Muskelgruppen" }}
                    </button>
                  </div>
                  <input style="width: 100%" type="text" v-model="searchQuery" placeholder="Übungen durchsuchen..." />
                </div>
                <div class="list-group exercise-list" style="max-height: 60vh; overflow-y: auto;">
                  <!-- Add loading state -->
                  <div v-if="!exercises.length" class="text-center p-3">
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Lade...</span>
                    </div>
                  </div>

                  <!-- Only show exercises when they're loaded -->
                  <template v-else>
                    <button
                      v-for="exercise in filteredAvailableExercises.filter((ex) => ex.enabled)"
                      style="margin-top: 2px; background: #f7f7f7; color: #202020; border: 0; font-weight: 400"
                      :key="exercise.id"
                      class="d-flex align-items-center"
                      :class="{ disabled: !canAddExercise }"
                      @click="handleExerciseClick(exercise)"
                      @touchend.prevent="handleExerciseClick(exercise)"
                    >
                      <img 
                        v-if="exercise && exercise.thumbnail" 
                        :src="exercise.thumbnail" 
                        class="me-3" 
                        style="width: 40px; height: 40px; object-fit: cover" 
                      />
                      <span style="text-align: left; flex-grow: 1">{{ exercise ? exercise.title : '' }}</span>
                    </button>

                    <div class="text-muted top-spacer-25">
                      Aktivierte Übungen: {{ filteredAvailableExercises.filter((ex) => ex.enabled).length }}/{{
                        filteredAvailableExercises.length
                      }}
                    </div>
                  </template>
                </div>
              </div>
            </div>

            <div class="col-12 col-lg-8">
              <div v-if="workoutBlocks.length === 0" class="text-center p-3 bg-light rounded">
                <i class="bi bi-pencil-square display-4" style="color: #808080"></i>
                <p class="mt-3">Füge Übungen hinzu, um einen Block zu erstellen</p>
              </div>

              <div v-else class="row ">
                <div v-for="(block, blockIndex) in workoutBlocks" :key="blockIndex" class="col-12">
                  <div class="card">
                    <div class="card-header block-header">
                      <div class="d-flex justify-content-between align-items-center w-100">
                        <div class="d-flex align-items-center">
                          <h6 class="mb-0">Block {{ blockIndex + 1 }}</h6>
                        </div>
                        <div class="d-flex align-items-center">
                          <div class="repetition-control">
                            <button
                              class="btn btn-icon"
                              @click="decrementRepetitions(blockIndex)"
                              :disabled="block.repetitions <= 1"
                            >
                              <i class="bi bi-dash-lg" style="margin: 0"></i>
                            </button>
                            <div class="repetition-display">
                              <span class="repetition-count">{{ block.repetitions }}</span>
                              <span class="repetition-label">Wiederholungen</span>
                            </div>
                            <button
                              class="btn btn-icon"
                              @click="incrementRepetitions(blockIndex)"
                              :disabled="block.repetitions >= 10"
                            >
                              <i class="bi bi-plus-lg" style="margin: 0"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <!-- Update the draggable component in the workout blocks section -->
                      <draggable
                        v-model="workoutBlocks[blockIndex].exercises"
                        :group="'exercises'"
                        class="row g-2 d-flex justify-content-center"
                        @start="drag = true"
                        @end="drag = false"
                        :disabled="isMobile"
                      >
                        <div
                          v-for="(exercise, exerciseIndex) in block.exercises"
                          :key="exercise.id"
                          style="margin-bottom: 25px"
                          class="col-md-6 col-lg-3 d-flex justify-content-center"
                        >
                          <div
                            class="card"
                            :class="{ 'is-disabled': !exercise.enabled, 'is-replaced': exercise.isNew }"
                          >
                            <div class="exercise-image" @click="openVideoPreview(exercise)">
                              <img :src="exercise.thumbnail" class="card-img-top" :alt="exercise.title" />
                              <div class="play-overlay">
                                <i class="bi bi-play-circle"></i>
                              </div>
                            </div>
                            <div class="card-body">
                              <p class="card-text">{{ exercise.title }}</p>
                              <div class="d-flex gap-2">
                                <button
                                  class="btn btn-sm btn-outline-primary icon-button"
                                  @click="shuffleUpcomingExercise(blockIndex, exerciseIndex)"
                                  data-bs-toggle="tooltip"
                                  title="Zufällig austauschen"
                                >
                                  <i class="bi bi-shuffle"></i>
                                </button>
                                <button
                                  class="btn btn-sm btn-outline-danger icon-button"
                                  @click="removeExerciseFromBlock(blockIndex, exerciseIndex)"
                                  data-bs-toggle="tooltip"
                                  title="Löschen"
                                >
                                  <i class="bi bi-trash"></i>
                                </button>
                              </div>
                            </div>
                            <div v-if="exercise.isNew" class="replacement-badge">
                              <i class="bi bi-arrow-repeat"></i> Ersetzt
                            </div>
                          </div>
                        </div>

                        <div
                          v-if="block.exercises.length < blockLength"
                          class="col-md-6 col-lg-3 d-flex justify-content-center"
                        >
                          <div class="card h-100 border-dashed">
                            <div class="card-body d-flex flex-column align-items-center justify-content-center">
                              <button
                                class="btn btn-outline-primary w-100 mb-2"
                                @click="showExercisePickerModal(blockIndex)"
                              >
                                <i class="bi bi-plus-lg"></i> Übung hinzufügen
                              </button>

                              <template v-if="getAvailablePairedExercise(block)">
                                <button
                                  class="btn btn-outline-secondary w-100"
                                  @click="addPairedExercise(blockIndex, getAvailablePairedExercise(block))"
                                >
                                  <i class="bi bi-link-45deg"></i>
                                  {{ getAvailablePairedExercise(block).title }} hinzufügen
                                </button>
                              </template>
                            </div>
                          </div>
                        </div>
                      </draggable>
                      <div v-if="block.exercises.length < blockLength" class="text-muted mt-3">
                        Noch {{ blockLength - block.exercises.length }} Übungen offen
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Replace the existing buttons section -->
              <div v-if="workoutBlocks.length < maxBlocks" class="mt-3 action-buttons">
                <button class="btn btn-outline-primary" @click="showGenerateWorkoutModal = true">
                  <i class="bi bi-robot"></i> Automatisch generieren
                </button>
                <button class="btn btn-outline-primary" @click="addNewBlock">
                  <i class="bi bi-plus-lg"></i> Block hinzufügen
                </button>
                <button
                  class="btn btn-outline-primary"
                  @click="showSaveBlueprintModal = true"
                  v-if="workoutBlocks.length > 0 && workoutBlocks.some((block) => block.exercises.length > 0)"
                >
                  <i class="bi bi-save"></i> Als Vorlage speichern
                </button>
                <button class="btn btn-outline-danger" @click="clearWorkout" :disabled="workoutBlocks.length === 0">
                  <i class="bi bi-trash"></i> Alles zurücksetzen
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Videos Mode Content -->
        <div v-if="workoutMode === 'videos'">
          <div class="row">
            <div class="col-lg-12 col-xl-12">
              <div class="row">
                <div class="col-lg-12">
                  <div v-if="!loaded">
                    <div class="col-lg-12 top-spacer-25">
                      <div class="spinner-border text-primary" role="status"></div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4" v-for="m in unsere" :key="m._id">
                  <a v-bind:href="'/#/kraftraum/' + m._id" class="media_link">
                    <div class="top-spacer-25 white_wrapper_tighter" style="text-align: center">
                      <div class="wrapper">
                        <iframe
                          width="100%"
                          v-bind:src="m.youtube_link"
                          frameborder="0"
                          height="300px"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                      </div>

                      <h4 class="top-spacer-15">
                        <strong>{{ m.title }}</strong>
                      </h4>
                    </div>
                  </a>
                </div>
              </div>
              <div class="row top-spacer-25">
                <div class="col-lg-12"></div>
                <div class="col-lg-4" v-for="m in andere" :key="m._id">
                  <a v-bind:href="'/#/kraftraum/' + m._id" class="media_link">
                    <div class="top-spacer-25 white_wrapper_tighter" style="text-align: center">
                      <div class="wrapper">
                        <iframe
                          width="100%"
                          v-bind:src="m.youtube_link"
                          frameborder="0"
                          height="300px"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                      </div>

                      <h4 class="top-spacer-15">
                        <strong>{{ m.title }}</strong>
                      </h4>
                    </div>
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <button class="pull-left" v-if="page > 0" @click="loadPreviousPage()">Seite zurück</button>
                </div>
                <div class="col-lg-6">
                  <button v-if="data.length > 24" class="pull-right" @click="loadNextPage()">Nächste Seite</button>
                </div>
              </div>

              <div v-if="user && user.isAdmin">
                <hr />
                Admin:
                <a href="/#/kraftraum/create">Neu anlegen</a>
              </div>
            </div>
          </div>
        </div>

        <!-- Workout Modal -->
        <div
          class="modal fade"
          :class="{ show: showWorkoutModal }"
          tabindex="-1"
          :style="{ display: showWorkoutModal ? 'block' : 'none' }"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
             
              <div class="modal-header">
            <h5 class="modal-title">Workout Einstellungen</h5>
                <i class="bi bi-x close-icon" @click="showWorkoutModal = false"></i>
              </div>
              <div class="modal-body">
                <div class="exercise-section">
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <h5 class="mb-0">Verfügbare Übungen</h5>
                    <button class="btn btn-outline-primary btn-sm" @click="toggleAllExercises">
                      {{ allExercisesEnabled ? "Alle deaktivieren" : "Alle aktivieren" }}
                    </button>
                  </div>

                  <div class="row g-4">
                    <div class="col-md-4 col-lg-3" v-for="exercise in exercises" :key="exercise.id">
                      <div class="card h-100">
                        <img :src="exercise.thumbnail" class="card-img-top" :alt="exercise.title" />
                        <div class="card-body d-flex justify-content-between align-items-left">
                          <div>
                            <h6 class="mb-0 text-start">{{ exercise.title }}</h6>
                          </div>
                          <div class="form-check form-switch">
                            <br />
                            <input
                              class="form-check-input"
                              type="checkbox"
                              v-model="exercise.enabled"
                              @change="handleExerciseToggle(exercise)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="showWorkoutModal = false">Schlieen</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-backdrop fade" :class="{ show: showWorkoutModal }" v-if="showWorkoutModal"></div>

        <!-- Exercise Picker Modal -->

        <div class="modal-backdrop fade" :class="{ show: showExercisePicker }" v-if="showExercisePicker"></div>
      </div>

      <!-- Workout Footer -->
       
      <div class="workout-footer" v-if="workoutBlocks.length > 0 && calculateTotalWorkoutTime() > 0">
        <div class="container-fluid">
          <div class="d-flex justify-content-between align-items-center">
            <div class="workout-time">
              <div class="time-label">Gesamtzeit</div>
              <div class="time-value">{{ formatTotalWorkoutTime }}</div>
            </div>
            <button
              class="btn btn-lg btn-success start-workout-btn"
              :disabled="!workoutBlocks.length || isStarting"
              @click="startWorkout"
            >
              <i class="bi bi-play-circle"></i>
              {{ isStarting ? "Wird gestartet..." : "Workout Starten" }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Workout Execution -->
    <div v-else class="workout-execution" style="margin:-15px;margin-top:0">
      <!-- Add Exit Button -->
      <div
        class="col-12 top-spacer-10"
        v-if="
          !this.$moment(user.premiumAccountUntil).isAfter(this.$moment()) &&
          !this.$moment(user.freeAccountUntil).isAfter(this.$moment())
        "
        style="text-align: center; color: #fff"
      >
        <div
          class="col-12"
          style="
            background-size: 200% auto;
            padding: 30px;
            background: #000;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
          "
        >
          <br />
          <strong>Hinweis:</strong> Dein Zugang ist abgelaufen. Um dauerhaft alle Funktionen nutzen zu können, kannst du
          über die App ein Abo abschließen.

          <h2>Diese Übungen stehen nur unseren zahlenden Club Mitgliedern zur Verfügung.</h2>
        </div>
      </div>

      <div v-else class="row">
        <!-- Video and controls column -->
        <div class="col-lg-9 mb-4">
          <div class="card" style="margin-right:-15px;margin-top:0">
            <div class="card-body"  >
              <!-- Video Player -->
              <div class="position-relative">
                <!-- Update the video container structure -->
                <div 
                  class="video-aspect-ratio"
                  :class="{ 'is-fullscreen': isPseudoFullscreen }"
                >
                  <video
                    ref="videoPlayer"
                    class="video-player"
                    :src="currentExercise.videoUrl"
                    @ended="handleVideoEnd"
                    @pause="handleVideoPause"
                    @play="handleVideoPlay"
                    @click="toggleFullscreen"
                    playsinline
                    webkit-playsinline
                    x5-playsinline
                    x5-video-player-type="h5"
                    x5-video-player-fullscreen="true"
                    preload="auto"
                    controlsList="nodownload"
                    :style="{ visibility: isBreak ? 'hidden' : 'visible' }"
                  ></video>
                  
                  <div 
                    v-if="isBreak" 
                    ref="breakContainer"
                    class="break-container"
                    @click="toggleFullscreen"
                  >
                    <div class="break-content">
                      <div class="next-exercise-preview">
                        <img :src="currentExercise.thumbnail_hq" :alt="currentExercise.title" />
                      </div>
                      <div class="break-content-center">
                        <div class="display-1">{{ formatTime(currentTimer) }}</div>
                        <div class="next-exercise-text">
                          <h3 style="color:#fff">Nächste Übung</h3>
                          <h4 style="color:#fff">{{ currentExercise.title }}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Video Controls -->
              <div class="d-flex align-items-center mt-3 mb-4" style="margin:0!important;border-radius:0!important">
                <button class="btn btn-lg btn-outline-secondary me-3" @click="togglePause">
                  <i class="bi" :class="isPaused ? 'bi-play-fill' : 'bi-pause-fill'"></i>
                </button>
                <button class="btn btn-lg btn-outline-secondary me-3" @click="toggleMute">
                  <i class="bi" :class="isMuted ? 'bi-volume-mute-fill' : 'bi-volume-up-fill'"></i>
                </button>
                <button class="btn btn-lg btn-outline-secondary me-3" @click="toggleFullscreen">
                  <i
                    class="bi"
                    :class="isFullscreen ? 'bi-fullscreen-exit' : 'bi-fullscreen'"
                  ></i>
                </button>
                <div class="progress w-100" style="height: 10px; margin-left: 10px">
                  <div class="progress-bar" role="progressbar" :style="{ width: `${progressPercentage}%` }"></div>
                </div>
                <button class="btn btn-lg btn-outline-secondary ms-3" @click="skipExercise">
                  <i class="bi bi-skip-forward-fill"></i>
                </button>
                <!-- Add this button to the video controls section -->
                <button class="btn btn-lg btn-outline-secondary" @click="showPauseSettingsModal = true">
                  <i class="bi bi-gear-fill"></i>
                </button>
              </div>
            </div>
          </div>
          
        </div>

        <!-- Upcoming exercises and stats column -->
        <div class="col-lg-3">
          <div class="card mb-2" v-if="upcomingExercises.length > 0"> 
                       
            <!-- Upcoming Exercises Section -->
            <div class="card-header py-2 pt-4">
              <h6 class="card-title mb-0">Kommende Übungen</h6>
            </div>
            <div class="list-group list-group-flush">
              <div 
                v-for="(item, index) in upcomingExercises" 
                :key="index"
                class="list-group-item"
              >
                <!-- Exercise item -->
                <template v-if="item.isExercise">
                  <div 
                    class="exercise-list-item" 
                    @click="toggleExerciseMenu(index)"
                    style="cursor: pointer"
                  >
                    <img
                      :src="item.thumbnail"
                      class="exercise-thumbnail"
                      alt=""
                    />
                    <span class="exercise-title">{{ item.title }}</span>
                  </div>

                  <!-- Action Menu -->
                  <div v-if="openMenuIndex === index" class="exercise-actions">
                    <button
                      class="action-btn delete"
                      @click.stop="removeUpcomingExercise(item.blockIndex, item.exerciseIndex)"
                    >
                      <i class="bi bi-trash"></i> Löschen
                    </button>
                    <button
                      class="action-btn shuffle"
                      @click.stop="shuffleUpcomingExercise(item.blockIndex, item.exerciseIndex)"
                    >
                      <i class="bi bi-shuffle"></i> Shuffle
                    </button>
                    <button
                      class="action-btn duplicate"
                      @click.stop="duplicateUpcomingExercise(item.blockIndex, item.exerciseIndex)"
                    >
                      <i class="bi bi-files"></i> Kopieren
                    </button>
                    <button
                      class="action-btn add"
                      @click.stop="addExerciseAfter(item.blockIndex, item.exerciseIndex)"
                    >
                      <i class="bi bi-plus-lg"></i> Hinzufügen
                    </button>
                  </div>
                </template>

                <!-- Break item -->
                <template v-else>
                  <div class="break-list-item">
                    <i class="bi bi-hourglass-split"></i>
                    <span class="break-title">{{ item.title }}</span>
                    <span class="break-duration">{{ item.duration }}s</span>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <!-- Update the button container -->
          <div class="exit-workout-container">
            <button class="exit-workout-btn" @click="exitWorkout">
              <i class="bi bi-x-circle"></i> Workout beenden
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      :class="{ show: showSaveBlueprintModal }"
      tabindex="-1"
      :style="{ display: showSaveBlueprintModal ? 'block' : 'none' }"
      @click.self="showSaveBlueprintModal = false"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          
           <div class="modal-header">
            <h5 class="modal-title">Workout als Vorlage speichern</h5>
           <i class="bi bi-x close-icon" @click="showSaveBlueprintModal = false"></i>
          </div>
          <div class="modal-body">
            <div class="form-group mb-3">
              <label>Name der Vorlage</label>
              <input type="text" class="form-control" v-model="blueprintName" placeholder="z.B. Mein HIIT Workout" />
            </div>

            <div class="form-group mb-3">
              <label>Übungen pro Block</label>
              <input type="number" class="form-control" v-model="blockLength" min="1" max="10" />
            </div>

            <div class="form-group mb-3">
              <label>Kurze Pause (Sekunden)</label>
              <input type="number" class="form-control" v-model="shortBreakDuration" min="0" />
            </div>

            <div class="form-group mb-3">
              <label>Block Pause (Sekunden)</label>
              <input type="number" class="form-control" v-model="breakDuration" min="0" />
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showSaveBlueprintModal = false">Abbrechen</button>
            <button type="button" class="btn btn-primary" @click="saveAsBlueprint">Speichern</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Blueprint Preview Modal -->
    <div
      class="modal fade"
      :class="{ show: showBlueprintPreviewModal }"
      tabindex="-1"
      :style="{ display: showBlueprintPreviewModal ? 'block' : 'none' }"
      @click.self="showBlueprintPreviewModal = false"
    >
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
        
          <div class="modal-header">
            <h5 class="modal-title">{{ selectedBlueprint?.name }} - Vorschau</h5>
           <i class="bi bi-x close-icon" @click="showBlueprintPreviewModal = false"></i>
          </div>
          <div class="modal-body">
            <div class="blueprint-info mb-4">
              <div class="row">
                <div class="col-sm-6 col-md-3">
                  <div class="info-card">
                    <i class="bi bi-stopwatch"></i>
                    <h6>Übungsdauer</h6>
                    <p>{{ selectedBlueprint?.settings.exerciseDuration }}s</p>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="info-card">
                    <i class="bi bi-pause-circle"></i>
                    <h6>Kurze Pause</h6>
                    <p>{{ selectedBlueprint?.settings.shortBreakDuration }}s</p>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="info-card">
                    <i class="bi bi-hourglass-split"></i>
                    <h6>Block Pause</h6>
                    <p>{{ selectedBlueprint?.settings.breakDuration }}s</p>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="info-card">
                    <i class="bi bi-grid-3x3"></i>
                    <h6>Übungen/Block</h6>
                    <p>{{ selectedBlueprint?.settings.blockLength }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="previewBlocks.length" class="">
              <div v-if="!exercises.length" class="text-center my-4">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              
              <template v-else>
                <div v-for="(block, blockIndex) in mappedPreviewBlocks" :key="blockIndex" class="mb-4">
                  <h6 class="block-title mb-3">Block {{ blockIndex + 1 }}</h6>
                  
                  <div class="row g-3">
                    <div v-for="exercise in block" :key="exercise.id" class="col-md-6 col-lg-3">
                      <div class="card h-100">
                        <div class="exercise-image" @click="openVideoPreview(exercise)">
                          <img :src="exercise.thumbnail" class="card-img-top" :alt="exercise.title" />
                          <div class="play-overlay">
                            <i class="bi bi-play-circle"></i>
                          </div>
                        </div>
                        <div class="card-body">
                          <p class="card-text">{{ exercise.title }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div class="total-duration">
              <i class="bi bi-clock"></i> Gesamtdauer: {{ calculateWorkoutDuration(selectedBlueprint) }}
            </div>
            <div>
              <button
                class="btn btn-danger"
                style="margin-right: 0.75rem"
                @click="deleteBlueprint(selectedBlueprint._id)"
              >
                <i class="bi bi-trash" style="margin-right: 0"></i>
              </button>
              <button type="button" class="btn btn-secondary" @click="showBlueprintPreviewModal = false">
                Schließen
              </button>
              <button type="button" class="btn btn-primary ms-2" @click="loadBlueprintFromPreview">
                <i class="bi bi-play-fill"></i> Workout laden
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      :class="{ show: showExercisePicker }"
      tabindex="-1"
      :style="{ display: showExercisePicker ? 'block' : 'none' }"
      @click.self="showExercisePicker = false"
    >
      <div class="modal-dialog modal-lg modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Übung hinzufügen</h5>
            <i class="bi bi-x close-icon" @click="showExercisePicker = false"></i>
          </div>
          <div class="modal-body" style="max-height: 70vh; overflow-y: auto; padding-top: 0 !important">
            <div class="search-container">
              <div style="padding:0 15px">
                <input type="text" class="form-control" v-model="searchQuery" placeholder="Übungen durchsuchen..." />
                <button
                  class="btn btn-outline-secondary clear-button"
                  type="button"
                  @click="searchQuery = ''"
                  v-if="searchQuery"
                >
                  <i class="bi bi-x"></i>
                </button>
              </div>
            </div>
            <div class="row g-3">
              <div class="col-md-4" v-for="exercise in filteredAvailableExercises" :key="exercise.id">
                <div
                  class="card h-100"
                  @click="addExerciseToSpecificBlock(selectedBlockIndex, exercise)"
                  style="cursor: pointer"
                >
                  <img :src="exercise.thumbnail" class="card-img-top" :alt="exercise.title" />
                  <div class="card-body">
                    <p class="card-text">{{ exercise.title }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Replace the existing workout generation modal with this new version -->
    <div
      class="modal fade"
      :class="{ show: showGenerateWorkoutModal }"
      tabindex="-1"
      :style="{ display: showGenerateWorkoutModal ? 'block' : 'none' }"
      @click.self="showGenerateWorkoutModal = false"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Workout generieren</h5>
            <i class="bi bi-x close-icon" @click="showGenerateWorkoutModal = false"></i>
          </div>
          <div class="modal-body">
            <!-- Step 1: Duration Selection -->
            <div v-if="generationStep === 1" class="generation-step">
              <h6 class="step-title">1. Workout-Dauer wählen</h6>
              <div class="preset-buttons">
                <button
                  v-for="option in durationOptions"
                  :key="option.value"
                  class="preset-btn"
                  @click="selectDuration(option.value)"
                >
                  {{ option.text }}
                </button>
              </div>
            </div>

            <!-- Step 2: Category Selection -->
            <div v-if="generationStep === 2" class="generation-step">
              <h6 class="step-title">2. Übungskategorien whlen</h6>
              <div class="categories-grid">
                <button
                  v-for="category in availableCategories"
                  :key="category.id"
                  class="category-select-btn"
                  :class="{ active: selectedCategories.includes(category.id) }"
                  @click="toggleCategory(category.id)"
                >
                  <i :class="category.icon"></i>
                  <span>{{ category.label }}</span>
                  <div class="check-indicator">
                    <i class="bi bi-check2"></i>
                  </div>
                </button>
              </div>
              <p class="text-muted mt-3" v-if="selectedCategories.length === 0">
                <i class="bi bi-info-circle"></i>
                Keine Auswahl bedeutet, dass alle Kategorien verwendet werden.
              </p>
              <p class="text-muted mt-3" v-if="selectedCategories.length > 0">
                <i class="bi bi-info-circle"></i>
                Aktuell
                {{
                  selectedCategories.length === 1
                    ? "ist eine Kategorie"
                    : "sind " + selectedCategories.length + " Kategorien"
                }}
                ausgewählt.
              </p>
            </div>

            <!-- Step 3: Block Configuration -->
            <div v-if="generationStep === 3" class="generation-step">
              <h6 class="step-title">3. Übungen pro Block</h6>
              <div class="preset-buttons">
                <button v-for="n in 12" :key="n" class="preset-btn" @click="selectBlockLength(n)">
                  {{ n }} Übungen
                </button>
              </div>
            </div>

            <!-- Step 4: Exercise Break Duration -->
            <div v-if="generationStep === 4" class="generation-step">
              <h6 class="step-title">4. Pause zwischen Übungen</h6>
              <div class="preset-buttons">
                <button
                  v-for="n in [0, 3, 5, 7, 10, 15, 20, 30]"
                  :key="n"
                  class="preset-btn"
                  @click="selectShortBreak(n)"
                >
                  {{ n }}s
                </button>
              </div>
            </div>

            <!-- Step 5: Block Break Duration -->
            <div v-if="generationStep === 5" class="generation-step">
              <h6 class="step-title">5. Pause zwischen Blöcken</h6>
              <div class="preset-buttons">
                <button
                  v-for="n in [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]"
                  :key="n"
                  class="preset-btn"
                  @click="selectBlockBreak(n)"
                >
                  {{ formatBreakDuration(n) }}
                </button>
              </div>
            </div>

            <!-- Step 6: Summary -->
            <div v-if="generationStep === 6" class="generation-step">
              <h6 class="step-title">6. Zusammenfassung</h6>
              <div class="settings-summary">
                <div class="summary-item">
                  <i class="bi bi-clock"></i>
                  <span>Dauer: {{ selectedDuration }} Minuten</span>
                </div>
                <div class="summary-item">
                  <i class="bi bi-grid-3x3"></i>
                  <span>{{ blockLength }} Übungen pro Block</span>
                </div>
                <div class="summary-item">
                  <i class="bi bi-pause-circle"></i>
                  <span>{{ shortBreakDuration }}s Übungspause</span>
                </div>
                <div class="summary-item">
                  <i class="bi bi-hourglass-split"></i>
                  <span>{{ formatBreakDuration(breakDuration) }} Blockpause</span>
                </div>
                <div class="summary-item" v-if="selectedCategories.length > 0">
                  <i class="bi bi-tag"></i>
                  <span
                    >Kategorien:
                    {{
                      selectedCategories.map((id) => availableCategories.find((c) => c.id === id)?.label).join(", ")
                    }}</span
                  >
                </div>
              </div>
            </div>

            <!-- Step 7: Summary -->
            <div v-if="generationStep === 7" class="generation-step">
              <h6 class="step-title">7. Zusammenfassung</h6>
              <div class="settings-summary">
                <div class="summary-item">
                  <i class="bi bi-clock"></i>
                  <span>Dauer: {{ selectedDuration }} Minuten</span>
                </div>
                <div class="summary-item">
                  <i class="bi bi-grid-3x3"></i>
                  <span>{{ blockLength }} Übungen pro Block</span>
                </div>
                <div class="summary-item">
                  <i class="bi bi-pause-circle"></i>
                  <span>{{ shortBreakDuration }}s Übungspause</span>
                </div>
                <div class="summary-item">
                  <i class="bi bi-hourglass-split"></i>
                  <span>{{ formatBreakDuration(breakDuration) }} Blockpause</span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button v-if="generationStep > 1" type="button" class="btn btn-outline-secondary" @click="generationStep--">
              Zurück
            </button>
            <button v-if="generationStep === 6" type="button" class="btn btn-success" @click="generateWorkoutFromModal">
              Workout generieren
            </button>
            <button v-if="generationStep < 6" type="button" class="btn btn-primary" @click="generationStep++">
              Weiter
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Add this to the template section, after your existing modals -->
    <div
      class="modal fade"
      :class="{ show: showVideoPreviewModal }"
      tabindex="-1"
      :style="{ display: showVideoPreviewModal ? 'block' : 'none' }"
      @click.self="closeVideoPreview"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ selectedExerciseForPreview?.title }}</h5>
            <i class="bi bi-x close-icon" @click="closeVideoPreview"></i>
          </div>
          <div class="modal-body p-0">
            <div class="video-preview-container">
              <video
                ref="previewVideo"
                class="preview-video"
                :src="selectedExerciseForPreview?.videoUrl"
                controls
                @ended="handlePreviewEnd"
              ></video>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add these modals at the bottom of the template section -->
    <!-- Category Filter Modal -->
    <div
      class="modal fade"
      :class="{ show: showCategoryFilterModal }"
      tabindex="-1"
      :style="{ display: showCategoryFilterModal ? 'block' : 'none' }"
      @click.self="showCategoryFilterModal = false"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Kategorien Filter</h5>
            <i class="bi bi-x close-icon" @click="showCategoryFilterModal = false"></i>
          </div>
          <div class="modal-body">
            <div class="filter-grid">
              <button
                v-for="category in [
                  'Alle',
                  'Bodyweight',
                  'Pilates',
                  'Kurzhantel',
                  'Yoga',
                  'Resistance Band',
                  'Dehnung & Mobilität',
                ]"
                :key="category"
                class="filter-btn"
                :class="{ active: selectedCategory === category }"
                @click="selectCategory(category)"
              >
                <i :class="getCategoryIcon(category)"></i>
                <span>{{ category }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Muscle Group Filter Modal -->
    <div
      class="modal fade"
      :class="{ show: showMuscleGroupFilterModal }"
      tabindex="-1"
      :style="{ display: showMuscleGroupFilterModal ? 'block' : 'none' }"
      @click.self="showMuscleGroupFilterModal = false"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
         
          <div class="modal-header">
            <h5 class="modal-title">Muskelgruppen Filter</h5>
            <i class="bi bi-x close-icon" @click="showMuscleGroupFilterModal = false"></i>
          </div>
          <div class="modal-body">
            <div class="filter-grid">
              <button
                v-for="group in filteredMuscleGroups"
                :key="group.english"
                class="filter-btn"
                :class="{ active: selectedMuscleGroup === group.english }"
                @click="selectMuscleGroup(group.english)"
              >
                <i class="bi bi-bullseye"></i>
                <span>{{ group.german }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Workout Complete Modal -->
    <div
      class="modal fade"
      :class="{ show: showWorkoutCompleteModal }"
      tabindex="-1"
      :style="{ display: showWorkoutCompleteModal ? 'block' : 'none' }"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
         
          <div class="modal-header">
            <h5 class="modal-title">Workout abgeschlossen</h5>
            <i class="bi bi-x close-icon" @click="showWorkoutCompleteModal = false"></i>
          </div>
          <div class="modal-body">
            <div class="completion-icon mb-4">
              <i class="bi bi-trophy-fill display-1 text-warning"></i>
            </div>
            <h4 class="mb-4">{{ completionMessage }}</h4>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success start-workout-btn w-100" @click="saveWorkout();clearWorkoutState()">Workout speichern</button>
            <button type="button" class="btn btn-primary w-100" @click="showWorkoutCompleteModal = false; clearWorkoutState()">
              Schließen
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade" :class="{ show: showWorkoutCompleteModal }" v-if="showWorkoutCompleteModal"></div>

    <div
      class="modal fade"
      :class="{ show: showPauseSettingsModal }"
      tabindex="-1"
      :style="{ display: showPauseSettingsModal ? 'block' : 'none' }"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Pausen anpassen</h5>
            <i class="bi bi-x close-icon" @click="showPauseSettingsModal = false"></i>
          </div>
          
          <div class="modal-body">
            <div class="pause-settings">
              <div class="setting-group">
                <label>Übungspause</label>
                <select v-model="shortBreakDuration" class="form-select">
                  <option v-for="n in [0, 3, 5, 7, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]" :key="n" :value="n">
                    {{ n }} Sekunden
                  </option>
                </select>
              </div>
              
              <div class="setting-group">
                <label>Blockpause</label>
                <select v-model="breakDuration" class="form-select">
                  <option
                    v-for="n in [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 75, 90, 120, 150, 180, 210, 240, 300]"
                    :key="n"
                    :value="n"
                  >
                    {{ n >= 60 ? Math.floor(n / 60) + "min " + (n % 60 ? (n % 60) + "s" : "") : n + "s" }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showPauseSettingsModal = false">Schließen</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade" :class="{ show: showPauseSettingsModal }" v-if="showPauseSettingsModal"></div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import axios from "axios";
import bootstrap from 'bootstrap';
import {
  exerciseTranslations,
  dumbbellExercises,
  yogaExercises,
  mobilityExercises,
  bandedExercises,
  pilatesExercises,
} from "@/data/strength_workouts";

export default {
  name: "KraftraumNew",

  components: {
    draggable,
  },

  props: {
    workoutId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      workoutMode: "custom",
      selectedDuration: 20,
      blockLength: 4,
      makeGlobal: false,
      isFullscreen: false,
      durationOptions: [
        { text: "5 Minuten", value: 5 },
        { text: "10 Minuten", value: 10 },
        { text: "15 Minuten", value: 15 },
        { text: "20 Minuten", value: 20 },
        { text: "25 Minuten", value: 25 },
        { text: "30 Minuten", value: 30 },
        { text: "35 Minuten", value: 35 },
        { text: "40 Minuten", value: 40 },
        { text: "45 Minuten", value: 45 },
        { text: "50 Minuten", value: 50 },
        { text: "55 Minuten", value: 55 },
        { text: "60 Minuten", value: 60 },
        { text: "75 Minuten", value: 75 },
        { text: "90 Minuten", value: 90 },
      ],
      exercises: [], 
      workoutBlocks: [],
      workoutStarted: false,
      currentBlockIndex: 0,
      currentExerciseIndex: 0,
      currentTimer: 0,
      isPaused: false,
      timerInterval: null,
      exerciseStats: {},
      breakDuration: 30, 
      shortBreakDuration: 10, 
      exerciseDuration: 30,
      isLoading: false,
      isStarting: false,
      showError: false,
      errorMessage: "",
      totalWorkoutTime: 0,
      workoutStartTime: null,
      maxBlocks: 100,
      drag: false,
      allExercisesEnabled: true,
      showWorkoutModal: false,
      isBreak: false,
      progressPercentage: 100,
      searchQuery: "",
      showExercisePicker: false,
      selectedBlockIndex: null,
      selectedExerciseIndex: undefined,
      data: [],
      page: 0,
      isMuted: false,
      user: undefined,
      loaded: false,
      unsere: [],
      andere: [],
      showSaveBlueprintModal: false,
      blueprintName: "",
      blueprints: [],
      showBlueprintPreviewModal: false,
      selectedBlueprint: null,
      previewBlocks: [],
      selectedCategory: "Alle",
      showGenerateWorkoutModal: false,
      showVideoPreviewModal: false,
      selectedExerciseForPreview: null,
      selectedBlueprintType: "all", // 'all', 'personal', 'global'
      generationStep: 1,
      selectedCategories: [], // Speichert die ausgewählten Kategorien
      availableCategories: [
        { id: "bodyweight", label: "Bodyweight", icon: "bi-person" },
        { id: "dumbbell", label: "Kurzhantel", icon: "bi-battery" },
        { id: "yoga", label: "Yoga", icon: "bi-flower1" },
        { id: "mobility", label: "Dehnung & Mobilität", icon: "bi-arrows-move" },
        { id: "banded", label: "Resistance Band", icon: "bi-bandaid" },
        { id: "pilates", label: "Pilates", icon: "bi-heart" },
      ],
      scrollPosition: 0,
      selectedMuscleGroup: "all", // Ensure all muscle groups are selected by default
      muscleGroupsMap: {
        all: "Alle",
        "full body": "Ganzkörper",
        core: "Bauchmuskulatur",
        obliques: "Seitliche Bauchmuskeln",
        chest: "Brust",
        back: "Rücken",
        shoulders: "Schultern",
        biceps: "Bizeps",
        triceps: "Trizeps",
        forearms: "Unterarme",
        quads: "Oberschenkel",
        hamstrings: "Hintere Oberschenkel",
        glutes: "Gesäß",
        calves: "Waden",
        "hip flexors": "Hüftbeuger",
        "hip abductors": "Hüftabduktoren",
        "inner thighs": "Innere Oberschenkel",
        "lower back": "Unterer Rücken",
        trapezius: "Trapezmuskel",
        spine: "Wirbelsäule",
        neck: "Nacken",
        "rotator cuff": "Rotatorenmanschette",
      },
      showMuscleGroupFilter: false,
      showCategoryFilterModal: false,
      showMuscleGroupFilterModal: false,
      currentBlockRepetition: 1,
      isPseudoFullscreen: false,
      shouldAutoplay: false,
      isMobile: false,
      workoutState: null,
      openMenuIndex: null,

      showWorkoutCompleteModal: false,
      completionMessage: '',
      motivationalMessages: [
        "Super gemacht! Dein Körper wird es dir danken! 💪",
        "Stark! Du hast es durchgezogen! 🔥", 
        "Fantastische Leistung! Jedes Training macht dich stärker! 🌟",
        "Wow! Das war ein richtig gutes Workout! ⭐",
        "Klasse! Du kannst stolz auf dich sein! 🏆",
        "Perfekt! Du bist deinen Zielen wieder einen Schritt näher! 🎉",
        "Großartig! Bleib dran! 💫",
        "Hervorragend! Du gibst immer 100%! 🌈",
        "Spitze! Mach weiter so! 🎯",
        "Toll gemacht! Jeder Schritt zählt! ⚡",
        "Ausgezeichnet! Du bist auf dem richtigen Weg! 🌟",
        "Fantastisch! Deine Motivation ist ansteckend! 🔥",
        "Weiter so! Du machst das super! 🌺",
        "Beeindruckend! Dein Einsatz macht sich bezahlt! 🎨",
        "Genial! Du übertriffst dich selbst! 🌠"
      ],
      showPauseSettingsModal: false,
      plannedWorkouts: [],
      currentPlannedWorkout: null,
      totalWorkoutTimeSpent: 0,
      pauseStartTime: null,
      totalPausedTime: 0,
    };
  },

  computed: {
    currentBlock() {
      return this.workoutBlocks[this.currentBlockIndex] || { exercises: [] };
    },
    currentExercise() {
      return this.currentBlock.exercises[this.currentExerciseIndex] || {};
    },
    totalDuration() {
      return this.selectedDuration;
    },
    availableExercises() {
      return this.exercises.filter((ex) => ex.enabled);
    },
    canAddBlock() {
      const currentBlock = this.workoutBlocks[this.workoutBlocks.length - 1];
      return !currentBlock || currentBlock.length === this.blockLength;
    },
    isWorkoutValid() {
      return this.workoutBlocks.length > 0 && this.workoutBlocks.every((block) => block.length === this.blockLength);
    },
    canAddExercise() {
      if (this.workoutBlocks.length === 0) return true;
      const currentBlock = this.workoutBlocks[this.workoutBlocks.length - 1];
      return currentBlock && currentBlock.length < this.blockLength;
    },
    upcomingExercises() {
      const upcoming = [];
      let blockIndex = this.currentBlockIndex;
      let exerciseIndex = this.currentExerciseIndex;
      let repetition = this.currentBlockRepetition;

      while (blockIndex < this.workoutBlocks.length) {
        const currentBlock = this.workoutBlocks[blockIndex];

        // Handle remaining repetitions of current block
        while (repetition <= currentBlock.repetitions) {
          // For first repetition and current block, start from next exercise
          // For subsequent repetitions or blocks, start from first exercise
          const startIndex =
            blockIndex === this.currentBlockIndex && repetition === this.currentBlockRepetition ? exerciseIndex + 1 : 0;

          // Add remaining exercises in current repetition
          for (let i = startIndex; i < currentBlock.exercises.length; i++) {
            const exercise = currentBlock.exercises[i];
            upcoming.push({
              ...exercise,
              blockIndex,
              exerciseIndex: i,
              repetition,
              showMenu: false,
              isExercise: true,
            });
          }

          // Add block break if there's another repetition or block coming
          if (repetition < currentBlock.repetitions || blockIndex < this.workoutBlocks.length - 1) {
            upcoming.push({
              title: repetition < currentBlock.repetitions ? "Block Wiederholung" : "Block Pause",
              duration: this.breakDuration,
              isBreak: true,
              isBlockBreak: true,
            });
          }

          repetition++;
        }

        // Move to next block
        blockIndex++;
        repetition = 1;
      }

      return upcoming;
    },
    timeUntilBlockBreak() {
      if (this.currentBlock.exercises.length === 0) return 0;

      // Calculate remaining exercises in current repetition
      const remainingExercises = this.currentBlock.exercises.length - (this.currentExerciseIndex + 1);

      // Calculate total time until block break
      let totalTime = this.currentTimer; // Current exercise/break time

      if (!this.isBreak) {
        // Add remaining time for current repetition
        totalTime += remainingExercises * (this.exerciseDuration + this.shortBreakDuration);

        // Add time for remaining repetitions
        const remainingRepetitions = this.currentBlock.repetitions - this.currentBlockRepetition;
        if (remainingRepetitions > 0) {
          totalTime +=
            remainingRepetitions *
            (this.currentBlock.exercises.length * this.exerciseDuration +
              (this.currentBlock.exercises.length - 1) * this.shortBreakDuration +
              this.breakDuration);
        }
      }

      return totalTime;
    },
    nextExercise() {
      // If we're in a break between exercises in the same block
      if (this.isBreak && this.currentExerciseIndex < this.currentBlock.exercises.length - 1) {
        return this.currentBlock.exercises[this.currentExerciseIndex + 1];
      }

      // If we're in a break between blocks
      if (this.isBreak && this.currentExerciseIndex === this.currentBlock.exercises.length - 1) {
        // Check if there is a next block
        const nextBlock = this.workoutBlocks[this.currentBlockIndex + 1];
        if (nextBlock && nextBlock.length > 0) {
          return nextBlock[0];
        }
      }

      // Fallback to current exercise if no next exercise exists
      return this.currentExercise;
    },
    filteredAvailableExercises() {
      let exercises = this.exercises;

      // Filter by category
      if (this.selectedCategory !== "Alle") {
        if (this.selectedCategory === "Kurzhantel") {
          exercises = exercises.filter((exercise) => exercise.categories.includes("dumbbell"));
        } else if (this.selectedCategory === "Bodyweight") {
          exercises = exercises.filter((exercise) => exercise.categories.includes("bodyweight"));
        } else if (this.selectedCategory === "Yoga") {
          exercises = exercises.filter((exercise) => exercise.categories.includes("yoga"));
        } else if (this.selectedCategory === "Dehnung & Mobilität") {
          exercises = exercises.filter((exercise) => exercise.categories.includes("mobility"));
        } else if (this.selectedCategory === "Resistance Band") {
          exercises = exercises.filter((exercise) => exercise.categories.includes("banded"));
        } else if (this.selectedCategory === "Pilates") {
          exercises = exercises.filter((exercise) => exercise.categories.includes("pilates"));
        } else {
          exercises = exercises.filter((exercise) => exercise.categories.includes(this.selectedCategory));
        }
      }

      // Filter by muscle group
      if (this.selectedMuscleGroup !== "all") {
        exercises = exercises.filter(
          (exercise) => exercise.muscles && exercise.muscles.includes(this.selectedMuscleGroup)
        );
      }

      // Filter by search
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        exercises = exercises.filter((ex) => ex.title.toLowerCase().includes(query));
      }

      return exercises;
    },
    formatTotalWorkoutTime() {
      const totalSeconds = this.calculateTotalWorkoutTime();
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      if (hours > 0) {
        return `${hours}h ${minutes}m ${seconds}s`;
      } else if (minutes > 0) {
        return `${minutes}m ${seconds}s`;
      }
      return `${seconds}s`;
    },
    filteredBlueprints() {
      if (this.selectedBlueprintType === "personal") {
        return this.blueprints.filter((bp) => !bp.isGlobal);
      } else if (this.selectedBlueprintType === "global") {
        return this.blueprints.filter((bp) => bp.isGlobal);
      }
      return this.blueprints;
    },
    exerciseBadges() {
      return (exercise) => {
        return exercise.categories.filter((category) => this.getCategoryLabel(category));
      };
    },
    availableMuscleGroups() {
      // Get exercises filtered by category only (not by muscle group)
      let exercises = this.exercises.filter((ex) => ex.enabled);

      if (this.selectedCategory !== "Alle") {
        const categoryMapping = {
          Bodyweight: "bodyweight",
          Pilates: "pilates",
          Kurzhantel: "dumbbell",
          Yoga: "yoga",
          "Resistance Band": "banded",
          "Dehnung & Mobilität": "mobility",
        };

        const internalCategory = categoryMapping[this.selectedCategory];
        if (internalCategory) {
          exercises = exercises.filter((ex) => ex.categories.includes(internalCategory));
        }
      }

      // Collect all muscle groups from the category-filtered exercises
      const muscleGroups = new Set();
      exercises.forEach((exercise) => {
        if (exercise.muscles) {
          exercise.muscles.forEach((muscle) => muscleGroups.add(muscle));
        }
      });

      return Array.from(muscleGroups);
    },

    filteredMuscleGroups() {
      // Create array of muscle groups that should be shown
      const groups = [{ english: "all", german: this.muscleGroupsMap["all"] }];

      // Add other muscle groups that are available in the filtered exercises
      Object.entries(this.muscleGroupsMap).forEach(([english, german]) => {
        if (english !== "all" && this.availableMuscleGroups.includes(english)) {
          groups.push({ english, german });
        }
      });

      return groups;
    },
    formattedPlannedWorkouts() {
      return this.plannedWorkouts.map(workout => ({
        ...workout,
        settings: {
          blockLength: workout.blockLength || 4,
          shortBreakDuration: workout.shortBreakDuration || 10,
          breakDuration: workout.breakDuration || 60,
        },
        blocks: workout.blocks ? workout.blocks.map(block => ({
          exercises: Array.isArray(block.exercises) ? block.exercises : [],
          repetitions: block.repetitions || 1
        })) : []
      }));
    },
    mappedPreviewBlocks() {
      return this.previewBlocks.map(block => 
        block.map(exerciseId => {
          const exercise = this.exercises.find(ex => ex.id === exerciseId);
          if (!exercise) {
            console.warn(`Exercise with ID ${exerciseId} not found`);
            return {
              id: exerciseId,
              title: 'Exercise not found',
              thumbnail: '', // Add a default thumbnail URL if needed
            };
          }
          return exercise;
        })
      );
    }
  },

  async created() {
    this.loadExercises();
    await this.loadPreferences(); // Load preferences after exercises
    this.loadBlueprints();

    // Add this new section
    if (this.workoutId) {
      try {
        const response = await axios.get(`${this.$host}/strength/blueprint/${this.workoutId}`);
        const blueprint = response.data;
        if (blueprint) {
          this.loadBlueprint(blueprint);
        }
      } catch (error) {
        console.error("Error loading workout:", error);
        this.showError = true;
        this.errorMessage = "Workout konnte nicht geladen werden";
      }
    }

    // Add visibility change listener
    document.addEventListener('visibilitychange', this.handleVisibilityChange);

    // Add this to check for mobile devices
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },

  methods: {
    async loadExercises() {
      this.exercises = this.transformExercises();

      // Debug: Überprüfe die Anzahl der Übungen pro Kategorie
      const categoryCounts = {
        bodyweight: this.exercises.filter((ex) => ex.categories.includes("bodyweight")).length,
        dumbbell: this.exercises.filter((ex) => ex.categories.includes("dumbbell")).length,
        yoga: this.exercises.filter((ex) => ex.categories.includes("yoga")).length,
        mobility: this.exercises.filter((ex) => ex.categories.includes("mobility")).length,
        banded: this.exercises.filter((ex) => ex.categories.includes("banded")).length,
        pilates: this.exercises.filter((ex) => ex.categories.includes("pilates")).length,
      };

      console.log("Übungen pro Kategorie:", categoryCounts);
    },

    generateWorkout() {
      // Filtere Übungen basierend auf ausgewählten Kategorien
      let enabledExercises = this.exercises.filter((ex) => ex.enabled);

      if (this.selectedCategories.length > 0) {
        enabledExercises = enabledExercises.filter((ex) => {
          const categories = Array.isArray(ex.categories) ? ex.categories : [];
          return this.selectedCategories.some((selectedCat) => categories.includes(selectedCat));
        });
      }

      if (enabledExercises.length === 0) {
        this.showError = true;
        this.errorMessage = "Bitte aktiviere mindestens eine Übung in den ausgewählten Kategorien";
        return;
      }

      const blockSize = parseInt(this.blockLength) || 4;
      const targetDuration = this.selectedDuration * 60; // Convert minutes to seconds

      // Calculate time components
      const exerciseTime = this.exerciseDuration;
      const shortBreakTime = this.shortBreakDuration;
      const blockBreakTime = this.breakDuration;

      // Calculate total time for one block
      const timePerBlock = blockSize * exerciseTime + (blockSize - 1) * shortBreakTime;

      // Calculate how many blocks we need to get close to target duration
      let numberOfBlocks = Math.max(1, Math.round((targetDuration + blockBreakTime) / (timePerBlock + blockBreakTime)));

      // Add safety limit to prevent endless loops
      const maxBlocks = Math.min(numberOfBlocks, 200); // Limit to 200 blocks maximum

      // Initialize workout structure
      this.workoutBlocks = [];

      // Track used exercises to ensure variety
      let usedExercisesInRound = new Set();
      let consecutiveRetries = 0;
      const MAX_RETRIES = 5;

      // Create blocks
      for (let i = 0; i < maxBlocks; i++) {
        const newBlock = {
          exercises: [],
          repetitions: 1,
        };
        let blockRetries = 0;
        const MAX_BLOCK_RETRIES = 10;

        // Fill the block
        while (newBlock.exercises.length < blockSize && blockRetries < MAX_BLOCK_RETRIES) {
          // Reset used exercises if we've used most available ones
          if (usedExercisesInRound.size >= enabledExercises.length * 0.8) {
            usedExercisesInRound.clear();
            consecutiveRetries = 0;
          }

          // Get valid exercises that haven't been used recently
          const validExercises = enabledExercises.filter(
            (ex) => {
              // Calculate remaining spots in the block
              const remainingSpots = blockSize - newBlock.exercises.length;
              
              // Filter out exercises with belongs_to_id if less than 2 spots remain
              if (remainingSpots < 2 && ex.belongs_to_id) {
                return false;
              }

              // Apply other filters
              return !usedExercisesInRound.has(ex.id) &&
                !newBlock.exercises.some(
                  (added) => added.id === ex.id || 
                            added.id === ex.belongs_to_id || 
                            added.belongs_to_id === ex.id
                );
            }
          );

          if (validExercises.length === 0) {
            blockRetries++;
            continue;
          }

          const randomIndex = Math.floor(Math.random() * validExercises.length);
          const selectedExercise = validExercises[randomIndex];

          newBlock.exercises.push({ ...selectedExercise, isNew: false });
          usedExercisesInRound.add(selectedExercise.id);

          // Handle paired exercises
          if (selectedExercise.belongs_to_id && newBlock.exercises.length < blockSize) {
            const pairedExercise = enabledExercises.find(
              (ex) =>
                ex.id === selectedExercise.belongs_to_id ||
                (ex.belongs_to_id === selectedExercise.id && !newBlock.exercises.some((added) => added.id === ex.id))
            );

            if (pairedExercise) {
              newBlock.exercises.push({ ...pairedExercise, isNew: false });
              usedExercisesInRound.add(pairedExercise.id);
            }
          }

          consecutiveRetries = 0;
        }

        // If we couldn't fill the block after max retries, break the loop
        if (newBlock.exercises.length < blockSize) {
          consecutiveRetries++;
          if (consecutiveRetries >= MAX_RETRIES) {
            break;
          }
          continue;
        }

        this.workoutBlocks.push(newBlock);
      }

      // If we couldn't generate enough blocks, show a warning
      if (this.workoutBlocks.length < numberOfBlocks) {
        this.showError = true;
        this.errorMessage = `Konnte nur ${this.workoutBlocks.length} von ${numberOfBlocks} Blcken generieren. Bitte aktiviere mehr Übungen oder reduziere die Workout-Dauer.`;
      }
    },

    toggleFullscreen() {
      console.log("toggleFullscreen");
      // Toggle pseudo-fullscreen state
      this.isPseudoFullscreen = !this.isPseudoFullscreen;
      
      // Add/remove class from body to prevent scrolling
      document.body.style.overflow = this.isPseudoFullscreen ? 'hidden' : '';

      // scroll to top
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },

    handleFullscreenChange() {
      this.isFullscreen = !!document.fullscreenElement;
    },

    getRandomExercises(exercises, count) {
      const result = [];
      const availableExercises = [...exercises];

      while (result.length < count && availableExercises.length > 0) {
        const remainingSpots = count - result.length;

        // Filter out exercises that have belongs_to_id if we only have 1 spot left
        const validExercises =
          remainingSpots === 1 ? availableExercises.filter((ex) => !ex.belongs_to_id) : availableExercises;

        if (validExercises.length === 0) break;

        const randomIndex = Math.floor(Math.random() * validExercises.length);
        const selectedExercise = validExercises[randomIndex];

        // Remove the selected exercise from available exercises
        const selectedIndex = availableExercises.indexOf(selectedExercise);
        availableExercises.splice(selectedIndex, 1);

        result.push({ ...selectedExercise, isNew: false });

        // If the exercise has a belongs_to_id, find and add its pair
        if (selectedExercise.belongs_to_id && remainingSpots > 1) {
          const pairedExercise = availableExercises.find(
            (ex) => ex.id === selectedExercise.belongs_to_id || ex.belongs_to_id === selectedExercise.id
          );

          if (pairedExercise) {
            // Remove the paired exercise from available exercises
            const pairedIndex = availableExercises.indexOf(pairedExercise);
            availableExercises.splice(pairedIndex, 1);

            result.push({ ...pairedExercise, isNew: false });
          }
        }
      }

      return result;
    },

    toggleMute() {
      this.isMuted = !this.isMuted;
      const video = this.$refs.videoPlayer;
      if (video) {
        video.muted = this.isMuted;
      }
    },

    replaceExercise(blockIndex, exerciseIndex) {
      const enabledExercises = this.exercises.filter((ex) => ex.enabled);
      const newExercise = this.getRandomExercises(enabledExercises, 1)[0];
      newExercise.isNew = true;
      this.$set(this.workoutBlocks[blockIndex], exerciseIndex, newExercise);
    },

    startWorkout() {
      if (this.workoutMode === "custom" && !this.validateWorkout()) {
        return;
      }

      if (this.workoutBlocks.length === 0) {
        this.showError = true;
        this.errorMessage = "Bitte erstelle zuerst ein Workout";
        return;
      }

      this.isStarting = true;
      this.currentBlockRepetition = 1;

      // Scroll to top smoothly before starting the workout
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });

      setTimeout(() => {
        this.workoutStarted = true;
        this.workoutStartTime = Date.now();
        this.isBreak = true;
        this.startTimer(this.shortBreakDuration);
        this.isStarting = false;
        this.$parent.setActiveKraftraumWorkout(true);
      }, 500);
    },

    validateWorkout() {
      if (this.workoutBlocks.length === 0) {
        this.showError = true;
        this.errorMessage = "Bitte füge mindestens einen Block hinzu";
        return false;
      }

      return true;
    },

    startExercise() {
      this.handleBreakEnd(); // Add this
      const video = this.$refs.videoPlayer;
      if (video) {
        video.onerror = (e) => {
          this.showError = true;
          this.errorMessage = "Video konnte nicht geladen werden. Bitte versuche es später erneut.";
          console.error("Video loading error:", e);
          this.moveToNext();
        };

        const attemptPlay = () => {
          this.shouldAutoplay = true; // Set this before attempting to play
          video.play()
            .then(() => {
              this.startTimer(this.exerciseDuration);
            })
            .catch((error) => {
              if (error.name === "NotAllowedError") {
                this.showPlayPrompt = true;
              } else {
                console.error("Video playback error:", error);
                this.showError = true;
                this.errorMessage = "Video konnte nicht abgespielt werden. Bitte versuche es später erneut.";
                this.moveToNext();
              }
            });
        };

        attemptPlay();
      }
    },

    handleVideoEnd() {
      this.updateStats();
      this.moveToNext();
    },

    moveToNext() {
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
      }

      this.isBreak = true;
      this.handleBreakStart(); // Add this

      // Check if we need to repeat the current block
      if (this.currentExerciseIndex >= this.currentBlock.exercises.length - 1) {
        if (this.currentBlockRepetition < this.currentBlock.repetitions) {
          // Start next repetition of current block
          this.currentBlockRepetition++;
          this.currentExerciseIndex = 0;
          this.startTimer(this.breakDuration);
        } else {
          // Move to next block
          if (this.currentBlockIndex < this.workoutBlocks.length - 1) {
            this.currentBlockIndex++;
            this.currentExerciseIndex = 0;
            this.currentBlockRepetition = 1;
            this.startTimer(this.breakDuration);
          } else {
            this.finishWorkout();
          }
        }
      } else {
        // Move to next exercise in current block
        this.currentExerciseIndex++;
        this.startTimer(this.shortBreakDuration);
      }
    },

    startTimer(duration) {
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
      }

      // If we're starting a break, use the current pause settings
      if (this.isBreak) {
        if (this.currentExerciseIndex >= this.currentBlock.exercises.length - 1 && 
            this.currentBlockRepetition >= this.currentBlock.repetitions) {
          // Use block break duration
          duration = this.breakDuration;
        } else if (this.currentExerciseIndex < this.currentBlock.exercises.length - 1) {
          // Use exercise break duration
          duration = this.shortBreakDuration;
        }
      }

      this.currentTimer = duration;
      this.progressPercentage = 100;

      const startTime = Date.now();
      const endTime = startTime + duration * 1000;

      this.timerInterval = setInterval(() => {
        if (!this.isPaused) {
          const now = Date.now();
          const timeLeft = Math.max(0, endTime - now);

          this.currentTimer = Math.ceil(timeLeft / 1000);
          this.progressPercentage = (timeLeft / (duration * 1000)) * 100;

          if (timeLeft <= 0) {
            clearInterval(this.timerInterval);
            if (this.isBreak) {
              this.isBreak = false;
              this.startExercise();
            } else {
              this.updateStats();
              this.moveToNext();
            }
          }
        }
      }, 16);
    },

    togglePause() {
      this.isPaused = !this.isPaused;
      const video = this.$refs.videoPlayer;

      if (this.isPaused) {
        // Start tracking pause time
        this.pauseStartTime = Date.now();
        if (video && !video.paused) {
          video.pause();
          this.shouldAutoplay = false;
        }
      } else {
        // Calculate and add pause duration when unpausing
        if (this.pauseStartTime) {
          this.totalPausedTime += Date.now() - this.pauseStartTime;
          this.pauseStartTime = null;
        }
        
        const remainingTime = this.currentTimer;
        this.startTimer(remainingTime);
        this.shouldAutoplay = true;
        
        if (video && video.paused) {
          video.play().catch((error) => {
            if (error.name === "NotAllowedError") {
              this.showPlayPrompt = true;
            }
          });
        }
      }
    },

    updateStats() {
      const exercise = this.currentExercise;
      if (!this.exerciseStats[exercise.id]) {
        this.exerciseStats[exercise.id] = {
          title: exercise.title,
          duration: 0,
        };
      }
      this.exerciseStats[exercise.id].duration += this.exerciseDuration;
    },

    calculateActualWorkoutTime() {
      if (!this.workoutStartTime) return 0;
      
      let totalTime = Date.now() - this.workoutStartTime;
      
      // Add current pause duration if workout is paused
      const currentPauseTime = this.pauseStartTime ? (Date.now() - this.pauseStartTime) : 0;
      
      // Subtract all pause time from total time
      return Math.floor((totalTime - this.totalPausedTime - currentPauseTime) / 1000);
    },

    async finishWorkout() {
      this.totalWorkoutTimeSpent = this.calculateActualWorkoutTime();
      this.workoutStarted = false;
      
      // Select random motivational message
      const randomIndex = Math.floor(Math.random() * this.motivationalMessages.length);
      this.completionMessage = this.motivationalMessages[randomIndex];
      
      // Show completion modal
      this.showWorkoutCompleteModal = true;
      
      // Clear the saved workout state
      this.clearWorkoutState();
      
      console.log("Workout Statistics:", this.exerciseStats);
    },

    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
    },

    handleVideoPause() {
      if (!this.isPaused) {
        this.isPaused = true;
      }
    },

    handleVideoPlay() {
      if (this.isPaused) {
        this.isPaused = false;
      }
    },

    addNewBlock() {
      if (this.workoutBlocks.length < this.maxBlocks) {
        this.workoutBlocks.push({
          exercises: [],
          repetitions: 1,
        });
      } else {
        this.showError = true;
        this.errorMessage = "Maximale Anzahl an Blöcken erreicht";
      }
    },

    addExerciseToBlock(exercise) {
      if (this.workoutBlocks.length === 0) {
        this.workoutBlocks.push({
          exercises: [],
          repetitions: 1,
        });
      }

      const currentBlock = this.workoutBlocks[this.workoutBlocks.length - 1];
      if (currentBlock.exercises.length < this.blockLength) {
        // Add the exercise
        currentBlock.exercises.push({ ...exercise, isNew: false });

        // Get the indices for the newly added exercise
        const blockIndex = this.workoutBlocks.length;
        const exerciseIndex = currentBlock.exercises.length - 1;

        // Scroll to the newly added exercise after DOM update
        this.$nextTick(() => {
          const blocks = document.querySelectorAll('.col-12');
          const targetBlock = blocks[blockIndex];
          if (targetBlock) {
            const exerciseCards = targetBlock.querySelectorAll('.card');
            const targetExercise = exerciseCards[exerciseIndex];
            if (targetExercise) {
              targetExercise.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest'
              });
            }
          }
        });
      }
      // Only create new block if current one is full AND we haven't reached max blocks
      else if (this.workoutBlocks.length < this.maxBlocks) {
        this.workoutBlocks.push({
          exercises: [{ ...exercise, isNew: false }],
          repetitions: 1,
        });

        // Scroll to the first exercise in the new block
        this.$nextTick(() => {
          const blocks = document.querySelectorAll('.col-12');
          const newBlock = blocks[blocks.length - 1];
          if (newBlock) {
            const firstExercise = newBlock.querySelector('.card');
            if (firstExercise) {
              firstExercise.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest'
              });
            }
          }
        });
      }
    },

    removeExerciseFromBlock(blockIndex, exerciseIndex) {
      this.workoutBlocks[blockIndex].exercises.splice(exerciseIndex, 1);

      if (this.workoutBlocks[blockIndex].exercises.length === 0) {
        this.workoutBlocks.splice(blockIndex, 1);
      }
    },

    async toggleAllExercises() {
      this.allExercisesEnabled = !this.allExercisesEnabled;
      this.exercises.forEach((exercise) => {
        exercise.enabled = this.allExercisesEnabled;
      });
      if (this.workoutMode === "auto") {
        this.generateWorkout();
      }

      // Save preferences when toggling all exercises
      await this.savePreferences();
    },

    async handleExerciseToggle(exercise) {
      this.allExercisesEnabled = this.exercises.every((ex) => ex.enabled);
      if (this.workoutMode === "auto") {
        this.generateWorkout();
      }
      if (this.selectedBlockIndex !== null) {
        this.addExerciseToSpecificBlock(this.selectedBlockIndex, exercise);
        this.showWorkoutModal = false;
        this.selectedBlockIndex = null;
      }

      // Save preferences whenever an exercise is toggled
      await this.savePreferences();
    },

    clearWorkout() {
      this.workoutBlocks = [];
      // Scroll to top smoothly
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },

    handleExerciseClick(exercise) {
      if (this.workoutStarted) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (this.isMobile) {
        if (
          this.workoutBlocks.length === 0 ||
          this.workoutBlocks[this.workoutBlocks.length - 1].exercises.length >= this.blockLength
        ) {
          this.workoutBlocks.push({
            exercises: [],
            repetitions: 1,
          });
        }
        this.addExerciseToBlock(exercise);
      } else {
        if (
          this.workoutBlocks.length === 0 ||
          this.workoutBlocks[this.workoutBlocks.length - 1].exercises.length >= this.blockLength
        ) {
          this.workoutBlocks.push({
            exercises: [],
            repetitions: 1,
          });
        }
        this.addExerciseToBlock(exercise);
      }
    },

    exitWorkout() {
      if (confirm("Möchtest du das Workout wirklich beenden?")) {
        if (this.timerInterval) {
          clearInterval(this.timerInterval);
          this.timerInterval = null;
        }
        
        // Calculate actual time spent including pause adjustments
        this.totalWorkoutTimeSpent = this.calculateActualWorkoutTime();
        
        this.workoutStarted = false;
        this.currentBlockIndex = 0;
        this.currentExerciseIndex = 0;
        this.currentTimer = 0;
        this.$parent.setActiveKraftraumWorkout(false);
        
        // Show completion modal
        this.showWorkoutCompleteModal = true;
      }
    },

    saveWorkoutSettings() {
      this.generateWorkout();
      this.showWorkoutModal = false;
    },

    skipExercise() {
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
        this.timerInterval = null;
      }

      const video = this.$refs.videoPlayer;
      if (video) {
        video.pause();
        video.currentTime = 0;
      }

      // If we're in a break, just end the break and start the next exercise
      if (this.isBreak) {
        this.isBreak = false;
        this.startExercise();
      } else {
        // If we're in an exercise, update stats and move to next
        this.updateStats();
        this.moveToNext();
      }
    },

    transformExercises() {
      let completeList = exerciseTranslations.map((exercise) => ({
        id: exercise.id,
        title: exercise.german,
        videoUrl: `https://plc-videos.fra1.cdn.digitaloceanspaces.com/${encodeURIComponent(exercise.original)}.mp4`,
        thumbnail: `https://plc-videos.fra1.cdn.digitaloceanspaces.com/${encodeURIComponent(exercise.original)}.jpg`,
        thumbnail_hq: `https://plc-videos.fra1.cdn.digitaloceanspaces.com/${encodeURIComponent(exercise.original)}-hq.jpg`,
        enabled: true,
        belongs_to_id: exercise.belongs_to_id || null,
        categories: exercise.categories,
        muscles: exercise.muscles || [],
      }));

      // append dumbbell exercises
      completeList.push(
        ...dumbbellExercises.map((exercise) => ({
          ...exercise,
          title: exercise.german,
          videoUrl: `https://plc-videos.fra1.cdn.digitaloceanspaces.com/${encodeURIComponent(
            exercise.original
          )}-dumbbell.mp4`,
          thumbnail: `https://plc-videos.fra1.cdn.digitaloceanspaces.com/${encodeURIComponent(
            exercise.original
          )}-dumbbell.jpg`,
          thumbnail_hq: `https://plc-videos.fra1.cdn.digitaloceanspaces.com/${encodeURIComponent(
            exercise.original
          )}-dumbbell-hq.jpg`,
          enabled: true,
          belongs_to_id: exercise.belongs_to_id || null,
          categories: exercise.categories ? [...exercise.categories, "dumbbell"] : ["dumbbell"],
          muscles: exercise.muscles || [],
        }))
      );

      // append yoga exercises with mapping
      completeList.push(
        ...yogaExercises.map((exercise) => ({
          id: exercise.id, // Add this line
          title: exercise.german,
          videoUrl: `https://plc-videos.fra1.cdn.digitaloceanspaces.com/${encodeURIComponent(
            exercise.original
          )}-yoga.mp4`,
          thumbnail: `https://plc-videos.fra1.cdn.digitaloceanspaces.com/${encodeURIComponent(
            exercise.original
          )}-yoga.jpg`,
          thumbnail_hq: `https://plc-videos.fra1.cdn.digitaloceanspaces.com/${encodeURIComponent(
            exercise.original
          )}-yoga-hq.jpg`,
          enabled: true,
          belongs_to_id: exercise.belongs_to_id || null,
          categories: exercise.categories ? [...exercise.categories, "yoga"] : ["yoga"],
          muscles: exercise.muscles || [],
        }))
      );

      // append mobility exercises with mapping
      completeList.push(
        ...mobilityExercises.map((exercise) => ({
          id: exercise.id, // Add this line
          title: exercise.german,
          videoUrl: `https://plc-videos.fra1.cdn.digitaloceanspaces.com/${encodeURIComponent(
            exercise.original
          )}-mobility.mp4`,
          thumbnail: `https://plc-videos.fra1.cdn.digitaloceanspaces.com/${encodeURIComponent(
            exercise.original
          )}-mobility.jpg`,
          thumbnail_hq: `https://plc-videos.fra1.cdn.digitaloceanspaces.com/${encodeURIComponent(
            exercise.original
          )}-mobility-hq.jpg`,
          enabled: true,
          belongs_to_id: exercise.belongs_to_id || null,
          categories: exercise.categories ? [...exercise.categories, "mobility"] : ["mobility"],
          muscles: exercise.muscles || [],
        }))
      );

      // append banded exercises with mapping
      completeList.push(
        ...bandedExercises.map((exercise) => ({
          id: exercise.id, // Add this line
          title: exercise.german,
          videoUrl: `https://plc-videos.fra1.cdn.digitaloceanspaces.com/${encodeURIComponent(
            exercise.original
          )}-banded.mp4`,
          thumbnail: `https://plc-videos.fra1.cdn.digitaloceanspaces.com/${encodeURIComponent(
            exercise.original
          )}-banded.jpg`,
          thumbnail_hq: `https://plc-videos.fra1.cdn.digitaloceanspaces.com/${encodeURIComponent(
            exercise.original
          )}-banded-hq.jpg`,
          enabled: true,
          belongs_to_id: exercise.belongs_to_id || null,
          categories: exercise.categories ? [...exercise.categories] : ["banded"],
          muscles: exercise.muscles || [],
        }))
      );

      completeList.push(
        ...pilatesExercises.map((exercise) => ({
          id: exercise.id, // Add this line
          title: exercise.german,
          videoUrl: `https://plc-videos.fra1.cdn.digitaloceanspaces.com/${encodeURIComponent(
            exercise.original
          )}-pilates.mp4`,
          thumbnail: `https://plc-videos.fra1.cdn.digitaloceanspaces.com/${encodeURIComponent(
            exercise.original
          )}-pilates.jpg`,
          thumbnail_hq: `https://plc-videos.fra1.cdn.digitaloceanspaces.com/${encodeURIComponent(
            exercise.original
          )}-pilates-hq.jpg`,
          enabled: true,
          belongs_to_id: exercise.belongs_to_id || null,
          categories: ["pilates"],
          muscles: exercise.muscles || [],
        }))
      );

      //sort by title
      completeList.sort((a, b) => a.title.localeCompare(b.title));

      return completeList;
    },

    getCategoryLabel(category) {
      // Only show main category labels, not warmup/cooldown
      const labels = {
        bodyweight: "Bodyweight",
        dumbbell: "Kurzhantel",
        yoga: "Yoga",
        mobility: "Dehnung & Mobilität",
        banded: "Resistance Band",
        pilates: "Pilates",
      };
      return labels[category] || null; // Return null for warmup/cooldown so they won't be displayed
    },

    showExercisePickerModal(blockIndex) {
      this.selectedBlockIndex = blockIndex;
      this.showExercisePicker = true;
    },

    addExerciseToSpecificBlock(blockIndex, exercise) {
      if (this.selectedExerciseIndex !== undefined) {
        const currentBlock = this.workoutBlocks[blockIndex];

        if (currentBlock.exercises.length >= this.blockLength) {
          if (blockIndex < this.maxBlocks - 1) {
            this.workoutBlocks.splice(blockIndex + 1, 0, {
              exercises: [{ ...exercise, isNew: false }],
              repetitions: 1,
            });
          } else {
            this.showError = true;
            this.errorMessage = "Maximale Anzahl an Blöcken erreicht";
          }
        } else {
          currentBlock.exercises.splice(this.selectedExerciseIndex + 1, 0, { ...exercise, isNew: false });
        }

        this.selectedExerciseIndex = undefined;
      } else {
        if (this.workoutBlocks[blockIndex].exercises.length < this.blockLength) {
          this.workoutBlocks[blockIndex].exercises.push({ ...exercise, isNew: false });
        }
      }

      this.showExercisePicker = false;
    },

    removeUpcomingExercise(blockIndex, exerciseIndex) {
      this.workoutBlocks[blockIndex].exercises.splice(exerciseIndex, 1);
      if (this.workoutBlocks[blockIndex].exercises.length === 0) {
        this.workoutBlocks.splice(blockIndex, 1);
      }
    },

    shuffleUpcomingExercise(blockIndex, exerciseIndex) {
      const enabledExercises = this.exercises.filter((ex) => ex.enabled);
      const currentBlock = this.workoutBlocks[blockIndex];
      const currentExercise = currentBlock.exercises[exerciseIndex];

      // If this is a paired exercise, we need to handle both exercises
      if (currentExercise.belongs_to_id) {
        // Find the index of the paired exercise
        const pairedIndex = currentBlock.exercises.findIndex(
          (ex) => ex.id === currentExercise.belongs_to_id || ex.belongs_to_id === currentExercise.id
        );

        if (pairedIndex !== -1) {
          // Get two new exercises that are either both paired or both unpaired
          const newExercises = this.getRandomExercises(enabledExercises, 2);

          // Replace both exercises
          const firstIndex = Math.min(exerciseIndex, pairedIndex);
          const secondIndex = Math.max(exerciseIndex, pairedIndex);

          this.$set(currentBlock.exercises, firstIndex, { ...newExercises[0], isNew: true });
          this.$set(currentBlock.exercises, secondIndex, { ...newExercises[1], isNew: true });
          return;
        }
      }

      // Handle single exercise replacement
      const newExercise = this.getRandomExercises(enabledExercises, 1)[0];
      this.$set(currentBlock.exercises, exerciseIndex, { ...newExercise, isNew: true });
    },

    duplicateUpcomingExercise(blockIndex, exerciseIndex) {
      // Get the exercise to duplicate
      const exerciseToDuplicate = { ...this.workoutBlocks[blockIndex].exercises[exerciseIndex] };
      
      // Insert the duplicated exercise after the original one
      this.workoutBlocks[blockIndex].exercises.splice(exerciseIndex + 1, 0, {
        ...exerciseToDuplicate,
        isNew: false // Keep isNew false since it's a duplicate
      });

      // If we've exceeded the block length, move the excess exercise to a new block
      if (this.workoutBlocks[blockIndex].exercises.length > this.blockLength) {
        // If we're not at the last block, move excess to next block
        if (blockIndex < this.workoutBlocks.length - 1) {
          const excessExercise = this.workoutBlocks[blockIndex].exercises.pop();
          this.workoutBlocks[blockIndex + 1].exercises.unshift(excessExercise);
        } 
        // If we are at the last block and haven't reached max blocks, create new block
        else if (this.workoutBlocks.length < this.maxBlocks) {
          const excessExercise = this.workoutBlocks[blockIndex].exercises.pop();
          this.workoutBlocks.push({
            exercises: [excessExercise],
            repetitions: 1
          });
        }
      }
    },

    addExerciseAfter(blockIndex, exerciseIndex) {
      this.selectedBlockIndex = blockIndex;
      this.selectedExerciseIndex = exerciseIndex;
      this.showExercisePicker = true;
      this.upcomingExercises.forEach((ex) => (ex.showMenu = false));
    },

    async loadPage() {
      this.loaded = false;
      this.data = (await axios.get(this.$host + "/video/page/" + this.page)).data;

      this.unsere = this.data.filter((v) => v.main_category == "PLC");
      this.andere = this.data.filter((v) => v.main_category != "PLC");

      this.loaded = true;
    },

    loadNextPage() {
      if (this.data.length > 3) {
        this.page++;
        this.loadPage();
      }
    },

    loadPreviousPage() {
      if (this.page > 0) {
        this.page--;
        this.loadPage();
      }
    },

    calculateTotalWorkoutTime() {
      let totalTime = 0;

      this.workoutBlocks.forEach((block, blockIndex) => {
        // Calculate time for one iteration of the block
        const blockTime =
          block.exercises.length * this.exerciseDuration + (block.exercises.length - 1) * this.shortBreakDuration;

        // Multiply by repetitions
        totalTime += blockTime * block.repetitions;

        // Add block break after each block except the last one
        if (blockIndex < this.workoutBlocks.length - 1) {
          totalTime += this.breakDuration;
        }
      });

      return totalTime;
    },

    shuffleExercises() {
      this.workoutBlocks = this.workoutBlocks.map((block) => {
        const shuffled = [...block].sort(() => Math.random() - 0.5);
        return shuffled;
      });
    },

    async saveAsBlueprint() {
      if (!this.blueprintName.trim()) {
        this.showError = true;
        this.errorMessage = "Bitte gib einen Namen ein";
        return;
      }

      try {
        const blocks = this.workoutBlocks.map((block) => ({
          exercises: block.exercises.map((ex) => ex.id),
          repetitions: block.repetitions,
        }));

        await axios.post(this.$host + "/strength/blueprint", {
          name: this.blueprintName,
          blocks,
          duration: this.calculateTotalWorkoutTime(),
          settings: {
            blockLength: this.blockLength,
            breakDuration: this.breakDuration,
            exerciseDuration: this.exerciseDuration,
            shortBreakDuration: this.shortBreakDuration,
          },
        });

        this.showSaveBlueprintModal = false;
        this.blueprintName = "";
        await this.loadBlueprints();
      } catch (error) {
        console.error("Error saving blueprint:", error);
        this.showError = true;
        this.errorMessage = "Fehler beim Speichern der Vorlage";
      }
    },

    async loadBlueprints() {
      try {
        const response = await axios.get(this.$host + "/strength/blueprints");
        this.blueprints = response.data;
      } catch (error) {
        console.error("Error loading blueprints:", error);
      }
    },

    async deleteBlueprint(id) {
      if (confirm("Möchten Sie diese Vorlage wirklich löschen?")) {
        try {
          await axios.delete(this.$host + "/strength/blueprint/" + id);
          this.showBlueprintPreviewModal = false; // Close the modal
          await this.loadBlueprints();
        } catch (error) {
          console.error("Error deleting blueprint:", error);
          this.showError = true;
          this.errorMessage = "Fehler beim Löschen der Vorlage";
        }
      }
    },

    loadBlueprint(blueprint) {
      // Load settings
      this.blockLength = blueprint.settings.blockLength;
      this.breakDuration = blueprint.settings.breakDuration;
      this.exerciseDuration = blueprint.settings.exerciseDuration;
      this.shortBreakDuration = blueprint.settings.shortBreakDuration;
      
      // Convert blocks with repetitions
      this.workoutBlocks = blueprint.blocks.map((block) => ({
        exercises: block.exercises.map((exerciseId) => {
          const exercise = this.exercises.find((ex) => ex.id === exerciseId);
          if (!exercise || !exercise.enabled) {
            const replacement = this.findReplacementExercise(exerciseId);
            return { ...replacement, isNew: true };
          }
          return { ...exercise, isNew: false };
        }),
        repetitions: block.repetitions || 1,
      }));

      this.workoutMode = "custom";
    },

    previewBlueprint(workout) {
      this.selectedBlueprint = workout;
      // Transform workout blocks into preview format
      this.previewBlocks = workout.blocks ? workout.blocks.map(block => block.exercises) : [];
      this.showBlueprintPreviewModal = true;
    },

    loadBlueprintFromPreview() {
      this.loadBlueprint(this.selectedBlueprint);
      this.showBlueprintPreviewModal = false;
      this.workoutMode = "custom"; // Switch to custom mode after loading
    },

    formatDuration(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;

      if (minutes === 0) {
        return `${seconds}s`;
      } else if (remainingSeconds === 0) {
        return `${minutes}min`;
      } else {
        return `${minutes}min ${remainingSeconds}s`;
      }
    },

    calculateWorkoutDuration(workout) {
      if (!workout || !workout.settings) return '0:00';
      
      const exerciseDuration = 30; // Default exercise duration in seconds
      const shortBreakDuration = workout.settings.shortBreakDuration || 0;
      const breakDuration = workout.settings.breakDuration || 0;
      
      // Calculate total blocks
      const totalBlocks = workout.blocks ? workout.blocks.length : 0;
      
      if (totalBlocks === 0) return '0:00';

      // Calculate total time in seconds
      let totalTime = 0;
      
      // For each block
      for (let i = 0; i < totalBlocks; i++) {
        const block = workout.blocks[i];
        const exercises = block.exercises ? block.exercises.length : 0;
        const repetitions = block.repetitions || 1;
        
        // Time for exercises and short breaks in this block
        const blockTime = (exercises * exerciseDuration) + ((exercises - 1) * shortBreakDuration);
        
        // Multiply by repetitions
        totalTime += blockTime * repetitions;
        
        // Add block break if not last block
        if (i < totalBlocks - 1) {
          totalTime += breakDuration;
        }
      }

      // Convert to minutes and seconds
      const minutes = Math.floor(totalTime / 60);
      const seconds = totalTime % 60;
      
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    },

   

    generateWorkoutFromModal() {
      this.generationStep = 1; // Reset step
      this.showGenerateWorkoutModal = false;
      this.generateWorkout();
    },

    openVideoPreview(exercise) {
      this.selectedExerciseForPreview = exercise;
      this.showVideoPreviewModal = true;
    },

    closeVideoPreview() {
      const video = this.$refs.previewVideo;
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
      this.showVideoPreviewModal = false;
      this.selectedExerciseForPreview = null;
    },

    handlePreviewEnd() {
      const video = this.$refs.previewVideo;
      if (video) {
        video.currentTime = 0;
      }
    },

    getAvailablePairedExercise(block) {
      // Look through the block for exercises with belongs_to_id
      for (const exercise of block.exercises) {
        if (exercise.belongs_to_id) {
          // Check if the paired exercise is already in the block
          const pairedExerciseInBlock = block.exercises.find(
            (ex) => ex.id === exercise.belongs_to_id || ex.belongs_to_id === exercise.id
          );

          if (!pairedExerciseInBlock) {
            // Find the paired exercise in available exercises
            const pairedExercise = this.exercises.find(
              (ex) =>
                ex.id === exercise.belongs_to_id ||
                (ex.belongs_to_id === exercise.id && ex.categories.some((cat) => exercise.categories.includes(cat)))
            );

            if (pairedExercise && pairedExercise.enabled) {
              return pairedExercise;
            }
          }
        }
      }
      return null;
    },

    addPairedExercise(blockIndex, exercise) {
      if (this.workoutBlocks[blockIndex].exercises.length < this.blockLength) {
        this.workoutBlocks[blockIndex].exercises.push({ ...exercise, isNew: false });
      }
    },

    selectDuration(value) {
      this.selectedDuration = value;
      this.generationStep++;
    },

    selectBlockLength(value) {
      this.blockLength = value;
      this.generationStep++;
    },

    selectShortBreak(value) {
      this.shortBreakDuration = value;
      this.generationStep++;
    },

    selectBlockBreak(value) {
      this.breakDuration = value;
      this.generationStep++;
    },

    formatBreakDuration(seconds) {
      if (seconds < 60) {
        return `${seconds}s`;
      }
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return remainingSeconds > 0 ? `${minutes}:${remainingSeconds.toString().padStart(2, "0")}` : `${minutes}min`;
    },

    // Add this helper method to find a replacement exercise
    findReplacementExercise(originalExerciseId) {
      const originalExercise = this.exercises.find((ex) => ex.id === originalExerciseId);
      const enabledExercises = this.exercises.filter((ex) => ex.enabled);

      // Try to find a similar exercise first (same category if original exercise exists)
      if (originalExercise) {
        const similarExercises = enabledExercises.filter(
          (ex) =>
            ex.id !== originalExerciseId &&
            !ex.belongs_to_id && // Avoid paired exercises for replacement
            this.getCategoryForExercise(ex) === this.getCategoryForExercise(originalExercise)
        );

        if (similarExercises.length > 0) {
          return similarExercises[Math.floor(Math.random() * similarExercises.length)];
        }
      }

      // If no similar exercise found, return any enabled exercise
      const availableExercises = enabledExercises.filter((ex) => !ex.belongs_to_id);
      return availableExercises[Math.floor(Math.random() * availableExercises.length)];
    },

    // Add this helper method to get exercise category
    getCategoryForExercise(exercise) {
      //if (exercise.warmup) return "warmup";
      //if (exercise.cooldown) return "cooldown";
      if (exercise.dumbbell) return "dumbbell";
      if (exercise.bodyweight) return "bodyweight";
      if (exercise.yoga) return "yoga";
      if (exercise.mobility) return "mobility";
      if (exercise.banded) return "banded";
      if (exercise.pilates) return "pilates";
      // Add more category logic here if needed
      return "regular";
    },

    toggleCategory(categoryId) {
      const index = this.selectedCategories.indexOf(categoryId);
      if (index === -1) {
        this.selectedCategories.push(categoryId);
      } else {
        this.selectedCategories.splice(index, 1);
      }
    },

    async toggleCategoryExercises(category, enable) {
      // Mapping der UI-Kategorienamen zu den internen Kategorienamen
      const categoryMapping = {
        Bodyweight: "bodyweight",
        Pilates: "pilates",
        Kurzhantel: "dumbbell",
        Yoga: "yoga",
        "Resistance Band": "banded",
        "Dehnung & Mobilität": "mobility",
      };

      const internalCategory = categoryMapping[category];
      if (!internalCategory) return;

      // Alle Übungen der gewählten Kategorie aktivieren/deaktivieren
      this.exercises.forEach((exercise) => {
        if (exercise.categories.includes(internalCategory)) {
          exercise.enabled = enable;
        }
      });

      // Update allExercisesEnabled Status
      this.allExercisesEnabled = this.exercises.every((ex) => ex.enabled);

      await this.savePreferences();
    },

    setScrollLock(lockIt) {
      const el = window.document.body;
      if (lockIt) {
        // Store current scroll position
        this.scrollPosition = window.pageYOffset;
        el.classList.add("locked");
        el.style.top = `-${this.scrollPosition}px`;
        el.style.position = "fixed";
        el.style.width = "100%";
      } else {
        el.classList.remove("locked");
        el.style.position = "";
        el.style.top = "";
        el.style.width = "";
        // Restore scroll position
        window.scrollTo(0, this.scrollPosition);
      }
    },

    showOverlay() {
      this.setScrollLock(true);
    },

    hideOverlay() {
      this.setScrollLock(false);
    },

    async loadPreferences() {
      try {
        const response = await axios.get(this.$host + "/strength/preferences");
        const deactivatedExercises = response.data.deactivatedExercises;

        // Update the enabled status of exercises based on preferences
        this.exercises = this.exercises.map((exercise) => ({
          ...exercise,
          enabled: !deactivatedExercises.includes(exercise.id),
        }));

        // Update allExercisesEnabled status
        this.allExercisesEnabled = this.exercises.every((ex) => ex.enabled);
      } catch (error) {
        console.error("Error loading preferences:", error);
        this.showError = true;
        this.errorMessage = "Fehler beim Laden der Übungspräferenzen";
      }
    },

    async savePreferences() {
      try {
        // Get IDs of all deactivated exercises
        const deactivatedExercises = this.exercises.filter((ex) => !ex.enabled).map((ex) => ex.id);

        await axios.post(this.$host + "/strength/preferences", {
          deactivatedExercises,
        });
      } catch (error) {
        console.error("Error saving preferences:", error);
        this.showError = true;
        this.errorMessage = "Fehler beim Speichern der Übungspräferenzen";
      }
    },

    selectCategory(category) {
      this.selectedCategory = category;
      this.selectedMuscleGroup = "all"; // Reset muscle group filter
      this.showMuscleGroupFilter = false; // Hide muscle group filter when changing category
      this.showCategoryFilterModal = false; // Close the modal immediately
    },

    selectMuscleGroup(group) {
      this.selectedMuscleGroup = group;
      this.showMuscleGroupFilterModal = false; // Close the modal immediately
    },

    getCategoryIcon(category) {
      const icons = {
        Alle: "bi bi-grid-3x3",
        Bodyweight: "bi bi-person",
        Pilates: "bi bi-heart",
        Kurzhantel: "bi bi-battery",
        Yoga: "bi bi-flower1",
        "Resistance Band": "bi bi-bandaid",
        "Dehnung & Mobilitt": "bi bi-arrows-move",
      };
      return icons[category] || "bi bi-grid-3x3";
    },

    incrementRepetitions(blockIndex) {
      if (this.workoutBlocks[blockIndex].repetitions < 10) {
        this.workoutBlocks[blockIndex].repetitions++;
      }
    },

    decrementRepetitions(blockIndex) {
      if (this.workoutBlocks[blockIndex].repetitions > 1) {
        this.workoutBlocks[blockIndex].repetitions--;
      }
    },

    // Add method to handle manual play
    handleManualPlay() {
      const video = this.$refs.videoPlayer;
      if (video) {
        this.showPlayPrompt = false;
        video.play()
          .then(() => {
            this.startTimer(this.exerciseDuration);
          })
          .catch((error) => {
            console.error("Manual play error:", error);
            this.showError = true;
            this.errorMessage = "Video konnte nicht abgespielt werden. Bitte versuche es später erneut.";
            this.moveToNext();
          });
      }
    },

    // Add method to handle page visibility changes
    handleVisibilityChange() {
      const video = this.$refs.videoPlayer;
      if (document.hidden) {
        // Don't pause the video when tab is hidden
        // Just keep track of the state
        if (video && !video.paused) {
          this.shouldAutoplay = true;
        }
      } else {
        // When returning to the tab, ensure video is playing if it should be
        if (video && video.paused && this.shouldAutoplay) {
          video.play().catch((error) => {
            console.error("Error resuming video playback:", error);
          });
          this.isPaused = false;
        }
      }
    },

    // Add these methods to handle video player state
    handleBreakStart() {
      const video = this.$refs.videoPlayer;
      if (video) {
        // Exit fullscreen if we're in it
        if (video.webkitPresentationMode === "fullscreen") {
          video.webkitExitFullscreen();
        }
        // Pause the video
        video.pause();
        // Keep video in DOM but hide it with visibility
        video.style.visibility = 'hidden';
        // Optional: Reset the current time
        video.currentTime = 0;
      }
    },

    handleBreakEnd() {
      const video = this.$refs.videoPlayer;
      if (video) {
        // Show video again
        video.style.visibility = 'visible';
        // Set the new source
        video.src = this.currentExercise.videoUrl;
        // Load the video
        video.load();
      }
    },

    handleFullscreenClick() {
      if (this.isPseudoFullscreen) {
        this.toggleFullscreen();
      }
    },

    // Add this method to check if device is mobile
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },

    handleKeyPress(event) {
      if (event.key === 'Escape' && this.isPseudoFullscreen) {
        this.toggleFullscreen();
      }
    },

    saveWorkoutState() {
      const state = {
        workoutBlocks: this.workoutBlocks,
        currentBlockIndex: this.currentBlockIndex,
        currentExerciseIndex: this.currentExerciseIndex,
        currentTimer: this.currentTimer,
        isBreak: this.isBreak,
        currentBlockRepetition: this.currentBlockRepetition,
        workoutStarted: this.workoutStarted,
        // Add any other relevant state properties
      };
      localStorage.setItem('kraftraumWorkoutState', JSON.stringify(state));
    },

    loadWorkoutState() {
      const savedState = localStorage.getItem('kraftraumWorkoutState');
      if (savedState) {
        console.log("Loading workout state:", savedState);
        const state = JSON.parse(savedState);
        Object.assign(this, state);
        
        if (this.workoutStarted) {
          this.$parent.setActiveKraftraumWorkout(true);
          
          // Add small delay to ensure DOM is ready
          setTimeout(() => {
            if (this.isBreak) {
              // If we were in a break, restart the break timer
              this.startTimer(this.currentTimer);
            } else {
              // If we were in an exercise, restart the video
              const video = this.$refs.videoPlayer;
              if (video) {
                video.src = this.currentExercise.videoUrl;
                video.load();
                video.play()
                  .then(() => {
                    this.startTimer(this.currentTimer);
                    this.isPaused = false;
                  })
                  .catch(error => {
                    console.error("Error auto-playing video:", error);
                    // Show play button if autoplay fails
                    this.isPaused = true;
                  });
              }
            }
          }, 100);
        }
      }
    },

    clearWorkoutState() {
      console.log("Clearing workout state");
      localStorage.removeItem('kraftraumWorkoutState');
      // Reset additional workout-related state
      this.currentBlockIndex = 0;
      this.currentExerciseIndex = 0;
      this.currentTimer = 0;
      this.currentBlockRepetition = 1;
      this.isBreak = false;
      this.workoutStarted = false;
      this.workoutStartTime = null;
      this.$parent.setActiveKraftraumWorkout(false);
    },

    toggleExerciseMenu(index) {
      this.openMenuIndex = index;
    },

    async loadPlannedWorkouts() {
      try {
        const today = this.$moment().add(-1, 'day').format('YYYY-MM-DD');
        const response = await axios.get(`${this.$host}/workout/nextworkouts/${today}/limit/15`);
        // Filter workouts and get their blueprints
        const strengthWorkouts = response.data.filter(workout => 
          workout.strength_blueprint && workout.sport === 'Kraft'
        );
        
        // Load the blueprint details for each workout
        const workoutsWithBlueprints = await Promise.all(
          strengthWorkouts.map(async workout => {
            try {
              const blueprintResponse = await axios.get(`${this.$host}/strength/blueprint/${workout.strength_blueprint}`);
              return {
                ...workout,
                ...blueprintResponse.data // Merge blueprint data into workout
              };
            } catch (error) {
              console.error('Error loading blueprint:', error);
              return workout;
            }
          })
        );
        
        this.plannedWorkouts = workoutsWithBlueprints;
      } catch (error) {
        console.error('Error loading planned workouts:', error);
        this.showError = true;
        this.errorMessage = 'Fehler beim Laden der geplanten Workouts';
      }
    },

    loadPlannedWorkout(workout) {
      // Transform the workout into the correct format
      const formattedWorkout = {
        ...workout,
        settings: {
          blockLength: workout.blockLength || 4,
          shortBreakDuration: workout.shortBreakDuration || 10,
          breakDuration: workout.breakDuration || 60,
        }
      };

      // Set the workout settings
      this.blockLength = formattedWorkout.settings.blockLength;
      this.shortBreakDuration = formattedWorkout.settings.shortBreakDuration;
      this.breakDuration = formattedWorkout.settings.breakDuration;

      // Load the blocks
      this.workoutBlocks = workout.blocks ? workout.blocks.map(block => ({
        exercises: block.exercises.map(exerciseId => {
          const exercise = this.exercises.find(ex => ex.id === exerciseId);
          if (!exercise || !exercise.enabled) {
            const replacement = this.findReplacementExercise(exerciseId);
            return { ...replacement, isNew: true };
          }
          return { ...exercise, isNew: false };
        }),
        repetitions: block.repetitions || 1
      })) : [];

      // Switch to custom mode to show the loaded workout
      this.workoutMode = 'custom';
    },

    async saveWorkout() {
      try {
        const workoutData = {
          name: this.currentPlannedWorkout?.name || 'Kraftraum Workout',
          sport: 'Kraft',
          date: this.$moment().format('YYYY-MM-DD'),
          durationIs: this.totalWorkoutTimeSpent || this.calculateTotalWorkoutTime(),
          durationShould: this.currentPlannedWorkout?.durationShould || 0,
          strength_blueprint: this.currentPlannedWorkout?.strength_blueprint || null,
          paceIs: 0,
          paceShould: 0,
          distance: 0,
          distanceShould: 0,
          rpe: 0,
        };

        // put the workout excercises into the workoutData description
        // add blocks and repetitions and put it in new lines
        workoutData.description = this.workoutBlocks
          .map((block, index) => {
            const exerciseNames = block.exercises.map(exercise => exercise.title).join('\n');
            return `Block ${index + 1} (${block.repetitions}x):\n${exerciseNames}`;
          })
          .join('\n\n');

        if (this.currentPlannedWorkout?._id) {
          // Update existing workout
          await axios.post(`${this.$host}/workout/${this.currentPlannedWorkout._id}`, workoutData);
        } else {
          // Create new workout
          await axios.post(`${this.$host}/workout`, workoutData);
        }
        this.clearWorkoutState();

        this.setScrollLock(false);

        this.showWorkoutCompleteModal = false;
        this.$router.push('/training/kalender');
      } catch (error) {
        console.error('Error saving workout:', error);
        this.showError = true;
        this.errorMessage = 'Fehler beim Speichern des Workouts';
      }
    },
  },

  watch: {
    workoutMode: {
      async handler(newMode) {
        if (newMode === "videos") {
          this.loaded = false;
          await this.loadPage();
          this.user = (await axios.get(this.$host + "/user/me")).data;
        }
      },
    },
    // Add watchers for duration settings
    breakDuration(newVal) {
      // Ensure it's a number and positive
      this.breakDuration = Math.max(0, parseInt(newVal) || 0);
    },
    shortBreakDuration(newVal) {
      // Ensure it's a number and positive
      this.shortBreakDuration = Math.max(0, parseInt(newVal) || 0);
    },
    exerciseDuration(newVal) {
      // Ensure it's a number and positive
      this.exerciseDuration = Math.max(0, parseInt(newVal) || 0);
    },
    showVideoPreviewModal(newVal) {
      this.setScrollLock(newVal);
    },
    showSaveBlueprintModal(newVal) {
      this.setScrollLock(newVal);
    },
    showBlueprintPreviewModal(newVal) {
      this.setScrollLock(newVal);
    },
    showGenerateWorkoutModal(newVal) {
      this.setScrollLock(newVal);
    },
    showExercisePicker(newVal) {
      this.setScrollLock(newVal);
    },
    showCategoryFilterModal(newVal) {
      this.setScrollLock(newVal);
    },
    showMuscleGroupFilterModal(newVal) {
      this.setScrollLock(newVal);
    },
    workoutStarted(newVal) {
      if (newVal) {
        this.saveWorkoutState();
      }
    },
    currentBlockIndex() {
      if (this.workoutStarted) {
        this.saveWorkoutState();
      }
    },
    currentExerciseIndex() {
      if (this.workoutStarted) {
        this.saveWorkoutState();
      }
    },
    currentTimer() {
      if (this.workoutStarted) {
        this.saveWorkoutState();
      }
    },
  },

  beforeDestroy() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }

    const video = this.$refs.videoPlayer;
    if (video) {
      video.removeEventListener('play', this.handleVideoPlay);
      video.removeEventListener('pause', this.handleVideoPause);
    }

    document.removeEventListener("fullscreenchange", this.handleFullscreenChange);
    document.removeEventListener("webkitfullscreenchange", this.handleFullscreenChange);
    document.removeEventListener("mozfullscreenchange", this.handleFullscreenChange);
    document.removeEventListener("MSFullscreenChange", this.handleFullscreenChange);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);

    // Clean up the event listener
    window.removeEventListener('resize', this.checkMobile);

    // Remove event listener
    document.removeEventListener('keydown', this.handleKeyPress);
    
    // Don't reset active workout state if workout is still running
    if (!this.workoutStarted) {
      this.$parent.setActiveKraftraumWorkout(false);
    }
    if (this.workoutStarted) {
      this.saveWorkoutState();
    }
  },

  mounted: async function () {
    this.user = (await axios.get(this.$host + "/user/me")).data;

    // Initialize tooltips
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });

    document.addEventListener("fullscreenchange", this.handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", this.handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", this.handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", this.handleFullscreenChange);

    const video = this.$refs.videoPlayer;
    if (video) {
      video.addEventListener('play', this.handleVideoPlay);
      video.addEventListener('pause', this.handleVideoPause);
    }

    // Add event listener for escape key
    document.addEventListener('keydown', this.handleKeyPress);
    this.loadWorkoutState(); // Load state when component mounts
    await this.loadPlannedWorkouts();
  },
};
</script>

<style scoped>
.video-container {
  position: relative;
  background: #000;
  max-width: 1600px; /* Prevent video from becoming too large */
  margin: 0 auto;
}

.btn-outline-secondary:hover {
  color: #000!important;
}

.exercise-thumbnail img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.toast {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.toast.show {
  opacity: 1;
}

/* Custom transitions for elements */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Custom styling for the video overlay */
.video-overlay {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7) 0%,
    transparent 30%,
    transparent 70%,
    rgba(0, 0, 0, 0.7) 100%
  );
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .btn-group {
    flex-direction: column;
  }

  .btn-group .btn {
    margin-bottom: 0.5rem;
  }
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.show {
  display: block;
}

.nav-tabs .nav-link {
  cursor: pointer;
}

.modal-backdrop {
  z-index: 1040;
}

.modal {
  z-index: 1050;
}

.tab-content {
  border: 1px solid #dee2e6;
  border-top: none;
  margin-top: -1px;
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link {
  padding: 1rem 2rem;
  background: #f8f9fa;
  border: 2px solid #f8f9fa!important;
}


.nav-tabs .nav-link.active {
  color:#0b5ed7;
  border: 2px solid #0b5ed7!important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

/* Improve card styling */
.card {
  border: none;
  border-radius: 0.75rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
  height: 100%; /* Ensure all cards have same height */
}

/*
.card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}*/

/* Improve button group spacing */
.btn-group .btn + .btn {
  margin-left: 0.5rem;
}

.countdown {
  font-size: 3rem;
  font-weight: bold;
  color: #0d6efd;
}

.list-group-item.active {
  background-color: #e9ecef;
  border-color: #dee2e6;
  color: inherit;
}

.list-group-item.active small {
  color: #6c757d;
}

@media (max-width: 991.98px) {
  .row > .col-md-4.col-lg-12 {
    width: 33.333333%;
  }
}

/* Adjust card margins for better spacing */
.card {
  border: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  transition: transform 0.2s ease-in-out;
  margin-bottom: 1rem;
}

/* Add this to fix the col-md-8 width issue */
.row .col-md-8 {
  width: calc(100% - 300px);
  flex: 0 0 auto;
}

@media (max-width: 768px) {
  .row .col-md-8 {
    width: 100%;
  }
}

/* Add some max-width to the setup section for better readability on very wide screens */
.setup-section {
  /* max-width: 1800px; <- remove this */
  margin: 0 auto;
  padding-bottom: 100px;
}

/* Adjust tab content padding for larger screens */
@media (min-width: 1200px) {
  .tab-content {
    padding: 0.5rem !important;
  }
}

/* Add max-width for non-workout view */
.container {
  max-width: 1200px !important;
}

.break-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 10;
}

.next-exercise-preview {
  text-align: center;
}

.next-exercise-preview img {
  width: 100%;
  object-fit: contain;
}

.display-1 {
  font-size: 6rem;
  font-weight: bold;
}

.progress-bar {
  transition: width 16ms linear;
}

/* Update the video container styles */
.video-aspect-ratio {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background: #000;
  margin: 0; /* Remove any margin */
  overflow: hidden;
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1; /* Add explicit z-index */
}

/* Update break container styles */
.break-container {
  position: absolute;
  inset: 0; /* Shorthand for top/right/bottom/left: 0 */
  background: rgba(0,0,0,0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  .video-aspect-ratio {
    /* Remove the safe area adjustment */
    padding-top: 56.25%;
  }

  .break-container {
    /* Remove transforms and positioning adjustments */
    position: absolute;
    inset: 0;
  }
}

/* Rest of the styles remain the same */
.break-content {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.next-exercise-preview {
  position: absolute;
  inset: 0;
  z-index: 1;
}

.next-exercise-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.break-content-center {
  position: relative;
  z-index: 3; /* Ensure this is above the gradient overlay */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
}

/* Style the timer and text */
.break-content .display-1 {
  font-size: clamp(4rem, 15vw, 8rem);
  font-weight: 700;
  color: white;
  text-shadow: 0 4px 12px rgba(0,0,0,0.5);
  margin: 0;
  line-height: 1;
  z-index: 3;
}

.next-exercise-preview h3 {
  font-size: clamp(1rem, 4vw, 1.5rem);
  font-weight: 500;
  color: rgba(255,255,255,0.9);
  margin: 1rem 0 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 3;
}

.next-exercise-preview h4 {
  font-size: clamp(1.25rem, 5vw, 2rem);
  font-weight: 600;
  color: white;
  margin: 0;
  padding: 0 1rem;
  text-align: center;
  max-width: 800px;
  z-index: 3;
}

/* Media queries for different screen sizes */
@media (max-height: 600px) {
  .break-content {
    padding: 0.5rem;
  }

  .next-exercise-preview {
    max-width: 200px;
  }

  .break-content .display-1 {
    margin-bottom: 0.25rem;
  }

  .next-exercise-preview h3 {
    margin-bottom: 0.25rem;
  }
}

@media (max-height: 400px) {
  .break-content {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 0.25rem;
  }

  .next-exercise-preview {
    max-width: 150px;
    margin: 0;
  }

  .break-content .display-1 {
    margin-bottom: 0;
  }
}

.logo-overlay {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 20;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.logo-overlay:hover {
  opacity: 1;
}

.logo-overlay img {
  width: auto;
  height: 8%;
  min-height: 30px;
  max-height: 60px;
  object-fit: contain;
}

/* Make logo smaller on mobile */
@media (max-width: 768px) {
  .logo-overlay {
    top: 10px;
    left: 10px;
  }

  .logo-overlay img {
    max-height: 40px;
  }
}

.border-dashed {
  border: 2px dashed #dee2e6 !important;
  background-color: #f8f9fa;
  display: flex;
  align-items: stretch; /* Make card stretch to match others */
}

.border-dashed:hover {
  border-color: #0d6efd !important;
}

.border-dashed .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: calc(75px + 1.5rem) 1.5rem; /* Reduced from 100px to match new image height */
}

.btn-group-sm .btn {
  padding: 0.25rem 0.5rem;
}

.card-header {
  background: #fff;
}

.exercise-item {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.exercise-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.exercise-menu {
  animation: slideDown 0.2s ease;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.btn i {
  margin-right: 0.5rem;
}

.exercise-item img {
  margin-right: 1rem;
}

.list-group button img {
  margin-right: 1rem;
}

.card-body .btn i {
  margin-right: 0;
}

.d-flex.justify-content-center.gap-1 {
  gap: 0.5rem !important;
}

/* Füge diese Styles zur style-Sektion hinzu */
.custom-tabs {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping */
  gap: 0.5rem;
  padding: 0.75rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
  position: relative;
  z-index: 1;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */
}

.custom-tabs::before,
.custom-tabs::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40px;
  pointer-events: none;
  z-index: 2;
}

.custom-tabs::after {
  right: 0;
  background: linear-gradient(to left, white, rgba(255,255,255,0));
}

/* Hide scrollbar but keep functionality */
.custom-tabs::-webkit-scrollbar {
  display: none;
}

.custom-tabs .nav-item {
  flex: 0 0 auto; /* Prevent shrinking */
  min-width: fit-content; /* Ensure content width */
}

.custom-tabs .nav-link {
  white-space: nowrap;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 0.75rem;
  font-weight: 500;
  color: #6c757d;
  background: #f8f9fa;
  transition: all 0.2s ease;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.custom-tabs .nav-link i {
  font-size: 1.1rem;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .custom-tabs {
    padding: 0.625rem;
    gap: 0.375rem;
  }

  .custom-tabs .nav-link {
    font-size: 0.9rem;
  }

  /* Add subtle animation to gradient to indicate scrollability */
  .custom-tabs::after {
    animation: pulseGradient 2s infinite;
  }
}

/* Add subtle gradient pulse animation */
@keyframes pulseGradient {
  0% { opacity: 0.7; }
  50% { opacity: 1; }
  100% { opacity: 0.7; }
}

/* Updated Button Styling */
.btn {
  padding: 0.75rem 1.5rem;
  border-radius: 0.75rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.btn-outline-primary {
  border: 1px solid #0d6efd;
}

.btn-success {
  background: #198754;
  box-shadow: 0 2px 8px rgba(25, 135, 84, 0.25);
}

.btn-success:hover {
  background: #157347;
  box-shadow: 0 4px 12px rgba(25, 135, 84, 0.35);
}

/* Card Styling */
.card {
  border: none;
  border-radius: 0.75rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

/*
.card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
  */

.card-header {
  border-radius: 0.75rem 0.75rem 0 0 !important;
  border-bottom: 1px solid #f0f0f0;
  padding: 1rem 1.5rem;
}

.card-body {
  padding: 1.5rem;
}

.btn {
  min-width: 24px;
}

/* Form Controls */
.form-select,
.form-control {
  border-radius: 0.75rem;
  padding: 0.75rem 1rem;
  border: 2px solid #e9ecef;
  transition: all 0.2s ease;
}

.form-select:focus,
.form-control:focus {
  border-color: #0d6efd;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

/* Action Buttons Group */
.d-flex.align-items-center .btn {
  margin-left: 0.75rem;
}

/* Tab Content */
.tab-content {
  border-radius: 1rem;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: 2rem !important;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .custom-tabs .nav-link {
    padding: 0.75rem 1.25rem;
  }

  .btn {
    padding: 0.5rem 1rem;
  }

  .card-body {
    padding: 1rem;
  }



  .d-flex.align-items-center {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .d-flex.align-items-center .btn {
    margin-left: 0;
    width: 100% !important;
  }
}

/* Delete Button Styling */
.delete {
  background: #dc3545;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 0.75rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.delete:hover:not(:disabled) {
  background: #bb2d3b;
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(220, 53, 69, 0.25);
}

.delete:disabled {
  background: #e9ecef;
  color: #6c757d;
  cursor: not-allowed;
}

/* Exercise Card Specific Styling */
.exercise-section .card {
  height: 100%;
}

.exercise-section .card-img-top {
  border-radius: 0.75rem 0.75rem 0 0;
  object-fit: cover;
  height: 150px; /* Reduced from 200px */
}

/* Switch Styling */
.form-check-input {
  width: 3rem;
  height: 1.5rem;
  cursor: pointer;
}

.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

/* Container Max Width */
.container {
  max-width: 1400px !important;
}

/* Footer Consistency */
.workout-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 1.25rem;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 900;
  /* Add safe area padding for mobile devices */
  padding-bottom: calc(1.25rem + env(safe-area-inset-bottom));
}

.workout-footer .container-fluid {
  padding: 0 0.75rem;
}

.workout-footer .time-value {
  font-size: 2rem;
  font-weight: 600;
  color: #0d6efd;
}

.start-workout-btn {
  min-width: 220px;
  padding: 1rem 2rem;
}

/* Duration Picker Styling */
.duration-picker {
  display: flex;
  align-items: center;
  gap: 1rem;
  min-width: 200px;
}

.duration-picker .form-label {
  margin-bottom: 0;
  white-space: nowrap;
  color: #6c757d;
  font-weight: 500;
}

.duration-picker .form-select {
  min-width: 140px;
  text-align: center;
  text-align-last: center;
  padding-right: 2rem;
  cursor: pointer;
  font-weight: 500;
}

/* Fix for Firefox */
.duration-picker .form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

/* Button Group Spacing */
.d-flex.align-items-center.gap-3 {
  gap: 1rem !important;
}

.d-flex.align-items-center.gap-3 .btn {
  min-width: 160px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .d-flex.align-items-center.gap-3 {
    flex-wrap: wrap;
    gap: 0.75rem !important;
  }

  .duration-picker {
    width: 100%;
    justify-content: space-between;
  }

  .duration-picker .form-select {
    flex: 1;
    min-width: auto;
  }

  .d-flex.align-items-center.gap-3 .btn {
    width: 100%;
  }
}

/* Icon Button Styling */
.icon-button {
  width: 32px !important;
  height: 32px !important;
  padding: 0 8px !important; /* Added horizontal padding */
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button i {
  font-size: 1rem;
  margin: 0;
}

/* Remove padding from form-select */
.form-select {
  padding: 0 2rem 0 1rem;
  height: 38px;
}

select {
  font-size: 13px;
}

/* Exercise Card Layout */
.draggable .row {
  display: flex;
  flex-wrap: wrap;
}

.draggable .col-md-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

@media (max-width: 768px) {
  .draggable .col-md-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 576px) {
  .draggable .col-md-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Responsive Footer Adjustments */
@media (max-width: 768px) {
  .workout-footer {
    padding: 1rem;
    padding-bottom: calc(1rem + env(safe-area-inset-bottom));
  }

  .workout-footer .time-value {
    font-size: 1.75rem;
  }

  .workout-footer .start-workout-btn {
    padding: 0.75rem 1.5rem;
  }
}

@media (max-width: 576px) {
  .workout-footer .container-fluid {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .workout-footer .time-value {
    font-size: 1.5rem;
  }

  .workout-footer .start-workout-btn {
    padding: 0.5rem 1rem;
    min-width: 160px;
  }
}

/* Search Input Group Styling */
.input-group {
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.input-group-text {
  background: white;
  border: 2px solid #e9ecef;
  border-right: none;
  padding: 0.75rem;
}

.input-group .form-control {
  border: 2px solid #e9ecef;
  border-left: none;
  border-right: none;
  padding: 0.75rem 1rem;
}

.input-group .form-control:focus {
  border-color: #0d6efd;
  box-shadow: none;
}

.input-group .form-control:focus + .clear-button {
  border-color: #0d6efd;
}

.clear-button {
  width: 42px !important;
  min-width: 42px !important;
  flex: 0 0 42px !important;
  height: 42px;
  padding: 0 !important;
  border: 2px solid #e9ecef;
  border-left: none;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.clear-button:hover {
  background: #f8f9fa;
  color: #dc3545;
}

.clear-button i {
  font-size: 1.25rem;
  margin: 0;
}

/* When input is focused, style the whole input group */
.input-group:focus-within {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.input-group:focus-within .input-group-text,
.input-group:focus-within .form-control,
.input-group:focus-within .clear-button {
  border-color: #0d6efd;
}

/* Add this to your existing styles */
.row.g-4 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.col-md-4.col-lg-3 {
  justify-content: center;
}

/* Update card and image heights */
.card-img-top {
  height: 150px;
  object-fit: cover;
}

.border-dashed .card-body {
  padding: calc(60px + 1.5rem) 1.5rem; /* Adjusted to match new image height */
}

/* Style the icon buttons */
.icon-button {
  width: 32px !important;
  height: 32px !important;
  padding: 0 8px !important; /* Added horizontal padding */
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button i {
  font-size: 1rem;
  margin: 0;
}

/* Add margin between buttons in the flex container */
.d-flex.gap-2 {
  gap: 8px !important; /* Increased gap between buttons */
}

/* Adjust card body padding */
.card-body {
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-text {
  margin-bottom: 0.5rem;
}

/* Add these styles to the existing <style> section */

/* Exercise Picker Modal Styling */
.modal-dialog-scrollable .modal-content {
  border-radius: 1rem;
  border: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.modal-dialog-scrollable .modal-header {
  padding: 1.25rem 1.5rem;
  background: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  border-radius: 1rem 1rem 0 0;
}

.modal-dialog-scrollable .modal-body {
  padding: 0 1.5rem 1.5rem 1.5rem !important; /* Remove top padding */
}

/* Add spacing between exercise cards in the modal */
.modal-dialog-scrollable .row.g-3 {
  --bs-gutter-y: 1.5rem !important; /* Added !important */
  --bs-gutter-x: 1rem !important; /* Added !important */
  margin-top: calc(-1 * var(--bs-gutter-y)); /* Add negative margin to counter first row spacing */
  margin-bottom: 1rem; /* Add bottom margin to the row */
}

/* Add spacing to columns */
.modal-dialog-scrollable .col-md-4 {
  padding-top: calc(var(--bs-gutter-y) * 0.5);
  padding-bottom: calc(var(--bs-gutter-y) * 0.5);
}

/* Style exercise cards in the modal */
.modal-dialog-scrollable .card {
  margin-bottom: 0; /* Remove any default card margin */
  height: 100%;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.modal-dialog-scrollable .card:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

/* Style card images in the modal */
.modal-dialog-scrollable .card-img-top {
  height: 160px;
  object-fit: cover;
  border-radius: 0.75rem 0.75rem 0 0;
}

/* Style card text in the modal */
.modal-dialog-scrollable .card-body {
  padding: 1rem;
  text-align: center;
}

.modal-dialog-scrollable .card-text {
  font-size: 0.95rem;
  font-weight: 500;
  color: #495057;
  margin: 0;
  line-height: 1.4;
}

/* Style the search input in the modal */
.modal-dialog-scrollable .input-group {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
  padding: 1rem 0;
  margin: 0 -1.5rem 1rem -1.5rem; /* Remove negative top margin since we removed the padding */
  border-bottom: 1px solid #e9ecef;
}

.modal-dialog-scrollable .form-control {
  height: 48px;
  font-size: 1rem;
}

/* Responsive adjustments for the modal */
@media (max-width: 768px) {
  .modal-dialog-scrollable .row.g-3 {
    --bs-gutter-y: 1rem;
    --bs-gutter-x: 0.75rem;
  }

  .modal-dialog-scrollable .card-img-top {
    height: 140px;
  }

  .modal-dialog-scrollable .card-body {
    padding: 0.75rem;
  }

  .modal-dialog-scrollable .card-text {
    font-size: 0.9rem;
  }
}

/* Add these styles to the existing <style> section */

/* Block Spacing */
.row.g-3 {
  --bs-gutter-y: 1.5rem !important;
  --bs-gutter-x: 1rem !important;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-bottom: 1rem;
}

/* Add spacing to block columns */
.col-12 {
  padding-top: calc(var(--bs-gutter-y) * 0.5);
  padding-bottom: calc(var(--bs-gutter-y) * 0.5);
}

/* Ensure blocks have proper spacing */
.col-12 .card {
  margin-bottom: 0;
  height: 100%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .row.g-3 {
    --bs-gutter-y: 1rem !important;
    --bs-gutter-x: 0.75rem !important;
  }
}

.search-container {
  position: sticky;
  top: 0;
  background: white;
  padding: 1rem;
  margin: 0 -1.5rem 1rem -1.5rem; /* Remove negative top margin since we removed the padding */
  border-bottom: 1px solid #dee2e6;
  z-index: 1020;
}

.search-container > div {
  position: relative;
  max-width: 100%;
}

.search-container .clear-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border: none;
  background: transparent;
}

.search-container .form-control {
  padding-right: 40px;
}

.blueprint-info {
  background: #f8f9fa;
  border-radius: 0.75rem;
  padding: 1.5rem;
}

.info-card {
  text-align: center;
  padding: 1rem;
  background: white;
  border-radius: 0.75rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.info-card i {
  font-size: 1.5rem;
  color: #0d6efd;
  margin-bottom: 0.5rem;
}

.info-card h6 {
  margin-bottom: 0.5rem;
  color: #6c757d;
  font-size: 0.875rem;
}

.info-card p {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 500;
  color: #212529;
}

.preview-blocks .block-title {
  color: #495057;
  font-weight: 500;
  padding: 0.5rem 0;
  border-bottom: 2px solid #e9ecef;
}

.preview-block {
  background: white;
  border-radius: 0.75rem;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem; /* Add spacing between blocks */
}

.preview-block .card {
  cursor: default; /* Remove pointer cursor */
}

.preview-block .card:hover {
  transform: none; /* Remove hover effect */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05); /* Keep original shadow */
}

.preview-block .block-title {
  color: #495057;
  font-weight: 500;
  padding: 0.5rem 0;
  border-bottom: 2px solid #e9ecef;
  margin-bottom: 1.5rem; /* Add more space below title */
}

@media (max-width: 768px) {
  .info-card {
    margin-bottom: 1rem;
  }

  .preview-block {
    padding: 1rem;
  }
}

.card-text .bi-clock {
  color: #0d6efd;
  margin-right: 0.5rem;
}

.info-card {
  margin-bottom: 1rem; /* Add margin to handle wrapping */
}

@media (min-width: 768px) {
  .info-card {
    margin-bottom: 0; /* Remove margin on larger screens */
  }
}

.preview-block {
  background: white;
  border-radius: 0.75rem;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.preview-block .block-title {
  position: relative;
  z-index: 2;
  background: white;
  margin: -1.5rem -1.5rem 2rem -1.5rem; /* Increased bottom margin from 1.5rem to 2rem */
  padding: 1.5rem;
  border-bottom: 2px solid #e9ecef;
}

.preview-block .card {
  cursor: default !important;
  pointer-events: none;
}

.preview-block .card:hover {
  transform: none !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05) !important;
}

.total-duration {
  color: #6c757d;
  font-weight: 500;
}

.total-duration i {
  color: #0d6efd;
  margin-right: 0.5rem;
}

/* Update modal footer styles */
.modal-footer {
  border-top: 1px solid #dee2e6;
  padding: 1rem 1.5rem;
}

@media (max-width: 768px) {
  .preview-block .block-title {
    margin: -1rem -1rem 1.5rem -1rem; /* Adjusted for mobile but still keeping more space at bottom */
    padding: 1rem;
  }

  .modal-footer {
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;
  }

  .modal-footer > div:last-child {
    display: flex;
    gap: 0.5rem;
  }

  .modal-footer > div:last-child .btn {
    flex: 1;
  }
}

/* Add spacing to the row of cards */
.preview-block .row.g-3 {
  margin-top: 1rem !important; /* Add top margin to the row of cards */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .preview-block .block-title {
    margin: -1rem -1rem 1.5rem -1rem; /* Adjusted for mobile but still keeping more space at bottom */
    padding: 1rem;
  }
}

/* Update the modal footer button spacing */
.modal-footer .btn-secondary {
  margin-right: 0.75rem; /* Add margin to the right of the Schließen button */
}

/* Add this to ensure proper spacing even in mobile view */
@media (max-width: 768px) {
  .modal-footer > div:last-child .btn:first-child {
    margin-right: 0.75rem;
  }
}

/* Template Card Styling */
.template-card {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: none;
  background: white;
  margin-bottom: 1.5rem; /* Add vertical gap between cards */
}

.template-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.template-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.template-header .card-title {
  margin: 0;
  font-weight: 600;
  color: #2c3e50;
}

.template-header .badge {
  padding: 0.5rem 1rem;
  font-weight: 500;
  font-size: 0.875rem;
  color: white; /* Add this line to make the text white */
}

.template-stats {
  background: #f8f9fa;
  border-radius: 0.75rem;
  padding: 1.25rem;
}

.stat-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  color: #495057;
}

.stat-item:last-child {
  margin-bottom: 0;
}

.stat-item i {
  width: 24px;
  color: #0d6efd;
  margin-right: 0.75rem;
  font-size: 1rem;
}

.stat-item span {
  font-size: 0.95rem;
  font-weight: 500;
}

.template-actions {
  display: flex;
  gap: 0.75rem;
}

.template-actions .btn {
  flex: 1;
  padding: 0.625rem 1rem;
}

.template-actions .btn-outline-danger {
  flex: 0 0 auto;
  width: 42px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media (max-width: 980px) {
  .card {
    margin:-15px!important;
    margin-top:0!important;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .template-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
  }

  

  .template-stats {
    padding: 1rem;
  }

  .stat-item {
    margin-bottom: 0.5rem;
  }

  .template-actions {
    flex-wrap: wrap;
  }

  .template-actions .btn {
    width: 100%;
  }

  .template-actions .btn-outline-danger {
    width: 100%;
    padding: 0.625rem 1rem;
  }
}

/* Block Card Styling */
.block-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  padding: 1.25rem 1.5rem;
  border-radius: 0.75rem 0.75rem 0 0;
}

.block-header h6 {
  font-weight: 600;
  color: #2c3e50;
  margin: 0;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
}

.block-header h6::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #0d6efd;
  border-radius: 50%;
  margin-right: 0.75rem;
}

.block-header .badge {
  padding: 0.6rem 1rem;
  font-weight: 500;
  font-size: 0.875rem;
  color: white;
  border-radius: 2rem;
  background: linear-gradient(45deg, #0d6efd, #0a58ca);
  box-shadow: 0 2px 4px rgba(13, 110, 253, 0.2);
}

/* Add this to improve the overall block card styling */
.col-12 .card {
  border: none;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.2s ease;
}

.col-12 .card:hover {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .block-header {
    padding: 1rem 1.25rem;
  }

  .block-header h6 {
    font-size: 1rem;
  }

  .block-header h6::before {
    width: 6px;
    height: 6px;
    margin-right: 0.5rem;
  }

  .block-header .badge {
    padding: 0.5rem 0.875rem;
    font-size: 0.8125rem;
  }
}

/* Exercise Card Styling within Blocks */
.draggable .card {
  border: none;
  border-radius: 0.75rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  height: 100%;
}

.draggable .card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.draggable .card-img-top {
  height: 160px;
  object-fit: cover;
  border-radius: 0.75rem 0.75rem 0 0;
}

.draggable .card-body {
  padding: 1rem;
}

.draggable .card-text {
  font-size: 0.95rem;
  font-weight: 500;
  color: #495057;
  margin-bottom: 1rem;
}

/* Add Exercise Card Styling */
.border-dashed {
  border: 2px dashed #dee2e6;
  background: #f8f9fa;
  transition: all 0.2s ease;
}

.border-dashed:hover {
  border-color: #0d6efd;
  background: #f0f7ff;
}

.border-dashed .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 160px;
}

.border-dashed .btn-outline-primary {
  border-width: 2px;
  font-weight: 500;
}

/* Block Container Styling */
.col-12 {
  margin-bottom: 1.5rem;
}

.col-12:last-child {
  margin-bottom: 0;
}

/* Action Buttons Styling */
.d-flex.gap-2 {
  margin-top: auto;
}

.icon-button {
  width: 36px !important;
  height: 36px !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
}

.icon-button i {
  font-size: 1.1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .block-header {
    padding: 0.75rem 1rem;
  }

  .draggable .card-img-top {
    height: 140px;
  }

  .border-dashed .card-body {
    min-height: 140px;
  }

  .icon-button {
    width: 32px !important;
    height: 32px !important;
  }
}

/* Drag and Drop Visual Feedback */
.sortable-ghost {
  opacity: 0.5;
}

.sortable-chosen {
  background: #fff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

/* Add Block Button Styling */
.mt-4.text-center .btn {
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  margin: 0 0.5rem;
}

.mt-4.text-center .btn i {
  margin-right: 0.5rem;
}

.break-item {
  padding: 0.75rem 0;
  color: #6c757d;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.break-item h6 {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.break-item small {
  display: block;
  text-align: center;
}

.list-group-item.active .break-item {
  color: inherit;
}

/* Remove the old break-icon class since we're not using it anymore */

/* Add these styles to the existing <style> section */

/* Video Player Column Styling */
.col-lg-9 {
  display: flex;
  flex-direction: column;
}

.col-lg-9 .card {
  height: fit-content; /* Ensure card only takes necessary height */
}

/* Upcoming Exercises Column Styling */
.col-lg-3 {
  display: flex;
  flex-direction: column;
}

.col-lg-3 .card {
  flex: 1;
  display: flex;
  flex-direction: column;
}
/* Add custom scrollbar styling */
.col-lg-3 .card .list-group::-webkit-scrollbar {
  width: 6px;
}

.col-lg-3 .card .list-group::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.col-lg-3 .card .list-group::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}

.col-lg-3 .card .list-group::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* Ensure the video container maintains aspect ratio */
.video-aspect-ratio {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background: #000;
  margin: 0;
  overflow: hidden;
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Video container and break overlay */
.video-aspect-ratio {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Keep 16:9 ratio */
  background: #000;
  margin: 0;
  overflow: hidden;
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.break-container {
  position: absolute;
  inset: 0; /* Shorthand for top/right/bottom/left: 0 */
  background: rgba(0,0,0,0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.break-content {
  position: relative; /* Change from absolute to relative */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.next-exercise-preview {
  width: 100%;
  height: 100%;
  position: relative;
  inset: 0;
  z-index: 1;
  overflow: hidden; /* Add this */
}

.next-exercise-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.break-content-center {
  position: relative;
  z-index: 3;
  width: 100%;
  text-align: center;
  padding: 2rem;
  background: linear-gradient(0deg, 
    rgba(0,0,0,0.8) 0%,
    rgba(0,0,0,0.6) 30%,
    rgba(0,0,0,0.4) 60%,
    rgba(0,0,0,0.6) 100%
  );
}

/* iOS specific fixes */
@supports (-webkit-touch-callout: none) {
  .break-container {
    transform: translate3d(0,0,0);
    -webkit-backface-visibility: hidden;
  }

  .next-exercise-preview {
    transform: translate3d(0,0,0);
    -webkit-backface-visibility: hidden;
  }
}

/* Add responsive adjustments */
@media (max-width: 991.98px) {
  .col-lg-3 {
    max-height: 50vh; /* Smaller height on mobile */
    margin-top: 1rem;
  }
}

/* Video controls section */
.card-body {
  padding: 1.5rem;
}

/* Progress bar and controls wrapper */
.d-flex.align-items-center.mt-3.mb-4 {
  margin: 0 !important;
  padding: 0.5rem 0;
}

/* Exit button container */
.d-flex.justify-content-between.align-items-center.mb-4 {
  margin-bottom: 0 !important;
  padding: 1rem 0;
}

/* Responsive adjustments */
@media (max-width: 991.98px) {
  .col-lg-9 .card {
    margin-bottom: 1rem;
  }
}

/* Template Cards Grid Spacing */
.row.g-4 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem 0.5rem !important; /* 2rem vertical gap, 0.5rem horizontal gap */
}

/* Template Card Column Sizing */
.row.g-4 > .col-md-6.col-lg-4 {
  flex: 0 0 calc(33.333% - 1rem);
  max-width: calc(33.333% - 1rem);
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .row.g-4 > .col-md-6.col-lg-4 {
    flex: 0 0 calc(50% - 1rem);
    max-width: calc(50% - 1rem);
  }
}

@media (max-width: 768px) {
  .row.g-4 > .col-md-6.col-lg-4 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Add these styles to the existing <style> section */

/* Sidebar Styling */
.col-md-3 {
  height: calc(100vh - 180px); /* Increased height */
  position: sticky;
  top: 100px;
  padding-bottom: 20px;
}

/* Main content area */
.col-md-9 {
  padding-bottom: 100px; /* Add padding to prevent footer overlap */
}

/* Container adjustments */
.container {
  max-width: 1600px !important; /* Increased from 1200px */
}

/* Workout blocks layout */
.row.g-3 {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

/* Exercise cards within blocks */
.draggable .col-md-6.col-lg-3 {
  padding: 0.5rem;
}

/* Template cards layout */
.row.g-4 > .col-md-6.col-lg-4 {
  flex: 0 0 calc(33.333% - 1rem);
  max-width: calc(33.333% - 1rem);
  margin-bottom: 1rem;
}

/* Tab content padding */
.tab-content {
  padding: 1.5rem !important;
}

/* Responsive adjustments */
@media (min-width: 1400px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .row.g-4 > .col-md-6.col-lg-4 {
    flex: 0 0 calc(25% - 1rem); /* 4 cards per row on very large screens */
    max-width: calc(25% - 1rem);
  }
}

@media (max-width: 768px) {
  .col-md-9 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

/* Hide scrollbar but keep functionality */
.col-md-3::-webkit-scrollbar {
  width: 6px;
}

.col-md-3::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.col-md-3::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}

.col-md-3::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* Hide sidebar on mobile */
@media (max-width: 768px) {
  .col-md-3 {
    display: none;
  }

  /* Make main content full width on mobile */
  .col-md-9 {
    width: 100%;
  }
}

/* Optimize footer spacing */
.workout-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 0.75rem;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1030;
  padding-bottom: calc(0.75rem + env(safe-area-inset-bottom));
}

.workout-footer .container-fluid {
  padding: 0 0.75rem;
}

.workout-footer .d-flex {
  align-items: center;
  gap: 1rem;
}

.workout-footer .workout-time {
  flex-shrink: 0;
}

.workout-footer .time-value {
  font-size: 1.75rem;
  white-space: nowrap;
}

.start-workout-btn {
  min-width: 200px;
  padding: 0.75rem 1.5rem;
  white-space: nowrap;
}

/* Responsive adjustments for footer */
@media (max-width: 768px) {
  .workout-footer {
    padding: 0.5rem;
    padding-bottom: calc(0.5rem + env(safe-area-inset-bottom));
  }

  .workout-footer .container-fluid {
    padding: 0 0.5rem;
  }

  .workout-footer .d-flex {
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
  }

  .workout-footer .workout-time {
    width: 100%;
    text-align: center;
  }

  .workout-footer .time-value {
    font-size: 1.5rem;
  }

  .start-workout-btn {
    width: 100%;
    min-width: unset;
    padding: 0.5rem 1rem;
  }
}

/* Additional adjustments for very small devices */
@media (max-width: 576px) {
  .workout-footer {
    padding: 0.5rem;
  }

  .workout-footer .time-value {
    font-size: 1.25rem;
  }

  .start-workout-btn {
    padding: 0.5rem;
  }
}

/* Update exercise card image styling */
.card-img-top {
  height: 150px;
  object-fit: cover;
  border-radius: 0.75rem 0.75rem 0 0;
}

/* Responsive image sizes */
@media (max-width: 768px) {
  .card-img-top {
    height: 120px; /* Smaller height on mobile */
  }
  
  /* Adjust exercise cards in blocks */
  .draggable .card-img-top {
    height: 100px;
  }

  /* Adjust "Add Exercise" placeholder height */
  .border-dashed .card-body {
    padding: calc(35px + 1rem) 1rem; /* Reduced padding to match smaller images */
  }

}

/* Even smaller images on very small devices */
@media (max-width: 576px) {
  .card-img-top {
    height: 100px;
  }

  .draggable .card-img-top {
    height: 80px;
  }

  .border-dashed .card-body {
    padding: calc(25px + 1rem) 1rem;
  }

}

/* Add these styles to the existing <style> section */

/* Sidebar search container */
.col-md-3 .mb-3 {
  position: sticky;
  top: 0;
  background: white;
  padding: 1rem 0;
  margin: 0 !important;
  z-index: 10;
}

/* Adjust the list-group to account for the sticky search */
.col-md-3 .list-group {
  margin-top: 1rem;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

/* Style the search input */
.col-md-3 input[type="text"] {
  border: 2px solid #e9ecef;
  border-radius: 0.75rem;
  padding: 0.75rem 1rem;
  transition: all 0.2s ease;
}

.col-md-3 input[type="text"]:focus {
  border-color: #0d6efd;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  outline: none;
}

/* Custom scrollbar for the exercise list */
.col-md-3 .list-group::-webkit-scrollbar {
  width: 6px;
}

.col-md-3 .list-group::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.col-md-3 .list-group::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}

.col-md-3 .list-group::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* Add these styles to the existing <style> section */

/* Exercise Grid Layout */
.exercise-section .row.g-4 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); /* Increased from 240px to 280px */
  gap: 1.5rem;
  padding: 1rem;
}

/* Add max-width to cards to prevent them from becoming too wide */
.exercise-section .card {
  max-width: 400px; /* Add max-width */
  width: 100%;
  margin: 0 auto; /* Center cards in their grid cells */
  /* ... rest of the card styles ... */
}

/* Responsive Adjustments */
@media (max-width: 1400px) {
  .exercise-section .row.g-4 {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
}

@media (max-width: 768px) {
  .exercise-section .row.g-4 {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    padding: 0.75rem;
  }
}

/* Exercise Card Styling */
.exercise-section .card {
  border: none;
  border-radius: 1rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.2s ease;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.exercise-section .card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

/* Exercise Image */
.exercise-section .card-img-top {
  height: 200px;
  object-fit: cover;
  border-radius: 1rem 1rem 0 0;
}

/* Exercise Card Body */
.exercise-section .card-body {
  padding: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 1) 100%);
}

/* Exercise Title */
.exercise-section .card-body h6 {
  font-size: 1rem;
  font-weight: 600;
  color: #2c3e50;
  margin: 0;
  line-height: 1.4;
  flex: 1;
}

/* Toggle Switch Styling */
.exercise-section .form-check-input {
  width: 3.5rem;
  height: 1.75rem;
  margin-left: 1rem;
  cursor: pointer;
  background-color: #e9ecef;
  border: none;
  position: relative;
  transition: all 0.2s ease;
}

.exercise-section .form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.exercise-section .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  border: none;
}

/* Section Header */
.exercise-section .d-flex.justify-content-between {
  background: white;
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
}

.exercise-section h5 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2c3e50;
  margin: 0;
}

/* Toggle All Button */
.exercise-section .btn-outline-primary {
  padding: 0.75rem 1.5rem;
  font-weight: 500;
  border-radius: 0.75rem;
  transition: all 0.2s ease;
}

.exercise-section .btn-outline-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(13, 110, 253, 0.2);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .exercise-section .row.g-4 {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    padding: 0.75rem;
  }

  .exercise-section .card-img-top {
    height: 160px;
  }

  .exercise-section .card-body {
    padding: 1rem;
  }

  .exercise-section .card-body h6 {
    font-size: 0.95rem;
  }

  .exercise-section .form-check-input {
    width: 3rem;
    height: 1.5rem;
  }

  .exercise-section .d-flex.justify-content-between {
    padding: 0.75rem;
    margin-bottom: 1rem;
  }

  .exercise-section h5 {
    font-size: 1.1rem;
  }

  .exercise-section .btn-outline-primary {
    padding: 0.5rem 1rem;
    font-size: 0.95rem;
  }
}

/* Loading State */
.exercise-section .card.loading {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

/* Exercise Categories */
.exercise-section .card::before {
  content: attr(data-category);
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.875rem;
  font-weight: 500;
  z-index: 1;
}

/* Disabled State */
.exercise-section .card.disabled {
  opacity: 0.7;
  filter: grayscale(0.5);
}

.exercise-section .card.disabled:hover {
  transform: none;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
}

/* Exercise Settings Tab Styles */
.exercise-settings {
  padding: 1rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
}

/* Header Section */
.settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.header-content h4 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  color: #2c3e50;
}

.header-content p {
  margin: 0.5rem 0 0 0;
  font-size: 1rem;
}

/* Categories */
.exercise-categories {
  display: flex;
  gap: 1rem;
  margin-bottom: 3rem;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 0.5rem;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
}

.category-btn {
  padding: 0.75rem 1.5rem;
  border: none;
  background: #f8f9fa;
  border-radius: 0.75rem;
  color: #6c757d;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  flex: 0 0 auto;
  scroll-snap-align: start;
}

.category-btn:hover {
  background: #e9ecef;
}

.category-btn.active {
  background: #0d6efd;
  color: white;
  box-shadow: 0 4px 12px rgba(13, 110, 253, 0.2);
}

/* Hide scrollbar but keep functionality */
.exercise-categories::-webkit-scrollbar {
  height: 0;
  width: 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .exercise-categories {
    gap: 0.75rem;
  }

  .category-btn {
    padding: 0.625rem 1rem;
    font-size: 0.9rem;
  }
}

/* Very small screens */
@media (max-width: 360px) {
  .category-btn {
    font-size: 0.85rem;
    padding: 0.5rem 0.75rem;
  }
}

/* Search Bar */
.search-bar {
  position: sticky;
  top: 80px; /* Adjust this value based on your navbar height */
  z-index: 10;
  background: white;
  padding: 1rem 0;
  margin: -1rem 0 2rem 0;
}

/* Add a white background that extends behind the search bar */
.search-bar::before {
  content: "";
  position: absolute;
  top: -20px; /* Add extra space above to prevent content from showing through */
  left: 0;
  right: 0;
  height: calc(100% + 20px);
  background: white;
  z-index: -1;
}

/* Keep the bottom border */
.search-bar::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: #e9ecef;
}

/* Update exercise grid to account for sticky search */
.exercise-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding-top: 0.5rem;
}

/* Ensure the categories stay below the search bar */
.exercise-categories {
  position: relative;
  z-index: 5;
  margin-top: 1rem; /* Add some space between search and categories */
}

.search-input {
  width: 100%;
  padding: 1rem 3rem;
  border: 2px solid #e9ecef;
  border-radius: 1rem;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.search-input:focus {
  outline: none;
  border-color: #0d6efd;
  box-shadow: 0 0 0 4px rgba(13, 110, 253, 0.1);
}

.clear-search {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  color: #6c757d;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
}

.clear-search:hover {
  background: #f8f9fa;
}

/* Exercise Grid */
.exercise-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

/* Exercise Card */
.exercise-card {
  background: white;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  transition: all 0.2s ease;
}

.exercise-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.exercise-image {
  position: relative;
  height: 200px;
}

.exercise-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.exercise-badges {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  gap: 0.5rem;
}

.badge {
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.badge.bodyweight {
  background-color: #28a745; /* Example color for bodyweight */
  color: white;
}

.badge.warmup {
  background-color: #ffc107; /* Example color for warmup */
  color: white;
}

.badge.cooldown {
  background-color: #17a2b8; /* Example color for cooldown */
  color: white;
}

.badge.dumbbell {
  background-color: #ff9f1c; /* Example color for dumbbell */
  color: white;
}

.badge.yoga {
  background-color: #0d6efd; /* Example color for yoga */
  color: white;
}

.badge.mobility {
  background-color: #6f42c1; /* Example color for mobility */
  color: white;
}

.badge.banded {
  background-color: #dc3545; /* Example color for banded */
  color: white;
}

.badge.pilates {
  background-color: #0d6efd; /* Example color for pilates */
  color: white;
}

.exercise-content {
  padding: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
}

.exercise-content h5 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: #2c3e50;
}

/* Toggle Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 48px; /* Reduced from 60px */
  height: 24px; /* Reduced from 32px */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e9ecef;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px; /* Reduced from 24px */
  width: 18px; /* Reduced from 24px */
  left: 3px; /* Reduced from 4px */
  bottom: 3px; /* Reduced from 4px */
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #0d6efd;
}

input:checked + .slider:before {
  transform: translateX(24px); /* Reduced from 28px */
}

/* Disabled State */
.exercise-card.is-disabled {
  opacity: 0.7;
}

.exercise-card.is-disabled img {
  filter: grayscale(0.5);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .exercise-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
  }

  .exercise-image {
    height: 160px;
  }

  .settings-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .settings-header button {
    width: 100%;
  }

  .category-btn {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}

/* Add these specific styles for the preview modal */
.modal-dialog-scrollable .modal-body {
  padding: 1.5rem !important;
  max-height: 80vh; /* Limit maximum height */
  overflow-y: auto;
}

/* Override the problematic height calculation for the preview modal */
.modal-dialog-scrollable .col-md-3,
.modal-dialog-scrollable .col-md-4 {
  height: auto !important; /* Override the 100vh calculation */
}

/* Adjust spacing in the preview blocks */
.preview-blocks {
  margin-top: 1rem; /* Reduce top margin */
}

.preview-block {
  margin-bottom: 1.5rem; /* Reduce bottom margin */
}

.preview-block:last-child {
  margin-bottom: 0; /* Remove margin from last block */
}

/* Adjust the blueprint info section spacing */
.blueprint-info {
  margin-bottom: 1.5rem !important; /* Override any other margin */
}

/* Ensure the modal content doesn't stretch unnecessarily */
.modal-content {
  height: auto !important;
  max-height: 90vh;
}

.exercise-image {
  position: relative;
  height: 200px;
  cursor: pointer;
  overflow: hidden;
}

.play-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.play-overlay i {
  font-size: 3rem;
  color: white;
}

.exercise-image:hover .play-overlay {
  opacity: 1;
}

.video-preview-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background: #000;
}

.preview-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Modal adjustments for video preview */
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

@media (min-width: 576px) {
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
}

.exercise-list-item {
  position: relative;
  padding: 0.5rem;
  transition: background-color 0.2s ease;
}

.exercise-list-item:hover {
  background-color: #eee !important;
}

.exercise-preview {
  position: relative;
  cursor: pointer;
}

.play-overlay-small {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.play-overlay-small i {
  font-size: 1.25rem;
  color: white;
}

.exercise-preview:hover .play-overlay-small {
  opacity: 1;
}

/* Also update the exercise cards in the blocks to be clickable */
.draggable .card {
  cursor: pointer;
}

.draggable .card-img-top {
  position: relative;
}

.draggable .card:hover .play-overlay {
  opacity: 1;
}

/* Add these styles to your <style> section */
.action-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  justify-content: center;
}

.action-buttons .btn {
  min-width: 200px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .action-buttons {
    gap: 0.75rem;
  }

  .action-buttons .btn {
    flex: 1 1 calc(50% - 0.75rem); /* Two buttons per row with gap consideration */
    min-width: 0;
  }
}

@media (max-width: 480px) {
  .action-buttons .btn {
    flex: 1 1 100%; /* One button per row on very small screens */
  }
}

/* Replace the action-buttons styles */
.action-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  justify-content: center;
}

.action-buttons .btn {
  flex: 1 1 200px; /* Base flex-grow with minimum width */
  max-width: calc(40% - 0.75rem); /* For 4 buttons per row, accounting for gap */
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .action-buttons .btn {
    flex: 1 1 calc(33.333% - 0.75rem); /* Three buttons per row */
    max-width: calc(33.333% - 0.75rem);
  }
}

@media (max-width: 992px) {
  .action-buttons .btn {
    flex: 1 1 calc(50% - 0.75rem); /* Two buttons per row */
    max-width: calc(50% - 0.75rem);
  }
}

@media (max-width: 480px) {
  .action-buttons .btn {
    flex: 1 1 100%; /* One button per row */
    max-width: 100%;
  }
}

.blueprint-filters {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.blueprint-filters .btn-group {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  overflow: hidden;
}

.blueprint-filters .btn {
  padding: 0.75rem 1.5rem;
  border: none;
  font-weight: 500;
}

.blueprint-filters .btn i {
  margin-right: 0.5rem;
}

.template-header .badge {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.template-header .badge i {
  margin-right: 0.25rem;
}

/* Add these styles */
.generation-step {
  padding: 1rem 0;
}

.step-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 1.5rem;
}

.duration-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 0.75rem;
  max-height: 400px;
  overflow-y: auto;
  padding-right: 0.5rem; /* Space for scrollbar */
}

.duration-btn,
.preset-btn {
  width: 100%;
  padding: 0.75rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Scrollbar styling */
.duration-buttons::-webkit-scrollbar,
.preset-buttons::-webkit-scrollbar {
  width: 6px;
}

.duration-buttons::-webkit-scrollbar-track,
.preset-buttons::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.duration-buttons::-webkit-scrollbar-thumb,
.preset-buttons::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .duration-buttons,
  .preset-buttons {
    grid-template-columns: repeat(4, 1fr); /* Force 4 columns on larger screens */
  }
}

@media (max-width: 767px) {
  .duration-buttons,
  .preset-buttons {
    grid-template-columns: repeat(2, 1fr);
    max-height: 300px;
  }

  .duration-btn,
  .preset-btn {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
}

.setting-group {
  margin-bottom: 1.5rem;
}

.setting-group label {
  display: block;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #495057;
}

.preset-buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Always show 4 buttons per row */
  gap: 0.75rem;
  width: 100%;
}

.preset-btn {
  padding: 1rem;
  border: 2px solid #e9ecef;
  border-radius: 0.75rem;
  background: white;
  color: #495057;
  font-weight: 500;
  transition: all 0.2s ease;
}

.preset-btn:hover {
  border-color: #0d6efd;
  transform: translateY(-2px);
}

.preset-btn.active {
  background: #0d6efd;
  color: white;
  border-color: #0d6efd;
  box-shadow: 0 4px 12px rgba(13, 110, 253, 0.2);
}

.settings-summary {
  background: #f8f9fa;
  border-radius: 0.75rem;
  padding: 1.5rem;
}

.summary-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-weight: 500;
}

.summary-item:last-child {
  margin-bottom: 0;
}

.summary-item i {
  width: 24px;
  color: #0d6efd;
  margin-right: 1rem;
}

@media (max-width: 768px) {
  .duration-buttons,
  .preset-buttons {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .preset-buttons {
    grid-template-columns: repeat(2, 1fr); /* 2 buttons per row on mobile */
  }

  .preset-btn {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 360px) {
  .preset-btn {
    padding: 0.4rem;
    font-size: 0.85rem;
  }
}

/* Update the modal sizing */
.modal-dialog {
  max-width: 800px; /* Increased from default */
  width: 90%;
  margin: 1.75rem auto;
}

/* Workout Settings Styling */
.workout-settings {
  background: white;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  margin-bottom: 1rem;
}

.settings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.setting-card {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 0.75rem;
  transition: all 0.2s ease;
}

.setting-card:hover {
  background: #f0f0f0;
}

.setting-icon {
  width: 48px;
  height: 48px;
  min-width: 48px;
  background: white;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.setting-icon i {
  font-size: 1.5rem;
  color: #0d6efd;
}

.setting-content {
  flex: 1;
}

.setting-content label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #6c757d;
  margin-bottom: 0.5rem;
}

.setting-content .form-select {
  border: 2px solid #e9ecef;
  border-radius: 0.5rem;
  padding: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.setting-content .form-select:hover {
  border-color: #0d6efd;
}

.setting-content .form-select:focus {
  border-color: #0d6efd;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .workout-settings {
    padding: 1rem;
  }

  .settings-grid {
    gap: 1rem;
  }

  .setting-card {
    padding: 0.75rem;
  }

  .setting-icon {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }

  .setting-icon i {
    font-size: 1.25rem;
  }
}

.replacement-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(255, 255, 255, 0.9);
  color: #0d6efd;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  z-index: 1;
}

.replacement-badge i {
  margin-right: 0.25rem;
}

.card.is-replaced {
  border: 2px solid #0d6efd;
}

.replacement-badge {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  background: rgba(13, 110, 253, 0.9);
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 1rem;
  font-size: 0.75rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.replacement-badge i {
  font-size: 0.875rem;
}

.categories-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.category-select-btn {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: white;
  border: 2px solid #e9ecef;
  border-radius: 0.75rem;
  font-weight: 500;
  color: #495057;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.category-select-btn i {
  font-size: 1.25rem;
  color: #0d6efd;
}

.category-select-btn:hover {
  border-color: #0d6efd;
  transform: translateY(-2px);
}

.category-select-btn.active {
  background: #f0f7ff;
  border-color: #0d6efd;
  color: #0d6efd;
}

.check-indicator {
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.category-select-btn.active .check-indicator {
  right: 1rem;
}

@media (max-width: 768px) {
  .categories-grid {
    flex-direction: column;
  }

  .category-select-btn {
    padding: 0.75rem;
    font-size: 0.9rem;
  }
}

/* Kategorie-Auswahl Styling */
.categories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
}

.category-select-btn {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: white;
  border: 2px solid #e9ecef;
  border-radius: 0.75rem;
  font-weight: 500;
  color: #495057;
  transition: all 0.2s ease;
  position: relative;
  overflow: hidden;
}

.category-select-btn i {
  font-size: 1.25rem;
  color: #0d6efd;
}

.category-select-btn:hover {
  border-color: #0d6efd;
  transform: translateY(-2px);
}

.category-select-btn.active {
  background: #f0f7ff;
  border-color: #0d6efd;
  color: #0d6efd;
}

.check-indicator {
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.category-select-btn.active .check-indicator {
  right: 1rem;
}

@media (max-width: 768px) {
  .categories-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .category-select-btn {
    padding: 0.75rem;
    font-size: 0.9rem;
  }
}

.category-actions {
  display: flex;
  justify-content: space-between;
}

.category-actions .btn {
  flex: 1;
  margin: 0 0.5rem;
}

.category-actions .btn i {
  margin-right: 0.5rem;
}

.category-actions {
  display: flex;
  gap: 1rem;
  margin: 2.3rem 0;
  padding: 0 1rem;
}

.category-actions .btn {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  font-weight: 500;
}

.category-actions .btn i {
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .category-actions {
    flex-direction: column;
  }

  .category-actions .btn {
    width: 100%;
  }
}

.templates-container {
  padding: 0.1rem;
}

.empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  background: #f8f9fa;
  border-radius: 1rem;
}

.empty-state-content {
  text-align: center;
}

.empty-state-content i {
  color: #dee2e6;
  margin-bottom: 1rem;
}

.empty-state-content h4 {
  color: #6c757d;
  margin-bottom: 0.5rem;
}

.templates-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
}

.template-card {
  background: white;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  transition: transform 0.2s ease;
}

.template-card:hover {
  transform: translateY(-4px);
}

.template-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.template-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2c3e50;
  margin: 0;
}

.template-duration {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #0d6efd;
  font-weight: 500;
}

.template-stats {
  background: #f8f9fa;
  border-radius: 0.75rem;
  padding: 1.25rem;
  margin-bottom: 1.5rem;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
}

.stat-item:last-child {
  margin-bottom: 0;
}

.stat-item i {
  color: #0d6efd;
  font-size: 1.1rem;
}

.stat-item span {
  color: #495057;
  font-weight: 500;
}

.template-actions {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: 0.75rem;
}

.template-actions button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem;
  border: none;
  border-radius: 0.75rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.btn-preview {
  background: #f8f9fa;
  color: #495057;
}

.btn-preview:hover {
  background: #e9ecef;
}

.btn-load {
  background: #0d6efd;
  color: white;
}

.btn-load:hover {
  background: #0b5ed7;
}

.btn-delete {
  background: #dc3545;
  color: white;
  width: 42px;
  padding: 0 !important;
}

.btn-delete:hover {
  background: #bb2d3b;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .templates-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .template-card {
    padding: 1rem;
  }

  .template-header {
    margin-bottom: 1rem;
  }

  .template-title {
    font-size: 1.1rem;
  }

  .template-stats {
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .stat-item {
    margin-bottom: 0.5rem;
  }

  .template-actions {
    grid-template-columns: 1fr 1fr auto;
  }

  .template-actions button {
    padding: 0.625rem;
    font-size: 0.9rem;
  }

  .btn-delete {
    width: 38px;
  }
}

/* Small mobile devices */
@media (max-width: 360px) {
  .template-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .template-actions {
    grid-template-columns: 1fr;
  }

  .btn-delete {
    width: 100%;
    padding: 0.625rem !important;
  }
}

/* Update the modal styles to ensure proper scrolling */
.modal-dialog {
  max-width: 800px;
  width: 90%;
  margin: 1.75rem auto;
  display: flex;
  min-height: calc(100% - 3.5rem);
}

.modal-content {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 3.5rem);
  height: auto;
}

.modal-body {
  overflow-y: auto;
  overflow-x: hidden;
  /* Add padding for iOS devices to account for safe area */
  padding-bottom: calc(1rem + env(safe-area-inset-bottom));
}

/* Style scrollbars for better appearance */
.modal-body::-webkit-scrollbar {
  width: 6px;
}

.modal-body::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.modal-body::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}

.modal-body::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* Ensure modal header and footer stay fixed */
.modal-header,
.modal-footer {
  flex-shrink: 0;
}

/* Add specific styles for the exercise picker modal */
.modal-dialog-scrollable {
  height: auto;
}

.modal-dialog-scrollable .modal-content {
  max-height: 90vh;
}

.modal-dialog-scrollable .modal-body {
  padding: 0 !important; /* Remove default padding */
  position: relative;
}

/* Add padding to the content inside scrollable modal */
.modal-dialog-scrollable .modal-body > div {
  padding: 1.5rem;
}

/* Ensure the search bar stays fixed at the top in exercise picker */
.modal-dialog-scrollable .search-container {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1020;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #dee2e6;
}

/* Add spacing after the search container */
.modal-dialog-scrollable .row.g-3 {
  padding: 1.5rem;
}

/* Blueprint preview modal specific styles */
.modal-dialog-scrollable .preview-blocks {
  padding: 1.5rem;
}

/* Video preview modal specific styles */
.modal-dialog-centered .modal-content {
  max-height: 90vh;
}

.video-preview-container {
  max-height: calc(90vh - 120px); /* Account for header and footer */
}

/* Workout generation modal specific styles */
.generation-step {
  padding: 1.5rem;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .modal-dialog {
    margin: 1rem auto;
    min-height: calc(100% - 2rem);
  }

  .modal-content {
    max-height: calc(100vh - 2rem);
  }

  .modal-dialog-scrollable .modal-body > div {
    padding: 1rem;
  }

  .modal-dialog-scrollable .search-container {
    padding: 0.75rem 1rem;
  }

  .modal-dialog-scrollable .row.g-3 {
    padding: 1rem;
  }

  .generation-step {
    padding: 1rem;
  }
}

/* Fix for iOS devices to prevent content from being hidden behind the notch */
@supports (-webkit-touch-callout: none) {
  .modal-content {
    max-height: calc(100vh - 3.5rem - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  }
}

/* Update these styles in the existing <style> section */

/* Exercise Card Base Styles */
.exercise-card {
  background: white;
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.exercise-image {
  position: relative;
  height: 160px; /* Reduced from 200px */
}

.exercise-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.exercise-content {
  padding: 0.75rem; /* Reduced from 1.25rem */
}

.exercise-content h5 {
  font-size: 0.9rem;
  margin: 0;
  line-height: 1.3;
}

/* Card Grid Layout */
.exercise-grid {
  display: grid;
  gap: 1rem;
}

/* Progressive Grid Layout */
@media (max-width: 576px) {
  .exercise-grid {
    grid-template-columns: 1fr; /* 1 column */
    gap: 0.75rem;
  }
}

@media (min-width: 577px) and (max-width: 768px) {
  .exercise-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .exercise-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .exercise-grid {
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
  }
}

@media (min-width: 1201px) {
  .exercise-grid {
    grid-template-columns: repeat(5, 1fr); /* 5 columns */
  }
}

/* Exercise Card Styles */
.exercise-card {
  background: white;
  border-radius: 0.75rem;
  overflow: hidden;
  boxshadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.exercise-image {
  position: relative;
  height: 160px;
}

.exercise-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.exercise-content {
  padding: 0.75rem;
}

.exercise-content h5 {
  font-size: 0.9rem;
  margin: 0;
  line-height: 1.3;
}

/* Mobile Optimizations */
@media (max-width: 768px) {
  .exercise-image {
    height: 140px;
  }
}

/* Update break container and content styles */
.break-container {
  position: absolute;
  inset: 0; /* Shorthand for top/right/bottom/left: 0 */
  background: rgba(0,0,0,0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

/* Rest of the styles remain the same */
.break-content {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.next-exercise-preview {
  position: absolute;
  inset: 0;
  z-index: 1;
}

.next-exercise-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Center content container */
.break-content-center {
  position: relative;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
}

/* Style the timer */
.break-content .display-1 {
  font-size: clamp(4rem, 15vw, 8rem);
  font-weight: 700;
  color: white;
  text-shadow: 0 4px 12px rgba(0,0,0,0.5);
  margin: 0;
  line-height: 1;
  z-index: 3;
}

/* Style the next exercise text */
.next-exercise-preview h3 {
  font-size: clamp(1rem, 4vw, 1.5rem);
  font-weight: 500;
  color: rgba(255,255,255,0.9);
  margin: 1rem 0 0.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 3;
}

/* Style the exercise title */
.next-exercise-preview h4 {
  font-size: clamp(1.25rem, 5vw, 2rem);
  font-weight: 600;
  color: white;
  margin: 0;
  padding: 0 1rem;
  text-align: center;
  max-width: 800px;
  z-index: 3;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .break-content {
    padding: 1rem;
  }

  .break-content .display-1 {
    margin-bottom: 0.5rem;
  }

  .next-exercise-preview h3 {
    margin-top: 0.5rem;
  }
}

/* Landscape mode optimizations */
@media (max-height: 500px) {
  .break-content {
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 2rem;
    /* Add background gradient that covers the entire content */
    background: linear-gradient(90deg,
      rgba(0,0,0,0.75) 0%,
      rgba(0,0,0,0.6) 50%,
      rgba(0,0,0,0.4) 100%
    );
  }

  /* Remove the previous gradient from the preview element */
  .next-exercise-preview::after {
    background: linear-gradient(90deg,
      rgba(0,0,0,0.75) 0%,
      rgba(0,0,0,0.6) 50%,
      rgba(0,0,0,0.4) 100%
    );
  }

  .break-content .display-1 {
    font-size: clamp(3rem, 10vw, 5rem);
    margin-right: 2rem;
    z-index: 3; /* Ensure text is above gradient */
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5); /* Add text shadow for better readability */
  }

  .next-exercise-text {
    text-align: left;
    z-index: 3; /* Ensure text is above gradient */
  }

  .next-exercise-text h3,
  .next-exercise-text h4 {
    text-shadow: 2px 2px 4px rgba(0,0,0,0.5); /* Add text shadow for better readability */
  }
}

/* Add animation for the timer */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.break-content .display-1 {
  animation: pulse 2s infinite ease-in-out;
}

/* Add to the <style> section */
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-size: 0.875rem;
  opacity: 0;
  transition: opacity 0.15s;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

/* Position the tooltip arrow */
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

/* Add styles for muscle group buttons similar to category buttons */
.muscle-group-buttons {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  overflow-x: auto;
  padding-bottom: 0.5rem;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  flex-wrap: wrap;
}

.muscle-btn {
  padding: 0.75rem 1.5rem;
  border: none;
  background: #f8f9fa;
  border-radius: 0.75rem;
  color: #6c757d;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  flex: 0 0 auto;
  scroll-snap-align: start;
}

.muscle-btn:hover {
  background: #e9ecef;
}

.muscle-btn.active {
  background: #0d6efd!important;
  color: #fff;
  box-shadow: 0 4px 12px rgba(13, 110, 253, 0.2);
}

/* Hide scrollbar but keep functionality */
.muscle-group-buttons::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.filter-section {
  position: sticky;
  top: 80px; /* Adjust this value based on your navbar height */
  z-index: 10;
  background: white;
  padding: 1rem 0;
  margin: -1rem 0 2rem 0;
}

.filter-section .muscle-groups {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.filter-section .muscle-group-buttons {
  display: flex;
  gap: 1rem;
}

.filter-section .muscle-btn {
  padding: 0.75rem 1.5rem;
  border: none;
  background: #f8f9fa;
  border-radius: 0.75rem;
  color: #6c757d;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  flex: 0 0 auto;
}

.filter-section .muscle-btn:hover {
  background: #e9ecef;
}

.filter-section .muscle-btn.active {
  background: #0d6efd;
  color: white;
  box-shadow: 0 4px 12px rgba(13, 110, 253, 0.2);
}

.showMuscleGroupFilter {
  background: #0d6efd!important;
  color: white;
  box-shadow: 0 4px 12px rgba(13, 110, 253, 0.2);
}

.showMuscleGroupFilter:hover {
  background: #0b5ed7;
}

.showMuscleGroupFilter:focus {
  outline: none;
  box-shadow: none;
}

.showMuscleGroupFilter i {
  margin-left: 0.5rem;
}

/* Filter Buttons */
.filter-buttons {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.filter-buttons .btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.45rem 0.4rem;
  font-weight: 400;
  color:#6c757d;
  font-size:0.9rem;
}

.filter-buttons .btn i {
  font-size: 1.1rem;
}

/* Filter Grid */
.filter-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  padding: 1rem;
}

.filter-btn {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: white;
  border: 2px solid #e9ecef;
  border-radius: 0.75rem;
  font-weight: 500;
  color: #495057;
  transition: all 0.2s ease;
  width: 100%;
  text-align: left;
}

.filter-btn:hover {
  border-color: #0d6efd;
  transform: translateY(-2px);
}

.filter-btn.active {
  background: #f0f7ff;
  border-color: #0d6efd;
  color: #0d6efd!important;
}

.filter-btn i {
  font-size: 1.25rem;
  color: #0d6efd;
  width: 24px;
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .filter-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 0.75rem;
  }

  .filter-btn {
    padding: 0.75rem;
    font-size: 0.9rem;
  }
}

/* Update filter button styles */
.filter-buttons .btn {
  transition: all 0.2s ease;
  border: none;
  font-weight: 500;
}

.filter-buttons .btn.btn-primary {
  box-shadow: 0 2px 8px rgba(13, 110, 253, 0.25);
}

.filter-buttons .btn.btn-primary:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(13, 110, 253, 0.35);
}

/* Ensure buttons maintain consistent width when text changes */
.filter-buttons {
  display: flex;
  gap: 0.5rem;
}

.filter-buttons .btn {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 120px;
}

.repetition-control {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #f8f9fa;
  padding: 0.25rem;
  border-radius: 0.5rem;
}

.repetition-count {
  min-width: 2.5rem;
  text-align: center;
  font-weight: 500;
  color: #495057;
}

.repetition-control .btn {
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.repetition-control .btn i {
  font-size: 0.875rem;
}

.repetition-control {
  display: flex;
  align-items: center;
  background: white;
  border: 2px solid #e9ecef;
  border-radius: 0.75rem;
  padding: 0.25rem;
  transition: all 0.2s ease;
  gap: 0.5rem;
}

.repetition-control:hover {
  border-color: #dee2e6;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.repetition-display {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.5rem;
  min-width: 100px;
}

.repetition-count {
  font-size: 1.25rem;
  font-weight: 600;
  color: #0d6efd;
  line-height: 1;
}

.repetition-label {
  font-size: 0.75rem;
  color: #6c757d;
  margin-top: 0.125rem;
}

.repetition-control .btn-icon {
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background: #f8f9fa;
  border: none;
  color: #495057;
  transition: all 0.2s ease;
}

.repetition-control .btn-icon:hover:not(:disabled) {
  background: #e9ecef;
  color: #0d6efd;
  transform: translateY(-1px);
}

.repetition-control .btn-icon:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.repetition-control .btn-icon i {
  font-size: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .repetition-display {
    min-width: 80px;
  }

  .repetition-count {
    font-size: 1.1rem;
  }

  .repetition-label {
    font-size: 0.7rem;
  }

  .repetition-control .btn-icon {
    width: 28px;
    height: 28px;
  }
}

/* Update the video container styles */
.video-aspect-ratio {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background: #000;
  margin: 0;
  overflow: hidden;
}

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Update card and card-body padding */
@media (max-width: 768px) {
  .card {
    border-radius: 0; /* Remove border radius on mobile */
  }
  
  .card-body {
    padding: 0; /* Remove padding on mobile */
  }

  /* Add padding only to the controls section */
  .d-flex.align-items-center.mt-3.mb-4 {
    padding: 1rem;
  }
}

/* Update the controls layout */
.d-flex.align-items-center.mt-3.mb-4 {
  display: flex !important;
  flex-direction: row !important; /* Force horizontal layout */
  flex-wrap: nowrap !important;
  gap: 0.5rem;
  width: 100%;
  align-items: center !important;
  justify-content: flex-start !important;
}

/* Style the control buttons */
.d-flex.align-items-center.mt-3.mb-4 .btn {
  padding: 0.5rem;
  width: 42px !important; /* Force fixed width */
  min-width: 42px !important;
  max-width: 42px !important;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto !important; /* Prevent flex growing/shrinking */
}

/* Make progress bar fill remaining space */
.d-flex.align-items-center.mt-3.mb-4 .progress {
  flex: 1 1 auto !important; /* Allow progress bar to grow and shrink */
  margin: 0 0.5rem;
  width: auto !important; /* Remove fixed width if any */
}

/* Ensure icons are centered */
.d-flex.align-items-center.mt-3.mb-4 .btn i {
  margin: 0;
  line-height: 1;
}

/* Mobile specific adjustments */
@media (max-width: 768px) {
  .d-flex.align-items-center.mt-3.mb-4 {
    padding: 1rem;
    margin: 0 !important;
  }
  
  .d-flex.align-items-center.mt-3.mb-4 .btn {
    padding: 0;
  }
}

/* Add these styles for the current exercise section */
.list-group-item {
  padding: 0 !important;
  position: relative;
  overflow: hidden;
}

.list-group-item .d-flex {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  margin: 0;
}

/* Style for the background image */
.current-exercise-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Overlay gradient for better text readability */
.current-exercise-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(0,0,0,0.8));
  display: flex;
  align-items: flex-end;
  padding: 1rem;
}

/* Style for the exercise title */
.current-exercise-title {
  color: white !important;
  text-shadow: 0 2px 4px rgba(0,0,0,0.2);
  z-index: 1;
  width: 100%;
}

.current-exercise-title span {
  color: white !important;
  font-size: 1.25rem !important;
  font-weight: 600;
}

/* Add/update these styles in the <style> section */

/* Update list group styling */
.list-group-item {
  padding: 0.5rem !important;
  border: none;
  border-bottom: 1px solid #f0f0f0;
}

.list-group-item:last-child {
  border-bottom: none;
}

/* Exercise item styling */
.exercise-item {
  display: flex;
  align-items: center;
  padding: 0.25rem;
  border-radius: 0.5rem;
  transition: background-color 0.2s ease;
}

.exercise-item:hover {
  background-color: #f8f9fa;
}

.exercise-item img {
  width: 32px;
  height: 32px;
  border-radius: 0.25rem;
  object-fit: cover;
}

.exercise-item h6 {
  font-size: 0.9rem;
  margin: 0;
  color: #495057;
}

/* Break item styling */
.break-item {
  padding: 0.5rem;
  background: #f8f9fa;
  border-radius: 0.5rem;
  margin: 0.25rem 0;
}

.break-item h6 {
  font-size: 0.9rem;
  margin: 0;
  color: #6c757d;
}

.break-item small {
  font-size: 0.8rem;
  color: #adb5bd;
}

/* Active item styling */
.list-group-item.active {
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.list-group-item.active .exercise-item h6,
.list-group-item.active .break-item h6 {
  color: #212529;
  font-weight: 500;
}

/* Exercise menu styling */
.exercise-menu {
  padding: 0.5rem 0 0.25rem 0;
}

.exercise-menu .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
}

.exercise-menu .btn i {
  font-size: 0.8rem;
}

.card-header {
  background: white;
  border-bottom: 1px solid #f0f0f0;
}

.card-title {
  font-size: 0.875rem;
  font-weight: 600;
  color: #6c757d;
}

.list-group-item {
  padding: 0.5rem 0.75rem;
  border: none;
  border-bottom: 1px solid #f0f0f0;
}

.current-exercise {
  padding: 0.75rem;
}

.exercise-name {
  font-weight: 500;
  color: #212529;
}

.exercise-list-item {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.25rem 0;
  cursor: pointer;
}

.exercise-thumbnail {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  object-fit: cover;
}

.exercise-title {
  font-size: 0.875rem;
  color: #495057;
  flex: 1;
}

.break-list-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  color: #6c757d;
  font-size: 1rem;
}

.break-list-item i {

  color: #adb5bd;
}

.break-duration {
  margin-left: auto;
  font-size: 1rem;
  color: #adb5bd;
}

.exercise-actions {
  display: flex;
  flex-direction: column; /* Change to column layout */
  gap: 0.25rem; /* Reduce gap between buttons */
  padding: 0.5rem 0 0 2.75rem;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Add gap between icon and text */
  padding: 0.25rem 0.5rem;
  border: none;
  border-radius: 4px;
  background: none;
  color: #6c757d;
  font-size: 0.75rem;
  transition: all 0.2s;
  width: 100%; /* Make buttons full width */
  text-align: left; /* Align text to left */
}

/* Add text labels to buttons */
.action-btn i::after {
  margin-left: 0.5rem;
}


.list-group-item.active {
  background: #f8f9fa;
}

.list-group-item.active .exercise-title {
  color: #212529;
  font-weight: 500;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  background: none;
  color: #6c757d;
  font-size: 0.875rem;
  transition: all 0.2s;
  width: 100%;
  text-align: left;
}

/* Remove the ::after pseudo-elements since we're using direct text now */
.action-btn i {
  width: 1rem;
  text-align: center;
}

.action-btn:hover {
  background: #f8f9fa;
}

.action-btn.delete:hover {
  color: #dc3545;
  background: #fff5f5;
}

.action-btn.shuffle:hover {
  color: #6c757d;
  background: #f0f7ff;
}

.action-btn.duplicate:hover {
  color: #198754;
  background: #f0fff4;
}

.action-btn.add:hover {
  color: #6c757d;
  background: #f8f9fa;
}

/* Update the action button styles */
.action-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  border: none;
  border-radius: 0.5rem;
  background: none;
  color: #6c757d;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.action-btn i {
  font-size: 1rem;
  width: 1.25rem;
  opacity: 0.8;
}

/* Specific button styles */
.action-btn.delete {
  color: #dc3545;
}

.action-btn.delete:hover {
  background-color: rgba(220, 53, 69, 0.1);
}

.action-btn.shuffle {
  color: #6c757d;
}

.action-btn.shuffle:hover {
  background-color: rgba(108, 117, 125, 0.1);
}

.action-btn.duplicate {
  color: #198754;
}

.action-btn.duplicate:hover {
  background-color: rgba(25, 135, 84, 0.1);
}

.action-btn.add {
  color: #0d6efd;
}

.action-btn.add:hover {
  background-color: rgba(13, 110, 253, 0.1);
}

/* Update the actions container */
.exercise-actions {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.5rem;
  background: white;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Add these styles to your existing styles */

/* Ensure video works on iOS */
.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #000;
  -webkit-tap-highlight-color: transparent;
}

/* Style for manual play prompt */
.play-prompt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 1rem 2rem;
  border-radius: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
}

.play-prompt i {
  font-size: 1.5rem;
}

/* Ensure break overlay works on mobile */
.break-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  -webkit-transform: translate3d(0,0,0); /* Force hardware acceleration */
}

/* Add specific styles for iOS devices */
@supports (-webkit-touch-callout: none) {
  .video-aspect-ratio {
    /* Add padding for notched devices */
    padding-top: calc(56.25% + env(safe-area-inset-top));
  }
  
  .break-container {
    /* Ensure content isn't hidden behind notch */
    padding-top: env(safe-area-inset-top);
  }
}

/* Update the workout execution container */
.workout-execution {
  padding-bottom: 80px; /* Add padding to account for footer height */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Update the row containing video and upcoming exercises */
.workout-execution .row {
  flex: 1;
  margin: 0; /* Remove default row margins */
}


/* Update the card in the upcoming exercises column */
.col-lg-3 .card {
  height: auto; /* Allow card to grow based on content */
  max-height: calc(90vh - 160px); /* Account for header and footer */
  overflow-y: auto;
}

/* Update the list group to enable proper scrolling */
.col-lg-3 .card .list-group {
  max-height: none; /* Remove max-height restriction */
}

/* Ensure footer stays at the bottom */
.workout-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1030;
  background: white;
  padding: 0.75rem;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  padding-bottom: calc(0.75rem + env(safe-area-inset-bottom));
}

/* Mobile specific adjustments */
@media (max-width: 991.98px) {
  .workout-execution {
    padding-bottom: 120px; /* Increase padding for mobile footer */
  }

  

  .col-lg-3 .card {
    max-height: 50vh; /* Limit height on mobile */
    margin-bottom: 1rem;
  }
}

/* Add safe area insets for iOS devices */
@supports (-webkit-touch-callout: none) {
  .workout-execution {
    padding-bottom: calc(80px + env(safe-area-inset-bottom));
  }

  @media (max-width: 991.98px) {
    .workout-execution {
      padding-bottom: calc(120px + env(safe-area-inset-bottom));
    }
  }
}

/* Update the exercise image styles */
.exercise-image {
  position: relative;
  height: 160px; /* Base height */
}

.exercise-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Default to cover */
}

/* Update for smaller devices */
@media (max-width: 768px) {
  .exercise-image {
    height: 140px; /* Slightly smaller height */
  }
  
  .exercise-image img {
    object-fit: cover; /* Switch to contain on mobile */
    background: #f8f9fa; /* Light background for letterboxing */
  }

  /* Improve button layout in zusammenstellen tab */
  .d-flex.gap-2 {
    display: flex;
    flex-direction: row;
    gap: 0.5rem !important;
    justify-content: center;
    padding: 0.5rem;
  }

  .icon-button {
    min-width: 36px;
    height: 36px;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* Even smaller devices */
@media (max-width: 576px) {
  .exercise-image {
    height: 120px; /* Even smaller height */
  }

  /* Adjust card body padding */
  .card-body {
    padding: 0.8rem;
  }

  /* Improve button spacing */
  .d-flex.gap-2 {
    padding: 0.25rem;
    gap: 0.25rem !important;
  }

  .icon-button {
    min-width: 32px;
    height: 32px;
  }
}

/* Update card text for better readability */
.card-text {
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
  line-height: 1.3;
}

/* Ensure buttons have proper spacing and alignment */
.d-flex.gap-2 {
  margin-top: auto;
  padding: 0.5rem 0 0 0;
}

/* Update the exercise actions styling */
.exercise-actions {
  display: flex;
  flex-direction: row; /* Change to row layout */
  flex-wrap: wrap; /* Allow wrapping */
  gap: 0.5rem;
  padding: 0.75rem;
  background: #f8f9fa;
  border-radius: 0.5rem;
  margin: 0.5rem;
}

.action-btn {
  flex: 1 1 calc(50% - 0.25rem); /* Two buttons per row with gap consideration */
  min-width: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  background: white;
  color: #6c757d;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  text-align: center;
  white-space: nowrap;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

/* Specific button styles */
.action-btn.delete {
  color: #dc3545;
}

.action-btn.delete:hover {
  background-color: #dc3545;
  color: white;
}

.action-btn.shuffle {
  color: #6c757d;
}

.action-btn.shuffle:hover {
  background-color: #6c757d;
  color: white;
}

.action-btn.duplicate {
  color: #198754;
}

.action-btn.duplicate:hover {
  background-color: #198754;
  color: white;
}

.action-btn.add {
  color: #0d6efd;
}

.action-btn.add:hover {
  background-color: #0d6efd;
  color: white;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .exercise-actions {
    padding: 0.5rem;
    margin: 0.25rem;
    gap: 0.25rem;
  }

  .action-btn {
    font-size: 0.8rem;
    padding: 0.4rem;
  }
}

/* Update the exercise list item to accommodate the actions */
.exercise-list-item {
  padding: 0.75rem;
  border-radius: 0.5rem;
  transition: background-color 0.2s ease;
}

.exercise-list-item:hover {
  background-color: rgba(0,0,0,0.03);
}

/* Ensure proper spacing in the list group */
.list-group-item {
  padding: 0.25rem !important;
}

/* Add pseudo-fullscreen styles */
.video-aspect-ratio {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  background: #000;
  margin-bottom: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

/* Pseudo-fullscreen styles */
.video-aspect-ratio.is-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding-top: 0;
  z-index: 9999;
}

.video-aspect-ratio.is-fullscreen .video-player,
.video-aspect-ratio.is-fullscreen .break-container {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Add safe area insets for iOS */
@supports (padding: max(0px)) {
  .video-aspect-ratio.is-fullscreen {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

/* Controls in fullscreen */
.video-aspect-ratio.is-fullscreen .d-flex.align-items-center {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(transparent, rgba(0,0,0,0.7));
  padding: 1rem;
  z-index: 10000;
}

/* Ensure break container stays on top in fullscreen */
.video-aspect-ratio.is-fullscreen .break-container {
  z-index: 10001;
}

/* Add/update these styles in the <style> section */

/* Video Controls Container */
.d-flex.align-items-center.mt-3.mb-4 {
  display: flex !important;
  align-items: center !important;
  gap: 0.75rem;
  padding: 1rem;
  background: linear-gradient(to bottom, rgba(248,249,250,0.95), rgba(248,249,250,1));
  border-radius: 0.75rem;
  margin: 0.75rem !important;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

/* Make all buttons the same size */
.d-flex.align-items-center.mt-3.mb-4 .btn {
  width: 44px !important;
  height: 44px !important;
  min-width: 44px !important;
  min-height: 44px !important;
  padding: 0 !important;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  border: none;
  background: white;
  box-shadow: 0 2px 6px rgba(0,0,0,0.08);
  flex: 0 0 auto;
}

/* Remove the different sizes for primary button */
.d-flex.align-items-center.mt-3.mb-4 .btn-primary {
  background: #0d6efd;
  color: white;
}

/* Icons - make them consistent size */
.d-flex.align-items-center.mt-3.mb-4 .btn i {
  font-size: 1.25rem;
  line-height: 1;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Mobile Optimizations - keep consistent sizes */
@media (max-width: 768px) {
  .d-flex.align-items-center.mt-3.mb-4 {
    padding: 0.75rem;
    margin: 0.5rem !important;
    gap: 0.5rem;
  }

  .d-flex.align-items-center.mt-3.mb-4 .btn,
  .d-flex.align-items-center.mt-3.mb-4 .btn-primary {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
  }

  .d-flex.align-items-center.mt-3.mb-4 .btn i {
    font-size: 1.125rem;
  }
}

/* Update the modal styles to handle safe areas and positioning */
.modal-dialog {
  margin: env(safe-area-inset-top) auto env(safe-area-inset-bottom) auto;
  padding-top: max(20px, env(safe-area-inset-top));
  max-height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}

.modal-content {
  max-height: calc(100vh - 60px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}

/* Fix the exercise picker modal's empty whitespace */
.modal-dialog-scrollable .modal-body {
  padding: 0 !important;
  height: auto !important;
  max-height: calc(100vh - 120px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}

/* Update the preview modal footer buttons layout */
@media (max-width: 768px) {
  .modal-footer > div:last-child {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
  }

  .modal-footer > div:last-child .btn {
    width: 100%;
    margin: 0 !important;
  }

  .modal-footer {
    flex-direction: column;
    gap: 1rem;
  }

  .modal-footer .total-duration {
    width: 100%;
    text-align: center;
  }

  /* Ensure delete button is full width on mobile */
  .modal-footer .btn-danger {
    width: 100% !important;
    margin: 0 !important;
  }
}

/* Add padding for iOS safe areas */
@supports (-webkit-touch-callout: none) {
  .modal-dialog {
    padding-top: max(20px, env(safe-area-inset-top));
  }

  .modal-content {
    margin-top: env(safe-area-inset-top);
  }
}

/* Update the modal styles */
.modal-dialog {
  /* Remove the previous margin styles */
  margin: 0 auto !important; /* Force override any Bootstrap margins */
  padding: 1rem;
  min-height: calc(100% - 1rem);
  display: flex;
  align-items: flex-start; /* Align to top instead of center */
  position: relative;
  top: env(safe-area-inset-top); /* Account for iOS safe area */
}

/* Update modal positioning for different types */
.modal-dialog-scrollable,
.modal-dialog-centered {
  margin: 0 auto !important;
  padding: 1rem;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered {
  align-items: center;
}

/* Adjust modal content max height */
.modal-content {
  max-height: calc(100vh - 2rem - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  margin: 0; /* Remove any margin */
}

/* Modal backdrop adjustment */
.modal-backdrop {
  top: 0;
  height: 100%;
}

/* Mobile specific adjustments */
@media (max-width: 768px) {
  .modal-dialog {
    padding: 0.5rem;
    min-height: calc(100% - 1rem);
    margin: 0 auto !important;
  }

  .modal-content {
    max-height: calc(100vh - 1rem - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  }
}

/* iOS specific adjustments */
@supports (-webkit-touch-callout: none) {
  .modal {
    padding-top: env(safe-area-inset-top);
  }

  .modal-dialog {
    top: 0; /* Reset top position for iOS */
    padding-top: max(0.5rem, env(safe-area-inset-top));
  }
}



/* Ensure proper sizing for fullscreen mode */
.video-aspect-ratio.is-fullscreen .break-container,
.video-aspect-ratio.is-fullscreen .next-exercise-preview,
.video-aspect-ratio.is-fullscreen .next-exercise-preview img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.stat-card {
  /* ... existing stat-card styles ... */
}

.text-orange {
  color: #fd7e14;
}

.stat-value {
  font-size: 2rem;
  font-weight: 600;
  margin: 0.5rem 0;
}

.stat-label {
  font-size: 0.875rem;
  color: #6c757d;
}

/* Add/update these styles */
.workout-settings {
  position: sticky;
  top: 20px; /* Adjust this value based on your layout */
  z-index: 10;
  background: white;
  border-radius: 0.75rem;
  padding: 1rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
  max-height: calc(100vh - 40px); /* Adjust based on your needs */
  display: flex;
  flex-direction: column;
}

.exercise-list {
  flex: 1;
  overflow-y: auto;
  margin-top: 1rem;
  border-radius: 0.5rem;
  scrollbar-width: thin;
}

button {
  min-width: 100px;
}

/* Custom scrollbar styling */
.exercise-list::-webkit-scrollbar {
  width: 6px;
}

.exercise-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.exercise-list::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 3px;
}

.exercise-list::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* Filter buttons container */
.filter-buttons {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

/* Search input styling */
input[type="text"] {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #e9ecef;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

input[type="text"]:focus {
  outline: none;
  border-color: #0d6efd;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

/* Exercise button styling */
.exercise-list button {
  width: 100%;
  padding: 0.75rem;
  transition: background-color 0.2s ease;
  border-radius: 0.5rem;
}

.exercise-list button:hover {
  background: #eee !important;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .workout-settings {
    position: relative;
    top: 0;
    margin-bottom: 1rem;
  }
}

/* Exit workout button container */
.exit-workout-container {
  margin-top: 1rem;
}

.exit-workout-btn {
  width: 100%;
  padding: 0.75rem;
  border: none;
  background: white;
  /* Change from red to a more subtle dark color */
  color: #343a40;
  border-radius: 0.75rem;
  font-weight: 500;
  transition: all 0.2s ease;
  -webkit-tap-highlight-color: transparent;
  box-shadow: 0 2px 10px rgba(0,0,0,0.08);
}

.exit-workout-btn:hover {
  /* Update hover state to dark gray instead of red */
  background: #343a40;
  color: white;
}

/* Update mobile specific styles */
@media (max-width: 991.98px) {
  .exit-workout-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    /* Reduce padding */
    padding: 0.75rem;
    background: white;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
    z-index: 900;
    margin: 0;
    /* Update iOS safe area padding to be more compact */
    padding-bottom: max(0.75rem, env(safe-area-inset-bottom));
    /* Add height constraint */
    height: auto;
    min-height: calc(3.5rem + env(safe-area-inset-bottom));
    display: flex;
    align-items: center;
  }

  .exit-workout-btn {
    border-radius: 0.5rem;
    max-width: 600px;
    margin: 0 auto;
    display: block;
    /* Reduce padding on mobile */
    padding: 0.5rem 1rem;
    /* Add subtle background */
    background: #f8f9fa;
    font-size: 0.95rem;
  }

  .exit-workout-btn:active {
    background: #e9ecef;
    transform: scale(0.98);
  }
}

/* Add specific iOS adjustments */
@supports (-webkit-touch-callout: none) {
  .exit-workout-container {
    /* Ensure consistent height on iOS */
    height: calc(3.5rem + env(safe-area-inset-bottom));
  }
}

/* Workout Complete Modal Styling */
.completion-icon {
  color: #ffc107;
  animation: trophy-bounce 1s ease;
}

@keyframes trophy-bounce {
  0% { transform: scale(0.3); opacity: 0; }
  50% { transform: scale(1.2); }
  70% { transform: scale(0.9); }
  100% { transform: scale(1); opacity: 1; }
}

.modal-body h4 {
  color: #495057;
  font-weight: 500;
  line-height: 1.4;
}

/* Ensure modal appears above other content */
.modal.show {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1060;
}

/* Add these styles to fix the iOS button highlight issue */
.d-flex.align-items-center.mt-3.mb-4 .btn {
  -webkit-tap-highlight-color: transparent;
  /* Update existing button styles */
  width: 44px !important;
  height: 44px !important;
  min-width: 44px !important;
  min-height: 44px !important;
  padding: 0 !important;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  border: none;
  background: white;
  box-shadow: 0 2px 6px rgba(0,0,0,0.08);
  flex: 0 0 auto;
  /* Add active state styles */
  &:active {
    background: #f8f9fa;
    transform: scale(0.95);
  }
}

/* Update primary button active state */
.d-flex.align-items-center.mt-3.mb-4 .btn-primary {
  &:active {
    background: #0b5ed7;
  }
}

/* Add this to ensure no gray highlight on any buttons */
button {
  -webkit-tap-highlight-color: transparent;
  &:active {
    background-color: inherit;
  }
}

/* Add specific active states for other buttons */
.action-btn:active,
.icon-button:active,
.category-btn:active,
.filter-btn:active {
  opacity: 0.8;
  transform: scale(0.98);
}

/* Add these styles */
.pause-settings {
  padding: 1rem;
}

.setting-group {
  margin-bottom: 1.5rem;
}

.setting-group:last-child {
  margin-bottom: 0;
}

.setting-group label {
  display: block;
  font-weight: 500;
  margin-bottom: 0.75rem;
  color: #495057;
}

.setting-group .form-select {
  width: 100%;
  padding-right: 0.75rem;
  border: 2px solid #e9ecef;
  border-radius: 0.75rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.setting-group .form-select:hover {
  border-color: #0d6efd;
}

.setting-group .form-select:focus {
  border-color: #0d6efd;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

@media (max-width: 768px) {
  .pause-settings {
    padding: 0.75rem;
  }

  .setting-group {
    margin-bottom: 1rem;
  }

  .setting-group .form-select {
    padding: 0.625rem;
  }
}

/* Add close icon styling */
.close-icon {
  font-size: 1.5rem;
  color: #495057;
  cursor: pointer;
  padding: 0.5rem;
  line-height: 0.7;
  transition: all 0.2s ease;
}

.close-icon:hover {
  color: #000;
  transform: scale(1.1);
}
</style>