<template>
  <div v-if="Object.keys(alternativeWorkouts).length > 0 || feelingButton == 'Krank' || feelingButton == 'Erschöpft'">
    <div v-if="Object.keys(alternativeWorkouts).length > 0 || feelingButton == 'Krank' || feelingButton == 'Erschöpft'" class="top-spacer-50">
      <h4>Training optimieren</h4>
    </div>


    <div class="adjusted-plan">

      <div v-if="sicknessNote && feelingButton != 'Krank'">
          <div class="alert alert-warning">
            <strong>Hinweis: Trainiere nicht, wenn du krank bist!</strong><br/> Da du zuletzt krank warst, empfehlen wir dir, die Intensität zu reduzieren und Workouts mit weniger Umfang zu trainieren.
            Hier findest du Vorschläge, die zum Wiedereinstieg passen können - eventuell ist es aber auch zu viel.
            Höre auf deinen Körper und trainiere nur, wenn du dich gut fühlst und achte darauf, dich nicht zu überlasten! Gib deinem Körper die Zeit, die er braucht. Sprich im Zweifel mit deinem Arzt.
          </div><br/>
        </div>
     
      <div v-if="feelingButton === 'Erschöpft' || feelingButton === 'Krank'" class="rest-container">
        <i class="fa fa-bed" aria-hidden="true"></i>
        <p>{{ $t("Ruhe dich heute aus!") }}</p>
        <div v-if="feelingButton == 'Krank'">
          Starte erst wieder mit dem Training, wenn du nicht mehr krank bist und dich gut fühlst! Ein zu frühes
          Training könnte die Erholung verzögern oder sogar deinen Krankheitsverlauf verschlimmern.
        </div><br />

        <button v-if="workouts.length > 0" @click="deleteAllWorkouts" class="delete" style="width: 250px" :disabled="isDeletingWorkouts">
          <span v-if="!isDeletingWorkouts">{{ $t("Heutige Workouts löschen") }}</span>
        </button>
      </div>

      <div v-if="workouts.length > 0">
      <div  v-for="(workout, index) in workouts" :key="workout._id" class="mb-4">

        
        <div
          v-if="alternativeWorkouts[workout._id] && alternativeWorkouts[workout._id].length > 0 && feelingButton != 'Krank'"
          class="row workout-row"
        >
          <div class="col-md-5 d-flex flex-column justify-content-center">
            <h6 class="d-none d-md-block">Aktuelles Workout</h6>
            <h4 @click="$emit('click')" class="workout-name">{{ workout.name }}</h4>
            <div
              class="workout-svg-container"
              v-if="
                workout.sport != 'Kraft' &&
                workout.sport != 'Schwimmen' &&
                workout.workoutFile &&
                workout.workoutFile.length != 0
              "
            >
              <workout-svg :workout-file="workout.workoutFile" @click="$emit('click')" />
            </div>
            <p class="top-spacer-15">
              <strong>Dauer:</strong>
              {{ formatDuration(workout.durationShould) }}
            </p>
            <intensity-distribution-view :workout="workout" :thresholds="thresholds" />
          </div>
          <div class="col-md-2 d-flex align-items-center justify-content-center">
            <i
              class="fa"
              :class="{ 'fa-arrow-right': !isMobile, 'fa-arrow-down': isMobile }"
              :style="{ 'font-size': '2em' }"
            ></i>
          </div>
          <div class="col-md-5 d-flex flex-column justify-content-center position-relative">
            <h6 class="d-none d-md-block">Alternative</h6>
            <button
              v-if="alternativeWorkouts[workout._id].length > 1"
              class="btn btn-link alternative-nav-btn alternative-nav-btn-left"
              @click="previousAlternative(workout._id)"
            >
              <i class="fa fa-chevron-left"></i>
            </button>
            <button
              v-if="alternativeWorkouts[workout._id].length > 1"
              class="btn btn-link alternative-nav-btn alternative-nav-btn-right"
              @click="nextAlternative(workout._id)"
            >
              <i class="fa fa-chevron-right"></i>
            </button>
            <h4 @click="$emit('click')" class="workout-name">{{ currentAlternative(workout._id).name }}</h4>
            <div
              class="workout-svg-container"
              v-if="
                currentAlternative(workout._id).sport != 'Kraft' &&
                currentAlternative(workout._id).sport != 'Schwimmen' &&
                currentAlternative(workout._id).workoutFile &&
                currentAlternative(workout._id).workoutFile.length != 0
              "
            >
              <workout-svg :workout-file="currentAlternative(workout._id).workoutFile" />
            </div>
            <p class="top-spacer-15">
              <strong>Dauer:</strong>
              {{ formatDuration(currentAlternative(workout._id).durationShould) }}
            </p>
            <intensity-distribution-view :workout="currentAlternative(workout._id)" :thresholds="thresholds" />
          </div>
          <div v-if="alternativeWorkouts[workout._id] && alternativeWorkouts[workout._id].length > 0" class="row mt-3">
         
        </div>
        <div class="col-12 text-center d-flex justify-content-center align-items-center top-spacer-25">
            <button style="margin: 3px; width: 200px" @click="acceptAlternative(workout._id)">
              <span v-if="!loadingWorkouts[workout._id]">Alternative akzeptieren</span>
              <span v-else class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>
            <button
              style="margin: 3px; width: 50px; min-width: 50px"
              class="delete"
              @click="discardAlternative(workout._id)"
              title="Vorschlag verwerfen"
            >
              <i class="fa fa-trash" aria-hidden="true"></i>
            </button>
          </div>
        </div>
       
        <div
          v-if="
            index < workouts.length - 1 &&
            alternativeWorkouts[workout._id] &&
            alternativeWorkouts[workout._id].length > 0
          "
          class="workout-separator"
        ></div>
      </div>
    </div>

      <div v-else>
        Das Training bleibt unverändert.
      </div>
    
      <div v-if="isDeletingWorkouts" class="spinner-border text-primary top-spacer-25" role="status"></div>
    </div>
  </div>
</template>

<script>
import IntensityDistributionView from "./IntensityDistributionView.vue";
import WorkoutSvg from "./WorkoutSvg.vue";
import axios from "axios";
import calendar_utils from "@/js/calendar_utils";
import AppleHealthService from "@/services/AppleHealthService";
import { Capacitor } from "@capacitor/core";

export default {
  name: "AdjustedWorkoutPlan",
  components: {
    IntensityDistributionView,
    WorkoutSvg,
  },
  data() {
    return {
      alternativeWorkouts: {},
      coachLoading: false,
      currentAlternativeIndices: {},
      showFeelingSpinner: false,
      isDeletingWorkouts: false,
      loadingWorkouts: {},
      sicknessNote: false
    };
  },
  props: {
    runThreshold: {
      type: Number,
      required: true,
    },
    feelingButton: {
      type: String,
      required: true,
    },
    workouts: {
      type: Array,
      required: true,
    },
    coachResults: {
      type: Object,
      required: true,
    },
    thresholds: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    this.fetchAlternativeWorkouts();
    this.showFeelingSpinner = true;
  },
  methods: {
    formatDuration(seconds) {
      const duration = this.$moment.duration(seconds, "seconds");
      const hours = duration.hours();
      const minutes = duration.minutes();
      const formattedSeconds = duration.seconds().toString().padStart(2, "0");
      return hours > 0
        ? `${hours}:${minutes.toString().padStart(2, "0")}:${formattedSeconds}`
        : `${minutes}:${formattedSeconds}`;
    },
    async getWorkouts(workout, optimalLoad) {
      this.coachLoading = true;

      try {

        if(workout.is_alternative) {
          return [];
        }

        const response = await axios.post(`${this.$host}/autocoach/get_workouts_new`, {
              sport: workout.sport,
              intensity: "LIT",
            });

            // Handle sickness
            if(response.data.sickDays > 3 || response.data.hasRecentSickDay) {
              this.sicknessNote = true;
            
              let coachWorkoutResults = response.data.workouts;

          // Filter out specific workout types
          coachWorkoutResults = coachWorkoutResults.filter((wo) => {
            return !["FTP", "Test", "Ruhe", "Race", "Wettkampf", "Vorbelastung", "Aktivierung"].some((term) =>
              wo.name.includes(term)
            );
          });

          let adjustLoadBySickness = 1;

          if(response.data.hasRecentSickDay) {
            adjustLoadBySickness = 0.55;
          } else if (response.data.sickDays > 0) {
            adjustLoadBySickness = 0.75;
          }

          // 
          let searchLoad = optimalLoad * adjustLoadBySickness;
          if(response.data.sickDays > 0 && searchLoad > 70) {
            searchLoad = 70;
          }
          // Filter workouts based on load
          coachWorkoutResults = coachWorkoutResults.filter(
            (wo) => wo.loadEstimate < searchLoad && wo.loadEstimate > searchLoad - 25
          );

          if(response.data.hasRecentSickDay) {
            // filter out all workouts that are not 100% LIT
            coachWorkoutResults = coachWorkoutResults.filter((wo) => wo.estimate_time_lit == 100);
          }
          else if(response.data.sickDays > 3) {
            // filter out all HIT Workouts, if more than 3 days sick in last 9 days
            coachWorkoutResults = coachWorkoutResults.filter((wo) => wo.estimate_time_lit > 95 && wo.estimate_time_hit == 0);
          }

          // Sort workouts based on similarity of MIT and HIT times to the original workout
          coachWorkoutResults.sort((a, b) => {
            const aDiffMIT = Math.abs(a.estimate_time_mit - workout.estimate_time_mit);
            const aDiffHIT = Math.abs(a.estimate_time_hit - workout.estimate_time_hit);
            const bDiffMIT = Math.abs(b.estimate_time_mit - workout.estimate_time_mit);
            const bDiffHIT = Math.abs(b.estimate_time_hit - workout.estimate_time_hit);
            return aDiffMIT + aDiffHIT - (bDiffMIT + bDiffHIT);
          });

          // Ensure only similar interval workouts are returned
          coachWorkoutResults = coachWorkoutResults.filter((wo) => {
            return !wo.workoutFile.some((segment) => segment.type === "IntervalsT" && segment.Repeat > 10);
          });

          // Ensure the alternative is not the same difficulty as the original workout
          coachWorkoutResults = coachWorkoutResults.filter((wo) => wo.loadEstimate != workout.loadEstimate);

  

          this.showFeelingSpinner = false;

          // Return the top 3 workouts
          return coachWorkoutResults.slice(0, 4);

        }

        if (
          workout.sport != "Schwimmen" &&
          workout.sport != "Kraft" &&
          // not ["FTP", "Test", "Ruhe", "Race", "Wettkampf", "Vorbelastung",
          !(
            workout.name.includes("FTP") ||
            workout.name.includes("Test") ||
            workout.name.includes("Ruhe") ||
            workout.name.includes("Race") ||
            workout.name.includes("Wettkampf") ||
            workout.name.includes("Vorbelastung") ||
            workout.name.includes("Aktivierung") ||
            workout.name.includes("Warm Up")
          )
        ) {
          let modifiedWorkout = JSON.parse(JSON.stringify(workout)); // Deep copy the workout
         

          if (this.feelingButton == "Müde") {
            // Fetch a LIT workout with less load

            let coachWorkoutResults = response.data.workouts;

            // Filter out specific workout types
            coachWorkoutResults = coachWorkoutResults.filter((wo) => {
              return !["FTP", "Test", "Ruhe", "Race", "Wettkampf", "Vorbelastung", "Aktivierung"].some((term) =>
                wo.name.includes(term)
              );
            });

            let adjustLoadBySickness = 1;

            if(response.data.hasRecentSickDay) {
              adjustLoadBySickness = 0.55;
            } else if (response.data.sickDays > 0) {
              adjustLoadBySickness = 0.75;
            }

            // 
            let searchLoad = optimalLoad * adjustLoadBySickness;
            if(response.data.sickDays > 0 && searchLoad > 60) {
              searchLoad = 60;
            }
            // Filter workouts based on load
            coachWorkoutResults = coachWorkoutResults.filter(
              (wo) => wo.loadEstimate < searchLoad && wo.loadEstimate > searchLoad - 25
            );

            // Sort workouts based on similarity of MIT and HIT times to the original workout
            coachWorkoutResults.sort((a, b) => {
              const aDiffMIT = Math.abs(a.estimate_time_mit - workout.estimate_time_mit);
              const aDiffHIT = Math.abs(a.estimate_time_hit - workout.estimate_time_hit);
              const bDiffMIT = Math.abs(b.estimate_time_mit - workout.estimate_time_mit);
              const bDiffHIT = Math.abs(b.estimate_time_hit - workout.estimate_time_hit);
              return aDiffMIT + aDiffHIT - (bDiffMIT + bDiffHIT);
            });

            // Ensure only similar interval workouts are returned
            coachWorkoutResults = coachWorkoutResults.filter((wo) => {
              return !wo.workoutFile.some((segment) => segment.type === "IntervalsT" && segment.Repeat > 10);
            });

            // Ensure the alternative is not the same difficulty as the original workout
            coachWorkoutResults = coachWorkoutResults.filter((wo) => wo.loadEstimate != workout.loadEstimate);

            this.showFeelingSpinner = false;

            // Return the top 3 workouts
            return coachWorkoutResults.slice(0, 4);
          } else if (this.feelingButton != "Normal") {
            const containsOnlySteadyStatesAndRamps = modifiedWorkout.workoutFile.every(
              (segment) => segment.type === "SteadyState" || segment.type === "Ramp" || segment.Repeat == 1
            );

            if (containsOnlySteadyStatesAndRamps) {
              console.log("Workout contains only steady states and/or ramps");
              // Increase the duration of the segments by 8% or 12%
              modifiedWorkout.workoutFile.forEach((segment) => {
                if (this.feelingButton == "Gut") {
                  segment.Duration = Math.round((segment.Duration * 1.08) / 60) * 60;
                } else if (this.feelingButton == "Exzellent") {
                  segment.Duration = Math.round((segment.Duration * 1.13) / 60) * 60;
                }
              });
            } else {
              // Iterate through the workoutFile and modify the repeats
              modifiedWorkout.workoutFile.forEach((segment) => {
                if (segment.type === "IntervalsT" && segment.Repeat > 1) {
                  if (this.feelingButton == "Gut") {
                    segment.Repeat += 1;
                  } else if (this.feelingButton == "Exzellent") {
                    segment.Repeat += 2;
                  }
                }
              });
            }

            // Recompute the duration
            modifiedWorkout.durationShould = this.totalWorkoutLength(modifiedWorkout.workoutFile);

            modifiedWorkout.loadEstimate = this.loadEstimate(
              modifiedWorkout.estimate_time_lit,
              modifiedWorkout.estimate_time_mit,
              modifiedWorkout.estimate_time_hit,
              modifiedWorkout.durationShould
            );

            // Generate a new workout title based on the workout file
            modifiedWorkout.name = this.generateWorkoutTitle(modifiedWorkout.workoutFile);

            this.showFeelingSpinner = false;

            // Return the modified workout as the only alternative
            return [modifiedWorkout];
          } else {
            return [];
          }
        } else {
          return [];
        }
      } catch (error) {
        console.error("Error modifying workouts:", error);
        return []; // Return an empty array in case of error
      } finally {
        this.coachLoading = false;
        this.showFeelingSpinner = false;
      }
    },
    currentAlternative(workoutId) {
      const alternatives = this.alternativeWorkouts[workoutId] || [];
      const index = this.currentAlternativeIndices[workoutId] || 0;
      return alternatives[index] || null;
    },
    currentAlternativeIndex(workoutId) {
      return this.currentAlternativeIndices[workoutId] || 0;
    },
    nextAlternative(workoutId) {
      const alternatives = this.alternativeWorkouts[workoutId] || [];
      if (alternatives.length > 0) {
        const currentIndex = this.currentAlternativeIndices[workoutId] || 0;
        this.currentAlternativeIndices = {
          ...this.currentAlternativeIndices,
          [workoutId]: (currentIndex + 1) % alternatives.length,
        };
      }
    },
    previousAlternative(workoutId) {
      const alternatives = this.alternativeWorkouts[workoutId] || [];
      if (alternatives.length > 0) {
        const currentIndex = this.currentAlternativeIndices[workoutId] || 0;
        this.currentAlternativeIndices = {
          ...this.currentAlternativeIndices,
          [workoutId]: (currentIndex - 1 + alternatives.length) % alternatives.length,
        };
      }
    },
    async fetchAlternativeWorkouts() {
      console.log("Fetching alternative workouts...");
      const alternativeWorkouts = {};

      for (const workout of this.workouts) {
        console.log(`Fetching alternatives for workout: ${workout._id}`);
        const alternatives = await this.getWorkouts(workout, workout.loadEstimate);
        console.log(`Alternatives received:`, alternatives);
        if (alternatives && alternatives.length > 0) {
          alternativeWorkouts[workout._id] = alternatives;
          this.currentAlternativeIndices[workout._id] = 0;
        }
      }
      this.alternativeWorkouts = alternativeWorkouts;
      console.log("All alternative workouts:", this.alternativeWorkouts);
    },

    async acceptAlternative(workoutId) {
      this.loadingWorkouts = { ...this.loadingWorkouts, [workoutId]: true };
      try {
        const alternative = this.currentAlternative(workoutId);
        await this.deleteWorkout(workoutId);
        await this.addWorkoutToCalendar(alternative);
        this.$emit("accepted-alternative");

        // delete key workoutId from alternativeWorkouts
        delete this.alternativeWorkouts[workoutId];
        this.$forceUpdate();
      } catch (error) {
        console.error("Error accepting alternative:", error);
      } finally {
        this.loadingWorkouts = { ...this.loadingWorkouts, [workoutId]: false };
      }
    },
    async deleteWorkout(workoutId) {
      this.showSpinner = true;

      if (workoutId) {
        try {
          await axios.delete(this.$host + "/workout/" + workoutId);
          if (Capacitor.getPlatform() === "ios") {
            await AppleHealthService.syncWorkoutsToApple();
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    totalWorkoutLength(workoutFile) {
      const _self = this;
      let length = workoutFile.reduce(function (prev, cur) {
        let distance = prev;
        let interval_distance = 0;

        if (!(cur.Distance > 0 || cur.OnDistance > 0 || cur.OffDistance > 0)) {
          distance +=
            (cur.Duration > 0 ? cur.Duration : 0) +
            (cur.Repeat > 0 ? (cur.OnDuration + cur.OffDuration) * cur.Repeat : 0);
        }

        if (cur.Distance > 0) {
          distance += Math.round((cur.Distance / 1000) * (_self.runThreshold / (cur.Power / 100)));
        }

        if (cur.OnDistance > 0) {
          interval_distance = Math.round((cur.OnDistance / 1000) * (_self.runThreshold / (cur.OnPower / 100)));

          distance += interval_distance * cur.Repeat;
          console.log("OnDistance", distance, interval_distance, cur, _self.runThreshold);
        }
        if (cur.OffDistance > 0) {
          interval_distance = Math.round((cur.OffDistance / 1000) * (_self.runThreshold / (cur.OffPower / 100)));

          distance += interval_distance * cur.Repeat;
        }
        return distance;
      }, 0);

      return length;
    },
    loadEstimate(lit, mit, hit, time) {
      let z1 = parseFloat(lit);
      let z2 = parseFloat(mit);
      let z3 = parseFloat(hit);

      time = parseFloat(time);

      if (!z1) {
        z1 = 0;
      }
      if (!z2) {
        z2 = 0;
      }
      if (!z3) {
        z3 = 0;
      }

      if (z1 == 0 && z2 == 0 && z3 == 0) {
        z2 = 100;
      }

      const totalIntensityPercent = 100 / (z1 + z2 + z3);

      z1 = Math.round(z1 * totalIntensityPercent);
      z2 = Math.round(z2 * totalIntensityPercent);
      z3 = Math.round(z3 * totalIntensityPercent);

      let pss = Math.round(((z1 * 0.7 + z2 * 1.2 + z3 * 2.2) / 100) * (time / 60)) + 1;

      return Math.round(pss);
    },
    async addWorkoutToCalendar(workout) {
      let tempWorkoutDuration = workout.durationShould;
      if (workout.sport == "Laufen" || workout.sport == "Radfahren") {
        tempWorkoutDuration = this.totalWorkoutLength(workout.workoutFile);
      }

      if (workout.sport == "Laufen") {
        workout.paceShould =
          calendar_utils.computeSpeedFromWorkoutFile(workout.workoutFile, this.thresholds.threshold_run) || 0;
      }

      if (workout.sport == "Laufen" && tempWorkoutDuration > 0 && workout.paceShould > 0) {
        workout.distanceShould = Math.round((tempWorkoutDuration / workout.paceShould) * 10) / 10;
      }

      let req = {
        name: workout.name,
        sport: workout.sport,
        description: workout.description,
        durationShould: tempWorkoutDuration,
        paceShould: workout.paceShould || 0,
        distanceShould: workout.distanceShould || 0,
        distance: workout.distance || 0,
        date: this.$moment(),
        workoutFile: workout.workoutFile,
        radraumWorkoutId: workout.radraumWorkoutId,
        is_alternative: true,
        durationIs: 0,
        paceIs: 0,
        youtubeEmbedLink: workout.youtubeEmbedLink,
        workout_blueprint: workout._id,
        isSuggestion: true,
        estimate_time_hit: workout.estimate_time_hit,
        estimate_time_lit: workout.estimate_time_lit,
        estimate_time_mit: workout.estimate_time_mit,
      };

      const _self = this;

      return axios
        .post(this.$host + "/workout", req)
        .then(async function () {
          _self.showFeelingForm = false;
        })
        .catch(function () {});
    },
    async deleteAllWorkouts() {
      this.isDeletingWorkouts = true;
      try {
        for (const workout of this.workouts) {
          await this.deleteWorkout(workout._id);
        }
        this.$emit("workouts-deleted");
      } catch (error) {
        console.error("Error deleting workouts:", error);
      } finally {
        this.isDeletingWorkouts = false;
      }
    },
    async discardAlternative(workoutId) {
      delete this.alternativeWorkouts[workoutId];

      await axios.post(this.$host + "/autocoach/discard_alternative", {workoutId: workoutId});

      this.$forceUpdate();
    },
    generateWorkoutTitle(workoutFile) {
      let title = "";
      let hasIntervals = false;
      let intervalCount = 0;
      let intervalDuration = 0;
      let intervalIntensity = 0;

      workoutFile.forEach((segment) => {
        if (segment.type === "IntervalsT") {
          hasIntervals = true;
          intervalCount += segment.Repeat;
          intervalDuration = segment.OnDuration;
          intervalIntensity = segment.OnPower;
        }
      });

      const totalDuration = this.totalWorkoutLength(workoutFile);
      const hours = Math.floor(totalDuration / 3600);
      const minutes = Math.round((totalDuration % 3600) / 60);

      // Add duration to the beginning of the title
      if (hours > 0) {
        title = `${hours}h${minutes}m`;
      } else {
        title = `${minutes}m`;
      }

      // Add interval details if present
      if (hasIntervals) {
        let intervalDurationFormatted;
        if (intervalDuration > 60) {
          const intervalMinutes = Math.floor(intervalDuration / 60);
          const intervalSeconds = intervalDuration % 60;
          intervalDurationFormatted =
            intervalSeconds > 0
              ? `${intervalMinutes}m${intervalSeconds.toString().padStart(2, "0")}s`
              : `${intervalMinutes}m`;
        } else {
          intervalDurationFormatted = `${intervalDuration}s`;
        }
        title += ` ${intervalCount}x${intervalDurationFormatted} @ ${intervalIntensity}%`;
      } else {
        title += ``;
      }

      return title;
    },
    calculateSegmentDuration(segment) {
      if (segment.type === "IntervalsT") {
        return (segment.OnDuration + segment.OffDuration) * segment.Repeat;
      } else {
        return segment.Duration || 0;
      }
    },
  },
  emits: ["showOverlay", "adjustWorkout", "click", "workouts-deleted", "discardAlternative"],
};
</script>

<style scoped>
.adjusted-plan {
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 25px;
  border: 1px solid #dee2e6;
}

.workout-row {
  min-height: 200px;
}

.workout-separator {
  height: 1px;
  background-color: #dee2e6;
  margin: 20px 0;
}

.rest-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 25px;
}

.rest-container i {
  font-size: 5em;
  color: #6c757d;
}

.rest-container p {
  margin-top: 1rem;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.adjust-workout-btn {
  width: 200px;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 25px;
  transition: all 0.3s ease;
  background-color: #28a745; /* Green color */
  border: none;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.adjust-workout-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  background-color: #218838; /* Darker green on hover */
}

.adjust-workout-btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5); /* Green focus ring */
}

@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }

  .col-md-5,
  .col-md-2 {
    width: 100%;
    margin-bottom: 1rem;
  }

  .fa-arrow-down {
    margin: 1rem 0;
  }

  .adjust-workout-btn {
    padding: 8px 16px;
    font-size: 0.9em;
  }
}

.position-relative {
  position: relative;
}

.alternative-nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 1.5em;
  color: #007bff;
  padding: 0;
  z-index: 1;
  outline: none; /* Add this line */
}

.alternative-nav-btn:hover {
  color: #0056b3;
}

.alternative-nav-btn:focus {
  outline: none; /* Add this line */
  box-shadow: none; /* Add this line */
}

.alternative-nav-btn-left {
  left: -20px;
}

.alternative-nav-btn-right {
  right: -20px;
}

@media (max-width: 768px) {
  .alternative-nav-btn-left {
    left: 0;
  }

  .alternative-nav-btn-right {
    right: 0;
  }
}

/* Add this new style for the adjust-workout-btn */
.adjust-workout-btn:focus {
  outline: none;
  box-shadow: none;
}

.delete-all-btn {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;
  background-color: #dc3545;
  border: none;
  color: white;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.delete-all-btn:hover {
  background-color: #c82333;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.discard-btn {
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  background-color: #f8f9fa;
  border-radius: 50%;
  font-size: 1.2em;
  color: #dc3545;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.discard-btn:hover {
  background-color: #dc3545;
  color: #fff;
}

.discard-btn:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.5);
}

.ml-2 {
  margin-left: 0.5rem;
}

.workout-name {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.delete:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

button {
  min-height: 38px; /* Adjust this value as needed */
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
</style>
